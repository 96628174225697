/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input, Select } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  getRouteDataRequest,
  setVisualizationState,
} from '@Store/actions/visualization';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useEffect, useState } from 'react';
import searchOSMQuery from '@Utils/searchOSMQuery';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { routeDropDownData } from '@Constants/visualization';
import SearchInput from '@Components/common/SearchInput';
import { useQuery } from '@tanstack/react-query';
import { getBuildingDataList } from '@Services/dataManagement';
import OSMSearch from './OSMSearch';

function RouteOverlay({
  popUpData,
  className,
}: {
  popUpData: Record<string, any>;
  className?: string;
}) {
  const dispatch = useTypedDispatch();

  const [queryData, setQueryData] = useState<Record<string, any>>([]);
  const [searchInput, setSearchInput] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string>('');
  const [showSearchByDropdown, setShowSearchByDropdown] =
    useState<boolean>(false);

  const locationCoordinates = useTypedSelector(
    state => state.visualization.locationCoordinates,
  );
  const selectedPlace = useTypedSelector(
    state => state.visualization.selectedPlace,
  );
  const searchBy = useTypedSelector(state => state.visualization.searchBy);
  const selectedBuilding = useTypedSelector(
    state => state.visualization.selectedBuilding,
  );

  // fetch building data list on search
  const { data: searchData } = useQuery({
    queryKey: ['map-search', searchInput],
    queryFn: () => getBuildingDataList({ search: searchInput }),
    select: res => res.data,
    enabled: !!searchInput,
  });

  // handles change in the search input and calls API likewise
  const handleChange = async (value: string) => {
    const params: Record<string, any> = {};
    params.q = value;
    params.format = 'json';
    params.limit = 5;

    const searchResults = await searchOSMQuery(params);

    const formattedSearchResults = searchResults.map(
      (result: Record<string, any>) => ({
        id: result.place_id,
        label: result.display_name,
        value: { longitude: +result.lon, latitude: +result.lat },
      }),
    );
    setQueryData({ results: formattedSearchResults });
  };

  // handles search result selects
  const handleSelect = (selected: Record<string, any>) => {
    dispatch(
      setVisualizationState({
        selectedPlace: selected,
        startDirectionCoordinates: selected.value,
      }),
    );
    dispatch(getRouteDataRequest());
    setSearchInput('');
    setIsOpen(false);
  };

  // getting the title of the selected building using the popUpData
  useEffect(() => {
    if (!popUpData) return;
    const isHousePopup = 'house_no' in popUpData;
    const titleOfHouse = isHousePopup
      ? `${popUpData?.house_no} - ${popUpData?.associate_road_name}`
      : popUpData?.road_name_en;
    setTitle(titleOfHouse);
  }, [popUpData]);

  return (
    <div
      className={`${className} naxatw-absolute naxatw-bottom-12 naxatw-left-4 naxatw-z-[150] naxatw-w-[17.5rem] naxatw-rounded-md naxatw-bg-white naxatw-p-3 naxatw-shadow-xl`}
    >
      <FlexRow className="naxatw-justify-between">
        <span className="naxatw-text-body-btn naxatw-text-primary-500">
          Route
        </span>
        <span
          role="button"
          tabIndex={0}
          className="naxatw-text-grey-600 hover:naxatw-text-primary-500"
          onClick={() =>
            dispatch(
              setVisualizationState({
                // setting everyting to default
                isRoutingOn: false,
                selectedPlace: {},
                locateOnMap: false,
                drawnCoordinate: null,
                searchBy: '',
                selectedBuilding: {},
              }),
            )
          }
          onKeyDown={() => {}}
        >
          <i className="material-icons">close</i>
        </span>
      </FlexRow>

      {/* dropdown only shown when the original div is clicked */}
      {showSearchByDropdown && (
        <div className="naxatw-mb-4 naxatw-animate-fade-up">
          <Select
            options={routeDropDownData}
            labelKey="name"
            valueKey="id"
            placeholder="Choose"
            onChange={selected => {
              dispatch(
                setVisualizationState({
                  searchBy: selected,
                  locateOnMap: selected === 'draw',
                }),
              );
              if (selected !== 'draw') {
                dispatch(setVisualizationState({ drawnCoordinate: null }));
              }
              if (selected === 'your location') {
                dispatch(
                  setVisualizationState({
                    startDirectionCoordinates: locationCoordinates,
                  }),
                );
                dispatch(getRouteDataRequest());
              }
            }}
            direction="top"
            selectedOption={searchBy}
          />
        </div>
      )}
      <FlexRow className="naxatw-gap-4">
        <FlexColumn className="naxatw-justify-center naxatw-py-3">
          <span
            role="button"
            tabIndex={0}
            className="naxatw-text-grey-600 hover:naxatw-text-primary-500"
            onClick={() =>
              dispatch(
                setVisualizationState({
                  isRoutingOn: false,
                  locateOnMap: false,
                }),
              )
            }
            onKeyDown={() => {}}
          >
            <i className="material-icons-outlined naxatw-text-[1.2rem]">
              circle
            </i>
          </span>
          <span className="naxatw-text-grey-600 hover:naxatw-text-primary-500">
            <i className="material-icons naxatw-text-[1.2rem]">more_vert</i>
          </span>
          <span className=" naxatw-text-grey-600 hover:naxatw-text-primary-500">
            <i className="material-icons naxatw-text-[1.2rem]">location_on</i>
          </span>
        </FlexColumn>
        <FlexColumn>
          {searchBy === '' && (
            <div
              onClick={() => {
                setShowSearchByDropdown(true);
                dispatch(setVisualizationState({ searchBy: 'places' }));
              }}
              className={`${
                showSearchByDropdown && 'naxatw-hidden'
              } naxatw-cursor-pointer naxatw-border-b-[2px] naxatw-border-gray-300 naxatw-px-2 naxatw-py-2 naxatw-font-primary naxatw-text-body-md hover:naxatw-border-primary-500`}
            >
              Choose a Point
            </div>
          )}
          {searchBy === 'your location' && (
            <div className="naxatw-cursor-pointer naxatw-border-b-[2px] naxatw-border-gray-300 naxatw-px-2 naxatw-py-2 naxatw-font-primary naxatw-text-body-md hover:naxatw-border-primary-500">
              Your Location
            </div>
          )}
          {searchBy === 'draw' && (
            <div
              onClick={() => {
                dispatch(setVisualizationState({ locateOnMap: true }));
              }}
              className="naxatw-cursor-pointer naxatw-border-b-[2px] naxatw-border-gray-300 naxatw-px-2 naxatw-py-2 naxatw-font-primary naxatw-text-body-md hover:naxatw-border-primary-500"
            >
              Draw a Point
            </div>
          )}
          {searchBy === 'places' && (
            <OSMSearch
              hasIcon={false}
              className="!naxatw-w-[12.6rem] naxatw-rounded-none naxatw-border-b-2 naxatw-border-grey-300 !naxatw-ring-0 hover:naxatw-border-primary-500"
              isOpen={isOpen}
              data={queryData}
              inputValue={searchInput}
              onChange={e => {
                handleChange(e.target.value);
                setSearchInput(e.target.value);
                setIsOpen(true);
              }}
              onSelect={selected => handleSelect(selected)}
              selectedValue={selectedPlace}
            />
          )}
          {searchBy === 'buildings' && (
            <SearchInput
              hasIcon={false}
              hasClearIcon={false}
              className="!naxatw-w-[12.6rem] !naxatw-rounded-none naxatw-border-b-2 naxatw-border-grey-300 naxatw-shadow-none !naxatw-ring-0 hover:naxatw-border-primary-500"
              isOpen={isOpen}
              data={searchData || []}
              inputValue={searchInput}
              onChange={e => {
                setSearchInput(e.target.value);
                setIsOpen(true);
              }}
              selectedValue={selectedBuilding}
              onSelect={selectedItem => {
                dispatch(
                  setVisualizationState({
                    selectedBuilding: selectedItem,
                    startDirectionCoordinates: {
                      latitude: selectedItem.lat_field,
                      longitude: selectedItem.long_field,
                    },
                  }),
                );
                dispatch(getRouteDataRequest());
                setSearchInput('');
                setIsOpen(false);
              }}
              onClear={() => {
                setSearchInput('');
              }}
            />
          )}
          <Input value={title} />
        </FlexColumn>
      </FlexRow>
    </div>
  );
}

export default hasErrorBoundary(RouteOverlay);
