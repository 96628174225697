import { api, authenticated } from '.';

export const getWardNoList = () =>
  authenticated(api).get('/dashboard/unique-ward-no/');

export const getPhysicalInstallation = (params: any) =>
  authenticated(api).get('/settings/physical-installation/latest/', { params });

export const getFeatureCount = (params: any) =>
  authenticated(api).get('/dashboard/feature-count/', {
    params,
  });

export const getRoadCount = (params: any) =>
  authenticated(api).get('/dashboard/road-count/', {
    params,
  });

export const getBuildingCount = (params: any) =>
  authenticated(api).get('/dashboard/building-count/', { params });

export const getBuildingRoadCount = (params: any) =>
  authenticated(api).get('/dashboard/building-field-count/', { params });

export const getPalikaWardList = (params: any) =>
  authenticated(api).get('/palika-ward/', { params });

export const getUserList = (params: any) =>
  authenticated(api).get('/user/user/', { params });

export const getUserLogs = (params: any) =>
  authenticated(api).get('/user/user/userlog/', { params });

export const getBuildingFloorCount = (params: any) =>
  authenticated(api).get('/dashboard/floor-count/', { params });
