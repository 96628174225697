/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { LngLat, LngLatBoundsLike } from 'maplibre-gl';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { togglePromptDialog } from '@Store/actions/common';
import {
  getMapPopupDataRequest,
  getRouteDataRequest,
  setVisualizationState,
} from '@Store/actions/visualization';
import {
  resetWorkshopModeState,
  setWorkshopModeState,
} from '@Store/actions/workshopMode';
import {
  setBuildingDataState,
  setRoadDataState,
} from '@Store/actions/dataManagement';
import { selectedWardSelector } from '@Store/selector/common';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import { createQueryParams } from '@Utils/createQueryParams';
import checkIfLoading from '@Utils/checkIfLoading';
import prepareQueryParam from '@Utils/prepareQueryParam';
import { useGenerateHouseNumbersQuery } from '@Api/visualization';
import { apiURL } from '@Services/index';
import {
  getBuildingPlanBoundary,
  getFilteredOverallBoundary,
} from '@Services/visualization';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import changeLayerOrder from '@Components/common/MapLibreComponents/helpers/changeLayerOrder';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import CircleMarker from '@Components/common/MapLibreComponents/Layers/CircleMarker';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import AsyncPopup from '@Components/common/MapLibreComponents/AsyncPopup';
import PopupUI from '@Components/common/MapLibreComponents/PopupUI';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  buildingStyles,
  drawStyles,
  getBuildingColor,
  getRoadColor,
  roadStyles,
  userInputColor,
  userLocationColor,
} from '@Constants/map';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@Components/common/MapLibreComponents/map.css';
import MapFooter from '@Components/common/MapLibreComponents/MapFooter';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import MeasureTool from '@Components/common/MapLibreComponents/MeasureTool';
import PlotPoint from '@Components/common/MapLibreComponents/CustomLayers/PlotPoint';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import RoadVTLayer from '@Components/common/MapLibreComponents/CustomLayers/RoadVTLayer';
import OLVectorTileLayer from '@Components/DataManagement/OverlayLayers/MultiStepForm/MapSection/OLVectorTileLayer';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import MapPopup from '@Components/common/MapLibreComponents/MapPopup';
import { useGetPalikaWardGeojsonQuery } from '@Api/common';
import {
  resetDataManagementFormState,
  setDataManagementFormState,
} from '@Store/actions/dataManagementForm';
import useDrawTool from '@Components/common/MapLibreComponents/useDrawTool';
import { updatePalikaWardBoundary } from '@Services/common';
import { geojsonToWKT } from '@terraformer/wkt';
import { Button } from '@Components/RadixComponents/Button';
import RouteOverlay from './RouteOverlay';
import MapTools from '../MapTools';
import MapExportSection from './ExportSection';
import SearchFilterSection from './SearchFilterSection/index';
import FilterSection from '../FilterSection';

/**
 * render popup title for house/road layer
 * @param {object} popupData - json containing popup data
 */
function getPopupTitle(popupData: Record<string, any> | null): string {
  if (!popupData) return '';
  const isHousePopup = 'house_no' in popupData;
  return isHousePopup
    ? `${popupData?.house_no} - ${popupData?.associate_road_name}`
    : popupData?.road_name_en;
}

function MapSection() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const [isBuildingLayerLoaded, setIsBuildingLayerLoaded] = useState(false);
  const [isRoadLayerLoaded, setIsRoadLayerLoaded] = useState(false);
  const [isWardBoundaryLayerLoaded, setIsWardBoundaryLayerLoaded] =
    useState(false);
  const [selectedWardBoundary, setSelectedWardBoundary] = useState(null);
  const [selectedWardBoundaryId, setSelectedWardBoundaryId] = useState(null);

  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );
  const isFullScreenToggled = useTypedSelector(
    state => state.visualization.isFullScreenToggled,
  );
  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 1,
      center: [84.124, 28.3949],
      pitch: 60,
      maxZoom: 19,
      transformRequest: url => {
        if (url.includes('localhost:7767')) {
          return {
            url,
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
            credentials: 'include', // Include cookies for cross-origin requests
          };
        }
        // Otherwise, return the request unmodified
        return { url };
      },
    },
    enable3D: is3DToggled,
    fullscreen: isFullScreenToggled,
  });
  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );
  const layers = useTypedSelector(state => state.visualization.layers);
  const popupData = useTypedSelector(state => state.visualization.popupData);
  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);
  const layerOrderChanged = useTypedSelector(
    state => state.visualization.layerOrderChanged,
  );
  const isPopupDataFetching = useTypedSelector(state =>
    checkIfLoading(state, [getMapPopupDataRequest.type]),
  );
  const changeStyle = useTypedSelector(
    state => state.visualization.changeStyle,
  );
  const selectedFeature = useTypedSelector(
    state => state.visualization.selectedFeature,
  );
  const geolocation = useTypedSelector(
    state => state.visualization.geolocation,
  );
  const zoomToGeolocation = useTypedSelector(
    state => state.visualization.zoomToGeolocation,
  );
  const inputCoordinates = useTypedSelector(
    state => state.visualization.inputCoordinates,
  );
  const zoomToLocationType = useTypedSelector(
    state => state.visualization.zoomToLocationType,
  );
  const isExportOn = useTypedSelector(state => state.mapExport.isExportOn);

  const isRoutingOn = useTypedSelector(
    state => state.visualization.isRoutingOn,
  );
  const locateOnMap = useTypedSelector(
    state => state.visualization.locateOnMap,
  );
  const routeData = useTypedSelector(state => state.visualization.routesData);
  const expandFilter = useTypedSelector(
    state => state.advanceFilter.expandFilter,
  );
  const queryExpression = useTypedSelector(
    state => state.advanceFilter.queryExpression,
  );
  const basicFilterParams = useTypedSelector(
    state => state.advanceFilter.basicFilterParams,
  );
  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );
  const measureType = useTypedSelector(state => state.workshopMode.measureType);
  const drawnCoordinate = useTypedSelector(
    state => state.visualization.drawnCoordinate,
  );
  const previousMapBounds = useTypedSelector(
    state => state.visualization.previousMapBounds,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.visualization.selectedBuildingId,
  );
  const refreshLayerId = useTypedSelector(
    state => state.workshopMode.refreshLayerId,
  );
  const selectedWards = useTypedSelector(selectedWardSelector);
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);
  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const hasGeomChanged = useTypedSelector(
    state => state.dataManagementForm.hasGeomChanged,
  );

  const redirectPath = { state: { redirectPath: pathname } };

  const isFilterApplied =
    !!basicFilterParams.ward_no?.length ||
    !!basicFilterParams.tole_name?.length ||
    !!basicFilterParams.owner_status?.length ||
    !!basicFilterParams.reg_type?.length;

  // fetch filtered-overall-boundary
  const { data: overallBbox } = useQuery({
    queryKey: ['filtered-overall-boundary', selectedLayerId, queryExpression],
    queryFn: () =>
      getFilteredOverallBoundary({
        layer: selectedLayerId,
        expression: JSON.stringify(queryExpression),
      }),
    select: res => res.data,
    enabled: !!queryExpression,
  });

  // fetch building-plan-boundary
  const { data: buildingPlanBbox } = useQuery({
    queryKey: ['getBuildingPlanBoundary', selectedLayerId, basicFilterParams],
    queryFn: () =>
      getBuildingPlanBoundary({
        layer: selectedLayerId,
        ...(basicFilterParams ? prepareQueryParam(basicFilterParams) : {}),
      }),
    select: res => res.data,
    enabled: !!isFilterApplied,
  });

  // generate house number api
  useGenerateHouseNumbersQuery(selectedBuildingId, {
    enabled: !!selectedBuildingId,
    onSuccess: () => {
      dispatch(setWorkshopModeState({ refreshLayerId: 'building' }));
    },
  });

  // set isBuildingLayerLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'building') {
        // setIsBuildingLayerLoaded to true if ev has tile property
        setIsBuildingLayerLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // check if road layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'road') {
        setIsRoadLayerLoaded(true);
      }
    });
  }, [map]);

  // zoom to palika boundary on initial map load
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !buildingBbox ||
      queryExpression ||
      isFilterApplied ||
      previousMapBounds // prevent zoom to palika boundary when there is previous map bounding box extent
    )
      return () => {};
    const { data } = buildingBbox;
    if (!data) return () => {};
    const [x1, y1, x2, y2] = data;
    const timer = setTimeout(() => {
      map.fitBounds(
        [
          [x1, y1],
          [x2, y2],
        ],
        { duration: 0 },
      );
    }, 0);
    return () => clearTimeout(timer);
  }, [
    map,
    isMapLoaded,
    buildingBbox,
    queryExpression,
    isFilterApplied,
    previousMapBounds,
  ]);

  // set current bbox on unmount
  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};
    return () => {
      const currentBound = map.getBounds().toArray();
      dispatch(setVisualizationState({ previousMapBounds: currentBound }));
    };
  }, [map, isMapLoaded, dispatch]);

  // zoom to previous map extent
  useEffect(() => {
    if (
      !map ||
      !isMapLoaded ||
      !previousMapBounds ||
      queryExpression ||
      isFilterApplied
    )
      return () => {};
    // for letting zoom to previous zoom extent
    const timer = setTimeout(() => {
      map.fitBounds(previousMapBounds as LngLatBoundsLike, {
        duration: 0,
      });
    }, 300);
    return () => clearTimeout(timer);
  }, [
    map,
    isMapLoaded,
    previousMapBounds,
    overallBbox,
    isFilterApplied,
    queryExpression,
  ]);

  // zoom to filtered overall boundary extent on apply advance filter
  useEffect(() => {
    if (!map || !isMapLoaded || !overallBbox) return;
    // for letting zoom to overall bbox
    map.fitBounds(overallBbox, { duration: 0 });
  }, [map, isMapLoaded, overallBbox]);

  // zoom to filtered building plan extent on apply basic filter
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingPlanBbox) return () => {};
    // for letting zoom to building plan bbox
    const timer = setTimeout(() => {
      map.fitBounds(buildingPlanBbox, { duration: 0 });
    }, 300);
    // cleanup timer on unmount
    return () => clearTimeout(timer);
  }, [map, isMapLoaded, buildingPlanBbox]);

  // handle layer order change
  useEffect(() => {
    if (!map || !layerOrderChanged) return;
    const [from, to] = layerOrderChanged;
    if (!from) return;
    changeLayerOrder(map, { id: from.toString(), beforeId: to.toString() });
  }, [map, layerOrderChanged]);

  // change layer styles
  useEffect(() => {
    if (!map || !changeStyle.layerId) return;
    Object.entries(changeStyle.styles).forEach(([key, val]) => {
      map.setPaintProperty(changeStyle.layerId, key, val);
    });
  }, [map, changeStyle]);

  // handle 3D toggle & pitch change
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded) return;
    map.setPaintProperty(
      'building',
      'fill-extrusion-height',
      is3DToggled ? buildingStyles.paint['fill-extrusion-height'] : 0,
    );
    // set building opacity to 1 on 3D toggle
    map.setPaintProperty(
      'building',
      'fill-extrusion-opacity',
      is3DToggled ? 0.9 : 0.5,
    );
    setTimeout(() => {
      map.easeTo({
        duration: 1000,
        pitch: is3DToggled ? 60 : 0,
      });
    }, 10);
  }, [map, is3DToggled, isBuildingLayerLoaded]);

  // zoom to geolocation
  useEffect(() => {
    if (!map || !geolocation || !zoomToGeolocation) return;
    map.flyTo({
      center: [geolocation.longitude, geolocation.latitude],
      zoom: 14,
    });
    dispatch(setVisualizationState({ zoomToGeolocation: false }));
  }, [dispatch, map, geolocation, zoomToGeolocation]);

  // zoom to user input coordinates
  useEffect(() => {
    if (!map || !inputCoordinates.length) return;
    const [lat, lng] = inputCoordinates;
    map.flyTo({
      center: new LngLat(lng, lat),
      zoom: 14,
    });
  }, [dispatch, map, inputCoordinates]);

  // cache geolocation coordinates
  const geolocationCoords: [number, number] | null = useMemo(
    () => (geolocation ? [geolocation.longitude, geolocation.latitude] : null),
    [geolocation],
  );

  // zoom to selected feature on search
  useEffect(() => {
    if (!selectedFeature?.bbox || !map || !isMapLoaded) return () => {};
    // for letting zoom to searched building
    const timer = setTimeout(() => {
      map.fitBounds(selectedFeature.bbox, { maxZoom: 18 });
    }, 300);
    // cleanup timer on unmount
    return () => clearTimeout(timer);
  }, [map, isMapLoaded, selectedFeature]);

  // highlight only selected building on map
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || selectedFeature?.layer !== 'building')
      return () => {};
    const buildingColor = getBuildingColor(selectedFeature.association_type);
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'building_pk'],
      selectedFeature.id,
      buildingColor,
      '#D7D7D7',
    ]);
    // color back building unmount
    return () => {
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [map, isBuildingLayerLoaded, selectedFeature]);

  // highlight only selected road on map
  useEffect(() => {
    if (!map || !isRoadLayerLoaded || selectedFeature?.layer !== 'road')
      return () => {};
    const roadColor = getRoadColor(selectedFeature.road_category);
    map.setPaintProperty('road', 'line-color', [
      'match',
      ['get', 'road_pk'],
      selectedFeature.id,
      roadColor,
      '#D7D7D7',
    ]);
    // color back road after unmount
    return () => {
      map.setPaintProperty(
        'road',
        'line-color',
        roadStyles.paint['line-color'],
      );
    };
  }, [map, isRoadLayerLoaded, selectedFeature]);

  // getting current users coordinates when routing feature in turned on
  useEffect(() => {
    if (!map && !isRoutingOn) return;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        dispatch(
          setVisualizationState({
            startDirectionCoordinates: position.coords,
            locationCoordinates: position.coords,
          }),
        );
      });
    }
  }, [map, isRoutingOn, dispatch]);

  // get route when the drawnCoordinate is changed and not null
  useEffect(() => {
    if (!locateOnMap || !drawnCoordinate) return;
    dispatch(
      setVisualizationState({ startDirectionCoordinates: drawnCoordinate }),
    );
    dispatch(getRouteDataRequest());
  }, [dispatch, drawnCoordinate, locateOnMap]);

  useEffect(() => {
    if (!routeData || !map || !isMapLoaded) return () => {};
    const timer = setTimeout(() => {
      map.fitBounds(routeData.bbox, { maxZoom: 18, duration: 0 });
    }, 300);
    // cleanup timer on unmount
    return () => clearTimeout(timer);
  }, [routeData, map, isMapLoaded]);

  // reset refreshLayerId after layer refresh
  useEffect(() => {
    if (!refreshLayerId) return;
    dispatch(setWorkshopModeState({ refreshLayerId: null }));
  }, [dispatch, refreshLayerId]);

  // check if palika ward boundary layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'palika-ward-boundary') {
        setIsWardBoundaryLayerLoaded(true);
      }
    });
  }, [map]);

  // fetch palika-ward-geojson
  const {
    data: fetchedPalikaBoundaryGeojson,
    isFetching: isPalikaBoundaryFetching,
  } = useGetPalikaWardGeojsonQuery(selectedWardBoundary);

  // update drawnGeom on  palika-ward-geojson change
  const handleDrawEnd = useCallback(
    (geojson: GeojsonType | null) => {
      dispatch(
        setDataManagementFormState({
          drawnGeom: geojson,
          hasGeomChanged: !!geojson,
        }),
      );
    },
    [dispatch],
  );

  // enable edit feture for palika ward boundary
  useDrawTool({
    map,
    enable: !measureType && !!drawType && isWardBoundaryLayerLoaded,
    drawMode: drawType,
    styles: drawStyles,
    geojson: fetchedPalikaBoundaryGeojson,
    onDrawEnd: handleDrawEnd,
  });

  // update  palika ward boundary layer on edit feature
  const { mutate, isLoading: isUpdatingWardBoundary } = useMutation<
    any,
    any,
    any,
    unknown
  >({
    mutationFn: payloadData =>
      updatePalikaWardBoundary(selectedWardBoundaryId, {
        geom: geojsonToWKT(payloadData.features[0].geometry),
      }),
    onSuccess: res => {
      // refresh palika ward boundary layer on successs
      dispatch(
        setWorkshopModeState({ refreshLayerId: 'palika-ward-boundary' }),
      );
      // reset values on onSuccess
      dispatch(resetDataManagementFormState());
      setSelectedWardBoundary(null);
      setSelectedWardBoundaryId(null);
      toast.success(res.data.message);
    },
    onError: (error: any) => {
      toast.error(
        `${error.response.data.message}: ${error.response.data.details}`,
      );
    },
  });

  const handleClose = () => {
    dispatch(resetDataManagementFormState());
    setSelectedWardBoundary(null);
    setSelectedWardBoundaryId(null);
  };

  const updatePalikaBoundary = () => {
    mutate(drawnGeom);
  };

  // reset workshopmode state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetWorkshopModeState());
    };
  }, [dispatch]);

  const getPopupUI = useCallback(() => {
    return isPopupDataFetching ? (
      // display skeleton loader when fetching data
      <FlexColumn gap={3} className="naxatw-mb-12">
        {Array.from({ length: 7 }, (_, index) => (
          <Skeleton
            key={index}
            className="naxatw-h-4 naxatw-w-full naxatw-rounded-md naxatw-bg-grey-100 naxatw-shadow-sm"
          />
        ))}
      </FlexColumn>
    ) : (
      <PopupUI data={popupData} />
    );
  }, [popupData, isPopupDataFetching]);

  const isLoading = useDebouncedLoadingState(
    !isBuildingLayerLoaded ||
      !isRoadLayerLoaded ||
      !isMapLoaded ||
      !isWardBoundaryLayerLoaded ||
      isPalikaBoundaryFetching,
  );

  return (
    <>
      <FilterSection />

      <div
        className={`${
          expandFilter ? 'naxatw-w-0' : 'naxatw-w-full'
        } naxatw-relative naxatw-animate-fade-down`}
      >
        {/* map loadder */}
        {isLoading && <MapLoader />}

        {/* search input / search filter section */}
        {!expandFilter && <SearchFilterSection />}

        <FlexRow
          gap={5}
          className="naxatw-absolute naxatw-bottom-10 naxatw-left-5 naxatw-z-20"
        >
          {hasGeomChanged && (
            <Button
              onClick={updatePalikaBoundary}
              isLoading={isUpdatingWardBoundary}
            >
              Update Ward Boundary
            </Button>
          )}
          {drawType && (
            <Button variant="outline" onClick={handleClose}>
              Cancel
            </Button>
          )}
        </FlexRow>

        {/* map section */}
        <MapContainer
          map={map}
          isMapLoaded={isMapLoaded}
          style={{ width: '100%', height: '100vh' }}
        >
          {/* add base layer switcher */}
          <BaseLayerSwitcher activeLayer={activeBaseLayer} />

          {/* reverse layers order for z-index */}
          {[...layers]
            .reverse()
            .map(
              ({
                id,
                endPoint,
                type,
                checked,
                styles,
                showLabel,
                showOutline,
                dynamicStyle,
                isSubLayerActive,
                dynamicSubLayerStyle,
              }) =>
                id === 'road' ? (
                  <RoadVTLayer
                    key={id}
                    id={id}
                    url={
                      queryExpression && selectedLayerId === 'road'
                        ? `${apiURL}/advance-filter-road-vectortile/{z}/{x}/{y}/?expression=${JSON.stringify(
                            queryExpression,
                          )}`
                        : selectedWards.length
                          ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                              ',',
                            )}`
                          : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                    }
                    layerOptions={styles}
                    visibleOnMap={checked}
                    showLabel={showLabel}
                    interactions={['select']} // add cursor pointer on layer hover
                    refreshLayer={refreshLayerId === 'road'}
                  />
                ) : type === 'overlay-layer' ? (
                  <OLVectorTileLayer
                    key={id}
                    id={`overlay-layer-${id}`}
                    visibleOnMap={checked}
                    layers={
                      isSubLayerActive
                        ? (dynamicSubLayerStyle as Record<string, any>[])
                        : (dynamicStyle as Record<string, any>[])
                    }
                    url={`${apiURL}/vector-layer/{z}/{x}/{y}/?vector_layer_id=${id}`}
                  />
                ) : (
                  <VectorTileLayer
                    key={id}
                    id={id}
                    url={
                      // advance filter
                      queryExpression &&
                      selectedLayerId === 'building' &&
                      id === 'building'
                        ? `${apiURL}/advance-filter-building-vectortile/{z}/{x}/{y}/?expression=${JSON.stringify(
                            queryExpression,
                          )}`
                        : // basic filter
                          isFilterApplied && id === 'building'
                          ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?${createQueryParams(
                              basicFilterParams,
                            )}`
                          : // overlay layer
                            type === 'overlay-layer'
                            ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?vector_layer_id=${id}`
                            : selectedWards.length
                              ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                                  ',',
                                )}`
                              : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                    }
                    layerOptions={styles}
                    visibleOnMap={checked}
                    showLabel={
                      id === 'building' && is3DToggled ? false : showLabel
                    }
                    showOutline={!is3DToggled && !!showOutline}
                    interactions={['select']} // add cursor pointer on layer hover
                    refreshLayer={refreshLayerId === id}
                  />
                ),
            )}

          {/* render map popup component for road and building layers only */}
          <AsyncPopup
            fetchPopupData={properties => {
              dispatch(
                getMapPopupDataRequest({
                  id:
                    properties.layer === 'building'
                      ? properties.building_pk
                      : properties.layer === 'road'
                        ? properties.road_pk
                        : properties.id,
                  type: properties.layer,
                }),
              );
            }}
            popupUI={getPopupUI}
            title={getPopupTitle(popupData)}
            handleBtnClick={properties => {
              const { layer } = properties;
              if (layer === 'building') {
                navigate(`building-detail/${properties.building_pk}`);
              } else if (layer === 'road') {
                navigate(`road-detail/${properties.road_pk}`);
              }
            }}
            handleEdit={properties => {
              const { layer } = properties;
              if (layer === 'building') {
                navigate(
                  `/data-management/building-data/form/${properties.building_pk}`,
                  redirectPath,
                );
              } else if (layer === 'road') {
                navigate(
                  `/data-management/road-data/form/${properties.road_pk}`,
                  redirectPath,
                );
              }
            }}
            handleDelete={properties => {
              const { layer } = properties;
              if (layer === 'building') {
                dispatch(togglePromptDialog('delete-building-data'));
                dispatch(
                  setBuildingDataState({
                    selectedBuilding: {
                      ...properties,
                      id: properties?.building_pk,
                    },
                  }),
                );
              } else if (layer === 'road') {
                dispatch(togglePromptDialog('delete-road-data'));
                dispatch(
                  setRoadDataState({
                    selectedRoad: {
                      ...properties,
                      id: properties?.road_pk,
                    },
                  }),
                );
              }
            }}
            handleShowHistory={properties => {
              const { layer } = properties;
              if (layer === 'building') {
                navigate(`building-edit-history/${properties.building_pk}`);
              } else if (layer === 'road') {
                navigate(`road-edit-history/${properties.road_pk}`);
              }
            }}
            handleGenerateHouse={properties => {
              const { layer } = properties;
              if (layer === 'building') {
                dispatch(
                  setVisualizationState({
                    selectedBuildingId: properties.building_pk,
                  }),
                );
              }
            }}
            isLoading={isPopupDataFetching}
            onClose={() => {
              dispatch(setVisualizationState({ popupData: null }));
            }}
            enable={!measureType && !locateOnMap && !drawType}
            onRoutingBtnClick={coordinates => {
              dispatch(
                setVisualizationState({
                  isRoutingOn: true,
                  endDirectionCoordinates: coordinates,
                }),
              );
              dispatch(getRouteDataRequest());
            }}
            showPopupFor={['building', 'road']}
          />
          <MapPopup
            showPopupFor={['palika-ward-boundary']}
            enable={!drawType}
            handleEdit={properties => {
              setSelectedWardBoundary(properties.ward_no);
              setSelectedWardBoundaryId(properties.id);
              dispatch(
                setDataManagementFormState({
                  drawType: 'simple_select',
                }),
              );
            }}
          />

          {isRoutingOn && (
            <VectorLayer
              key="route-layer"
              id="route-layer"
              geojson={routeData as GeojsonType}
              layerOptions={{
                paint: {
                  'line-color': '#ff474c',
                  'line-width': 6,
                },
              }}
              visibleOnMap
            />
          )}

          {isRoutingOn && (
            <RouteOverlay popUpData={popupData as Record<string, any>} />
          )}

          {locateOnMap && (
            <PlotPoint
              enabled={locateOnMap}
              onClick={(coordinates: Record<string, any>) => {
                dispatch(
                  setVisualizationState({ drawnCoordinate: coordinates }),
                );
              }}
            />
          )}

          {/* plot circle marker on user location enable */}
          {geolocationCoords && (
            <CircleMarker
              id="geolocation"
              coordinates={geolocationCoords}
              zoomToLayer={zoomToLocationType === 'geolocation'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userLocationColor,
                },
              }}
            />
          )}

          {drawnCoordinate !== null && (
            <CircleMarker
              id="routeCoordinates"
              coordinates={[
                drawnCoordinate.longitude,
                drawnCoordinate.latitude,
              ]}
              zoomToLayer={zoomToLocationType === 'user-input'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': '#0000ff',
                },
              }}
            />
          )}

          {/* plot circle marker on user location enable */}
          {inputCoordinates.length && (
            <CircleMarker
              id="inputCoordinates"
              coordinates={[inputCoordinates[1], inputCoordinates[0]]}
              zoomToLayer={zoomToLocationType === 'user-input'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userInputColor,
                },
              }}
            />
          )}

          {/* map tools */}
          <MapTools />

          {/* measure tool */}
          <MeasureTool
            enable={!!measureType}
            measureType={measureType}
            onDrawChange={result => {
              dispatch(setWorkshopModeState({ measureResult: result }));
            }}
          />

          {/* map export section */}
          {isExportOn && <MapExportSection map={map} />}

          {/* map footer */}
          <MapFooter />
        </MapContainer>
      </div>
    </>
  );
}

export default hasErrorBoundary(MapSection);
