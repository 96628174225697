/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { LegacyRef, useEffect, useLayoutEffect, useState } from 'react';
import Icon from '@Components/common/Icon';
import useOutsideClick from '@Hooks/useOutsideClick';
import Input from '../Input';

interface ISelectProps {
  options: Record<string, any>[];
  selectedOption?: string | number | null;
  placeholder?: string;
  onChange?: (selectedOption: any) => void;
  labelKey?: string;
  valueKey?: string;
  direction?: string;
  className?: string;
  withSearch?: boolean;
  inputTagClassname?: string;
}

function getPosition(direction: string) {
  switch (direction) {
    case 'top':
      return 'naxatw-bottom-[2.4rem]';
    case 'bottom':
      return 'naxatw-top-[2.8rem]';
    default:
      return 'naxatw-top-[3rem]';
  }
}

export default function Select({
  options,
  selectedOption,
  onChange,
  placeholder = 'Select',
  labelKey = 'label',
  valueKey = 'value',
  direction = 'bottom',
  className,
  withSearch = false,
  inputTagClassname,
}: ISelectProps) {
  const [selected, setSelected] = useState(selectedOption);
  const [position, setPosition] = useState(direction);
  const [searchText, setSearchText] = useState('');
  const [dropdownRef, isOpen, handleToggle] = useOutsideClick();

  useEffect(() => {
    setSelected(selectedOption);
  }, [selectedOption]);

  const handleOptionClick = (value: string) => {
    setSelected(value);
    // @ts-ignore
    onChange(value);
  };

  // check if selected option value matches with item value key
  const selectedLabel = options.find(item => item[valueKey] === selected)?.[
    labelKey
  ];

  const getPlaceholderText = () => {
    if (selected) {
      return selectedLabel || placeholder;
    }
    return placeholder;
  };

  const filterOptions = options?.filter(opt =>
    opt[labelKey].toLowerCase().includes(searchText.toLowerCase()),
  );

  const showClearIcon = !!searchText.length;

  useLayoutEffect(() => {
    if (!isOpen) return;
    // @ts-ignore
    const { bottom } = dropdownRef?.current?.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (windowHeight / 2 < bottom - 120) {
      setPosition('top');
    } else {
      setPosition('bottom');
    }
  }, [isOpen, dropdownRef]);

  return (
    <div className="naxatw-relative">
      <div
        ref={dropdownRef as LegacyRef<HTMLDivElement>}
        className={`naxatw-group naxatw-relative naxatw-flex naxatw-h-11 naxatw-w-full naxatw-cursor-pointer naxatw-items-center naxatw-justify-between naxatw-border-b-2
        hover:naxatw-border-blue-400
        ${className}`}
        onClick={withSearch ? () => {} : () => handleToggle()}
      >
        {withSearch ? (
          <Input
            type="text"
            placeholder={getPlaceholderText()}
            className={`naxatw-w-full naxatw-border-none ${inputTagClassname} ${
              selected ? 'placeholder:naxatw-text-grey-800' : ''
            } focus:placeholder:naxatw-text-grey-400 `}
            value={searchText}
            onFocus={e => {
              e.stopPropagation();
              handleToggle();
            }}
            onChange={e => {
              setSearchText(e.target.value);
            }}
          />
        ) : (
          <p
            className={`naxatw-w-full naxatw-border-none ${
              selected && selectedLabel ? 'naxatw-text-grey-800' : ''
            } naxatw-px-2 naxatw-text-sm naxatw-text-grey-400`}
          >
            {getPlaceholderText()}
          </p>
        )}

        {showClearIcon ? (
          <Icon
            name="clear"
            className="naxatw-absolute naxatw-right-0 naxatw-items-center 
              !naxatw-text-base hover:naxatw-text-primary-400"
            onClick={() => setSearchText('')}
          />
        ) : (
          <Icon
            name={
              !isOpen ? 'expand_more' : withSearch ? 'search' : 'expand_less'
            }
            className="naxatw-absolute naxatw-right-1 naxatw-items-center group-hover:naxatw-text-primary-400"
          />
        )}
      </div>

      {isOpen && (
        <ul
          className={`scrollbar naxatw-absolute  naxatw-z-20 naxatw-flex naxatw-max-h-[150px] naxatw-w-full
           naxatw-animate-flip-down naxatw-flex-col naxatw-overflow-auto naxatw-rounded-md naxatw-border naxatw-bg-white naxatw-shadow-lg
             naxatw-duration-300 ${getPosition(position)} `}
        >
          {options && filterOptions.length > 0 ? (
            filterOptions.map(option => (
              <li
                className="naxatw-flex naxatw-cursor-pointer naxatw-list-none naxatw-items-start naxatw-px-4 naxatw-py-2.5
                naxatw-text-sm naxatw-text-grey-800 hover:naxatw-bg-primary-50"
                key={option[valueKey]}
                onClick={() => handleOptionClick(option[valueKey])}
              >
                <div>{option[labelKey]}</div>
              </li>
            ))
          ) : (
            <li className="naxatw-cursor-default naxatw-px-4 naxatw-py-2.5 naxatw-text-sm">
              No options available
            </li>
          )}
        </ul>
      )}
    </div>
  );
}
