import { FlexRow } from '@Components/common/Layouts';
import { ReactNode, useState } from 'react';
import { MAX_ZOOM } from '@Constants/overlayLayers';
import { updateZoomLevel } from '@Store/actions/overlayLayer';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';

import ZoomLevelCustomization from '../ZoomLevelCustomization/ZoomLevelCustomization';
import { handleDeleteZoomLevelFuncType } from '../..';

interface IZoomBasedStyle {
  minZoom: number;
  maxZoom: number;
  children: ReactNode;
  defaultOpen: boolean;
  index: number;
  styleType: 'layer' | 'label';
  zoomLevels: Record<string, any>[];
  handleDeleteZoomLevel: handleDeleteZoomLevelFuncType;
}

function ZoomBasedStyle({
  minZoom,
  maxZoom,
  children,
  defaultOpen = false,
  index,
  styleType,
  zoomLevels,
  handleDeleteZoomLevel,
}: IZoomBasedStyle) {
  const dispatch = useTypedDispatch();
  const [showStyles, setShowStyles] = useState(defaultOpen);
  const [isSectionHovered, setIsSectionHovered] = useState<boolean>(false);
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );

  const handleZoomValueChange = (newZoomLevel: number) => {
    const zoomInNumber = newZoomLevel;
    const currentZoomLevel = zoomLevels[index].min;
    if (
      (index === zoomLevels.length - 1 &&
        zoomInNumber <= MAX_ZOOM &&
        zoomInNumber >= zoomLevels[index - 1].min + 1) ||
      (index < zoomLevels.length - 1 &&
        zoomInNumber < zoomLevels[index + 1].min &&
        zoomInNumber > zoomLevels[index - 1].min + 1)
    ) {
      return dispatch(
        updateZoomLevel({
          subLayer: isActiveSubLayer,
          currentZoomLevel,
          newZoomLevel: zoomInNumber,
          styleType,
        }),
      );
    }
    return null;
  };

  return (
    <>
      <FlexRow
        className="naxatw-my-4 naxatw-cursor-pointer naxatw-justify-between"
        role="presentation"
        onClick={() => setShowStyles(!showStyles)}
        onMouseEnter={() => setIsSectionHovered(true)}
        onMouseLeave={() => setIsSectionHovered(false)}
      >
        <FlexRow gap={1}>
          <h5 className="naxatw-text-base">Zoom level</h5>
          <h5 className="naxatw-text-base naxatw-text-primary-400">
            ({minZoom}-{maxZoom})
          </h5>
        </FlexRow>
        <FlexRow className="naxatw-items-center naxatw-gap-3">
          {isSectionHovered && index > 0 && (
            <i
              className="material-icons naxatw-cursor-pointer naxatw-text-lg hover:naxatw-text-red-600"
              role="presentation"
              onClick={e => {
                e.stopPropagation();
                handleDeleteZoomLevel(minZoom, styleType);
              }}
            >
              delete
            </i>
          )}
          <i className="material-icons naxatw-cursor-pointer hover:naxatw-text-blue-500">
            {showStyles ? 'expand_less' : 'expand_more'}
          </i>
        </FlexRow>
      </FlexRow>
      {showStyles && (
        <>
          <ZoomLevelCustomization
            handleChange={handleZoomValueChange}
            minZoom={minZoom}
            maxZoom={maxZoom}
          />
          {children}
        </>
      )}
    </>
  );
}

export default hasErrorBoundary(ZoomBasedStyle);
