export default function About() {
  return (
    <div className="naxatw-h-screen naxatw-w-full">
      <iframe
        className="naxatw-h-full naxatw-w-full"
        title="about"
        src="https://dmaps.org/about"
      />
    </div>
  );
}
