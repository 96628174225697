import { useDispatch } from 'react-redux';
import WorkshopModeOff from '@Assets/images/workshop-mode-off.svg';
import WorkshopModeOn from '@Assets/images/workshop-mode-on.svg';
import { FlexColumn } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import { Button } from '@Components/RadixComponents/Button';
// import Switch from '@Components/RadixComponents/Switch';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { useTypedSelector } from '@Store/hooks';
import useTranslation from '@Hooks/useTranslation';

export default function WorkshopMode() {
  const dispatch = useDispatch();
  const { _t } = useTranslation();

  const isWorkshopModeOn = useTypedSelector(
    state => state.workshopMode.isWorkshopModeOn,
  );

  const handleWorkshopMode = () => {
    dispatch(setWorkshopModeState({ isWorkshopModeOn: !isWorkshopModeOn }));
  };

  return (
    <FlexColumn
      gap={3}
      className="naxatw-relative naxatw-h-screen naxatw-w-screen naxatw-px-5 naxatw-pt-5"
    >
      <h3 className=" naxatw-text-grey-800">{_t('Workshop Mode')}</h3>
      <FlexColumn
        gap={10}
        className="naxatw-h-screen naxatw-items-center naxatw-justify-center"
      >
        <FlexColumn gap={5} className="naxatw-items-center">
          <Image src={isWorkshopModeOn ? WorkshopModeOn : WorkshopModeOff} />
          <FlexColumn className="naxatw-items-center">
            <h4>{_t(`Workshop Mode ${isWorkshopModeOn ? 'ON' : 'OFF'}`)}</h4>
            <p className="naxatw-text-sm">
              {isWorkshopModeOn
                ? _t('Please navigate to Workshop Mode through sidebar')
                : _t('Please press the button below to turn on Workshop Mode')}
            </p>
          </FlexColumn>
        </FlexColumn>
        {/* {isWorkshopModeOn && (
          <FlexColumn gap={3}>
            <FlexRow gap={10} className="naxatw-justify-between">
              <h5 className="naxatw-text-sm">{_t('Bulk Edit')}</h5>
              <Switch />
            </FlexRow>
            <FlexRow gap={10} className="naxatw-justify-between">
              <h5 className="naxatw-text-sm">{_t('Road Edit')}</h5>
              <Switch />
            </FlexRow>
            <FlexRow gap={10} className="naxatw-justify-between">
              <h5 className="naxatw-text-sm">{_t('Building edit')}</h5>
              <Switch />
            </FlexRow>
            <FlexRow gap={10} className="naxatw-justify-between">
              <h5 className="naxatw-text-sm">{_t('Attribute edit')}</h5>
              <Switch />
            </FlexRow>
          </FlexColumn>
        )} */}
        <Button
          variant={isWorkshopModeOn ? 'destructive' : 'default'}
          onClick={handleWorkshopMode}
        >
          {_t(`Turn ${isWorkshopModeOn ? 'Off' : 'On'} Workshop Mode`)}
        </Button>
      </FlexColumn>
    </FlexColumn>
  );
}
