/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { getUserList } from '@Services/userManagement';
import { format } from 'date-fns';
import Image from '@Components/RadixComponents/Image';
import User from '@Assets/images/avatar-images.svg';
import { toggleModal, togglePromptDialog } from '@Store/actions/common';
import { setUserManagementState } from '@Store/actions/userManagement';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { selectedWardSelector } from '@Store/selector/common';

export default function TableSection() {
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();

  const searchInput = useTypedSelector(
    state => state.userManagement.searchInput,
  );
  const permissions = useTypedSelector(state => state.user.permissions);
  const selectedWards = useTypedSelector(selectedWardSelector);

  const userColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Users',
      accessorKey: 'username',
      cell: (info: any) => (
        <FlexRow className=" naxatw-items-center naxatw-gap-2">
          <Image
            src={
              info.row.original.thumbnail ? info.row.original.thumbnail : User
            }
            width={24}
            className="naxatw-rounded-full"
          />
          <span>{info.row.original.username}</span>
        </FlexRow>
      ),
    },
    {
      header: 'Designation',
      accessorKey: 'designation',
    },
    {
      header: 'Role',
      accessorKey: 'role_type',
    },
    {
      header: 'User Status',
      accessorKey: 'is_active',
      cell: (info: any) => (info.getValue() ? 'Active' : 'Not Active'),
    },
    {
      header: 'Last Login',
      accessorKey: 'last_login',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
    {
      header: 'Last Modified',
      accessorKey: 'date_modified',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="naxatw-cursor-pointer naxatw-justify-around">
          {permissions.includes('change_user') && (
            <ToolTip
              name="edit"
              message="Edit"
              onClick={() => {
                dispatch(toggleModal('edit-user'));
                dispatch(
                  setUserManagementState({
                    selectedUser: row.original,
                  }),
                );
              }}
            />
          )}
          {permissions.includes('delete_user') && (
            <ToolTip
              name="delete"
              message="Delete"
              onClick={() => {
                dispatch(togglePromptDialog('user-delete-layer'));
                dispatch(
                  setUserManagementState({
                    selectedUser: row.original,
                  }),
                );
              }}
              iconClassName="naxatw-text-red-400 hover:naxatw-text-red-500"
            />
          )}
        </FlexRow>
      ),
    },
  ];

  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);

  return (
    <DataTable
      columns={
        permissions.includes('change_user') ||
        permissions.includes('delete_user')
          ? userColumns
          : userColumns.filter(item => item.accessorKey !== 'icon')
      }
      queryKey="user-list"
      queryFn={getUserList}
      queryFnParams={{ ward: selectedWards?.join(',') }}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          width <= 390
            ? '60vh'
            : showInfoDialog
              ? 'calc(100vh - 15rem)'
              : 'calc(100vh - 11.5rem)',
      }}
      sortingKeyMap={{
        role_type: 'group',
      }}
      useQueryOptions={{ enabled: !!selectedWards }}
    />
  );
}
