import { useState } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import ToolTip from '@Components/RadixComponents/ToolTip';
import BaseLayers from '@Components/Visualization/MapTools/BaseLayers';
import Measure from '@Components/Visualization/MapTools/Measure';
import Location from '@Components/Visualization/MapTools/Location';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';
import OverlayLayers from './OverlayLayers';

const iconData = [
  { id: 'toggle', icon: 'chevron_right' },
  { id: 'base_layers', icon: 'public', message: 'View Layer' },
  { id: 'layers', icon: 'layers', message: 'View Overlay' },
  { id: 'measure', icon: 'straighten', message: 'Measure' },
  { id: 'my_location', icon: 'my_location', message: 'GPS Location' },
];

interface IMapToolsProps {
  map?: MapInstanceType | null;
}

export default function MapTools({ map }: IMapToolsProps) {
  const [showMapTool, setShowMapTool] = useState(true);
  const [activeTab, setActiveTab] = useState('base_layers');
  const dispatch = useTypedDispatch();

  const activeBaseLayer = useTypedSelector(
    state => state.dataManagementForm.activeBaseLayer,
  );
  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);

  function getTabContent(name: string) {
    switch (name) {
      case 'base_layers':
        return (
          <BaseLayers
            activeBaseLayer={activeBaseLayer}
            onChange={id =>
              dispatch(setDataManagementFormState({ activeBaseLayer: id }))
            }
          />
        );
      case 'layers':
        return <OverlayLayers />;
      case 'measure':
        return <Measure />;
      case 'my_location':
        return <Location />;
      default:
        return '';
    }
  }

  return (
    <div
      className={`${
        showMapTool
          ? 'naxatw-w-[270px] naxatw-translate-x-0'
          : 'naxatw-w-10 naxatw--translate-x-0'
      } naxatw-absolute naxatw-bottom-[1.625rem] naxatw-right-0 naxatw-z-20 naxatw-flex 
      naxatw-h-[24.25rem] naxatw-duration-200 naxatw-ease-in-out`}
    >
      <div className="naxatw-flex naxatw-w-10 naxatw-flex-col naxatw-gap-3 naxatw-bg-transparent">
        <div className="naxatw-overflow-hidden naxatw-rounded-tl-md">
          {iconData.map(({ id, icon, message }) => {
            const isActive = showMapTool && activeTab === id && id !== 'toggle';
            return (
              <ToolTip
                key={id}
                className={`naxatw-flex naxatw-h-10 naxatw-w-10 naxatw-cursor-pointer naxatw-items-center
                 naxatw-justify-center naxatw-bg-primary-400 naxatw-text-white hover:naxatw-bg-primary-600
                 ${isActive ? 'naxatw-bg-primary-600 naxatw-text-white' : ''} ${
                   id === 'toggle' && !showMapTool ? 'naxatw-rotate-180' : ''
                 }`}
                name={icon}
                message={message}
                iconClassName="naxatw-icon-md naxatw-text-white hover:naxatw-text-white hover:naxatw-animate-none "
                onClick={() => {
                  if (id === 'toggle') {
                    setShowMapTool(!showMapTool);
                  } else {
                    setActiveTab(id);
                    setShowMapTool(true);
                  }
                }}
              />
            );
          })}
        </div>
        <div className="naxatw-divide-y-[1px] naxatw-divide-grey-500 ">
          <ToolTip
            name="center_focus_weak"
            message="Zoom to Extent"
            className="400 naxatw-bg-white  naxatw-p-2"
            iconClassName="naxatw-icon-md naxatw-text-primary-400"
            onClick={() => {
              if (!map || !buildingBbox) return;
              map.fitBounds(buildingBbox.data, { padding: 20 });
            }}
          />
          <ToolTip
            name="add"
            message="Zoom In"
            className="400 naxatw-bg-white naxatw-p-2"
            iconClassName="naxatw-icon-md naxatw-text-primary-400"
            onClick={() => {
              map?.zoomIn();
            }}
          />
          <ToolTip
            name="remove"
            message="Zoom Out"
            className="400 naxatw-bg-white naxatw-p-2"
            iconClassName="naxatw-icon-md naxatw-text-primary-400"
            onClick={() => {
              map?.zoomOut();
            }}
          />
        </div>
      </div>

      <div
        className={`naxatw-translate-x-0 naxatw-overflow-clip naxatw-rounded-lg naxatw-rounded-tl-none
           naxatw-bg-white ${
             showMapTool ? 'naxatw-w-[14.438rem]' : 'naxatw-w-0'
           }`}
      >
        {getTabContent(activeTab)}
      </div>
    </div>
  );
}
