import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { wktToGeoJSON } from '@terraformer/wkt';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn } from '@Components/common/Layouts';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';

export default function GeometrySection() {
  const { pathname } = useLocation();
  const historyLogType = pathname.includes('building') ? 'building' : 'road';
  const historyLogs = useTypedSelector(state => state.editHistory.historyLogs);
  const selectedAttributeIds = useTypedSelector(
    state => state.editHistory.selectedAttributeIds,
  );
  const relatedIdColors = useTypedSelector(
    state => state.editHistory.relatedIdColors,
  );

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 1,
      center: [84.124, 28.3949],
      maxZoom: 19,
    },
  });

  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );
  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);

  // zoom to palika boundary on initial map load
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingBbox) return () => {};
    const { data } = buildingBbox;
    if (!data) return () => {};
    const [x1, y1, x2, y2] = data;
    const timer = setTimeout(() => {
      map.fitBounds(
        [
          [x1, y1],
          [x2, y2],
        ],
        { duration: 0 },
      );
    }, 0);
    return () => clearTimeout(timer);
  }, [map, isMapLoaded, buildingBbox]);

  const transformedData = useMemo(
    () =>
      historyLogs
        ?.filter(
          item => item.changes.geom && selectedAttributeIds.includes(item.id),
        )
        .map((item: any) => ({
          ...item,
          changes: {
            ...item.changes,
            geom: wktToGeoJSON(item.changes.geom),
          },
        })),
    [historyLogs, selectedAttributeIds],
  );

  return (
    <FlexColumn gap={3} className="naxatw-w-full">
      <h5>Edited Geometry</h5>
      <MapContainer
        map={map}
        isMapLoaded={isMapLoaded}
        style={{ width: '100%', height: '88vh' }}
      >
        <BaseLayerSwitcher activeLayer={activeBaseLayer} />

        {transformedData?.length ? (
          transformedData.map(item => (
            <VectorLayer
              key={item.id}
              id={item.id}
              geojson={item.changes.geom}
              layerOptions={
                historyLogType === 'building'
                  ? {
                      type: 'fill',
                      paint: {
                        'fill-opacity': 1,
                        'fill-color': relatedIdColors[item.related_id],
                      },
                    }
                  : {
                      type: 'line',
                      paint: {
                        'line-width': 6,
                        'line-color': relatedIdColors[item.related_id],
                      },
                    }
              }
            />
          ))
        ) : (
          <> </>
        )}
      </MapContainer>
    </FlexColumn>
  );
}
