/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';
import { Button } from '@Components/RadixComponents/Button';
import {
  DrawModeTypes,
  GeojsonType,
} from '@Components/common/MapLibreComponents/types';
import { activeTabNameSelector } from '@Store/selector/dataManagementForm';
import useTranslation from '@Hooks/useTranslation';
import { FlexRow } from '@Components/common/Layouts';

interface IToolbarProps {
  setDrawMode: (mode: DrawModeTypes) => void;
  resetDraw: () => void;
  reverseLineGeometry: () => void;
  drawnFeatureGeojson: null | GeojsonType;
  undo: () => void;
  redo: () => void;
}

export default function Toolbar({
  setDrawMode,
  resetDraw,
  reverseLineGeometry,
  drawnFeatureGeojson,
  undo,
  redo,
}: IToolbarProps) {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const selectedRoadId = useTypedSelector(
    state => state.dataManagementForm.selectedRoadId,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.dataManagementForm.selectedBuildingId,
  );
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);
  const fetchedGeojson = useTypedSelector(
    state => state.dataManagementForm.fetchedGeojson,
  );

  const formType = pathname.includes('building-data') ? 'building' : 'road';
  const activeTabName = useTypedSelector(activeTabNameSelector);

  const handleRedraw = () => {
    resetDraw();
    dispatch(
      setDataManagementFormState({
        drawType: formType === 'building' ? 'draw_polygon' : 'draw_line_string',
        drawnGeom: null,
      }),
    );
  };

  const handleReverseRoadDirection = () => {
    reverseLineGeometry();
    dispatch(
      setDataManagementFormState({
        hasGeomChanged: true,
      }),
    );
  };

  useEffect(() => {
    setDrawMode(drawType);
  }, [setDrawMode, drawType]);

  const handleDrawBuilding = () => {
    dispatch(setDataManagementFormState({ drawType: 'draw_polygon' }));
  };

  // const handleMove = () => {
  //   dispatch(setDataManagementFormState({ drawType: 'simple_select' }));
  // };

  const handleDrawRoad = () => {
    dispatch(setDataManagementFormState({ drawType: 'draw_line_string' }));
  };

  const handleSelectRoadToggle = () => {
    dispatch(setDataManagementFormState({ enableRoadSelect: true }));
  };

  const handleSelectBuildingToggle = () => {
    dispatch(setDataManagementFormState({ enableBuildingSelect: true }));
  };

  const handleRoadReselect = () => {
    dispatch(
      setDataManagementFormState({
        enableRoadSelect: true,
        selectedRoadId: null,
      }),
    );
  };

  const handleBuildingReselect = () => {
    dispatch(
      setDataManagementFormState({
        enableBuildingSelect: true,
        selectedBuildingId: null,
      }),
    );
  };

  const handleUndo = () => {
    undo();
  };

  const handleRedo = () => {
    redo();
  };

  function getContent() {
    if (formType === 'building') {
      switch (activeTabName) {
        case 'geometry':
          return drawnFeatureGeojson || drawnGeom ? (
            <FlexRow gap={2}>
              <Button
                leftIcon="draw"
                variant="secondary"
                onClick={handleRedraw}
              >
                {_t('Redraw')}
              </Button>
              <Button leftIcon="undo" variant="secondary" onClick={handleUndo}>
                {_t('Undo')}
              </Button>
              <Button leftIcon="redo" variant="secondary" onClick={handleRedo}>
                {_t('Redo')}
              </Button>
            </FlexRow>
          ) : (
            <Button leftIcon="draw" onClick={handleDrawBuilding}>
              {_t('Draw Building')}
            </Button>
          );
        case 'access':
          return selectedRoadId ? (
            <Button
              leftIcon="draw"
              variant="secondary"
              onClick={handleRoadReselect}
            >
              {_t('Reselect')}
            </Button>
          ) : (
            <Button leftIcon="draw" onClick={handleSelectRoadToggle}>
              {_t('Select Road')}
            </Button>
          );
        case 'main_building':
          return selectedBuildingId ? (
            <Button
              leftIcon="draw"
              variant="secondary"
              onClick={handleBuildingReselect}
            >
              {_t('Reselect')}
            </Button>
          ) : (
            <Button leftIcon="draw" onClick={handleSelectBuildingToggle}>
              {_t('Select Main Building')}
            </Button>
          );
        default:
          return '';
      }
    } else {
      switch (activeTabName) {
        case 'geometry':
          return drawnFeatureGeojson || drawnGeom ? (
            <FlexRow gap={2}>
              <Button
                leftIcon="draw"
                variant="secondary"
                onClick={handleRedraw}
              >
                {_t('Redraw')}
              </Button>
              <Button leftIcon="undo" variant="secondary" onClick={handleUndo}>
                {_t('Undo')}
              </Button>
              <Button leftIcon="redo" variant="secondary" onClick={handleRedo}>
                {_t('Redo')}
              </Button>
              {fetchedGeojson && (
                <Button
                  leftIcon="swap_horiz"
                  variant="secondary"
                  onClick={handleReverseRoadDirection}
                >
                  {_t('Reverse Direction')}
                </Button>
              )}
            </FlexRow>
          ) : (
            <Button leftIcon="draw" onClick={handleDrawRoad}>
              {_t('Draw Road')}
            </Button>
          );
        default:
          return '';
      }
    }
  }

  return (
    <div className="naxatw-absolute naxatw-left-3 naxatw-top-3 naxatw-z-10">
      {getContent()}
    </div>
  );
}
