import { useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useInView } from 'react-intersection-observer';
import * as chroma from 'chroma-js';
import { produce } from 'immer';
import { useTypedDispatch } from '@Store/hooks';
import {
  setEditHistoryState,
  setInitialAttributeIds,
} from '@Store/actions/editHistory';
import isEmpty from '@Utils/isEmpty';
import { getHistoryLogs } from '@Services/visualization';
import { FlexColumn } from '@Components/common/Layouts';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { avatarColors } from '@Constants/editHistory';
import HistoryCard from './HistoryCard';

// group data based on relatedId
function groupData(data: Record<string, any>[]) {
  const uniqueRelatedIds = [...new Set(data.map(item => item.related_id))];
  const result = uniqueRelatedIds.map(relatedId => {
    const children = data.filter(
      item => item.related_id === relatedId && !isEmpty(item.changes),
    );
    return {
      relatedId,
      children,
    };
  });
  return result;
}

export default function SidebarSection() {
  const { ref, inView } = useInView();
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();

  const historyLogType = pathname.includes('building') ? 'building' : 'road';

  const {
    data: historyLogsData,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery({
    queryKey: ['get-history-logs', historyLogType, id],
    queryFn: ({ pageParam }) => {
      return getHistoryLogs({
        layer: historyLogType,
        association_id: id,
        page: pageParam,
      });
    },
    getPreviousPageParam: firstPage => firstPage.data.previous ?? undefined,
    getNextPageParam: (lastPage, allpages) => {
      return allpages.length ? allpages.length + 1 : undefined;
    },
  });

  const transformedData = useMemo(() => {
    if (!historyLogsData) return null;
    const updatedData = produce(
      historyLogsData,
      (draft: Record<string, any>) => {
        draft.pages.forEach((page: any) => {
          const { data } = page;
          data.results = groupData(
            data.results.filter((item: any) => !isEmpty(item.changes)),
          );
        });
      },
    );
    return updatedData;
  }, [historyLogsData]);

  // flatten paginated api data and store in slice
  useEffect(() => {
    if (!historyLogsData) return;
    const results = historyLogsData.pages.map(item => item.data.results).flat();
    const uniqueRelatedIds = [
      ...new Set(results.map(result => result?.related_id)),
    ];
    const relatedIdColors = uniqueRelatedIds.reduce(
      (obj, relatedId, index) => ({
        ...obj,
        [relatedId]: avatarColors[index] || chroma.random().hex(),
      }),
      {},
    );
    dispatch(
      setEditHistoryState({
        historyLogs: results ?? [],
        relatedIdColors,
      }),
    );
    if (!results[0]) return;
    const relatedId = [results[1]?.related_id, results[0]?.related_id];
    const relatedIdResults = results
      .filter(item => relatedId.includes(item?.related_id))
      .map(item => item.id);
    dispatch(setInitialAttributeIds(relatedIdResults));
  }, [dispatch, historyLogsData]);

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, fetchNextPage]);

  if (isLoading) {
    return (
      <FlexColumn gap={5} className="naxatw-w-[325px]">
        {Array.from({ length: 5 }, (_, index) => (
          <Skeleton
            key={index}
            className="naxatw-h-32 naxatw-rounded-lg naxatw-bg-primary-50 naxatw-shadow-sm md:naxatw-h-28"
          />
        ))}
      </FlexColumn>
    );
  }

  return (
    <FlexColumn
      gap={3}
      className="scrollbar naxatw-h-[calc(100vh-3rem)] naxatw-w-full naxatw-overflow-y-auto naxatw-pr-1 md:naxatw-w-[325px]"
    >
      {transformedData?.pages.map(page =>
        page?.data?.results?.map((item: Record<string, any>) => (
          <HistoryCard
            innerRef={ref}
            key={item.relatedId}
            data={item?.children}
          />
        )),
      )}
      {isFetchingNextPage && <h5>Loading more...</h5>}
    </FlexColumn>
  );
}
