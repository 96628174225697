import { useEffect, useMemo } from 'react';
import { IVectorTileLayer } from '../types';

export default function SymbolLayer({
  map,
  id,
  url,
  isMapLoaded,
  layerOptions,
  visibleOnMap = true,
}: IVectorTileLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);

  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};

    map.addSource(sourceId, {
      type: 'vector',
      tiles: [url],
    });

    if (visibleOnMap) {
      map.addLayer({
        id: sourceId,
        type: 'symbol',
        source: sourceId,
        'source-layer': 'default',
        ...layerOptions,
      });
    }

    return () => {
      if (map.getLayer(sourceId)) {
        map.removeLayer(sourceId);
      }
      if (map.getSource(sourceId)) {
        map.removeSource(sourceId);
      }
    };
  }, [map, isMapLoaded, layerOptions, visibleOnMap, sourceId, url]);

  return null;
}
