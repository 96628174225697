import RoundedContainer from '@Components/common/RoundedContainer';
import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import DataTable from '@Components/common/DataTable';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { selectedWardSelector } from '@Store/selector/common';
import useTranslation from '@Hooks/useTranslation';
import { useGetPalikaWardListQuery } from '@Api/dashboard';

export default function WardDetailTable() {
  const { _t } = useTranslation();
  const selectedWards = useTypedSelector(selectedWardSelector);

  const { width } = useWindowDimensions();

  const wardDetailColumn = [
    {
      header: 'Ward',
      accessorKey: 'ward_no',
    },
    {
      header: 'Area (km²)',
      accessorKey: 'area',
      cell: (info: any) => info.getValue().toFixed(2),
    },
    {
      header: 'House count',
      accessorKey: 'buildings',
    },
  ];

  const { data: palikaWardList, isLoading } = useGetPalikaWardListQuery(
    selectedWards?.join(','),
    { enabled: !!selectedWards },
  );

  return (
    <RoundedContainer className="naxatw-w-full naxatw-bg-grey-50 naxatw-px-3 naxatw-py-2">
      <h3 className="naxatw-text-lg">{_t('Ward Detail')}</h3>
      <FlexColumn className="naxatw-h-[314px] naxatw-py-1">
        <DataTable
          columns={wardDetailColumn}
          data={palikaWardList}
          wrapperStyle={{
            height: width <= 390 ? 'full' : '310px',
          }}
          withPagination={false}
          tableOptions={{
            manualSorting: false,
          }}
          loading={isLoading}
        />
      </FlexColumn>
    </RoundedContainer>
  );
}
