import Icon from '@Components/common/Icon';
import { Flex, FlexColumn, FlexRow } from '../Layouts';

type InfoDialogProps = {
  status?: string;
  title?: string;
  className?: string;
  description?: string;
  onClose?: () => void;
};

const getStatus = (status: string | undefined) => {
  switch (status) {
    case 'info':
      return { icon: 'info', bgColor: 'naxatw-bg-primary-400' };
    case 'success':
      return {
        icon: 'check_circle',
        bgColor: 'naxatw-bg-primary-50 naxatw-border-[#2ec961]',
        iconColor: 'naxatw-text-[#2ec961] !naxatw-text-3xl',
        textColor: '!hover:naxatw-text-[#2ec961]',
      };
    case 'error':
      return {
        icon: 'cancel',
        bgColor: 'naxatw-bg-red-400 naxatw-opacity-90',
        iconColor: 'naxatw-text-white',
        textColor: 'naxatw-text-white',
      };
    default:
      return { icon: 'info', bgColor: 'naxatw-bg-primary-100' };
  }
};

export default function InfoDialog({
  status,
  title,
  description,
  onClose,
  className,
}: InfoDialogProps) {
  const infoStatus = getStatus(status);

  return (
    <FlexRow
      className={`${infoStatus.bgColor} naxatw-w-full naxatw-items-center  naxatw-justify-between
      naxatw-rounded-lg naxatw-border naxatw-border-primary-400 naxatw-px-3 naxatw-py-1 ${className}`}
    >
      <FlexRow gap={3} className="naxatw-items-center ">
        <Flex>
          <Icon name={infoStatus.icon} className={`${infoStatus.iconColor}`} />
        </Flex>
        <FlexColumn className={infoStatus.textColor}>
          <h5 className="naxatw-text-sm">{title}</h5>
          <div className="naxatw-text-sm">{description}</div>
        </FlexColumn>
      </FlexRow>
      <Icon
        name="close"
        className={`naxatw-text-grey-600  hover:naxatw-text-primary-400 ${infoStatus.textColor}`}
        onClick={onClose}
      />
    </FlexRow>
  );
}
