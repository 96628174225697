/* eslint-disable import/prefer-default-export */

import { FormFieldType } from './dataManagementForm';

export const userManagementFormSchema: FormFieldType[] = [
  {
    id: 'username',
    label: 'User Name',
    type: 'input',
    placeholder: 'Enter User Name',
    required: true,
    validationSchema: {
      matchPattern: (v: any) =>
        /^[a-zA-Z][a-zA-Z0-9\-_\s.,$;]+$/.test(v) ||
        'User name must be alphanumeric.',
    },
  },
  {
    id: 'email',
    label: 'Email Address',
    type: 'input',
    placeholder: 'Enter Email Address',
    required: true,
    validationSchema: {
      matchPattern: (v: any) =>
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        'Email address must be a valid address',
    },
  },
  {
    id: 'designation',
    label: 'Designation',
    type: 'input',
    placeholder: 'Enter Designation',
    required: true,
    validationSchema: {
      matchPattern: (v: any) =>
        /^[a-zA-Z][a-zA-Z0-9\-_\s.,$;]+$/.test(v) ||
        'Designation must be alphanumeric.',
    },
  },
  {
    id: 'role_type',
    label: 'Role',
    type: 'select',
    placeholder: 'Choose',
    required: true,
  },
  {
    id: 'ward',
    label: 'Ward',
    type: 'multi-select',
    placeholder: 'All ward',
    required: false,
  },
  {
    id: 'image',
    label: 'Upload Picture',
    type: 'file-upload',
    required: false,
    multiple: false,
  },
];
