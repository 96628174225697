import { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { updateRestoreHistoryLog } from '@Services/visualization';
import { togglePromptDialog } from '@Store/actions/common';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';

const restoreConfirmationText = 'restore';

export default function RestoreEditHistoryPrompt() {
  const dispatch = useTypedDispatch();
  const [input, setInput] = useState('');

  const selectedItemsToRestore = useTypedSelector(
    state => state.editHistory.selectedItemsToRestore,
  );

  const [checkedRestoreIds, setCheckedRestoreIds] = useState<number[]>([]);

  useEffect(() => {
    setCheckedRestoreIds(selectedItemsToRestore.map((item: any) => item.id));
  }, [selectedItemsToRestore]);

  const handlePromptClose = () => {
    dispatch(togglePromptDialog());
  };

  const handleCheckbox = (e: any) => {
    const { id, checked } = e.target;
    setCheckedRestoreIds(prev =>
      checked ? [...prev, +id] : prev.filter(item => item !== +id),
    );
  };

  const { mutate: restoreEditHistoryRequest, isLoading } = useMutation<
    any,
    any,
    any,
    unknown
  >({
    mutationFn: attributeIds => {
      const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
      return Promise.all(
        attributeIds.map((attributeId: number) =>
          updateRestoreHistoryLog(attributeId, { timestamp }),
        ),
      );
    },
    onSuccess: () => {
      handlePromptClose();
    },
  });

  const handleRestore = () => {
    if (!checkedRestoreIds.length) return;
    if (restoreConfirmationText !== input) return;
    restoreEditHistoryRequest(checkedRestoreIds);
  };

  return (
    <FlexColumn gap={2} className="naxatw-text-sm">
      <p className="naxatw-text-grey-600">
        Which attribute do you want to restore ?
      </p>
      <FlexColumn gap={2}>
        {selectedItemsToRestore.map(item => (
          <FlexRow key={item.id} gap={2} className="naxatw-cursor-pointer">
            <input
              type="checkbox"
              id={item.id}
              checked={checkedRestoreIds.includes(item.id)}
              onChange={handleCheckbox}
            />
            <p>{item.changes.geom ? 'Geometry' : 'Attribute'}</p>
          </FlexRow>
        ))}
      </FlexColumn>
      <p>
        Please type &quot;
        <span className="naxatw-select-none">{restoreConfirmationText}</span>
        &quot; to confirm
      </p>
      <Input
        type="text"
        placeholder="restore"
        onPaste={e => e.preventDefault()}
        onChange={e => setInput(e.target.value)}
      />
      <FlexRow className=" naxatw-w-full naxatw-justify-end naxatw-pt-5">
        <Button variant="ghost" onClick={() => dispatch(togglePromptDialog())}>
          Cancel
        </Button>
        <Button
          leftIcon="settings_backup_restore"
          variant="destructive"
          withLoader
          isLoading={isLoading}
          onClick={handleRestore}
          disabled={restoreConfirmationText !== input}
          className="disabled:!naxatw-pointer-events-auto disabled:!naxatw-cursor-not-allowed"
        >
          Confirm
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
