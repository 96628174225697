import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useTranslation from '@Hooks/useTranslation';
import useDebounceListener from '@Hooks/useDebounceListener';
import { setVisualizationState } from '@Store/actions/visualization';
import { getBuildingDataList } from '@Services/dataManagement';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import SearchInput from '@Components/common/SearchInput';
import { Button } from '@Components/RadixComponents/Button';
import { FlexRow } from '@Components/common/Layouts';

export default function SearchFilterSection() {
  const { _t } = useTranslation();
  const [searchInput, setSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useTypedDispatch();

  const debouncedValue = useDebounceListener(searchInput || '', 800);

  // fetch building data list on search
  const { data: searchData } = useQuery({
    queryKey: ['map-search', debouncedValue],
    queryFn: () => getBuildingDataList({ search: debouncedValue }),
    select: res => res.data,
    enabled: !!debouncedValue,
  });

  const selectedFeature = useTypedSelector(
    state => state.visualization.selectedFeature,
  );
  const showFilterSection = useTypedSelector(
    state => state.advanceFilter.showFilterSection,
  );

  return (
    <FlexRow
      gap={3}
      className="naxatw-absolute naxatw-left-3 naxatw-top-4 naxatw-z-30 naxatw-animate-fade-up"
    >
      <SearchInput
        isOpen={isOpen}
        data={searchData || []}
        inputValue={searchInput}
        onChange={e => {
          setSearchInput(e.target.value);
          setIsOpen(true);
        }}
        onSelect={selectedItem => {
          dispatch(
            setVisualizationState({
              selectedFeature: { ...selectedItem, layer: 'building' },
            }),
          );
          setSearchInput('');
          setIsOpen(false);
        }}
        selectedValue={selectedFeature}
        onClear={() => {
          setSearchInput('');
          dispatch(
            setVisualizationState({
              selectedFeature: null,
            }),
          );
        }}
      />
      <Button
        variant="outline"
        leftIcon="tune"
        className="!naxatw-h-9 !naxatw-px-5"
        onClick={() => {
          dispatch(
            setAdvanceFilterState({
              showFilterSection: !showFilterSection,
            }),
          );
          dispatch(setVisualizationState({ showMapTool: false }));
        }}
      >
        {_t('Filter')}
      </Button>
    </FlexRow>
  );
}
