import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { filterTypeType } from '@Store/slices/advanceFilter';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import { FlexColumn } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { Button } from '@Components/RadixComponents/Button';
import useTranslation from '@Hooks/useTranslation';
import BasicFilter from './BasicFilter';
import AdvanceFilter from './AdvanceFilter';
import FilterHeader from './FilterHeader';

function getFilterContent(type: filterTypeType) {
  switch (type) {
    case 'basic':
      return <BasicFilter />;
    case 'advance':
      return <AdvanceFilter />;
    default:
      return <BasicFilter />;
  }
}

export default function FilterSection() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const expandFilter = useTypedSelector(
    state => state.advanceFilter.expandFilter,
  );
  const filterType = useTypedSelector(state => state.advanceFilter.filterType);
  const hideQueryFilter = useTypedSelector(
    state => state.advanceFilter.hideQueryFilter,
  );
  const queryExpression = useTypedSelector(
    state => state.advanceFilter.queryExpression,
  );
  const showFilterSection = useTypedSelector(
    state => state.advanceFilter.showFilterSection,
  );
  const minimizeFilterSection = useTypedSelector(
    state => state.advanceFilter.minimizeFilterSection,
  );

  return (
    <>
      {showFilterSection && (
        <FlexColumn
          className={`${
            expandFilter
              ? 'naxatw-w-full'
              : 'naxatw-shadow-[0_4px_11px_0_#00000040]'
          } naxatw-h-screen naxatw-min-w-[630px] naxatw-translate-x-0
        naxatw-p-4 naxatw-duration-500`}
        >
          {/* filter header section */}
          <FilterHeader />

          {/* toggle query section button */}
          {queryExpression && (
            <Button
              rightIcon={hideQueryFilter ? 'expand_more' : 'expand_less'}
              variant="ghost"
              onClick={() =>
                dispatch(
                  setAdvanceFilterState({ hideQueryFilter: !hideQueryFilter }),
                )
              }
              className="!naxatw-h-fit naxatw-w-fit !naxatw-px-0"
            >
              {_t(`${hideQueryFilter ? 'Show' : 'Hide'} Filter`)}
            </Button>
          )}

          {/* filter content */}
          {getFilterContent(filterType)}
        </FlexColumn>
      )}

      {/* minimize filter toggle button */}
      {(minimizeFilterSection || showFilterSection) && !expandFilter && (
        <ToolTip
          name={showFilterSection ? 'chevron_left' : 'chevron_right'}
          className={`${
            showFilterSection ? '!naxatw-left-[710px]' : 'naxatw-left-[80px]'
          } naxatw-absolute naxatw-bottom-0 naxatw-left-[80px] naxatw-top-0 
            naxatw-z-10 naxatw-my-auto naxatw-h-9 naxatw-w-6 
            naxatw-rounded-r-full naxatw-bg-primary-400`}
          iconClassName="naxatw-text-white hover:naxatw-text-white"
          onClick={() => {
            dispatch(
              setAdvanceFilterState({
                minimizeFilterSection: showFilterSection,
                showFilterSection: !showFilterSection,
              }),
            );
          }}
        />
      )}
    </>
  );
}
