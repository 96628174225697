import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setBuildingDataState,
  setDataManagementState,
  handleFilterReset,
} from '@Store/actions/dataManagement';
import { allWardsSelector } from '@Store/selector/common';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import Popover from '@Components/RadixComponents/Popover';
import Chip from '@Components/common/Chip';
import { FormControl, Label, MultiSelect } from '@Components/common/FormUI';
import {
  useGetBuildingRegListQuery,
  useGetToleNameListQuery,
} from '@Api/dataManagement';
import SearchInput from '@Components/common/FormUI/SearchInput';
import useTranslation from '@Hooks/useTranslation';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { apiURL } from '@Services/index';

function FilterSection() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const [selectedWard, setSelectWard] = useState<string[]>([]);
  const [selectedTole, setSelectedTole] = useState<string[]>([]);
  const [selectedBuildingType, setSelectedBuildingType] = useState<string[]>(
    [],
  );
  const permissions = useTypedSelector(state => state.user.permissions);
  const showFilterPopover = useTypedSelector(
    state => state.dataManagement.showFilterPopover,
  );
  const queryFnParams = useTypedSelector(
    state => state.dataManagement.buildingData.queryFnParams,
  );
  const allWards = useTypedSelector(allWardsSelector);
  const searchInput = useTypedSelector(
    state => state.dataManagement.buildingData.searchInput,
  );

  // handle filter reset inside select dropdown on handleChipClose
  useEffect(() => {
    setSelectWard(queryFnParams.ward_no || []);
  }, [queryFnParams.ward_no]);
  useEffect(() => {
    setSelectedTole(queryFnParams.tole_name || []);
  }, [queryFnParams.tole_name]);
  useEffect(() => {
    setSelectedBuildingType(queryFnParams.reg_type || []);
  }, [queryFnParams.reg_type]);

  const { data: toleNameOptions } = useGetToleNameListQuery();

  const { data: buildingRegistrationType } = useGetBuildingRegListQuery();

  const handleWardFilter = (updatedSelected: string[]) => {
    setSelectWard(updatedSelected);
  };
  const handleToleNameFilter = (updatedSelected: string[]) => {
    setSelectedTole(updatedSelected);
  };
  const handleBuildingType = (updatedSelected: string[]) => {
    setSelectedBuildingType(updatedSelected);
  };

  const handleApplyFilter = () => {
    dispatch(
      setBuildingDataState({
        queryFnParams: {
          ward_no: selectedWard,
          tole_name: selectedTole,
          reg_type: selectedBuildingType,
        },
      }),
    );
    dispatch(
      setDataManagementState({
        showFilterPopover: !showFilterPopover,
      }),
    );
  };

  const handleChipClose = ({ key, val }: Record<string, any>) =>
    dispatch(
      handleFilterReset({
        stateName: 'buildingData',
        key,
        val,
      }),
    );

  const isFilterApplied =
    !!queryFnParams.ward_no?.length ||
    !!queryFnParams.tole_name?.length ||
    !!queryFnParams.reg_type?.length;

  return (
    <FlexColumn gap={2}>
      <FlexRow className="naxatw-flex-col naxatw-justify-between md:naxatw-flex-row">
        <SearchInput
          inputValue={searchInput}
          placeholder="Search by HN, Owner Name or Plus Code"
          onChange={e =>
            dispatch(
              setBuildingDataState({
                searchInput: e.target.value,
              }),
            )
          }
          onClear={() =>
            dispatch(
              setBuildingDataState({
                searchInput: '',
              }),
            )
          }
          className="md:!naxatw-w-[320px]"
        />

        <FlexRow className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-pt-3 md:naxatw-py-0">
          <FlexColumn>
            <a
              href={`${apiURL}/download/?file_format=csv&layer=building`}
              download="downloaded_file.csv"
              target="_blank"
              rel="noreferrer"
            >
              <ToolTip name="download" message="Download Table" />
            </a>
          </FlexColumn>
          <Popover
            show={showFilterPopover}
            triggerContent={
              <Button
                leftIcon="tune"
                className="naxatw-font-bold naxatw-text-blue-500  "
                variant="outline"
                onClick={() =>
                  dispatch(
                    setDataManagementState({
                      showFilterPopover: !showFilterPopover,
                    }),
                  )
                }
              >
                {_t('Filter')}
              </Button>
            }
            popoverContent={
              <>
                <h5 className="naxatw-text-grey-800">Filter</h5>
                <FlexColumn className="naxatw-gap-4">
                  <FormControl>
                    <Label>Ward</Label>
                    <MultiSelect
                      options={allWards}
                      selectedOptions={selectedWard}
                      onChange={handleWardFilter}
                    />
                  </FormControl>
                  <FormControl>
                    <Label>Tole Name</Label>
                    <MultiSelect
                      options={toleNameOptions}
                      selectedOptions={selectedTole}
                      onChange={handleToleNameFilter}
                    />
                  </FormControl>
                  <FormControl>
                    <Label>Building Registration Type</Label>
                    <MultiSelect
                      options={buildingRegistrationType}
                      selectedOptions={selectedBuildingType}
                      onChange={handleBuildingType}
                    />
                  </FormControl>
                </FlexColumn>
                <FlexRow className="naxatw-justify-end">
                  <Button
                    className="naxatw-gap-3 naxatw-font-bold"
                    onClick={handleApplyFilter}
                    size="sm"
                  >
                    Apply
                  </Button>
                </FlexRow>
              </>
            }
          />
          {permissions.includes('add_building') && (
            <Button
              leftIcon="add"
              className=" naxatw-gap-3 naxatw-font-bold"
              onClick={() =>
                navigate('form', { state: { redirectPath: pathname } })
              }
            >
              {_t('Add New Building')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>

      {isFilterApplied && (
        <FlexRow
          className="naxatw-items-center naxatw-justify-between naxatw-rounded-md  
         naxatw-bg-primary-50 naxatw-py-1 naxatw-pl-3"
        >
          <FlexRow className="naxatw-gap-4">
            {Object.entries(queryFnParams).map(([key, value]) =>
              !value
                ? null
                : value?.map(val => (
                    <Chip
                      key={key}
                      label={key === 'ward_no' ? `Ward ${val}` : val}
                      onClose={() => handleChipClose({ key, val })}
                    />
                  )),
            )}
          </FlexRow>
          <Button
            className="naxatw-gap-1 naxatw-font-bold"
            variant="ghost"
            leftIcon="close"
            onClick={() =>
              dispatch(handleFilterReset({ stateName: 'buildingData' }))
            }
          >
            Clear All Filter
          </Button>
        </FlexRow>
      )}
    </FlexColumn>
  );
}

export default hasErrorBoundary(FilterSection);
