import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import { setLayerAttributeState } from '@Store/actions/layerAttribute';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { Select } from '@Components/common/FormUI';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import TableSection from './TableSection';

function AttributeSection() {
  const dispatch = useTypedDispatch();
  const selectedLayer = useTypedSelector(
    state => state.layerAttribute.selectedLayer,
  );
  const layerList = useTypedSelector(state => state.layerAttribute.layerList);

  const layerOptions = layerList.map(layerName => {
    return {
      label: capitalizeFirstLetter(layerName),
      value: layerName,
    };
  });
  return (
    <FlexColumn gap={7}>
      <div className="naxatw-w-1/5">
        <Select
          withSearch
          options={layerOptions}
          placeholder="Choose"
          selectedOption={selectedLayer}
          onChange={val =>
            dispatch(setLayerAttributeState({ selectedLayer: val }))
          }
          className="hover:naxatw-border-b-2"
          inputTagClassname="placeholder:!naxatw-text-[1.75rem] naxatw-font-bold !naxatw-border-none"
        />
      </div>
      <TableSection />
    </FlexColumn>
  );
}

export default hasErrorBoundary(AttributeSection);
