import { FlexColumn } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import SelectLayerSection from './SelectLayerSection';
import AttributeSection from './AttributeSection';

export default function LayerAttribute() {
  const showTableSection = useTypedSelector(
    state => state.layerAttribute.showTableSection,
  );
  return (
    <FlexColumn
      gap={3}
      className="naxatw-relative naxatw-h-screen naxatw-w-screen naxatw-p-5"
    >
      {!showTableSection ? <SelectLayerSection /> : <AttributeSection />}
    </FlexColumn>
  );
}
