import { useState, useEffect } from 'react';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { FlexColumn } from '@Components/common/Layouts';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import DroneOrtho from '@Assets/images/map/drone.svg';
import Image from '@Components/RadixComponents/Image';
import Satellite from '@Assets/images/map/satellite.png';
import OSM from '@Assets/images/map/osm.png';
import { setVisualizationState } from '@Store/actions/visualization';
import { setPublicPageState } from '@Store/actions/publicPage';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { LngLatBoundsLike } from 'maplibre-gl';

interface IMapToolsProps {
  map?: MapInstanceType | null;
}

export default function MapTools({ map }: IMapToolsProps) {
  const [toggleLayer, setToggleLayer] = useState(false);
  const [toggleDroneLayer, setToggleDroneLayer] = useState(false);
  const dispatch = useTypedDispatch();

  const buildingBbox = useTypedSelector(state => state.publicPage.buildingBbox);

  useEffect(() => {
    dispatch(
      setPublicPageState({
        activeBaseLayer: toggleLayer ? 'satellite' : 'osm',
      }),
    );
    setToggleDroneLayer(false);
  }, [dispatch, toggleLayer]);

  return (
    <FlexColumn className="naxatw-absolute naxatw-right-5 naxatw-top-[30%] naxatw-z-30 naxatw-m-auto">
      <FlexColumn gap={2}>
        <ToolTip
          name="layers"
          message="Overlay Layer"
          className="naxatw-rounded-lg naxatw-bg-white naxatw-p-2"
        />
        <FlexColumn
          onClick={() => {
            setToggleLayer(!toggleLayer);
          }}
        >
          <Image
            src={toggleLayer ? OSM : Satellite}
            className="naxatw-h-12 naxatw-cursor-pointer naxatw-rounded-full naxatw-bg-white naxatw-p-1"
            width={48}
          />
        </FlexColumn>
        <FlexColumn
          onClick={() => {
            setToggleDroneLayer(true);
            dispatch(
              setPublicPageState({
                activeBaseLayer: 'droneOrtho',
              }),
            );
          }}
        >
          <Image
            src={DroneOrtho}
            className={`naxatw-h-12 naxatw-cursor-pointer naxatw-rounded-full naxatw-bg-white naxatw-p-1 ${
              toggleDroneLayer
                ? 'naxatw-border-2 naxatw-border-primary-400'
                : ''
            }`}
            width={48}
          />
        </FlexColumn>
        <ToolTip
          name="my_location"
          message="My Location"
          className="naxatw-rounded-lg naxatw-bg-white naxatw-p-2"
          onClick={() => {
            if ('geolocation' in navigator) {
              navigator.geolocation.getCurrentPosition(position => {
                dispatch(
                  setVisualizationState({
                    geolocation: position.coords,
                    zoomToLocationType: 'geolocation',
                  }),
                );
              });
            }
          }}
        />
        <ToolTip
          name="add"
          message="Zoom In"
          className="naxatw-rounded-lg naxatw-bg-white naxatw-p-2"
          onClick={() => {
            map?.zoomIn();
          }}
        />
        <ToolTip
          name="remove"
          message="Zoom Out"
          className="naxatw-rounded-lg naxatw-bg-white naxatw-p-2"
          onClick={() => {
            map?.zoomOut();
          }}
        />
        <ToolTip
          name="center_focus_weak"
          message="Zoom to Extent"
          className="naxatw-rounded-lg naxatw-bg-white naxatw-p-2"
          onClick={() => {
            if (!map || !buildingBbox) return;
            map.fitBounds(buildingBbox as LngLatBoundsLike);
          }}
        />
      </FlexColumn>
    </FlexColumn>
  );
}
