/* eslint-disable import/prefer-default-export */

export const avatarColors = [
  '#0088F8',
  '#008080',
  '#AB47BC',
  '#FFB72A',
  '#652020',
  '#008000',
  '#FFB72A',
  '#ffff00',
  '#800080',
  '#40B449',
  '#ff00ff',
  '#00ffff',
  '#ffa500',
];
