import { createSelector } from 'reselect';
import { buildingDataFormIndex } from '@Constants/dataManagementForm';
import { RootState } from '..';

const activeIndexSelector = (state: RootState) =>
  state.dataManagementForm.activeIndex;

const isAssociateDissociateTypeSelector = (state: RootState) =>
  state.dataManagementForm.isAssociateDissociateType;

export const activeTabNameSelector = createSelector(
  [activeIndexSelector, isAssociateDissociateTypeSelector],
  (activeIndex, isAssociateDissociateType) => {
    const activeTabName = buildingDataFormIndex[activeIndex];
    return isAssociateDissociateType && activeTabName === 'access'
      ? 'main_building'
      : activeTabName;
  },
);

export const nothing = '';
