/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Popup } from 'maplibre-gl';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import { apiURL } from '@Services/index';
import { getPalikaWardList } from '@Services/dashboard';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import SymbolLayer from '@Components/common/MapLibreComponents/Layers/SymbolLayer';
import Select from '@Components/common/FormUI/Select';
import {
  style,
  symbolStyle,
  selectOptions,
  numberingStatusPaintProperty,
  buidlingCountColors,
  buildingDensityColors,
} from '@Components/Dashboard/MapSection/constants';
import { Button } from '@Components/RadixComponents/Button';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { useTypedSelector } from '@Store/hooks';
import { selectedWardSelector } from '@Store/selector/common';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import { useGetPalikaWardListQuery } from '@Api/dashboard';

interface ILegendComponent {
  color: string;
  value: string;
}
interface IgenerateLegendData {
  min: number;
  max: number;
  range: number;
  colors: string[];
}

function generateLegendData({ min, max, range, colors }: IgenerateLegendData) {
  const legendData = [];
  const difference = max - min;
  const average = difference / range;
  for (let i = 0; i < range; i += 1) {
    const low = min + average * i;
    const high = min + average * (i + 1);
    legendData.push({
      color: colors[i] || '#333',
      label: `${Math.ceil(low)} - ${Math.ceil(high)}`,
      value: Math.ceil(high),
    });
  }
  return legendData;
}

const LegendComponent = ({ color, value }: ILegendComponent) => {
  return (
    <div className="naxatw-flex naxatw-flex-row naxatw-gap-1 naxatw-text-sm naxatw-text-grey-800">
      <div
        className="naxatw-h-5 naxatw-w-5"
        style={{ backgroundColor: color }}
      />
      {value}
    </div>
  );
};

function MapSection() {
  const { width } = useWindowDimensions();
  const [wardNo, setWardNo] = useState(null);
  const [isWardLayerLoaded, setIsWardLayerLoaded] = useState(false);
  const [isSymbolLayerLoaded, setIsSymbolLayerLoaded] = useState(false);
  const [toggleLegend, setToggleLegend] = useState(false);
  const [legendData, setLegendData] = useState<Record<string, any>[]>([]);
  const [selectedOption, setselectedOption] = useState('Building Count');

  const selectedWards = useTypedSelector(selectedWardSelector);

  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 6,
      center: [84.124, 28.3949],
      maxZoom: 17,
    },
  });

  // zoom to palika boundary on initial map load
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingBbox) return;
    const { data } = buildingBbox;
    if (!data) return;
    const [x1, y1, x2, y2] = data;
    map.fitBounds(
      [
        [x1, y1],
        [x2, y2],
      ],
      { duration: 0, padding: 80 },
    );
  }, [map, isMapLoaded, buildingBbox]);

  const { data: palikaWardList } = useGetPalikaWardListQuery(
    selectedWards?.join(','),
    { enabled: !!selectedWards },
  );

  useEffect(() => {
    if (!palikaWardList) return;
    switch (selectedOption) {
      case 'Building Count': {
        setLegendData(
          generateLegendData({
            min: Math.min(...palikaWardList.map((item: any) => item.buildings)),
            max: Math.max(...palikaWardList.map((item: any) => item.buildings)),
            range: 3,
            colors: buidlingCountColors,
          }),
        );
        break;
      }
      case 'Building Density': {
        setLegendData(
          generateLegendData({
            min: Math.min(...palikaWardList.map((item: any) => item.density)),
            max: Math.max(...palikaWardList.map((item: any) => item.density)),
            range: 3,
            colors: buildingDensityColors,
          }),
        );
        break;
      }
      case 'Numbering Status': {
        setLegendData([
          { color: '#fff8e3', label: 'Not Started' },
          { color: '#badfff', label: 'Ongoing' },
          { color: '#5bb6ff', label: 'Completed' },
        ]);
        break;
      }
      default:
    }
  }, [palikaWardList, selectedOption]);

  useEffect(() => {
    if (!map || !legendData.length) return;
    const propertyType =
      selectedOption === 'Building Count'
        ? 'buildings'
        : selectedOption === 'Building Density'
          ? 'density'
          : 'numbering_status';
    map.setPaintProperty(
      'palika-ward-boundary',
      'fill-color',
      propertyType === 'numbering_status'
        ? numberingStatusPaintProperty
        : [
            'step',
            ['get', propertyType],
            ...legendData
              .reduce(
                (arr: any, item) => [...arr, [item.color, item.value]],
                [],
              )
              .flat()
              .slice(0, -1),
          ],
    );
  }, [map, legendData, selectedOption]);

  const renderLegend = () => {
    return (
      <div className="naxatw-flex naxatw-flex-col naxatw-gap-2">
        {legendData.map(({ color, label }) => (
          <LegendComponent key={label} color={color} value={label} />
        ))}
      </div>
    );
  };

  const handleSelect = (updatedValue: string) => {
    setselectedOption(updatedValue);
  };

  useEffect(() => {
    if (!map) return;
    const popup = new Popup({ closeButton: false });
    map?.on('mousemove', 'palika-ward-boundary', e => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['palika-ward-boundary'],
      });
      if (features.length > 0) {
        const feature = features[0];
        const description = feature.properties.buildings;
        popup
          .setLngLat(e.lngLat)
          .setHTML(
            `<div style="padding:6px 12px; font-weight:700;">${selectedOption}: ${description}</div>`,
          )
          .addTo(map);
      }
    });

    map.on('mouseleave', 'palika-ward-boundary', () => {
      map.getCanvas().style.cursor = '';
      popup.remove();
    });
  }, [map, selectedOption]);

  // set setIsWardLayerLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'palika-ward-boundary') {
        // setIsWardLayerLoaded to true if ev has tile property
        setIsWardLayerLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // check if symbol layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'palika-ward-boundary-symbol') {
        setIsSymbolLayerLoaded(true);
      }
    });
  }, [map]);

  const isLoading = useDebouncedLoadingState(
    !isWardLayerLoaded || !isSymbolLayerLoaded || !isMapLoaded,
  );

  return (
    <div className="naxatw-relative naxatw-h-full naxatw-w-full naxatw-animate-fade-up naxatw-rounded-xl naxatw-bg-grey-50">
      <Button
        leftIcon="legend_toggle"
        onClick={() => setToggleLegend(!toggleLegend)}
        className="naxatw-absolute naxatw-bottom-2 naxatw-right-2 naxatw-z-10 md:naxatw-hidden"
      >
        Legend
      </Button>
      {!!legendData.length && (
        <div
          className={`naxatw-pointer-events-auto naxatw-z-20  naxatw-rounded-lg naxatw-border naxatw-bg-white
       naxatw-px-3 naxatw-py-2 naxatw-shadow-lg md:naxatw-absolute md:naxatw-bottom-2 md:naxatw-left-2 md:naxatw-w-52
       ${
         toggleLegend
           ? 'naxatw-fixed naxatw-bottom-0 naxatw-left-0 naxatw-right-0 naxatw-w-full naxatw-rounded-t-xl naxatw-shadow-top'
           : 'naxatw-hidden md:naxatw-block'
       }
       `}
        >
          <h5>Legend</h5>
          <Select
            options={prepareDropdownOptions(selectOptions)}
            onChange={handleSelect}
            selectedOption={selectedOption}
            placeholder="Select"
          />
          <div className="naxatw-py-3">{renderLegend()}</div>
        </div>
      )}
      {/* map loadder */}
      {isLoading && <MapLoader />}
      <MapContainer
        map={map}
        isMapLoaded={isMapLoaded}
        style={{ width: '100%', height: width <= 390 ? '390px' : '490px' }}
      >
        <VectorTileLayer
          key="palika-ward-boundary"
          id="palika-ward-boundary"
          url={
            selectedWards?.length
              ? `${apiURL}/palika-ward-boundary/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                  ',',
                )}`
              : `${apiURL}/palika-ward-boundary/{z}/{x}/{y}/`
          }
          layerOptions={style}
          visibleOnMap
          interactions={['select']} // add cursor pointer on layer hover
        />
        <SymbolLayer
          key="palika-ward-boundary-symbol"
          id="palika-ward-boundary-symbol"
          url={
            selectedWards?.length
              ? `${apiURL}/palika-ward-boundary/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                  ',',
                )}`
              : `${apiURL}/palika-ward-boundary/{z}/{x}/{y}/`
          }
          layerOptions={symbolStyle}
          visibleOnMap
        />
      </MapContainer>
    </div>
  );
}

export default hasErrorBoundary(MapSection);
