import { useTypedSelector } from '@Store/hooks';
import { FlexRow, FlexColumn } from '@Components/common/Layouts';
import LegendItem from '@Components/common/Charts/LegendItem';
import getLayerStyleColors from '@Components/common/MapLibreComponents/helpers/getLayerStyleColors';

export default function ExportLegend() {
  const orientation = useTypedSelector(state => state.mapExport.orientation);
  const layers = useTypedSelector(state => state.visualization.layers);
  return (
    <div className="naxatw-px-4">
      <h5>Legends</h5>
      <div
        className={`naxatw-flex ${
          orientation === 'landscape'
            ? 'naxatw-flex-col naxatw-gap-3'
            : 'naxatw-flex-row naxatw-gap-8'
        }`}
      >
        {layers
          .filter(layer => layer.checked === true)
          .map(({ id, name, styles }) => {
            const layerColors = getLayerStyleColors(styles);
            return (
              <FlexColumn key={id} className="naxatw-mt-1.5">
                <span className="naxatw-font-primary naxatw-text-body-btn">
                  {name}
                </span>
                <FlexColumn
                  gap={3}
                  className="naxatw-mt-1.5 naxatw-flex naxatw-flex-col"
                >
                  {/* legend items */}
                  {layerColors?.map(({ color, label }) => (
                    <FlexRow
                      gap={1}
                      className="naxatw-items-center"
                      key={label}
                    >
                      <LegendItem
                        color={color}
                        // @ts-ignore
                        type={styles?.type || 'fill'}
                      />
                      <p className="naxatw-text-body-sm naxatw-text-grey-600">
                        {label}
                      </p>
                    </FlexRow>
                  ))}
                </FlexColumn>
              </FlexColumn>
            );
          })}
      </div>
    </div>
  );
}
