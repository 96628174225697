import { FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import JanakpurMuniLogo from '@Assets/images/janakpur-muni-logo.svg';
import DemoMuniLogo from '@Assets/images/demo-muni-logo.png';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import LanguageToggle from '@Components/common/LanguageToggle';
import { setCommonState } from '@Store/actions/common';
import useTranslation from '@Hooks/useTranslation';
import { NavLink } from 'react-router-dom';

const { PROJECT } = process.env;

export default function PublicPageHeader() {
  const dispatch = useTypedDispatch();
  const language = useTypedSelector(state => state.common.language);
  const { _t } = useTranslation();
  return (
    <FlexRow className="naxatw-flex-col naxatw-items-center naxatw-justify-between naxatw-border-b-[0.5px] naxatw-border-gray-300 naxatw-px-4 naxatw-py-4 md:naxatw-flex-row">
      <Image
        src={
          PROJECT?.toLowerCase() === 'janakpur'
            ? JanakpurMuniLogo
            : DemoMuniLogo
        }
        className="naxatw-hidden naxatw-w-[60%] md:naxatw-block md:naxatw-h-16 md:naxatw-w-full"
      />
      <FlexRow className="naxatw-items-center naxatw-gap-6">
        <NavLink
          to="https://janakpurmun.gov.np/en"
          target="_blank"
          rel="noopener noreferrer"
          className="naxatw-rounded-2xl naxatw-px-4 naxatw-py-2 naxatw-text-sm naxatw-font-bold naxatw-text-gray-600 hover:naxatw-bg-primary-300"
        >
          {_t('Home')}
        </NavLink>
        <NavLink
          to="http://dmaps.org"
          target="_blank"
          rel="noopener noreferrer"
          className="naxatw-rounded-2xl naxatw-px-4 naxatw-py-2 naxatw-text-sm naxatw-font-bold naxatw-text-gray-600 hover:naxatw-bg-primary-300"
        >
          {_t('About Us')}
        </NavLink>
        <NavLink
          to="/login"
          className="naxatw-rounded-2xl naxatw-px-4 naxatw-py-2 naxatw-text-sm naxatw-font-bold naxatw-text-gray-600 hover:naxatw-bg-primary-300"
        >
          {_t('Login')}
        </NavLink>

        <LanguageToggle
          selectedLanguage={language}
          languages={['English', 'Nepali']}
          onLanguageChange={changedLanguage => {
            dispatch(setCommonState({ language: changedLanguage }));
          }}
        />
      </FlexRow>
    </FlexRow>
  );
}
