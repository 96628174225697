/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  BuildingFormKeys,
  FormOptionsRequestDataType,
  FormType,
  RoadFormKeys,
  buildingFormModal,
  roadFormModal,
} from '@Constants/dataManagementForm';
import { DrawModeTypes } from '@Components/common/MapLibreComponents/types';
import { defaultLayersDataPublicPage, ILayerState } from '@Constants/map';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export type FormStateType =
  | Record<BuildingFormKeys | RoadFormKeys, any>
  | Record<string, any>;

export type ChangeStyleType = {
  layerId: string | number;
  styles: Record<string, any>;
};

export type DataManagementFormState = {
  stepIndex: number;
  activeIndex: number;
  formType: FormType;
  formState: FormStateType;
  drawnGeom: any;
  selectedRoadId: number | null;
  selectedBuildingId: number | null;
  hasGeomChanged: boolean;
  hasSelectedRoadIdChanged: boolean;
  hasSelectedBuildingIdChanged: boolean;
  dropdownOptions: Record<string, any>;
  drawType: DrawModeTypes;
  enableRoadSelect: boolean;
  enableBuildingSelect: boolean;
  gateNextBtnClick: boolean;
  isSubmitClicked: boolean;
  activeBaseLayer: string;
  inputCoordinates: number[];
  geolocation: Record<string, any> | null;
  zoomToLocationType: 'geolocation' | 'user-input' | null;
  layers: ILayerState[];
  changeStyle: ChangeStyleType | Record<string, any>;
  fetchedGeojson: any;
  featureId: number | null;
  isAssociateDissociateType: boolean;
  refCentroid: any;
  existingRoadName: string | null;
  isRefCentroidValid: boolean;
};

const initialState: DataManagementFormState = {
  stepIndex: 0,
  activeIndex: 0,
  formState: {},
  drawnGeom: null, // save drawn geom
  selectedRoadId: null, // save selected road_id in form
  selectedBuildingId: null, // save selected building_id in form
  hasGeomChanged: false, // for tracking if the geom has changed while form edit
  hasSelectedRoadIdChanged: false, // for tracking if selected road_id has changed while form edit
  hasSelectedBuildingIdChanged: false, // for tracking if selected building_id has changed while form edit
  formType: 'building',
  drawType: null,
  dropdownOptions: {},
  layers: defaultLayersDataPublicPage,
  enableRoadSelect: false,
  enableBuildingSelect: false,
  gateNextBtnClick: false, // true on next button click
  isSubmitClicked: false, // true on next button click
  activeBaseLayer: 'satellite',
  inputCoordinates: [],
  geolocation: null, // [lat, lng]
  zoomToLocationType: null,
  changeStyle: {},
  fetchedGeojson: null,
  featureId: null, // fetched geojson featureId
  isAssociateDissociateType: false, // for building association_type
  refCentroid: null, // for ref centroid in 'access' step
  existingRoadName: null, // check existingRoadName in road form edit
  isRefCentroidValid: true, // check validation for ref centroid
};

const setDataManagementFormState: CaseReducer<
  DataManagementFormState,
  PayloadAction<Partial<DataManagementFormState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setActiveIndex: CaseReducer<
  DataManagementFormState,
  PayloadAction<number>
> = (state, { payload }) => ({
  ...state,
  activeIndex: payload,
  stepIndex: state.stepIndex > payload ? state.stepIndex : payload,
});

const setFormState: CaseReducer<
  DataManagementFormState,
  PayloadAction<Partial<FormStateType>>
> = (state, action) => ({
  ...state,
  formState: {
    ...state.formState,
    ...action.payload,
  },
});

const getDropdownOptionsSuccess: CaseReducer<
  DataManagementFormState,
  PayloadAction<{ key: string; data: Record<string, any> }[]>
> = (state, { payload }) => {
  payload.forEach(item => {
    const { key, data } = item;
    state.dropdownOptions[key] =
      typeof data?.[0] === 'object'
        ? data
        : prepareDropdownOptions(data, { labelKey: 'name', valueKey: 'alias' });
  });
};

const setFormStateData: CaseReducer<
  DataManagementFormState,
  PayloadAction<Record<string, any>>
> = (state, { payload }) => {
  state.formState = Object.keys(
    payload.formType === 'building' ? buildingFormModal : roadFormModal,
  ).reduce(
    (obj, key: any) => ({
      ...obj,
      [key]: payload[key] || '',
    }),
    {},
  );
  state.stepIndex = payload.formType === 'building' ? 4 : 3;
  state.drawType =
    payload.formType === 'building' ? 'draw_polygon' : 'draw_line_string';
};

const resetDataManagementFormState: CaseReducer<
  DataManagementFormState
> = () => ({ ...initialState });

const handleLayerSort: CaseReducer<
  DataManagementFormState,
  PayloadAction<DragEndEvent>
> = (state, action) => {
  const { active, over } = action.payload;
  if (!over?.id) return;
  if (active.id === over.id) return;
  const oldIndex = state.layers.findIndex(item => item.id === active.id);
  const newIndex = state.layers.findIndex(item => item.id === over.id);
  const sortedLayers = arrayMove(state.layers, oldIndex, newIndex);
  state.layers = sortedLayers;
  // @ts-ignore
  state.layerOrderChanged =
    newIndex > oldIndex ? [active.id, over.id] : [over.id, active.id];
};

const handleLayerToggle: CaseReducer<
  DataManagementFormState,
  PayloadAction<string | number>
> = (state, action) => {
  const id = action.payload;
  const toggledLayer = state.layers.find(item => item.id === id);
  if (!toggledLayer) return;
  toggledLayer.checked = !toggledLayer.checked;
};

const handleLabelToggle: CaseReducer<
  DataManagementFormState,
  PayloadAction<string | number>
> = (state, action) => {
  const layer = state.layers.find(item => item.id === action.payload);
  if (!layer) return;
  layer.showLabel = !layer.showLabel;
};

const handleLayerStyleChange: CaseReducer<
  DataManagementFormState,
  PayloadAction<ChangeStyleType>
> = (state, action) => {
  const { layerId, styles } = action.payload;
  const layer = state.layers.find(item => item.id === layerId);
  if (!layer) return;
  const keys = Object.keys(styles);
  if (!(keys[0] in layer.styles.paint)) return;
  layer.styles.paint = {
    ...layer.styles.paint,
    ...styles,
  };
  state.changeStyle = action.payload;
};

const dataManagementFormSlice = createSlice({
  name: 'dataManagementForm',
  initialState,
  reducers: {
    setDataManagementFormState,
    setActiveIndex,
    setFormState,
    getDropdownOptionsRequest(
      _,
      payload: PayloadAction<FormOptionsRequestDataType>,
    ) {},
    getDropdownOptionsSuccess,
    getDropdownOptionsFailure() {},
    postFormDataRequest(
      _,
      payload: PayloadAction<{
        formType: FormType;
        data: FormStateType;
        navigate: NavigateFunction;
        pathname?: string;
        redirectPath?: string;
      }>,
    ) {},
    postFormDataSuccess() {},
    postFormDataFailure() {},
    updateFormDataRequest(
      _,
      payload: PayloadAction<{
        formType: FormType;
        formId: string;
        data: FormStateType;
        navigate: NavigateFunction;
        pathname?: string;
        redirectPath?: string;
      }>,
    ) {},
    updateFormDataSuccess() {},
    updateFormDataFailure() {},
    setFormStateData,
    getBuildingDataByIdRequest(_, payload: PayloadAction<string>) {},
    getBuildingDataByIdSuccess() {},
    getBuildingDataByIdFailure() {},
    getRoadDataByIdRequest(_, payload: PayloadAction<string>) {},
    getRoadDataByIdSuccess() {},
    getRoadDataByIdFailure() {},
    resetDataManagementFormState,
    handleLayerSort,
    handleLayerToggle,
    handleLabelToggle,
    handleLayerStyleChange,
  },
});

export { dataManagementFormSlice };

export default dataManagementFormSlice.reducer;
