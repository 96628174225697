import { all } from 'redux-saga/effects';
import visualizationWatcher from './visualization';
import dataManagementFormWatcher from './dataManagementForm';
import overlayLayersWatcher from './overlayLayers';

export default function* rootSaga() {
  yield all([
    visualizationWatcher(),
    dataManagementFormWatcher(),
    overlayLayersWatcher(),
  ]);
}
