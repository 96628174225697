import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import useTranslation from '@Hooks/useTranslation';
import { setOverlayLayerState } from '@Store/actions/dataManagement';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { useNavigate } from 'react-router-dom';

export default function FilterSection() {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const searchInput = useTypedSelector(
    state => state.dataManagement.overlayLayerData.searchInput,
  );
  const permissions = useTypedSelector(state => state.user.permissions);
  return (
    <FlexColumn>
      <FlexRow className="naxatw-mb-3 naxatw-flex-col naxatw-justify-between md:naxatw-flex-row">
        <FlexRow className="naxatw-group naxatw-relative naxatw-w-full naxatw-items-center naxatw-border-b-2 naxatw-px-3 hover:naxatw-border-b-primary-400 md:naxatw-w-1/4">
          <Icon
            name="search"
            className="naxatw-text-grey-500 group-hover:naxatw-text-primary-400"
          />
          <Input
            type="text"
            className="naxatw-w-full naxatw-border-none"
            value={searchInput}
            onChange={e =>
              dispatch(setOverlayLayerState({ searchInput: e.target.value }))
            }
          />
        </FlexRow>
        <FlexRow className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-pt-3 md:naxatw-py-0">
          {permissions.includes('add_vectorlayer') && (
            <Button leftIcon="add" onClick={() => navigate('form')}>
              {_t('Add Layers')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>
    </FlexColumn>
  );
}
