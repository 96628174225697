import { useNavigate } from 'react-router-dom';

import DataTable from '@Components/common/DataTable';
import Switch from '@Components/RadixComponents/Switch';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { FlexRow } from '@Components/common/Layouts';
import { getOverlayLayerDataList } from '@Services/dataManagement';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { setLayerState } from '@Store/actions/overlayLayer';
import { togglePromptDialog } from '@Store/actions/common';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';

function TableSection() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { width } = useWindowDimensions();

  const permissions = useTypedSelector(state => state.user.permissions);
  const searchInput = useTypedSelector(
    state => state.dataManagement.overlayLayerData.searchInput,
  );

  const overlayLayerColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Display Name',
      accessorKey: 'display_name_en',
    },
    {
      header: 'Category',
      accessorKey: 'category',
    },
    {
      header: 'Data Group',
      accessorKey: 'data_group',
    },
    {
      header: 'Data Source',
      accessorKey: 'data_source',
    },
    {
      header: 'Count',
      accessorKey: 'count',
    },
    {
      header: 'Default Load',
      accessorKey: 'default_load',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.default_load} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Public View',
      accessorKey: 'is_public',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_public} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Download',
      accessorKey: 'is_downloadable',
      // eslint-disable-next-line react/no-unstable-nested-components,
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_downloadable} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: ({ row }: any) => (
        <FlexRow className="naxatw-cursor-pointer naxatw-justify-around">
          <ToolTip name="info" message="More info" />

          {permissions.includes('change_vectorlayer') && (
            <ToolTip
              name="edit"
              message="Edit"
              onClick={() => {
                navigate(
                  `/data-management/overlay-layer/${row?.original?.id}/`,
                );
              }}
            />
          )}
          {permissions.includes('delete_vectorlayer') && (
            <ToolTip
              name="delete"
              message="Delete"
              iconClassName="naxatw-text-red-400 hover:naxatw-text-red-500"
              onClick={() => {
                dispatch(togglePromptDialog('delete-layer'));
                dispatch(
                  setLayerState({
                    selectedLayer: row.original,
                  }),
                );
              }}
            />
          )}
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={overlayLayerColumns}
      queryKey="overlay-layer-list"
      queryFn={getOverlayLayerDataList}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      wrapperStyle={{
        height: width <= 390 ? '60vh' : 'calc(100vh - 11.5rem)',
      }}
      searchInput={searchInput}
    />
  );
}

export default hasErrorBoundary(TableSection);
