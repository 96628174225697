import { useState } from 'react';
import { useQueries } from '@tanstack/react-query';
import { useTypedDispatch } from '@Store/hooks';
import { setPublicPageState } from '@Store/actions/publicPage';
import {
  filterDropdownOptions,
  filterDropdownOptionsIndex,
} from '@Constants/publicPage';
import { Button } from '@Components/RadixComponents/Button';
import { MultiSelect } from '@Components/common/FormUI';
import { FlexRow } from '@Components/common/Layouts';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import useTranslation from '@Hooks/useTranslation';

export default function FilterSection() {
  const { _t } = useTranslation();
  const [filterStates, setFilterStates] = useState({});
  const [toggleFilter, setToggleFilter] = useState(false);
  const dispatch = useTypedDispatch();

  const dropdownOptions = useQueries({
    queries: filterDropdownOptions.map(({ queryKey, params, queryFn }) => {
      return {
        queryKey,
        queryFn: () =>
          queryFn(params).then(res =>
            typeof res.data?.[0] === 'object'
              ? res.data
              : prepareDropdownOptions(res.data, {
                  labelKey: 'name',
                  valueKey: 'alias',
                }),
          ),
      };
    }),
  });

  const handleApply = () => {
    dispatch(
      setPublicPageState({
        filterParams: filterStates,
      }),
    );
  };

  const handleClearFilter = () => {
    setFilterStates({});
    dispatch(
      setPublicPageState({
        filterParams: {},
      }),
    );
  };

  return (
    <>
      <FlexRow className="naxatw-w-full naxatw-items-center naxatw-justify-end naxatw-py-3 naxatw-pr-3 lg:naxatw-hidden">
        <Button
          leftIcon="tune"
          className="naxatw-font-bold naxatw-text-blue-500 lg:naxatw-hidden"
          variant="outline"
          onClick={() => setToggleFilter(!toggleFilter)}
        >
          Filter
        </Button>
      </FlexRow>
      <FlexRow
        gap={8}
        className={`${
          !toggleFilter && 'naxatw-hidden lg:naxatw-flex'
        } naxatw-fixed naxatw-bottom-0 naxatw-right-0 naxatw-z-50 naxatw-w-full naxatw-flex-col naxatw-items-center naxatw-justify-center naxatw-rounded-lg naxatw-bg-grey-50 naxatw-px-4 naxatw-py-2 lg:naxatw-sticky lg:naxatw-left-0 lg:naxatw-top-0 lg:naxatw-flex-row lg:naxatw-justify-center lg:naxatw-px-4`}
      >
        <FlexRow
          gap={5}
          className="naxatw-flex-collg:naxatw-flex naxatw-flex naxatw-w-full lg:naxatw-w-[40rem] lg:naxatw-flex-row lg:naxatw-py-1"
        >
          <p className="naxatw-mb-[-10px] naxatw-text-[17px] naxatw-font-bold naxatw-text-[#212121] lg:naxatw-hidden">
            Filter
          </p>
          {filterDropdownOptions.map(
            ({ queryKey, className, paramKey, placeholder }) => {
              const id = queryKey[0];
              const index = filterDropdownOptionsIndex[id];
              return (
                <MultiSelect
                  className={` ${className} !naxatw-bg-grey-50  `}
                  key={id}
                  options={dropdownOptions[index]?.data || []}
                  valueKey="name"
                  labelKey="alias"
                  placeholder={_t(placeholder)}
                  onChange={selectedValue => {
                    setFilterStates(prev => ({
                      ...prev,
                      [paramKey]: selectedValue,
                    }));
                  }}
                  // @ts-ignore
                  selectedOptions={filterStates?.[paramKey] || []}
                />
              );
            },
          )}
        </FlexRow>
        <FlexRow
          gap={2}
          className="naxatw-w-full naxatw-justify-end lg:naxatw-flex lg:naxatw-w-auto lg:naxatw-justify-center"
        >
          <Button onClick={handleApply}>{_t('Apply')}</Button>
          <Button variant="outline" onClick={handleClearFilter}>
            {_t('Clear')}
          </Button>
        </FlexRow>
      </FlexRow>
    </>
  );
}
