export const orthoPhotoRasterId = process.env.ORTHOPHOTO_RASTER_ID || 1;

export const refCentroidDistanceThreshold = 111; // in meter

const lineColor = '#EC7FA0';
const wardBoundaryColor = '#EC7F00';
const palikaBoundaryColor = '#0044EC ';
const measureColor = '#fbb64b';
export const startPointColor = '#FF9525';
export const endPointColor = '#e55e5e';

export const mainBuildingColor = '#FF9525';

export function getBuildingColor(type: string) {
  const buildingType = type?.toLowerCase();
  switch (buildingType) {
    case 'main':
      return mainBuildingColor;
    case 'associate':
      return '#0664D2';
    case 'dissociate':
      return '#AB47BC';
    default:
      return '#333333';
  }
}

export function getRoadColor(type: string) {
  const roadType = type?.toLowerCase();
  switch (roadType) {
    case 'major':
      return '#0664D2';
    case 'minor':
      return '#42B4A2';
    case 'subsidiary':
      return '#333333';
    default:
      return '#333333';
  }
}

export const buildingStyles = {
  type: 'fill-extrusion',
  paint: {
    'fill-extrusion-opacity': 0.5,
    'fill-extrusion-color': [
      'match',
      ['get', 'association_type'],
      'main',
      '#FF9525',
      'associate',
      '#570987',
      'dissociate',
      '#347C98',
      '#333333',
    ],
    'fill-extrusion-height': [
      '*',
      ['max', 1, ['coalesce', ['get', 'floor'], 3]], // If 'floor' property is null or less than 1, use 1 as the default value
      3,
    ],
  },
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['step', ['zoom'], '', 17, ['get', 'house_no']],
    'text-size': 13,
    'text-anchor': 'center',
  },
};

export const roadStyles = {
  type: 'line',
  paint: {
    'line-opacity': 1,
    'line-color': [
      'match',
      ['get', 'road_category'],
      'major',
      '#838383',
      'minor',
      '#CDCDC7',
      'subsidiary',
      '#fff',
      '#333333',
    ],
    'line-width': [
      'match',
      ['get', 'road_category'],
      'major',
      6,
      'minor',
      4,
      'subsidiary',
      3,
      3,
    ],
  },
  layout: {
    'text-font': ['Open Sans Regular', 'Arial Unicode MS Regular'],
    'text-field': ['step', ['zoom'], '', 17, ['get', 'road_name_en']],
    'text-size': 13,
    'text-anchor': 'center',
    'symbol-placement': 'line-center',
  },
};

export const wardPalikaBoundaryStyles = {
  type: 'line',
  layout: {},
  paint: {
    'line-opacity': 1,
    'line-color': wardBoundaryColor,
    'line-width': 3,
  },
};

export const palikaBoundaryStyles = {
  type: 'line',
  layout: {},
  paint: {
    'line-opacity': 1,
    'line-color': palikaBoundaryColor,
    'line-dasharray': [1, 0.1],
    'line-width': 5,
  },
};

export const defaultLayersData = [
  {
    id: 'building',
    name: 'Building',
    endPoint: 'building-vector-tile',
    checked: true,
    styles: buildingStyles,
    showLabel: true,
    showOutline: true,
  },
  {
    id: 'road',
    name: 'Road',
    endPoint: 'road-vector-tile',
    checked: true,
    styles: roadStyles,
    showLabel: true,
  },
  {
    id: 'palika-ward-boundary',
    name: 'Ward Boundary',
    endPoint: 'palika-ward-boundary',
    checked: true,
    styles: wardPalikaBoundaryStyles,
    showLabel: false,
  },
  {
    id: 'palika-boundary',
    name: 'Palika Boundary',
    endPoint: 'palika-boundary',
    checked: true,
    styles: palikaBoundaryStyles,
    showLabel: false,
  },
];

export const defaultLayersDataPublicPage = [
  {
    id: 'building',
    name: 'Building',
    endPoint: 'building-vector-tile',
    checked: true,
    styles: {
      ...buildingStyles,
      paint: {
        ...buildingStyles.paint,
        'fill-extrusion-height': 0,
      },
    },
    showLabel: false,
    showOutline: true,
  },
  {
    id: 'road',
    name: 'Road',
    endPoint: 'road-vector-tile',
    checked: true,
    styles: roadStyles,
    showLabel: false,
  },
  {
    id: 'palika-ward-boundary',
    name: 'Ward Boundary',
    endPoint: 'palika-ward-boundary',
    checked: true,
    styles: wardPalikaBoundaryStyles,
    showLabel: false,
  },
  {
    id: 'palika-boundary',
    name: 'Palika Boundary',
    endPoint: 'palika-boundary',
    checked: true,
    styles: palikaBoundaryStyles,
    showLabel: false,
  },
];

export interface ILayerState {
  id: string | number;
  name: string;
  endPoint: string;
  checked: boolean;
  styles: Record<string, any>;
  showLabel: boolean;
  showOutline?: boolean;
  type?: string;
  dynamicStyle?: Record<string, any>[];
  isSubLayerActive?: boolean;
  dynamicSubLayerStyle?: Record<string, any>[];
}

const buildingColor = '#0077E4';
const roadColor = '#0077E4';
const gateColor = '#FF9525';
export const userLocationColor = '#0088F8';
export const userInputColor = '#FF9525';

export const drawnBuildingStyle = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': buildingColor,
    'fill-opacity': 1,
  },
};

export const drawnRoadStyle = {
  type: 'line',
  layout: {},
  paint: {
    'line-color': roadColor,
    'line-width': 3,
  },
};

export const drawnGateLocationStyle = {
  type: 'circle',
  layout: {},
  paint: {
    'circle-radius': 7,
    'circle-color': gateColor,
  },
};

export const drawStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': lineColor,
      'fill-outline-color': lineColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': lineColor,
      'fill-outline-color': lineColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': lineColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-dasharray': [0.2, 2],
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-dasharray': [0.2, 2],
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': lineColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': lineColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];
export const measureStyles = [
  {
    id: 'gl-draw-polygon-fill-inactive',
    type: 'fill',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'fill-color': measureColor,
      'fill-outline-color': measureColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-fill-active',
    type: 'fill',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': measureColor,
      'fill-outline-color': measureColor,
      'fill-opacity': 0.5,
    },
  },
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'midpoint']],
    paint: {
      'circle-radius': 5,
      'circle-color': measureColor,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Polygon'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 4,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-dasharray': [0.2, 2],
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-inactive',
    type: 'line',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'LineString'],
      ['!=', 'mode', 'static'],
    ],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['==', 'active', 'true']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-dasharray': [0.2, 2],
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-polygon-and-line-vertex-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'meta', 'vertex'],
      ['==', '$type', 'Point'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 3,
      'circle-color': measureColor,
    },
  },
  {
    id: 'gl-draw-point-point-stroke-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-opacity': 1,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-inactive',
    type: 'circle',
    filter: [
      'all',
      ['==', 'active', 'false'],
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['!=', 'mode', 'static'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-point-stroke-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'active', 'true'],
      ['!=', 'meta', 'midpoint'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#fff',
    },
  },
  {
    id: 'gl-draw-point-active',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['!=', 'meta', 'midpoint'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': gateColor,
    },
  },
  {
    id: 'gl-draw-polygon-fill-static',
    type: 'fill',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    paint: {
      'fill-color': '#404040',
      'fill-outline-color': '#404040',
      'fill-opacity': 0.1,
    },
  },
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#404040',
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-line-static',
    type: 'line',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': measureColor,
      'line-width': 3,
    },
  },
  {
    id: 'gl-draw-point-static',
    type: 'circle',
    filter: ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point']],
    paint: {
      'circle-radius': 5,
      'circle-color': '#404040',
    },
  },
];
