import './styles.css';

export default function MapLoader() {
  return (
    <div
      className="naxatw-absolute naxatw-top-5 naxatw-z-50 naxatw-flex naxatw-w-full 
        naxatw-items-center naxatw-justify-center"
    >
      <div
        className="naxatw-flex naxatw-items-center naxatw-justify-center naxatw-rounded-lg
          naxatw-bg-white naxatw-px-9 naxatw-py-4 naxatw-shadow-3xl naxatw-shadow-gray-300"
      >
        <div className="dot-pulse" />
      </div>
    </div>
  );
}
