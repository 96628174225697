import { labelMappings } from '@Constants/index';
import capitalizeFirstLetter from './capitalizeFirstLetter';

export default function prepareChartData(data: any) {
  return Object.entries(data).map(([key, val]) => ({
    // id: key,
    name:
      key in labelMappings ? labelMappings[key] : capitalizeFirstLetter(key),
    value: val,
  }));
}
