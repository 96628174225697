import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleInfoDialog, togglePromptDialog } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { handleLayerTableDelete } from '@Queries/overlayLayers';
import { deleteVectorLayer } from '@Services/overlayLayers';

const deleteConfirmationText = 'delete';

export default function DeleteLayer() {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const [input, setInput] = useState('');

  const selectedLayer = useTypedSelector(
    state => state.overlayLayer.selectedLayer,
  );

  const handlePromptClose = () => {
    dispatch(togglePromptDialog());
    dispatch(toggleInfoDialog('delete-layer-success'));
  };

  // update query cache on delete
  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      handleLayerTableDelete(queryClient, payloadData),
  });

  const { mutate: deleteOverlayLayerRequest, isLoading } = useMutation({
    mutationFn: (id: number) => deleteVectorLayer(id),
    onSuccess: () => {
      handlePromptClose();
      if (!selectedLayer?.id) return;
      updateTableData({ id: selectedLayer.id });
    },
  });

  const handleLayerDelete = () => {
    if (!selectedLayer?.id) return;
    if (deleteConfirmationText !== input) return;
    deleteOverlayLayerRequest(selectedLayer.id);
  };

  return (
    <FlexColumn gap={2} className="naxatw-text-sm">
      <p className=" naxatw-text-grey-600">
        Are you sure want to delete {selectedLayer?.display_name_en} ?
      </p>
      <p>
        Please type &quot;
        <span className="naxatw-select-none">{deleteConfirmationText}</span>
        &quot; to confirm
      </p>
      <Input
        type="text"
        placeholder="delete"
        onPaste={e => e.preventDefault()}
        onChange={e => setInput(e.target.value)}
      />
      <FlexRow className=" naxatw-w-full naxatw-justify-end naxatw-pt-5">
        <Button variant="ghost" onClick={() => dispatch(togglePromptDialog())}>
          Cancel
        </Button>
        <Button
          leftIcon="delete"
          variant="destructive"
          withLoader
          isLoading={isLoading}
          onClick={handleLayerDelete}
          disabled={deleteConfirmationText !== input}
          className="disabled:!naxatw-pointer-events-auto disabled:!naxatw-cursor-not-allowed"
        >
          Confirm
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
