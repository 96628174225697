import { createAction } from '@reduxjs/toolkit';
import { visualizationSlice } from '../slices/visualization';

type GetMapPopupDataRequestPayload = { id: number; type: string };

export const getMapPopupDataRequest =
  createAction<GetMapPopupDataRequestPayload>(
    'visualization/getMapPopupDataRequest',
  );

export const {
  setVisualizationState,
  setOverlayLayersState,
  getMapPopupDataSuccess,
  getMapPopupDataFailure,
  handleLayerSort,
  handleLayerToggle,
  handleLayerStyleChange,
  handle3DToggle,
  handleLabelToggle,
  getRouteDataRequest,
  getRouteDataSuccess,
  getRouteDataFailure,
} = visualizationSlice.actions;
