import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import getLayerStyleColors from '@Components/common/MapLibreComponents/helpers/getLayerStyleColors';
import LegendItem from '@Components/common/MapLibreComponents/LegendItem';
import { setPublicPageState } from '@Store/actions/publicPage';

export default function MapLegend() {
  const dispatch = useTypedDispatch();
  const showLegend = useTypedSelector(state => state.publicPage.showLegend);
  const layers = useTypedSelector(state => state.publicPage.layers);

  return (
    <FlexColumn
      className={`naxatw-absolute naxatw-left-4 naxatw-z-20 naxatw-w-fit naxatw-rounded-lg naxatw-bg-white naxatw-p-2 ${
        showLegend
          ? 'naxatw-top-[calc(100%-12.5rem)]'
          : 'naxatw-top-[calc(100%-6rem)]'
      }`}
    >
      <div className="naxatw-h-full">
        <FlexRow
          className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-between"
          onClick={() =>
            dispatch(setPublicPageState({ showLegend: !showLegend }))
          }
        >
          <p className="naxatw-text-sm naxatw-font-bold">Legend</p>
          <Icon name={showLegend ? 'expand_more' : 'expand_less'} />
        </FlexRow>
        {showLegend && (
          <FlexRow gap={5}>
            {layers
              .filter(
                layer =>
                  layer.id !== 'palika-ward-boundary' &&
                  layer.id !== 'palika-boundary',
              )
              .map(({ id, name, styles }) => {
                const layerColors = getLayerStyleColors(styles);
                return (
                  <FlexColumn
                    key={`item-${id}`}
                    className="naxatw-rounded-sm naxatw-py-1.5 naxatw-pl-1"
                    gap={1.5}
                  >
                    <p className="naxatw-text-xs naxatw-font-bold">{name}</p>
                    <FlexColumn>
                      {styles && (
                        <FlexColumn gap={1} className="naxatw-px-1.5">
                          {/* legend items */}
                          {layerColors?.map(({ color, label }) => (
                            <FlexRow
                              key={label}
                              gap={1}
                              className="naxatw-h-5 naxatw-items-center"
                            >
                              <LegendItem
                                color={color}
                                type={styles?.type || 'fill'}
                                // className="naxatw-h-2.5"
                              />
                              <p className="naxatw-text-body-sm naxatw-text-grey-600">
                                {label}
                              </p>
                            </FlexRow>
                          ))}
                        </FlexColumn>
                      )}
                    </FlexColumn>
                  </FlexColumn>
                );
              })}
          </FlexRow>
        )}
      </div>
    </FlexColumn>
  );
}
