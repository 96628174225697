/* eslint-disable import/prefer-default-export */
import { getPalikaWardList } from '@Services/dashboard';
import { useQuery, UseQueryOptions } from '@tanstack/react-query';

type PalikaWard = Record<string, unknown>;

export const useGetPalikaWardListQuery = (
  ward: string,
  queryOptions?: Partial<
    UseQueryOptions<{ data: PalikaWard[] }, Error, PalikaWard[]>
  >,
) => {
  return useQuery({
    queryKey: ['palika-ward-list', ward],
    queryFn: () => getPalikaWardList({ ward_no: ward }),
    select: res => res.data,
    ...queryOptions,
  });
};
