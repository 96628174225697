import { Controller } from 'react-hook-form';
import { Input, MultiSelect, Select } from '@Components/common/FormUI';
import { FormFieldType } from '@Constants/dataManagementForm';
import FileUpload from '@Components/common/FormUI/FileUpload';
import Switch from '@Components/RadixComponents/Switch';
import { UseFormPropsType } from '../types';

export default function getInputElement(
  data: FormFieldType,
  formProps: UseFormPropsType,
  dropdownOptions: Record<string, any>,
  _t?: Function,
) {
  const {
    id,
    type,
    placeholder,
    required,
    inputType,
    multiple,
    fileAccept,
    validationSchema,
    readOnly,
  } = data;
  const { register, control, getValues } = formProps;

  switch (type) {
    case 'input':
      return (
        <Input
          type={inputType || 'text'}
          placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
          defaultValue={id === 'floor' ? 1 : undefined}
          {...register(`${id}`, {
            required: required ? 'Required' : false,
            validate: validationSchema || undefined,
          })}
          readOnly={!!readOnly}
        />
      );
    case 'select':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue=""
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <Select
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              options={dropdownOptions[id] || []}
              labelKey="alias"
              valueKey="name"
              selectedOption={value}
              onChange={onChange}
            />
          )}
        />
      );
    case 'multi-select':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue=""
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <MultiSelect
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              options={dropdownOptions[id] || []}
              labelKey="alias"
              valueKey="name"
              selectedOptions={value}
              onChange={onChange}
            />
          )}
        />
      );
    case 'file-upload':
      return (
        <Controller
          control={control}
          name={id}
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <FileUpload
              placeholder={_t ? _t?.(`${placeholder}`) : placeholder}
              multiple={!!multiple}
              name={data.id}
              data={value}
              fileAccept={fileAccept}
              onChange={() => onChange(getValues?.()?.[data.id])}
              {...formProps}
            />
          )}
        />
      );

    case 'switch':
      return (
        <Controller
          control={control}
          name={id}
          defaultValue={false}
          rules={{
            required: required ? 'Required' : false,
          }}
          render={({ field: { value, onChange } }) => (
            <Switch checked={value} onClick={() => onChange(!value)} />
          )}
        />
      );
    default:
      return '';
  }
}
