export const buildingDetailKeys = {
  ward_no: 'Ward',
  tole_name: 'Tole Name',
  owner_status: 'Building Ownership Status',
  building_use: 'Building Use',
  reg_type: 'Building Permit Status',
};

export const roadDetailKeys = {
  ward_no: 'Ward',
  road_category: 'Road Type',
  road_type: 'Road Pavement Type',
  road_length: 'Length',
  road_width: 'Width',
};

export const imageFieldKeys = ['building_images', 'building_plans'];

export const buildingAttributeKeys = {
  associate_road_name: 'Associated Road Name',
  association_type: 'Association',
  building_plans: 'Building Plan',
  plinth_area: 'Plinth Area',
  building_structure: 'Building Structure',
  roof_type: 'Building Roof Type',
  floor: 'Building Floor',
  temporary_type: 'Building Permanency Type',
  building_sp_use: 'Building Specific Use',
  gate_location: 'Gate Location',
  owner_name: "Owners's Name",
  building_images: 'Building Picture',
  remarks: 'Remarks',
  build_id: 'House ID',
  road_id: 'Road ID',
  informant_name: 'Informant Name',
  informant_contact: 'Informant Contact',
};

export const roadAttributeKeys = {
  assoc_build_no: 'Number of Building Associated',
  road_class: 'Road Class',
  start_point: 'Start Location',
  direction: 'Direction',
  road_id: 'Road ID',
  remarks: 'Remarks',
};
