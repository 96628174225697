/* eslint-disable no-nested-ternary */
import { Fragment, useState, useEffect, ChangeEvent, useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setVisualizationState } from '@Store/actions/visualization';
import degreesToStringHDMS from '@Utils/degreeToStringHDMS';
import isEmpty from '@Utils/isEmpty';
import CoordinateMarker from '@Assets/images/map/coordinate-marker.svg';
import LocationMarker from '@Assets/images/map/location_marker.svg';
import SelectCoordinate from '@Assets/images/map/coordinate-placeholder-img.svg';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import { Input, Label } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { useLocation } from 'react-router-dom';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import useTranslation from '@Hooks/useTranslation';

const activeClassName =
  'naxatw-rounded naxatw-bg-primary-400 naxatw-text-white';

interface dataProps {
  label: string;
  icon: string;
  iconClassName: string;
  onClick?: () => void;
}

const coordinateValues = {
  latitude: '',
  longitude: '',
};

export default function Location() {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const [toggleTab, setToggleTab] = useState('');
  const [displayImage, setDisplayImage] = useState(true);
  const [activeTab, setActiveTab] = useState<'degree' | 'dms'>('degree');
  const [coordinates, setCoordinates] = useState<{
    latitude: string | null;
    longitude: string | null;
  }>(coordinateValues);

  const locationType = pathname.includes('visualization')
    ? 'visualization'
    : pathname.includes('workshop-mode')
      ? 'workshop-mode'
      : 'data-management-form';

  const geolocation = useTypedSelector(state =>
    locationType === 'visualization'
      ? state.visualization.geolocation
      : locationType === 'workshop-mode'
        ? state.workshopMode.geolocation
        : state.dataManagementForm.geolocation,
  );

  const inputCoordinates = useTypedSelector(state =>
    locationType === 'visualization'
      ? state.visualization.inputCoordinates
      : locationType === 'workshop-mode'
        ? state.workshopMode.inputCoordinates
        : state.dataManagementForm.inputCoordinates,
  );

  const setState =
    locationType === 'visualization'
      ? setVisualizationState
      : locationType === 'workshop-mode'
        ? setWorkshopModeState
        : setDataManagementFormState;

  const data: dataProps[] = [
    {
      label: 'Enter Coordinate',
      icon: 'place',
      iconClassName: 'material-symbols-outlined',
    },
    {
      label: 'My Location',
      icon: 'my_location',
      iconClassName: 'material-icons',
      onClick: () => {
        if ('geolocation' in navigator) {
          navigator.geolocation.getCurrentPosition(position => {
            dispatch(
              setState({
                geolocation: position.coords,
                zoomToLocationType: 'geolocation',
              }),
            );
          });
        }
      },
    },
  ];

  const handleCoordinatesInput = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value, max, min } = e.target;
    // @ts-ignore
    if (Number(value) > Number(max) || Number(value) < Number(min)) return;
    setCoordinates(prev => ({
      ...prev,
      [name]: +value,
    }));
  };

  const setInputCoordinates = useCallback(() => {
    const { latitude, longitude } = coordinates;
    if (!latitude || !longitude) return;
    dispatch(
      setState({
        inputCoordinates: [+latitude, +longitude],
        zoomToLocationType: 'user-input',
      }),
    );
  }, [dispatch, coordinates, setState]);

  useEffect(() => {
    setInputCoordinates();
  }, [setInputCoordinates, coordinates]);

  const handleResetCoordinate = () => {
    dispatch(
      setState({
        inputCoordinates: [],
        zoomToLocationType: null,
      }),
    );
    setCoordinates(coordinateValues);
  };

  return (
    <FlexColumn className="naxatw-px-3">
      <FlexRow className="naxatw-h-11 naxatw-items-center">
        <h5>{_t('Location')}</h5>
      </FlexRow>

      <FlexColumn gap={5}>
        <FlexRow>
          {data.map(({ label, icon, iconClassName, onClick }, index) => (
            <Fragment key={label}>
              <FlexColumn
                className={`naxatw-w-full naxatw-cursor-pointer naxatw-items-center 
                  naxatw-pb-2 naxatw-pt-4 hover:naxatw-bg-primary-50 ${
                    toggleTab === label ? 'naxatw-bg-primary-50' : ''
                  }`}
                gap={0.5}
                onClick={() => {
                  setToggleTab(label);
                  setDisplayImage(false);
                  onClick?.();
                }}
              >
                <Icon
                  className="naxatw-h-7 naxatw-text-[2rem] naxatw-text-primary-400"
                  name={icon}
                  iconClassName={iconClassName}
                />
                <p className="naxatw-px-1 naxatw-text-center naxatw-text-xs naxatw-font-bold naxatw-text-primary-400">
                  {_t(`${label}`)}
                </p>
              </FlexColumn>

              {!index && <div className="naxatw-w-[1px] naxatw-bg-grey-400" />}
            </Fragment>
          ))}
        </FlexRow>
        {displayImage && (
          <FlexColumn
            gap={10}
            className="naxatw-items-center naxatw-justify-center naxatw-pt-10"
          >
            <Image src={SelectCoordinate} />
            <p className="naxatw-text-sm naxatw-text-grey-500">
              {_t('Select any one to continue')}
            </p>
          </FlexColumn>
        )}
        {toggleTab === 'Enter Coordinate' && !displayImage ? (
          <FlexColumn gap={2}>
            <FlexColumn>
              <Label>Latitude(Y)</Label>
              <Input
                placeholder="Enter Latitude"
                type="number"
                name="latitude"
                min={-85}
                max={85}
                // @ts-ignore
                value={coordinates.latitude}
                onChange={handleCoordinatesInput}
              />
            </FlexColumn>
            <FlexColumn>
              <Label>Longitude(X)</Label>
              <Input
                placeholder="Enter Longitude"
                type="number"
                name="longitude"
                min={-179}
                max={179}
                // @ts-ignore
                value={coordinates.longitude}
                onChange={handleCoordinatesInput}
              />
            </FlexColumn>
            {}
            <FlexRow
              gap={1}
              className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-end"
              onClick={handleResetCoordinate}
            >
              <Icon
                name="restart_alt"
                className="!naxatw-text-xl naxatw-text-primary-400"
              />
              <p className="naxatw-text-sm naxatw-font-bold naxatw-text-primary-400">
                Clear
              </p>
            </FlexRow>
            {!isEmpty(inputCoordinates) && (
              <FlexRow className="naxatw-items-center naxatw-justify-between">
                <FlexRow
                  gap={3}
                  className="naxatw-w-full naxatw-items-center naxatw-text-sm"
                >
                  <Image src={CoordinateMarker} />
                  <p>Your Coordinate</p>
                </FlexRow>
                <Icon
                  name="zoom_in_map"
                  className="naxatw-text-grey-600"
                  onClick={setInputCoordinates}
                />
              </FlexRow>
            )}
          </FlexColumn>
        ) : (
          geolocation &&
          !displayImage && (
            <FlexColumn>
              <FlexRow className="naxatw-justify-end">
                <FlexRow className="naxatw-overflow-clip naxatw-rounded naxatw-bg-primary-50 naxatw-p-0.5">
                  <span
                    role="button"
                    tabIndex={0}
                    className={`naxatw-px-2 naxatw-py-0.5 naxatw-text-xs ${
                      activeTab === 'degree' ? activeClassName : ''
                    }`}
                    onClick={() => setActiveTab('degree')}
                    onKeyDown={() => {}}
                  >
                    Degree
                  </span>
                  <span
                    role="button"
                    tabIndex={0}
                    className={`naxatw-px-2 naxatw-py-0.5 naxatw-text-xs ${
                      activeTab === 'dms' ? activeClassName : ''
                    }`}
                    onClick={() => setActiveTab('dms')}
                    onKeyDown={() => {}}
                  >
                    DMS
                  </span>
                </FlexRow>
              </FlexRow>

              <FlexColumn gap={3} className="naxatw-pt-5">
                <FlexColumn>
                  <p className="naxatw-text-sm">Latitude (Y)</p>
                  <p className="naxatw-text-sm naxatw-font-bold">
                    {activeTab === 'degree'
                      ? `${geolocation.latitude.toFixed(4)}°`
                      : degreesToStringHDMS('N', geolocation.latitude, 2)}
                  </p>
                </FlexColumn>
                <FlexColumn>
                  <p className="naxatw-text-sm">Longitude (X)</p>
                  <p className="naxatw-text-sm naxatw-font-bold">
                    {activeTab === 'degree'
                      ? `${geolocation.longitude.toFixed(4)}°`
                      : degreesToStringHDMS('N', geolocation.longitude, 2)}
                  </p>
                </FlexColumn>
              </FlexColumn>

              <FlexRow className="naxatw-mt-7 naxatw-h-10 naxatw-items-center">
                <span>
                  <Image src={LocationMarker} />
                </span>
                <p className="naxatw-ml-3 naxatw-text-sm">My Location</p>
                <span
                  role="button"
                  tabIndex={0}
                  title="Zoom to Location"
                  className="naxatw-ml-auto naxatw-text-grey-600"
                  onClick={() => {
                    if ('geolocation' in navigator) {
                      navigator.geolocation.getCurrentPosition(position => {
                        dispatch(
                          setState({
                            geolocation: position.coords,
                            zoomToLocationType: 'geolocation',
                          }),
                        );
                      });
                    }
                  }}
                  onKeyDown={() => {}}
                >
                  <Icon name="zoom_in_map" />
                </span>
              </FlexRow>
            </FlexColumn>
          )
        )}
      </FlexColumn>
    </FlexColumn>
  );
}
