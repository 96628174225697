import React from 'react';
import * as SliderPrimitive from '@radix-ui/react-slider';
import { cn } from '@Utils/index';

const RangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn(
      `naxatw-relative naxatw-flex naxatw-w-full naxatw-cursor-pointer naxatw-touch-none
       naxatw-select-none naxatw-items-center`,
      className,
    )}
    {...props}
  >
    <SliderPrimitive.Track
      className="naxatw-relative naxatw-h-0.5 naxatw-w-full naxatw-grow naxatw-overflow-hidden
        naxatw-rounded-full naxatw-bg-grey-200"
    >
      <SliderPrimitive.Range className="naxatw-absolute naxatw-h-full naxatw-bg-grey-600" />
    </SliderPrimitive.Track>
    <SliderPrimitive.Thumb
      className="naxatw-ring-offset-background focus-visible:naxatw-ring-ring
        naxatw-block naxatw-h-3 naxatw-w-3 naxatw-rounded-full naxatw-bg-grey-600
        naxatw-transition-colors focus-visible:naxatw-outline-none 
        focus-visible:naxatw-ring-2 focus-visible:naxatw-ring-offset-2 
        disabled:naxatw-pointer-events-none disabled:naxatw-opacity-50"
    />
  </SliderPrimitive.Root>
));

RangeSlider.displayName = SliderPrimitive.Root.displayName;

export default RangeSlider;
