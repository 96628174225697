import { useTypedSelector } from '@Store/hooks';
import prepareQueryParam from '@Utils/prepareQueryParam';
import Skeleton from '@Components/RadixComponents/Skeleton';
import DataCard from '@Components/common/DataCard';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { getFeatureCount } from '@Services/publicPage';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import useTranslation from '@Hooks/useTranslation';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';

function DataCountSection() {
  const { _t } = useTranslation();
  const [featureCountData, setFeatureCountData] = useState([
    { id: 'building_count', title: 'Buildings', count: 0, iconName: 'home' },
    {
      id: 'road_count',
      title: 'Road sections',
      count: 0,
      iconName: 'add_road',
    },
  ]);

  const filterParams = useTypedSelector(state => state.publicPage.filterParams);

  const updateFeatureCountData = (res: any) => {
    setFeatureCountData(prev =>
      prev.map(item => ({
        ...item,
        count: res?.data[item.id] ?? item.count,
      })),
    );
  };

  const { isLoading: isBuildingCountLoading } = useQuery({
    queryKey: ['feature-building-count', filterParams],
    queryFn: () =>
      getFeatureCount({
        layer: 'building',
        ...prepareQueryParam(filterParams),
      }),
    onSuccess: res => updateFeatureCountData(res),
  });

  const { isLoading: isRoadCountLoading } = useQuery({
    queryKey: ['feature-road-count', filterParams],
    queryFn: () =>
      getFeatureCount({ layer: 'road', ...prepareQueryParam(filterParams) }),
    onSuccess: res => updateFeatureCountData(res),
  });

  if (isBuildingCountLoading || isRoadCountLoading) {
    return (
      <FlexColumn className="naxatw-h-full naxatw-w-[full]">
        <Skeleton className="naxatw-mt-4 naxatw-h-full" />
      </FlexColumn>
    );
  }

  return (
    <FlexRow gap={4} className="naxatw-flex-col md:naxatw-flex-row">
      {featureCountData?.map(({ title, count, iconName }) => (
        <DataCard
          title={_t(title)}
          key={title}
          count={count}
          iconName={iconName}
          // className="naxatw-border-none naxatw-bg-white md:naxatw-shadow-none"
        />
      ))}
    </FlexRow>
  );
}

export default hasErrorBoundary(DataCountSection);
