import { api, authenticated } from '.';

export const getPalikaProfile = () =>
  authenticated(api).get('/palika-profile/');

export const getOverallBuildingBbox = () =>
  authenticated(api).get(`/overall_building_bbox/`);

export const fetchPalikaWardGeojson = (params: any) =>
  authenticated(api).get(`/palika-ward-geojson/`, { params });

export const updatePalikaWardBoundary = (id: any, data: any) =>
  authenticated(api).patch(`/palika-ward-boundary/${id}/`, data);
