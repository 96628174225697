/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Image from '@Components/RadixComponents/Image';
import MeasureDistance from '@Assets/images/map/measure_distance.svg';
import MeasureArea from '@Assets/images/map/measure_area.svg';
import SelectCoordinate from '@Assets/images/map/coordinate-placeholder-img.svg';
import DrawDistance from '@Assets/images/map/draw-hand.svg';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { useTypedSelector } from '@Store/hooks';
import { Button } from '@Components/RadixComponents/Button';
import useTranslation from '@Hooks/useTranslation';

const data = [
  { label: 'Distance', icon: MeasureDistance },
  { label: 'Area', icon: MeasureArea },
];

export default function Measure() {
  const [displayImage, setDisplayImage] = useState(true);
  const [activeMeasureType, setActiveMeasureType] = useState('');
  const dispatch = useDispatch();
  const { _t } = useTranslation();
  const measureResult = useTypedSelector(
    state => state.workshopMode.measureResult,
  );
  const measureType = useTypedSelector(state => state.workshopMode.measureType);

  return (
    <FlexColumn className="naxatw-px-3">
      <FlexRow className="naxatw-h-11 naxatw-items-center">
        <h5>{_t('Measure')}</h5>
      </FlexRow>

      <FlexColumn>
        <FlexRow>
          {data.map(({ label, icon }, index) => (
            <React.Fragment key={label}>
              <FlexColumn
                key={label}
                className={`${
                  activeMeasureType === label ? 'naxatw-bg-primary-50' : ''
                } naxatw-w-full naxatw-cursor-pointer naxatw-select-none 
                  naxatw-items-center naxatw-py-2 hover:naxatw-bg-primary-50`}
                onClick={() => {
                  setDisplayImage(false);
                  dispatch(
                    setWorkshopModeState({
                      measureType: label === 'Distance' ? 'length' : 'area',
                      measureResult: null,
                    }),
                  );
                  setActiveMeasureType(label);
                }}
              >
                <Image className="naxatw-h-10 naxatw-w-10" src={icon} />
                <p className="naxatw-text-xs naxatw-font-bold naxatw-text-primary-400">
                  {_t(label)}
                </p>
              </FlexColumn>
              {!index && (
                <div className="naxatw-w-[1.5px] naxatw-bg-grey-400" />
              )}
            </React.Fragment>
          ))}
        </FlexRow>
      </FlexColumn>

      <FlexColumn>
        <p className="naxatw-text-sm naxatw-font-bold">{_t('Result')}</p>
        <FlexColumn gap={10} className="naxatw-pt-10">
          {measureResult ? (
            <FlexColumn gap={2}>
              <p className="naxatw-font-semibold">
                Total {measureType === 'length' ? 'Distance' : 'Area'} :{' '}
                <span className="naxatw-font-semibold naxatw-text-primary-400">
                  {measureResult.measurement.toFixed(2)} {measureResult.unit}
                </span>
              </p>
              <FlexRow className="naxatw-w-full naxatw-justify-end">
                <Button
                  leftIcon="restart_alt"
                  variant="ghost"
                  onClick={() =>
                    dispatch(
                      setWorkshopModeState({
                        measureResult: null,
                        measureType: null,
                      }),
                    )
                  }
                >
                  Reset
                </Button>
              </FlexRow>
            </FlexColumn>
          ) : (
            <FlexColumn className="naxatw-items-center naxatw-justify-center">
              <Image src={displayImage ? SelectCoordinate : DrawDistance} />
              <p className="naxatw-text-sm naxatw-text-grey-500">
                {displayImage
                  ? _t('Choose distance or area to draw')
                  : 'Click on Map to start drawing'}
              </p>
            </FlexColumn>
          )}
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
}
