export default function reverseLineString(geojson: any) {
  const geometry = geojson?.features ? geojson.features[0].geometry : geojson;
  if (!geometry) return geojson;

  const reverseCoordinates = (coordinates: any) => {
    if (Array.isArray(coordinates[0][0])) {
      // MultiLineString case
      return [...coordinates.map((line: any) => [...line].reverse())].reverse();
    }
    // LineString case
    return [...coordinates].reverse();
  };

  return {
    type: 'FeatureCollection',
    features: [
      {
        type: 'Feature',
        properties: {},
        geometry: {
          ...geometry,
          coordinates: reverseCoordinates(geometry.coordinates),
        },
      },
    ],
  };
}
