import { api, authenticated } from '.';

export const getPhysicalInstallation = (params: any) =>
  authenticated(api).get(`/settings/physical-installation/`, { params });

export const postPhysicalInstallation = (data: any) =>
  authenticated(api).post(`/settings/physical-installation/`, data);

export const updatePhysicalInstallation = (id: any, data: any) =>
  authenticated(api).patch(`/settings/physical-installation/${id}/`, data);
