import { useEffect, useState } from 'react';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import FullLogo from '@Assets/images/dmaps-logo-full.svg';
import JanakpurMuniLogo from '@Assets/images/janakpur-muni-logo.svg';
import DemoMuniLogo from '@Assets/images/demo-muni-logo.png';
import DmapsLogo from '@Assets/images/dmaps-logo.svg';
import sidebarData, { ISidebar } from '@Constants/sidebarData';
import User from '@Assets/images/avatar-images.svg';
import Image from '@Components/RadixComponents/Image';
import Icon from '@Components/common/Icon';
import { setCommonState, togglePromptDialog } from '@Store/actions/common';
import useTranslation from '@Hooks/useTranslation';
import { FlexColumn, FlexRow } from '../Layouts';
import LanguageToggle from '../LanguageToggle';

const { PROJECT } = process.env;

export default function Sidebar() {
  const { _t } = useTranslation();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [isSidebarHovered, setIsSidebarHovered] = useState(false);
  const [activeTab, setActiveTab] = useState('');
  const [activeHoverTab, setActiveHoverTab] = useState('');
  const [showMobileNav, setShowMobileNav] = useState(false);

  const language = useTypedSelector(state => state.common.language);
  const userProfile = useTypedSelector(state => state.user.userProfile);
  const isWorkshopModeOn = useTypedSelector(
    state => state.workshopMode.isWorkshopModeOn,
  );

  const handleActiveTab = (tabName: string) => {
    const activeTabData = sidebarData.find(item => {
      if (item.children) {
        return item.children.some(child =>
          tabName.includes(`${item.path}/${child.path}`),
        );
      }
      return tabName.includes(`${item.path}`);
    });

    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
  };

  useEffect(() => {
    if (!pathname || pathname === '/') {
      navigate('/dashboard');
    }
  }, [pathname, navigate]);

  useEffect(() => {
    const activeTabData = sidebarData.find(item => {
      if (item.children) {
        return item.children.some(child =>
          pathname.includes(`${item.path}/${child.path}`),
        );
      }
      return pathname.includes(`${item.path}`);
    });
    if (activeTabData) {
      setActiveTab(activeTabData.path);
    }
    setShowMobileNav(false);
    setIsSidebarHovered(false);
  }, [pathname]);

  const toggleMobileMenu = () => {
    setShowMobileNav(!showMobileNav);
    setIsSidebarHovered(!isSidebarHovered);
  };

  return (
    <>
      <FlexRow
        className="naxatw-fixed naxatw-left-0 naxatw-top-0 naxatw-z-50 naxatw-mb-3 naxatw-w-full naxatw-items-center
       naxatw-justify-between naxatw-border-b naxatw-border-grey-300  naxatw-bg-white
        naxatw-px-3 naxatw-py-3 md:naxatw-hidden"
      >
        <Image
          className="naxatw-h-10 naxatw-w-full"
          src={
            PROJECT?.toLowerCase() === 'janakpur'
              ? JanakpurMuniLogo
              : DemoMuniLogo
          }
          width={200}
        />
        <Icon
          name={`${showMobileNav ? 'close' : 'menu'}`}
          className="naxatw-rounded naxatw-px-1 naxatw-py-2 !naxatw-text-2xl hover:naxatw-bg-primary-50 md:naxatw-hidden"
          onClick={toggleMobileMenu}
        />
      </FlexRow>
      <aside
        className={`${
          isSidebarHovered
            ? 'naxatw-w-full md:naxatw-w-[14.625rem] md:naxatw-translate-x-0'
            : 'naxatw-hidden md:naxatw-w-[80px]'
        }
      naxatw-fixed naxatw-left-0 naxatw-top-0 naxatw-z-40 naxatw-h-screen naxatw-duration-100 
      naxatw-ease-in-out sm:naxatw-translate-x-0 md:naxatw-grid`}
        aria-label="Sidebar"
        onMouseEnter={() => {
          if (!showMobileNav) {
            setIsSidebarHovered(true);
          }
        }}
        onMouseLeave={() => {
          if (!showMobileNav) {
            setIsSidebarHovered(false);
            setActiveHoverTab('');
          }
        }}
      >
        <div
          className="scrollbar naxatw-h-full naxatw-overflow-y-auto 
          naxatw-bg-white naxatw-pt-4 naxatw-shadow-[0_4px_11px_0_#00000040]"
        >
          <div
            className={
              isSidebarHovered
                ? 'naxatw-flex naxatw-flex-row naxatw-gap-5 naxatw-px-3'
                : ''
            }
          >
            <Link
              to="/"
              className={`naxatw-flex naxatw-items-center naxatw-justify-center md:naxatw-pb-10 `}
            >
              <Image src={!isSidebarHovered ? DmapsLogo : FullLogo} />
            </Link>
            {isSidebarHovered && (
              <LanguageToggle
                selectedLanguage={language}
                languages={['English', 'Nepali']}
                className="naxatw-mt-1"
                onLanguageChange={changedLanguage => {
                  dispatch(setCommonState({ language: changedLanguage }));
                }}
              />
            )}
          </div>
          <FlexColumn
            className="scrollbar naxatw-flex-col-reverse naxatw-overflow-auto 
           md:naxatw-h-[540px] md:naxatw-flex-col"
          >
            <div className="naxatw-mt-5 naxatw-font-medium md:naxatw-mt-0 ">
              {sidebarData
                .filter(item =>
                  isWorkshopModeOn ? true : item.name !== 'Workshop Mode',
                )
                .map(({ name, icon, path, children }: ISidebar) => (
                  <div
                    key={name}
                    onMouseEnter={() => {
                      setActiveHoverTab(name);
                    }}
                    className={`${
                      children &&
                      activeHoverTab === name &&
                      pathname.includes(`${path}`)
                        ? 'naxatw-bg-primary-50'
                        : ''
                    }`}
                  >
                    <NavLink
                      to={
                        children?.length ? `${path}/${children[0].path}` : path
                      }
                      onClick={() => {
                        handleActiveTab(
                          children?.length
                            ? `${path}/${children[0].path}`
                            : path,
                        );
                      }}
                      className={`${
                        activeTab === path
                          ? 'naxatw-border-primary-400  naxatw-bg-primary-50 naxatw-text-primary-400'
                          : 'naxatw-border-white naxatw-text-grey-700'
                      } ${
                        !isSidebarHovered
                          ? 'naxatw-flex-col naxatw-py-2'
                          : 'naxatw-py-[18px]'
                      } naxatw-group naxatw-flex naxatw-items-center  naxatw-border-l-[5px] naxatw-px-2
                 hover:naxatw-bg-primary-50 hover:naxatw-text-primary-400 `}
                    >
                      <i className="material-icons-outlined">{icon}</i>
                      <div
                        className={`${
                          isSidebarHovered && 'naxatw-pl-4'
                        } naxatw-flex-1 naxatw-whitespace-nowrap naxatw-text-xs naxatw-font-bold`}
                      >
                        {/* {name === 'Workshop Mode' && isWorkshopModeOn ? name : ''} */}
                        {isSidebarHovered ? _t(name) : _t(name.split(' ')[0])}
                      </div>
                      {children && isSidebarHovered && (
                        <i className="material-icons-outlined">
                          {activeHoverTab === name
                            ? 'expand_less'
                            : 'expand_more'}
                        </i>
                      )}
                    </NavLink>
                    <div>
                      {children &&
                        (activeHoverTab === name || showMobileNav) &&
                        children.map(child => (
                          <NavLink
                            key={child.name}
                            to={`${path}/${child.path}`}
                            className="naxatw-group naxatw-flex naxatw-items-center naxatw-p-2 
                        naxatw-text-grey-700 hover:naxatw-bg-primary-50 hover:naxatw-text-primary-400"
                          >
                            <span
                              className={`${
                                isSidebarHovered
                                  ? 'naxatw-ml-11'
                                  : 'naxatw-ml-4'
                              } ${
                                pathname.includes(`${path}/${child.path}`) &&
                                ' naxatw-text-primary-400 '
                              }  `}
                            >
                              {isSidebarHovered
                                ? _t(`${child.name}`)
                                : _t(`${child?.name.split(' ')[0]}`)}
                            </span>
                          </NavLink>
                        ))}
                    </div>
                  </div>
                ))}
            </div>
            {/* user profile section */}
            {userProfile && (
              <div
                className={`${
                  !isSidebarHovered && 'naxatw-flex-col naxatw-items-center'
                } naxatw-bottom-2 naxatw-z-0 naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-border-t-2
                 naxatw-border-grey-100 naxatw-bg-white naxatw-px-3 naxatw-py-2 hover:naxatw-bg-primary-50 md:naxatw-fixed`}
              >
                <div
                  className={`${
                    isSidebarHovered && 'naxatw-flex'
                  } naxatw-items-center naxatw-justify-center `}
                >
                  <Image
                    src={userProfile.thumbnail ? userProfile.thumbnail : User}
                    width={30}
                  />
                  <div
                    className={`${
                      isSidebarHovered && 'naxatw-pl-3'
                    } naxatw-flex naxatw-flex-col `}
                  >
                    <div className="naxatw-text-sm naxatw-font-bold naxatw-text-grey-700 ">
                      {isSidebarHovered
                        ? userProfile.username
                        : userProfile.username.split(' ')[0]}
                    </div>
                    {isSidebarHovered && (
                      <div className="naxatw-text-xs naxatw-capitalize naxatw-text-grey-700">
                        {userProfile.role_type}
                      </div>
                    )}
                  </div>
                </div>
                {isSidebarHovered && (
                  <Icon
                    name="logout"
                    className="naxatw-cursor-pointer naxatw-pt-1 naxatw-text-grey-700 "
                    onClick={() =>
                      dispatch(togglePromptDialog('confirm-logout'))
                    }
                  />
                )}
              </div>
            )}
          </FlexColumn>
        </div>
      </aside>
    </>
  );
}
