import { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { resetDataManagementState } from '@Store/actions/dataManagement';
import { FlexColumn } from '@Components/common/Layouts';
import InfoDialog from '@Components/common/InfoDialog';
import { getInfoDialogContent } from '@Constants/getInfoDialogContent';
import { toggleInfoDialog } from '@Store/actions/common';
import { infoDialogTimeoutValue } from '@Constants/index';
import NoPermission from '@Components/common/NoPermission';
import useTranslation from '@Hooks/useTranslation';
import FilterSection from './FilterSection';
import TableSection from './TableSection';

export default function RoadData() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);
  const infoDialogContent = useTypedSelector(
    state => state.common.infoDialogContent,
  );
  const permissions = useTypedSelector(state => state.user.permissions);
  // reset dataManagemetState  on unmount
  useEffect(
    () => () => {
      dispatch(resetDataManagementState());
      dispatch(toggleInfoDialog());
    },
    [dispatch],
  );

  useEffect(() => {
    if (!infoDialogContent) return () => {};
    const timer = setTimeout(
      () => dispatch(toggleInfoDialog()),
      infoDialogTimeoutValue,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, infoDialogContent]);

  if (!permissions.includes('view_road')) {
    return <NoPermission />;
  }

  return (
    <FlexColumn
      gap={3}
      className="naxatw-relative naxatw-h-screen naxatw-w-screen naxatw-animate-fade naxatw-px-5 naxatw-pt-5"
    >
      <h3 className=" naxatw-text-grey-800">{_t('Road Data Management')}</h3>
      <FlexColumn gap={2.5}>
        <FilterSection />
        {showInfoDialog && !!infoDialogContent && (
          <InfoDialog
            status={getInfoDialogContent(infoDialogContent)?.status}
            title={getInfoDialogContent(infoDialogContent)?.title}
            description={getInfoDialogContent(infoDialogContent)?.description}
            onClose={() => dispatch(toggleInfoDialog())}
          />
        )}
      </FlexColumn>
      <TableSection />
    </FlexColumn>
  );
}
