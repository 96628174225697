import { api } from '.';

export const getWardNoList = () => api.get(`/public-page/unique-ward-no/`);

export const getRoadUniqueFields = (params: any) =>
  api.get(`/public-page/road-unique-fields/`, {
    params,
  });

export const getBuildingUniqueFields = (params: any) =>
  api.get(`/public-page/building-unique-fields/`, {
    params,
  });

export const getBuildingDataList = (params: any) =>
  api.get(`/public-page/building-data/`, { params });

export const getFeatureCount = (params: any) =>
  api.get(`/public-page/feature-count/`, {
    params,
  });

export const getBuildingCount = (params: any) =>
  api.get(`/public-page/building-count/`, {
    params,
  });

export const getHouseFloorCount = () =>
  api.get(`/public-page/count-unique-floors/`);

export const getRoadCount = (params: any) =>
  api.get(`/public-page/road-count/`, {
    params,
  });

export const getOverallBuildingBbox = () =>
  api.get(`/public-page/overall_building_bbox/`);

export const getMapPopupData = (params: any) =>
  api.get('/public-page/map-popup/', {
    params,
  });
