export type ColorHexType = `#${string}`;

interface ILegendItemProps {
  color: ColorHexType;
  type: 'fill' | 'line';
}

export default function LegendItem({ color, type }: ILegendItemProps) {
  return type === 'line' ? (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="7"
      viewBox="0 0 20 7"
      fill="none"
    >
      <path
        d="M1.43507 5.01093C1.96442 3.54668 4.29296 1.04413 9.37232 2.74795C14.4517 4.45177 17.3511 2.75162 18.1659 1.68856"
        stroke={color}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
    </svg>
  ) : (
    <span
      className="naxatw-h-4 naxatw-w-4 naxatw-bg-yellow-500"
      style={{ background: color }}
    />
  );
}
