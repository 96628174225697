import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { generateHouseNumbers } from '@Services/visualization';

/* eslint-disable import/prefer-default-export */
export const useGenerateHouseNumbersQuery = (
  id: any,
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['generate-house-number', id],
    queryFn: () =>
      generateHouseNumbers({
        data_id: id,
      }),
    select: (res: any) => res.data,
    ...queryOptions,
  });
};
