/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import Skeleton from '@Components/RadixComponents/Skeleton';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import SearchInput from '@Components/common/FormUI/SearchInput';

const AttributeSkeleton = () => {
  return (
    <FlexColumn gap={2} className="naxatw-mx-3">
      {Array.from({ length: 4 }).map((__, index) => (
        <Skeleton
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          className="naxatw-h-9 naxatw-w-full naxatw-bg-primary-50"
        />
      ))}
    </FlexColumn>
  );
};

interface ISearchListProps {
  title: string;
  data: any;
  onClick: (item: string | number) => void;
  isLoading: boolean;
}

export default function SearchList({
  title,
  data,
  onClick,
  isLoading,
}: ISearchListProps) {
  const [searchInput, setSearchInput] = useState('');
  const [displayList, setDisplayList] = useState(false);

  const filteredData = data.filter((item: any) =>
    !item
      ? data
      : item.toString().toLowerCase().includes(searchInput.toLowerCase()),
  );

  return (
    <FlexColumn className="naxatw-w-52 naxatw-rounded-lg naxatw-shadow-top">
      <FlexColumn className="naxatw-px-3">
        {displayList ? (
          <SearchInput
            inputValue={searchInput}
            onChange={e => {
              setSearchInput(e.target.value);
            }}
            showClearIcon
            onClear={() => {
              setSearchInput('');
              setDisplayList(false);
            }}
          />
        ) : (
          <FlexRow className="naxatw-h-11 naxatw-items-center naxatw-justify-between">
            <h5>{title}</h5>
            <Icon name="search" onClick={() => setDisplayList(true)} />
          </FlexRow>
        )}
      </FlexColumn>
      <FlexColumn className="scrollbar naxatw-h-40 naxatw-overflow-auto ">
        {!isLoading ? (
          filteredData.map((item: any) => (
            <p
              key={item}
              className="naxatw-cursor-pointer naxatw-px-3 naxatw-py-2.5 naxatw-text-sm 
              naxatw-capitalize hover:naxatw-bg-primary-50"
              onClick={() => onClick(item)}
            >
              {item?.toString()?.replace(/_/g, ' ')}
            </p>
          ))
        ) : (
          <AttributeSkeleton />
        )}
      </FlexColumn>
    </FlexColumn>
  );
}
