/* eslint-disable no-param-reassign */
import { ILayerState, defaultLayersDataPublicPage } from '@Constants/map';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ChangeStyleType } from './visualization';

export interface WorkshopModeState {
  attributeType:
    | 'add_building'
    | 'add_road'
    | 'edit_building'
    | 'edit_road'
    | null;
  isWorkshopModeOn: boolean;
  layers: ILayerState[];
  selectedFeature: Record<string, any> | null;
  searchedFeature: Record<string, any> | null;
  activeBaseLayer: string;
  changeStyle: ChangeStyleType | Record<string, any>;
  layerOrderChanged: string[] | number[] | null; // [from, to]
  geolocation: Record<string, any> | null;
  inputCoordinates: number[];
  zoomToGeolocation: boolean;
  zoomToLocationType: 'geolocation' | 'user-input' | null;
  refreshLayerId: string | null;
  measureType: 'length' | 'area' | null;
  measureResult: Record<string, any> | null;
}

const initialState: WorkshopModeState = {
  attributeType: null,
  isWorkshopModeOn: false,
  layers: defaultLayersDataPublicPage,
  selectedFeature: null,
  searchedFeature: null,
  activeBaseLayer: 'osm',
  changeStyle: {},
  layerOrderChanged: null,
  geolocation: null,
  inputCoordinates: [],
  zoomToGeolocation: false,
  zoomToLocationType: null,
  refreshLayerId: null,
  measureType: null,
  measureResult: null,
};

const setWorkshopModeState: CaseReducer<
  WorkshopModeState,
  PayloadAction<Partial<WorkshopModeState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const handleLayerSort: CaseReducer<
  WorkshopModeState,
  PayloadAction<DragEndEvent>
> = (state, action) => {
  const { active, over } = action.payload;
  if (!over?.id) return;
  if (active.id === over.id) return;
  const oldIndex = state.layers.findIndex(item => item.id === active.id);
  const newIndex = state.layers.findIndex(item => item.id === over.id);
  const sortedLayers = arrayMove(state.layers, oldIndex, newIndex);
  state.layers = sortedLayers;
  // @ts-ignore
  state.layerOrderChanged =
    newIndex > oldIndex ? [active.id, over.id] : [over.id, active.id];
};

const handleLayerToggle: CaseReducer<
  WorkshopModeState,
  PayloadAction<string | number>
> = (state, action) => {
  const id = action.payload;
  const toggledLayer = state.layers.find(item => item.id === id);
  if (!toggledLayer) return;
  toggledLayer.checked = !toggledLayer.checked;
};

const handleLayerStyleChange: CaseReducer<
  WorkshopModeState,
  PayloadAction<ChangeStyleType>
> = (state, action) => {
  const { layerId, styles } = action.payload;
  const layer = state.layers.find(item => item.id === layerId);
  if (!layer) return;
  const keys = Object.keys(styles);
  if (!(keys[0] in layer.styles.paint)) return;
  layer.styles.paint = {
    ...layer.styles.paint,
    ...styles,
  };
  state.changeStyle = action.payload;
};

const handleLabelToggle: CaseReducer<
  WorkshopModeState,
  PayloadAction<string | number>
> = (state, action) => {
  const layer = state.layers.find(item => item.id === action.payload);
  if (!layer) return;
  layer.showLabel = !layer.showLabel;
};

const resetWorkshopModeState: CaseReducer<WorkshopModeState> = () => ({
  ...initialState,
});

const WorkshopModeSlice = createSlice({
  name: 'workshopMode',
  initialState,
  reducers: {
    setWorkshopModeState,
    handleLayerSort,
    handleLayerToggle,
    handleLayerStyleChange,
    handleLabelToggle,
    resetWorkshopModeState,
  },
});

export { WorkshopModeSlice };

export default WorkshopModeSlice.reducer;
