import {
  AnyAction,
  CombinedState,
  combineReducers,
  Reducer,
} from '@reduxjs/toolkit';
import workshopMode, { WorkshopModeState } from '@Store/slices/workshopMode';
import overlayLayer, { OverlayLayersState } from '@Store/slices/overlayLayers';
import editHistory, { EditHistoryState } from '@Store/slices/editHistory';
import curtain, { CurtainState } from '@Store/slices/curtain';
import common, { CommonState } from '../slices/common';
import user, { UserState } from '../slices/user';
import publicPage, { PublicPageState } from '../slices/publicPage';
import visualization, { VisualizationState } from '../slices/visualization';
import dataManagement, { DataManagementState } from '../slices/dataManagement';
import dataManagementForm, {
  DataManagementFormState,
} from '../slices/dataManagementForm';
import layerAttribute, { LayerAttributeState } from '../slices/layerAttribute';
import userManagement, { UserManagementState } from '../slices/userManagement';
import physicalInstallation, {
  PhysicalInstallationState,
} from '../slices/physicalInstallation';
import loader, { LoaderState } from '../slices/loader';
import advanceFilter, { AdvanceFilterState } from '../slices/advanceFilter';
import mapExport, { MapExportState } from '../slices/mapExport';

export interface IRootReducer {
  common: CommonState;
  user: UserState;
  publicPage: PublicPageState;
  visualization: VisualizationState;
  dataManagement: DataManagementState;
  dataManagementForm: DataManagementFormState;
  userManagement: UserManagementState;
  loader: LoaderState;
  physicalInstallation: PhysicalInstallationState;
  workshopMode: WorkshopModeState;
  advanceFilter: AdvanceFilterState;
  overlayLayer: OverlayLayersState;
  editHistory: EditHistoryState;
  layerAttribute: LayerAttributeState;
  mapExport: MapExportState;
  curtain: CurtainState;
}

const rootReducer: Reducer<
  CombinedState<IRootReducer>,
  AnyAction
> = combineReducers({
  common,
  user,
  publicPage,
  visualization,
  dataManagement,
  dataManagementForm,
  userManagement,
  loader,
  physicalInstallation,
  workshopMode,
  advanceFilter,
  overlayLayer,
  editHistory,
  layerAttribute,
  mapExport,
  curtain,
});

export default rootReducer;
