/* eslint-disable no-param-reassign */
import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ILayerState, defaultLayersDataPublicPage } from '@Constants/map';
import { SidebarSectionKeys } from '@Constants/publicPage';

export interface PublicPageState {
  activeBaseLayer: string;
  layers: ILayerState[];
  activeTab: SidebarSectionKeys;
  filterParams: Record<string, any>;
  selectedFeature: Record<string, any> | null;
  selectedLayerId: number | null;
  selectedLayerType: string;
  popupData: Record<string, any> | null;
  buildingBbox: number[] | null;
  showLegend: boolean;
}

const initialState: PublicPageState = {
  activeBaseLayer: 'osm',
  layers: defaultLayersDataPublicPage,
  activeTab: 'building-structure',
  filterParams: {},
  selectedFeature: null,
  selectedLayerId: null,
  selectedLayerType: '',
  popupData: null,
  buildingBbox: null,
  showLegend: true,
};

const setPublicPageState: CaseReducer<
  PublicPageState,
  PayloadAction<Partial<PublicPageState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setActiveTab: CaseReducer<
  PublicPageState,
  PayloadAction<SidebarSectionKeys>
> = (state, action) => {
  state.activeTab = action.payload;
};

const publicPageSlice = createSlice({
  name: 'publicPage',
  initialState,
  reducers: {
    setPublicPageState,
    setActiveTab,
  },
});

export { publicPageSlice };

export default publicPageSlice.reducer;
