import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getRoadUniqueValues } from '@Services/dataManagement';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import {
  setRoadDataState,
  setDataManagementState,
  handleFilterReset,
} from '@Store/actions/dataManagement';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import Popover from '@Components/RadixComponents/Popover';
import Chip from '@Components/common/Chip';
import { FormControl, MultiSelect, Label } from '@Components/common/FormUI';
import { allWardsSelector } from '@Store/selector/common';
import SearchInput from '@Components/common/FormUI/SearchInput';
import useTranslation from '@Hooks/useTranslation';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { apiURL } from '@Services/index';
import ToolTip from '@Components/RadixComponents/ToolTip';

function FilterSection() {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { _t } = useTranslation();
  const [selectedWard, setSelectWard] = useState<string[]>([]);
  const [selectedRoadType, setSelectedRoadType] = useState<string[]>([]);
  const permissions = useTypedSelector(state => state.user.permissions);
  const showFilterPopover = useTypedSelector(
    state => state.dataManagement.showFilterPopover,
  );
  const queryFnParams = useTypedSelector(
    state => state.dataManagement.roadData.queryFnParams,
  );
  const allWards = useTypedSelector(allWardsSelector);
  const searchInput = useTypedSelector(
    state => state.dataManagement.roadData.searchInput,
  );

  // handle filter reset inside select dropdown on handleFilterReset
  useEffect(() => {
    setSelectWard(queryFnParams.ward_no || []);
  }, [queryFnParams.ward_no]);
  useEffect(() => {
    setSelectedRoadType(queryFnParams.road_type || []);
  }, [queryFnParams.road_type]);

  const { data: roadTypeOptions } = useQuery({
    queryKey: ['road-type-list'],
    queryFn: () =>
      getRoadUniqueValues({
        field_name: 'road_type',
      }),
    select: res => prepareDropdownOptions(res.data),
  });

  const handleWardFilter = (updatedSelected: string[]) => {
    setSelectWard(updatedSelected);
  };
  const handleRoadTypeFilter = (updatedSelected: string[]) => {
    setSelectedRoadType(updatedSelected);
  };

  const handleApplyFilter = () => {
    dispatch(
      setRoadDataState({
        queryFnParams: {
          ward_no: selectedWard,
          road_type: selectedRoadType,
        },
      }),
    );
    dispatch(
      setDataManagementState({
        showFilterPopover: !showFilterPopover,
      }),
    );
  };

  const isFilterApplied =
    !!queryFnParams.ward_no?.length || !!queryFnParams.road_type?.length;

  return (
    <FlexColumn gap={2}>
      <FlexRow className="naxatw-flex-col naxatw-justify-between md:naxatw-flex-row">
        <SearchInput
          inputValue={searchInput}
          placeholder="Search by Road Name, Road Type or Ward Number"
          onChange={e =>
            dispatch(
              setRoadDataState({
                searchInput: e.target.value,
              }),
            )
          }
          onClear={() =>
            dispatch(
              setRoadDataState({
                searchInput: '',
              }),
            )
          }
          className="md:!naxatw-w-[360px]"
        />

        <FlexRow className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-pt-3 md:naxatw-py-0">
          <FlexColumn>
            <a
              href={`${apiURL}/download/?file_format=csv&layer=road`}
              download="downloaded_file.csv"
              target="_blank"
              rel="noreferrer"
            >
              <ToolTip name="download" message="Download Table" />
            </a>
          </FlexColumn>
          <Popover
            show={showFilterPopover}
            triggerContent={
              <Button
                leftIcon="tune"
                className="naxatw-font-bold naxatw-text-blue-500  "
                variant="outline"
                onClick={() =>
                  dispatch(
                    setDataManagementState({
                      showFilterPopover: !showFilterPopover,
                    }),
                  )
                }
              >
                {_t('Filter')}
              </Button>
            }
            popoverContent={
              <>
                <h5 className="naxatw-text-grey-800">Filter</h5>
                <FlexColumn className="naxatw-gap-4">
                  <FormControl>
                    <Label>Ward</Label>
                    <MultiSelect
                      options={allWards}
                      selectedOptions={selectedWard}
                      onChange={handleWardFilter}
                    />
                  </FormControl>
                  <FormControl>
                    <Label>Road Type</Label>
                    <MultiSelect
                      options={roadTypeOptions}
                      selectedOptions={selectedRoadType}
                      onChange={handleRoadTypeFilter}
                    />
                  </FormControl>
                </FlexColumn>
                <FlexRow className="naxatw-justify-end">
                  <Button
                    className="naxatw-gap-3 naxatw-font-bold"
                    onClick={handleApplyFilter}
                    size="sm"
                  >
                    Apply
                  </Button>
                </FlexRow>
              </>
            }
          />
          {permissions.includes('add_road') && (
            <Button
              leftIcon="add"
              className=" naxatw-gap-3 naxatw-font-bold"
              onClick={() =>
                navigate('form', { state: { redirectPath: pathname } })
              }
            >
              {_t('Add New Road')}
            </Button>
          )}
        </FlexRow>
      </FlexRow>

      {isFilterApplied && (
        <FlexRow
          className="naxatw-items-center naxatw-justify-between naxatw-rounded-md  
         naxatw-bg-primary-50 naxatw-py-1 naxatw-pl-3"
        >
          <FlexRow className="naxatw-gap-4">
            {Object.entries(queryFnParams).map(([key, value]) =>
              !value
                ? null
                : value?.map(val => (
                    <>
                      <Chip
                        key={key}
                        label={key === 'ward_no' ? `Ward ${val}` : val}
                        onClose={() =>
                          dispatch(
                            handleFilterReset({
                              stateName: 'roadData',
                              key,
                              val,
                            }),
                          )
                        }
                      />
                    </>
                  )),
            )}
          </FlexRow>
          <Button
            leftIcon="close"
            iconClassname="naxatw-text-base naxatw-font-bold"
            className="naxatw-gap-1 naxatw-font-bold"
            variant="ghost"
            onClick={() =>
              dispatch(handleFilterReset({ stateName: 'roadData' }))
            }
          >
            Clear All Filter
          </Button>
        </FlexRow>
      )}
    </FlexColumn>
  );
}

export default hasErrorBoundary(FilterSection);
