import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import SearchInput from '@Components/common/SearchInput';
import { getBuildingDataList } from '@Services/publicPage';
import { setPublicPageState } from '@Store/actions/publicPage';

export default function FilterSection() {
  const [searchInput, setSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useTypedDispatch();

  // fetch building data list on search
  const { data: searchData } = useQuery({
    queryKey: ['map-search', searchInput],
    queryFn: () => getBuildingDataList({ search: searchInput }),
    select: res => res.data,
    enabled: !!searchInput,
  });

  const selectedFeature = useTypedSelector(
    state => state.publicPage.selectedFeature,
  );

  return (
    <div className="naxatw-absolute naxatw-left-4 naxatw-top-5 naxatw-z-30 naxatw-shadow-dark naxatw-shadow-gray-400">
      <SearchInput
        isOpen={isOpen}
        data={searchData || []}
        inputValue={searchInput}
        onChange={e => {
          setSearchInput(e.target.value);
          setIsOpen(true);
        }}
        onSelect={selectedItem => {
          dispatch(
            setPublicPageState({
              selectedFeature: { ...selectedItem, layer: 'building' },
            }),
          );
          setSearchInput('');
          setIsOpen(false);
        }}
        selectedValue={selectedFeature}
        onClear={() => {
          setSearchInput('');
          dispatch(
            setPublicPageState({
              selectedFeature: null,
            }),
          );
        }}
      />
    </div>
  );
}
