import { useLocation } from 'react-router-dom';
import { initDomToCode } from 'dom-to-code';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  setCommonState,
  setModalContent,
  setPromptDialogContent,
  toggleModal,
  togglePromptDialog,
} from '@Store/actions/common';
import { setUserState } from '@Store/actions/user';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import {
  useGetOverallBuildingBboxQuery,
  useGetPalikaProfileQuery,
  useGetUserPermissionsQuery,
  useGetUserProfileQuery,
  useGetWardNoListQuery,
} from '@Api/common';
import appRoutes from '@Routes/appRoutes';
import generateRoutes from '@Routes/generateRoutes';
import testRoutes from '@Routes/testRoutes';
import {
  getModalContent,
  getPromptDialogContent,
} from '@Constants/modalContents';
import Sidebar from '@Components/common/Sidebar';
import Modal from '@Components/common/Modal';
import PromptDialog from '@Components/common/PromptDialog';
import ErrorComponent from '@Components/common/ErrorComponent';
import { ErrorBoundaryProvider } from '@xmanscript/has-error-boundary';

export default function App() {
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const permissions = useTypedSelector(state => state.user.permissions);
  const showModal = useTypedSelector(state => state.common.showModal);
  const modalContent = useTypedSelector(state => state.common.modalContent);
  const showPromptDialog = useTypedSelector(
    state => state.common.showPromptDialog,
  );
  const promptDialogContent = useTypedSelector(
    state => state.common.promptDialogContent,
  );
  const userProfile = useTypedSelector(state => state.user.userProfile);

  const routesWithoutSidebar = [
    '/login',
    '/sign-up',
    '/forgot-password',
    '/public-page',
  ];

  const hideSideBar = routesWithoutSidebar.some(url => pathname.includes(url));

  // fetch palika profile and store in common store
  useGetPalikaProfileQuery({
    enabled: !hideSideBar && !!localStorage.getItem('token'),
    onSuccess: (res: any) => {
      dispatch(
        setCommonState({
          palikaProfile: res.data,
        }),
      );
    },
  });

  // fetch overall building bbox
  useGetOverallBuildingBboxQuery({
    enabled: !hideSideBar && !!localStorage.getItem('token'),
    onSuccess: (res: any) => {
      dispatch(
        setCommonState({
          buildingBbox: res,
        }),
      );
    },
  });

  // fetch user permissions
  useGetUserPermissionsQuery({
    // fetch only when not persisted on localStorage
    enabled: !!localStorage.getItem('token') && !permissions.length,
    onSuccess: (res: any) => {
      dispatch(
        setUserState({
          permissions: res.data,
        }),
      );
    },
  });

  // fetch user profile
  useGetUserProfileQuery({
    // fetch only when token exists
    enabled: !userProfile && !!localStorage.getItem('token'),
    onSuccess: (res: any) => {
      dispatch(
        setUserState({
          userProfile: res.data,
        }),
      );
    },
  });

  // fetch ward number list
  useGetWardNoListQuery({
    select: (res: any) => {
      dispatch(
        setCommonState({
          allWards: prepareDropdownOptions(res.data).map((item: any) => ({
            ...item,
            label: `Ward ${item.label}`,
          })),
        }),
      );
    },
  });

  const handleModalClose = () => {
    dispatch(toggleModal());
    setTimeout(() => {
      dispatch(setModalContent(null));
    }, 150);
  };

  const handlePromptDialogClose = () => {
    dispatch(togglePromptDialog());
    setTimeout(() => {
      dispatch(setPromptDialogContent(null));
    }, 150);
  };

  return (
    <>
      {process.env.NODE_ENV !== 'production' &&
        !process.env.DISABLE_DOM_TO_CODE &&
        initDomToCode()}
      <ErrorBoundaryProvider errorComponent={ErrorComponent}>
        <div
          className={`${
            hideSideBar
              ? 'naxatw-ml-0'
              : `naxatw-ml-0 naxatw-mt-14 naxatw-flex md:naxatw-ml-[80px] md:naxatw-mt-0`
          }`}
        >
          <ToastContainer />

          <Modal
            show={showModal}
            className={getModalContent(modalContent)?.className || ''}
            title={getModalContent(modalContent)?.title}
            subtitle={getModalContent(modalContent)?.subtitle}
            onClose={handleModalClose}
            hideCloseButton={!!getModalContent(modalContent)?.hideCloseButton}
          >
            {getModalContent(modalContent)?.content}
          </Modal>

          <PromptDialog
            show={showPromptDialog}
            title={getPromptDialogContent(promptDialogContent)?.title}
            iconName={getPromptDialogContent(promptDialogContent)?.iconName}
            onClose={handlePromptDialogClose}
          >
            {getPromptDialogContent(promptDialogContent)?.content}
          </PromptDialog>

          {!hideSideBar && <Sidebar />}

          {generateRoutes({
            routes:
              process.env.NODE_ENV !== 'production'
                ? [...testRoutes, ...appRoutes]
                : appRoutes,
          })}
        </div>
      </ErrorBoundaryProvider>
    </>
  );
}
