import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import prepareChartData from '@Utils/prepareChartData';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import { getBuildingCount } from '@Services/dashboard';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { selectedWardSelector } from '@Store/selector/common';
import useTranslation from '@Hooks/useTranslation';
import { prepareDataWithLimit } from '@Utils/prepareDataWithLimit';

export default function BuildingRegistration() {
  const { _t } = useTranslation();
  const language = useTypedSelector(state => state.common.language);
  const selectedWards = useTypedSelector(selectedWardSelector);

  const { data, isLoading } = useQuery({
    queryKey: ['buildingRegistrationCount', selectedWards],
    queryFn: () =>
      getBuildingCount({
        field_name: 'reg_type',
        ward_no: selectedWards?.join(','),
      }),
    select: (res: any) => {
      const modifiedData = prepareChartData(
        language === 'English' ? res.data?.en : res.data?.ne,
      );
      return prepareDataWithLimit(modifiedData, 100);
    },
  });

  if (isLoading) {
    return (
      <Skeleton
        className="naxatw-h-[235px] naxatw-w-full
       naxatw-rounded-lg naxatw-bg-primary-50 naxatw-shadow-sm "
      />
    );
  }

  return (
    <div className="naxatw-animate-fade-up">
      <ChartContainer
        className="!naxatw-gap-0 lg:!naxatw-h-[235px]"
        type="horizontalBar"
        chartTitle={_t('Building count by registration status')}
        hasDownloadBtn
        header={ChartHeader}
        data={data || []}
      />
    </div>
  );
}
