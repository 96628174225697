import prepareChartData from '@Utils/prepareChartData';
import { useTypedSelector } from '@Store/hooks';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getRoadCount } from '@Services/publicPage';
import { useQuery } from '@tanstack/react-query';
import prepareQueryParam from '@Utils/prepareQueryParam';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import useTranslation from '@Hooks/useTranslation';

function RoadByCategory() {
  const { _t } = useTranslation();
  const filterParams = useTypedSelector(state => state.publicPage.filterParams);
  const language = useTypedSelector(state => state.common.language);

  const { data: roadByCategoryData, isLoading: isRoadByCategoryLoading } =
    useQuery({
      queryKey: ['road-use-count', filterParams],
      queryFn: () =>
        getRoadCount({
          field_name: 'road_category',
          ...prepareQueryParam(filterParams),
        }),
      select: res =>
        prepareChartData(language === 'English' ? res.data.en : res.data.ne),
    });

  return (
    <div>
      {isRoadByCategoryLoading ? (
        <Skeleton className="lg:naxatw-h-[256px]" />
      ) : (
        <ChartContainer
          className="!naxatw-gap-0 lg:!naxatw-h-[256px]"
          type="horizontalBar"
          chartTitle={_t('Road use category count')}
          header={ChartHeader}
          hasDownloadBtn
          data={roadByCategoryData || []}
        />
      )}
    </div>
  );
}

export default hasErrorBoundary(RoadByCategory);
