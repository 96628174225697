import React, { useState, useRef, useLayoutEffect } from 'react';
import useOutsideClick from '@Hooks/useOutsideClick';
import { CSSTransition } from 'react-transition-group';
import Icon from '../Icon';

function getPosition(direction: string) {
  switch (direction) {
    case 'left-top':
      return 'naxatw-top-[2px] naxatw-right-[40px]';
    case 'left-bottom':
      return 'naxatw-top-[30px] naxatw-right-[6px]';
    case 'right-bottom':
      return 'naxatw-top-[40px] naxatw-left-[0px]';
    case 'right-top':
      return 'naxatw-top-[2px] naxatw-left-[40px]';
    case 'top-left':
      return 'naxatw-bottom-[40px] naxatw-right-[0px]';
    case 'top-right':
      return 'naxatw-bottom-[40px] naxatw-left-[0px]';
    default:
      return 'naxatw-top-[2px] naxatw-right-[40px]';
  }
}

interface Option {
  id: string;
  icon: string;
  label: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (data: any) => void;
  iconClassName?: string;
}

interface IDropdownOptionsProps {
  options: Option[];
  data?: Record<string, any>[];
  stopPropagation?: boolean;
  direction?: string;
}

export default function DropdownOptions({
  options,
  data,
  stopPropagation,
  direction,
}: IDropdownOptionsProps): JSX.Element {
  const [toggleRef, toggle, handleToggle] = useOutsideClick();
  const nodeRef = useRef(null);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState(direction);

  useLayoutEffect(() => {
    if (!toggle || !dropdownRef?.current) return;
    const { bottom } = dropdownRef.current.getBoundingClientRect();
    // @ts-ignore
    const { height } = nodeRef.current.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    if (bottom + height > windowHeight) {
      setPosition('top-left');
    } else {
      setPosition('left-bottom');
    }
  }, [toggle]);

  return (
    <div className="naxatw-relative" ref={dropdownRef}>
      <div
        ref={toggleRef as React.RefObject<HTMLDivElement>}
        className="naxatw-select-none"
        onClick={e => {
          if (stopPropagation) e.stopPropagation();
          handleToggle();
        }}
        tabIndex={0}
        role="button"
        onKeyDown={() => {}}
      >
        <Icon
          name="more_vert"
          className="naxatw-text-grey-500 hover:naxatw-text-primary-400"
        />
      </div>
      {toggle && (
        <CSSTransition
          in={toggle}
          nodeRef={nodeRef as any}
          timeout={300}
          unmountOnExit
        >
          <div
            ref={nodeRef}
            className={`
        naxatw-absolute ${getPosition(
          // @ts-ignore
          position,
        )} naxatw-z-[10] naxatw-w-[10rem]  naxatw-divide-grey-100 naxatw-rounded naxatw-bg-white
        naxatw-shadow-3xl`}
          >
            <ul
              className=" naxatw-text-sm naxatw-text-grey-800"
              aria-labelledby="dropdownMenuIconButton"
            >
              {options.map(({ id, icon, label, onClick, iconClassName }) => (
                <li
                  role="presentation"
                  key={id}
                  className="naxatw-flex naxatw-cursor-pointer naxatw-flex-row
                   naxatw-items-center naxatw-gap-3 naxatw-p-3 hover:naxatw-bg-primary-50"
                  onClick={e => {
                    onClick(data);
                    if (stopPropagation) e.stopPropagation();
                  }}
                >
                  <Icon
                    name={icon}
                    className={
                      iconClassName ||
                      'naxatw-text-grey-600 hover:naxatw-text-primary-400'
                    }
                  />
                  <p>{label}</p>
                </li>
              ))}
            </ul>
          </div>
        </CSSTransition>
      )}
    </div>
  );
}
