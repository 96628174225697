import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {
  getMapPopupDataFailure,
  getMapPopupDataRequest,
  getMapPopupDataSuccess,
  getRouteDataRequest,
  getRouteDataSuccess,
  getRouteDataFailure,
} from '@Store/actions/visualization';
import { getMapPopupData, getGraphopperRoute } from '@Services/visualization';
import { withLoader } from '@Utils/sagaUtils';
import { createQueryParams } from '@Utils/createQueryParams';

function* getMapPopupDataSaga({
  payload,
}: PayloadAction<{ id: number; type: string }>) {
  try {
    const response: AxiosResponse = yield call(getMapPopupData, payload);
    yield put(getMapPopupDataSuccess(response.data));
  } catch (error) {
    yield put(getMapPopupDataFailure());
  }
}

function* getRouteDataSaga() {
  try {
    const { startDirectionCoordinates, endDirectionCoordinates } = yield select(
      state => state.visualization,
    );
    const from = `${startDirectionCoordinates?.latitude},${startDirectionCoordinates?.longitude}`;
    const to = `${endDirectionCoordinates?.lat},${endDirectionCoordinates?.lng}`;
    const paramObj = {
      point: to,
      points_encoded: false,
      vehicle: 'foot',
    };

    const params = `point=${from}${createQueryParams(paramObj)}`;
    const response: AxiosResponse = yield call(getGraphopperRoute, params);
    yield put(getRouteDataSuccess({ data: response.data }));
  } catch (error) {
    yield put(getRouteDataFailure());
  }
}

function* visualizationWatcher() {
  yield takeLatest(
    getMapPopupDataRequest.type,
    withLoader(getMapPopupDataSaga),
  );
  yield takeLatest(getRouteDataRequest.type, withLoader(getRouteDataSaga));
}

export default visualizationWatcher;
