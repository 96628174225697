import { ReactElement } from 'react';
import Icon from '@Components/common/Icon';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import { FlexRow } from '../Layouts';

interface IChipProps {
  label: string | ReactElement;
  onClose: any;
}

export default function Chip({ label, onClose }: IChipProps) {
  return (
    <FlexRow
      gap={1}
      className="naxatw-group naxatw-h-7 naxatw-min-w-fit naxatw-cursor-pointer naxatw-items-center naxatw-rounded-lg 
    naxatw-border naxatw-border-grey-300 naxatw-bg-grey-100 naxatw-px-2 naxatw-text-xs hover:naxatw-border-primary-400"
    >
      <p className="naxatw-w-full group-hover:naxatw-text-primary-400">
        {capitalizeFirstLetter(label.toString())}
      </p>
      <Icon
        onClick={onClose}
        name="close"
        className="!naxatw-text-sm !naxatw-font-bold group-hover:naxatw-text-primary-400"
      />
    </FlexRow>
  );
}
