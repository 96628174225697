import { createSelector } from 'reselect';
import { RootState } from '..';

const allWardsStateSelector = (state: RootState) => state.common.allWards;
const userProfileSelector = (state: RootState) => state.user.userProfile;
const selectedWardStateSelector = (state: RootState) =>
  state.common.selectedWards;

const isWardViewerSelector = createSelector(userProfileSelector, userProfile =>
  ['ward editor', 'ward viewer'].includes(userProfile?.role_type),
);

export const allWardsSelector = createSelector(
  [allWardsStateSelector, userProfileSelector, isWardViewerSelector],
  (allWards, userProfile, isWardViewer) => {
    const wards = userProfile?.ward;
    if (!wards?.length || !isWardViewer) return allWards;
    return allWards.filter(item => wards.includes(item.value));
  },
);

export const selectedWardSelector = createSelector(
  [selectedWardStateSelector, userProfileSelector, isWardViewerSelector],
  (selectedWards, userProfile, isWardViewer) => {
    const wards = userProfile?.ward;
    if (!wards) return null;
    if (wards?.length && !selectedWards.length && isWardViewer) return wards;
    return selectedWards;
  },
);
