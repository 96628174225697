import { FlexColumn } from '@Components/common/Layouts';
import NoDataImage from '@Assets/images/no-data.png';
import useTranslation from '@Hooks/useTranslation';

export default function NoDataComponent() {
  const { _t } = useTranslation();
  return (
    <div className="naxatw-flex naxatw-h-[220px] naxatw-w-full naxatw-items-center naxatw-justify-center">
      <FlexColumn className="naxatw-gap-3">
        <img src={NoDataImage} alt="No Data" height={100} width={100} />
        <h6>{_t('No Data Available')}</h6>
      </FlexColumn>
    </div>
  );
}
