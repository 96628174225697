import Dashboard from '@Views/Dashboard';
import Visualization from '@Views/Visualization';
import DataManagement from '@Views/DataManagement';
import Setting from '@Views/Setting';
import UserManagement from '@Views/UserManagement';
import RoadData from '@Components/DataManagement/RoadData';
import BuildingData from '@Components/DataManagement/BuildingData';
import LayerAttribute from '@Components/LayerAttribute';
import PhysicalInstallationComponent from '@Components/Settings/PhysicalInstallation';
import OverlayLayersComponent from '@Components/DataManagement/OverlayLayers';
import WorkshopModeSettings from '@Components/Settings/WorkshopMode';
import About from '@Views/About';
import WorkshopMode from '@Components/WorkshopMode/MapSection';

export interface ISidebar {
  name: string;
  icon?: string;
  path: string;
  component: () => JSX.Element;
  children?: ISidebar[];
}

const sidebarData: ISidebar[] = [
  {
    name: 'Dashboard',
    icon: 'dashboard',
    path: 'dashboard',
    component: Dashboard,
  },
  {
    name: 'Visualization',
    icon: 'bar_chart',
    path: 'visualization',
    component: Visualization,
  },
  {
    name: 'Data Management',
    icon: 'timeline',
    path: 'data-management',
    component: DataManagement,
    children: [
      {
        name: 'Building Data',
        path: 'building-data',
        component: BuildingData,
      },
      { name: 'Road Data', path: 'road-data', component: RoadData },
      {
        name: 'Overlay Layers',
        path: 'overlay-layer',
        component: OverlayLayersComponent,
      },
    ],
  },
  {
    name: 'Layer Attribute',
    icon: 'checklist',
    path: 'layer-attribute',
    component: LayerAttribute,
  },
  {
    name: 'Settings',
    icon: 'settings',
    path: 'setting',
    component: Setting,
    children: [
      {
        name: 'Physical Installation',
        path: 'physical-installation',
        component: PhysicalInstallationComponent,
      },
      {
        name: 'Workshop Mode',
        path: 'workshop-mode',
        component: WorkshopModeSettings,
      },
    ],
  },
  {
    name: 'User Management',
    icon: 'people',
    path: 'user-management',
    component: UserManagement,
  },
  {
    name: 'About',
    icon: 'info',
    path: 'about',
    component: About,
  },
  {
    name: 'Workshop Mode',
    icon: 'handyman',
    path: 'workshop-mode',
    component: WorkshopMode,
  },
];

export default sidebarData;
