/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { DragEndEvent } from '@dnd-kit/core';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  handleLabelToggle,
  handleLayerSort,
  handleLayerStyleChange,
  handleLayerToggle,
} from '@Store/actions/dataManagementForm';
import Icon from '@Components/common/Icon';
import { SortableList, SortableItem } from '@Components/common/DNDKit';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import getLayerStyleColors from '@Components/common/MapLibreComponents/helpers/getLayerStyleColors';
import LegendItem from '@Components/common/MapLibreComponents/LegendItem';
import RangeSlider from '@Components/RadixComponents/RangeSlider';
import IconButton from '@Components/common/IconButton';

function getFillOpacity(styles: Record<string, any>) {
  if (!('paint' in styles)) return 0;
  const keys = Object.keys(styles.paint);
  const fillOpacityKey = keys.find(
    key =>
      (key.includes('fill') || key.includes('line')) && key.includes('opacity'),
  );
  if (!fillOpacityKey) return 0;
  return styles.paint[fillOpacityKey] * 100;
}

export default function OverlayLayers() {
  const dispatch = useTypedDispatch();
  const layers = useTypedSelector(state => state.dataManagementForm.layers);

  return (
    <FlexColumn className="naxatw-h-full naxatw-bg-grey-100 naxatw-px-3 naxatw-pb-3">
      <FlexRow className="naxatw-h-11 naxatw-items-center">
        <h5>Overlay Layers</h5>
      </FlexRow>

      <SortableList
        items={layers}
        onDragEnd={(event: DragEndEvent) => {
          dispatch(handleLayerSort(event));
        }}
      >
        <FlexColumn gap={2} className="scrollbar naxatw-overflow-auto ">
          {layers.map(({ id, name, checked, styles, showLabel }) => {
            const layerColors = getLayerStyleColors(styles);
            return (
              <SortableItem
                key={`item-${id}`}
                id={id}
                // @ts-ignore
                content={({ listeners, attributes }) => (
                  <FlexColumn
                    key={id}
                    className="naxatw-rounded naxatw-border naxatw-border-grey-200  naxatw-bg-white naxatw-p-2"
                  >
                    <FlexRow className="naxatw-items-center naxatw-justify-between">
                      <FlexRow className="naxatw-items-center" gap={1}>
                        {/* drag handle */}
                        <Icon
                          name="drag_indicator"
                          className="naxatw-cursor-move naxatw-text-grey-600"
                          {...listeners}
                          {...attributes}
                        />
                        {/* label */}
                        <p className="naxatw-btn-text naxatw-cursor-default">
                          {name}
                        </p>
                      </FlexRow>
                      <FlexRow gap={1} className="naxatw-items-center">
                        {/* checkbox - layer toggle */}
                        <IconButton
                          name={
                            checked ? 'check_box' : `check_box_outline_blank`
                          }
                          className="naxatw-h-8 naxatw-w-8 naxatw-text-primary-400"
                          onClick={() => {
                            dispatch(handleLayerToggle(id));
                          }}
                        />
                      </FlexRow>
                    </FlexRow>
                    {checked && (
                      <FlexColumn className="naxatw-pt-1.5">
                        {/* legend section */}
                        <FlexColumn>
                          {styles && (
                            <FlexColumn gap={2}>
                              {/* legend items */}
                              {layerColors?.map(({ color, label }) => (
                                <FlexRow
                                  key={label}
                                  gap={1}
                                  className="naxatw-items-center"
                                >
                                  <LegendItem
                                    color={color}
                                    type={styles?.type || 'fill'}
                                  />
                                  <p className="naxatw-text-body-sm naxatw-text-grey-600">
                                    {label}
                                  </p>
                                </FlexRow>
                              ))}
                            </FlexColumn>
                          )}
                        </FlexColumn>

                        <FlexRow className="naxatw-mt-3" gap={4}>
                          {/* layer opacity */}
                          <RangeSlider
                            defaultValue={[50]}
                            max={100}
                            step={1}
                            value={[getFillOpacity(styles)]}
                            onValueChange={value => {
                              const opacityValue = value[0] / 100;
                              const styleKey =
                                id === 'building'
                                  ? 'fill-extrusion-opacity'
                                  : styles.type === 'line'
                                    ? 'line-opacity'
                                    : 'fill-opacity';
                              dispatch(
                                handleLayerStyleChange({
                                  layerId: id,
                                  styles: {
                                    [styleKey]: opacityValue,
                                  },
                                }),
                              );
                            }}
                          />
                          {/* label toggle */}
                          <span
                            role="button"
                            tabIndex={0}
                            className="naxatw-cursor-pointer naxatw-px-1"
                            onClick={() => {
                              dispatch(handleLabelToggle(id));
                            }}
                            onKeyUp={() => {}}
                          >
                            <p
                              className={`naxatw-btn-text ${
                                showLabel
                                  ? 'naxatw-text-primary-400'
                                  : 'naxatw-text-grey-500'
                              }`}
                            >
                              Aa
                            </p>
                          </span>
                        </FlexRow>
                      </FlexColumn>
                    )}
                  </FlexColumn>
                )}
              />
            );
          })}
        </FlexColumn>
      </SortableList>
    </FlexColumn>
  );
}
