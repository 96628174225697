import { buildingFormSchema, roadFormSchema } from './dataManagementForm';

export const buildingDataFields = [
  ...buildingFormSchema.basic,
  ...buildingFormSchema.attributes
    // @ts-ignore
    .map((item: any) => item.children || item)
    .flat(),
].map(item => ({ ...item, required: false }));

export const roadDataFields = [
  ...roadFormSchema.basic,
  ...roadFormSchema.attributes,
  // @ts-ignore
].map(item => ({ ...item, required: false }));
