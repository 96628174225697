/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
import { useState } from 'react';

interface LanguageToggleProps {
  selectedLanguage: string;
  languages: [string, string];
  className?: string;
  onLanguageChange: (language: string) => void;
}

export default function LanguageToggle({
  selectedLanguage,
  languages,
  className,
  onLanguageChange,
}: LanguageToggleProps) {
  const [currentLanguage, setCurrentLanguage] = useState(selectedLanguage);

  const handleToggle = () => {
    const nextLanguageIndex =
      (languages.indexOf(currentLanguage) + 1) % languages.length;
    const nextLanguage = languages[nextLanguageIndex];
    setCurrentLanguage(nextLanguage);
    onLanguageChange(nextLanguage);
  };

  return (
    <div
      className={`${className} naxatw-h-[2rem] naxatw-w-[3.75rem] naxatw-rounded-2xl naxatw-bg-grey-200 naxatw-p-1 hover:naxatw-cursor-pointer`}
      onClick={handleToggle}
    >
      <div
        className={`naxatw-h-[1.5rem] naxatw-w-[1.5rem] naxatw-rounded-[50%] naxatw-bg-primary-500 naxatw-text-center ${
          selectedLanguage === languages[1]
            ? 'naxatw-translate-x-6 naxatw-transition-all naxatw-duration-300 naxatw-ease-in-out'
            : 'naxatw-translate-x-0 naxatw-transition-all naxatw-duration-300 naxatw-ease-in-out'
        }`}
      >
        <span className="naxatw-font-primary naxatw-text-[0.75rem] naxatw-font-semibold naxatw-text-white">
          {currentLanguage === 'Nepali' ? 'ने' : currentLanguage.slice(0, 2)}
        </span>
      </div>
    </div>
  );
}
