import prepareChartData from '@Utils/prepareChartData';
import { useTypedSelector } from '@Store/hooks';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { getBuildingCount } from '@Services/publicPage';
import { useQuery } from '@tanstack/react-query';
import prepareQueryParam from '@Utils/prepareQueryParam';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import { prepareDataWithLimit } from '@Utils/prepareDataWithLimit';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import useTranslation from '@Hooks/useTranslation';

function BuildingByCategory() {
  const { _t } = useTranslation();
  const filterParams = useTypedSelector(state => state.publicPage.filterParams);
  const language = useTypedSelector(state => state.common.language);

  const {
    data: buildingByCategoryData,
    isLoading: isBuildingByCategoryLoading,
  } = useQuery({
    queryKey: ['building-use-count', filterParams],
    queryFn: () =>
      getBuildingCount({
        field_name: 'building_use',
        ...prepareQueryParam(filterParams),
      }),
    select: res => {
      const chartData = prepareChartData(
        language === 'English' ? res.data.en : res.data.ne,
      );
      const modifiedData = prepareDataWithLimit(chartData, 100);
      return modifiedData;
    },
  });

  return (
    <div>
      {isBuildingByCategoryLoading ? (
        <Skeleton className="lg:naxatw-h-[256px]" />
      ) : (
        <ChartContainer
          className="!naxatw-gap-0 lg:!naxatw-h-[256px]"
          type="horizontalBar"
          chartTitle={_t('Building use category count')}
          header={ChartHeader}
          hasDownloadBtn
          data={buildingByCategoryData || []}
        />
      )}
    </div>
  );
}

export default hasErrorBoundary(BuildingByCategory);
