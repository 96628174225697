/* eslint-disable import/prefer-default-export */
import { FormFieldType } from './dataManagementForm';

export const physicalInstallationFormSchema: FormFieldType[] = [
  {
    id: 'planned',
    label: 'Planned',
    type: 'input',
    inputType: 'number',
    placeholder: 'Planned',
    required: false,
  },
  {
    id: 'installed',
    label: 'Installed',
    type: 'input',
    inputType: 'number',
    placeholder: 'Installed',
    required: false,
  },
  {
    id: 'planned_date',
    label: 'Planned Installation Date',
    type: 'input',
    inputType: 'date',
    placeholder: 'Date',
    required: false,
  },
];
