import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleInfoDialog, togglePromptDialog } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { updatedBuildingData, updatedRoadData } from '@Services/dataManagement';
import {
  handleBuildingTableDelete,
  handleRoadTableDelete,
} from '@Queries/dataManagement';
import { useLocation } from 'react-router-dom';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { resetDataManagementFormState } from '@Store/actions/dataManagementForm';

const deleteConfirmationText = 'delete';

export default function DeleteData() {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const [input, setInput] = useState('');
  const queryClient = useQueryClient();

  const promptDialogContent = useTypedSelector(
    state => state.common.promptDialogContent,
  );

  const isBuildingData = promptDialogContent === 'delete-building-data';
  const selectedBuilding = useTypedSelector(
    state => state.dataManagement.buildingData.selectedBuilding,
  );
  const selectedRoad = useTypedSelector(
    state => state.dataManagement.roadData.selectedRoad,
  );

  const selectedId = isBuildingData ? selectedBuilding?.id : selectedRoad?.id;
  const attributeType = useTypedSelector(
    state => state.workshopMode.attributeType,
  );

  const handlePromptClose = () => {
    dispatch(togglePromptDialog());
    dispatch(
      toggleInfoDialog(
        isBuildingData ? 'delete-building-success' : 'delete-road-success',
      ),
    );
  };

  // update query cache on delete
  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      isBuildingData
        ? handleBuildingTableDelete(queryClient, payloadData)
        : handleRoadTableDelete(queryClient, payloadData),
  });

  const { mutate: deleteDataRequest, isLoading } = useMutation({
    mutationFn: id =>
      isBuildingData
        ? updatedBuildingData(id, { is_deleted: true })
        : updatedRoadData(id, { is_deleted: true }),
    onSuccess: () => {
      handlePromptClose();
      // reset workshop mode states after delete
      if (pathname.includes('workshop-mode')) {
        dispatch(
          setWorkshopModeState({
            selectedFeature: null,
            attributeType: null,
            refreshLayerId: attributeType?.includes('building')
              ? 'building'
              : 'road',
          }),
        );
        dispatch(resetDataManagementFormState());
        return;
      }
      if (pathname.includes('visualization')) {
        dispatch(
          setWorkshopModeState({
            refreshLayerId: isBuildingData ? 'building' : 'road',
          }),
        );
        dispatch(resetDataManagementFormState());
        return;
      }
      if (!selectedId) return;
      updateTableData({ id: selectedId });
    },
  });

  const handleDelete = () => {
    if (
      (!input || input !== deleteConfirmationText) &&
      !pathname.includes('workshop-mode')
    )
      return;
    if (!selectedId) return;
    deleteDataRequest(selectedId);
  };

  const itemToDelete = isBuildingData
    ? selectedBuilding?.house_no || selectedBuilding?.associate_road_name
    : selectedRoad?.road_name_en || selectedRoad?.road_id;

  return (
    <FlexColumn gap={2} className="naxatw-text-sm">
      <p className="naxatw-text-grey-600">
        Are you sure you want to delete {itemToDelete}?
      </p>
      {!pathname.includes('workshop-mode') && (
        <>
          <p>
            Please type &quot;
            <span className="naxatw-select-none">{deleteConfirmationText}</span>
            &quot; to confirm
          </p>
          <Input
            type="text"
            placeholder="Type Delete"
            onPaste={e => e.preventDefault()}
            onChange={e => setInput(e.target.value)}
          />
        </>
      )}
      <FlexRow className="naxatw-w-full naxatw-justify-end naxatw-pt-5">
        <Button variant="ghost" onClick={() => dispatch(togglePromptDialog())}>
          Cancel
        </Button>
        <Button
          leftIcon="delete"
          variant="destructive"
          withLoader
          isLoading={isLoading}
          onClick={handleDelete}
          disabled={
            !pathname.includes('workshop-mode') &&
            input !== deleteConfirmationText
          }
          className={`${
            input !== deleteConfirmationText &&
            !pathname.includes('workshop-mode')
              ? 'disabled:!naxatw-pointer-events-auto disabled:!naxatw-cursor-not-allowed'
              : ''
          }`}
        >
          Confirm
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
