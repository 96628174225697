import { useCallback } from 'react';
import { useTypedDispatch } from '@Store/hooks';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';
import debounce from '@Utils/debounce';
import useTranslation from '@Hooks/useTranslation';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';

interface IProgressStepProps {
  data: Record<string, any>;
  activeIndex: number;
  stepIndex: number;
}

export default function ProgressStep({
  data,
  activeIndex,
  stepIndex,
}: IProgressStepProps) {
  const dispatch = useTypedDispatch();
  const stepKeys = Object.keys(data);
  const { _t } = useTranslation();
  const lastIndex = stepKeys.length - 1;

  const onChange = useCallback(
    (index: any) => {
      const isClickable = +index <= stepIndex;
      if (!isClickable) return;
      dispatch(setDataManagementFormState({ activeIndex: +index }));
    },
    [dispatch, stepIndex],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(debounce(onChange, 300), [onChange]);

  return (
    <FlexRow className="naxatw-select-none naxatw-justify-center">
      {stepKeys.map((index, idx) => {
        const isClickable = +index <= stepIndex;
        return (
          <FlexColumn key={index}>
            <FlexRow className="naxatw-items-center ">
              <FlexColumn
                gap={3}
                role="presentation"
                className={`${
                  isClickable
                    ? 'naxatw-cursor-pointer'
                    : 'naxatw-cursor-default'
                } naxatw-w-10 naxatw-items-center naxatw-justify-center
                `}
                onClick={() => {
                  debouncedHandler(index);
                }}
              >
                <h5
                  className={`${
                    activeIndex === parseInt(index, 10)
                      ? ' naxatw-border-primary-400 naxatw-bg-white '
                      : ''
                  } ${
                    idx < stepIndex
                      ? ' naxatw-bg-primary-400 naxatw-text-primary-400'
                      : ''
                  } naxatw-flex naxatw-h-10 naxatw-w-10  naxatw-items-center naxatw-justify-center
                naxatw-rounded-full naxatw-border naxatw-text-base naxatw-text-grey-600`}
                >
                  {idx < stepIndex ? (
                    <Icon
                      name="check"
                      className={`${
                        idx < stepIndex && activeIndex === parseInt(index, 10)
                          ? 'naxatw-text-primary-400'
                          : 'naxatw-text-white'
                      }`}
                    />
                  ) : (
                    parseInt(index, 10) + 1
                  )}
                </h5>
                <h5
                  className={`${
                    activeIndex === parseInt(index, 10)
                      ? 'naxatw-text-primary-400'
                      : ''
                  }  ${
                    isClickable
                      ? 'naxatw-text-grey-800'
                      : 'naxatw-text-grey-400'
                  } naxatw-w-24 naxatw-text-center naxatw-text-sm naxatw-capitalize`}
                >
                  {_t(data[index])}
                </h5>
              </FlexColumn>
              {idx !== lastIndex && (
                <div className="-naxatw-mt-4 naxatw-h-[1px] naxatw-w-9 naxatw-bg-grey-200 md:naxatw-w-[3.75rem] " />
              )}
            </FlexRow>
          </FlexColumn>
        );
      })}
    </FlexRow>
  );
}
