// eslint-disable
// @ts-nocheck
import { point, featureCollection } from '@turf/helpers';
import nearestPointOnLine from '@turf/nearest-point-on-line';
import lineSplit from '@turf/line-split';
import { Popup } from 'maplibre-gl';
import length from '@turf/length';

const popup = new Popup({
  closeButton: false,
  closeOnClick: false,
  className: 'map-tooltip',
  offset: 12,
});

const SplitLineMode = {
  onSetup(opts) {
    const state = { ...opts };
    return state;
  },
  onClick(state, e) {
    const { onLineSplit, geojson } = state;
    const feature = this.featuresAt(e)?.[0];
    if (!feature) return;
    let lineFeature = feature;
    if (feature.geometry.type === 'MultiLineString') {
      const flattenedLine = {
        type: 'Feature',
        properties: {},
        geometry: {
          type: 'LineString',
          coordinates: feature.geometry.coordinates.flat(),
        },
      };
      lineFeature = flattenedLine;
    }
    const { id } = feature.properties;
    // const actualFeature = this.getFeature(id);
    const cursorAt = point([e.lngLat.lng, e.lngLat.lat]);
    const snappedPointOnLine = nearestPointOnLine(lineFeature, cursorAt);
    const splittedLines = lineSplit(lineFeature, snappedPointOnLine);
    this.deleteFeature(id);
    let allFeatures = splittedLines.features;
    if (geojson.type === 'FeatureCollection') {
      allFeatures = geojson.features.reduce(
        (arr, item) =>
          item.id !== id ? [...arr, item] : [...arr, ...splittedLines.features],
        [],
      );
    }
    const newFeatures = allFeatures.map((f, idx) => {
      // this.addFeature(this.newFeature(f));
      return {
        ...f,
        id: idx + 1,
        properties: { id: idx + 1, length: length(f) },
      };
    });
    newFeatures.forEach(feat => {
      this.addFeature(this.newFeature(feat));
    });
    const features = featureCollection(newFeatures);
    setTimeout(() => {
      this.map.fire('draw.create', {
        features,
      });
    }, 10);
    this.changeMode('direct_select', { featureId: 2 });
    onLineSplit(features);
    this.exitMode();
  },
  toDisplayFeatures(state, geojson, display) {
    display(geojson);
  },
  onMouseMove(state, e) {
    const features = this.featuresAt(e);
    this.map.getCanvas().style.cursor = features.length
      ? 'crosshair'
      : 'inherit';
    const description = 'Click on the line feature where you want to split';
    popup.setLngLat(e.lngLat).setHTML(description).addTo(this.map);
  },
  exitMode() {
    popup.remove();
    this.map.getCanvas().style.cursor = 'inherit';
    return this.changeMode('simple_select');
  },
};

export default SplitLineMode;
