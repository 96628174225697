export const BuildingSectionSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="33"
    viewBox="0 0 34 33"
    fill="none"
  >
    <path
      d="M0.958334 32.7208V8.10833H8.42917V0.637498H25.5708V15.5792H33.0417V32.7208H19.6583V25.25H14.3417V32.7208H0.958334ZM3.15833 30.5208H8.42917V25.25H3.15833V30.5208ZM3.15833 23.05H8.42917V17.7792H3.15833V23.05ZM3.15833 15.5792H8.42917V10.3083H3.15833V15.5792ZM10.6292 23.05H15.9V17.7792H10.6292V23.05ZM10.6292 15.5792H15.9V10.3083H10.6292V15.5792ZM10.6292 8.10833H15.9V2.8375H10.6292V8.10833ZM18.1 23.05H23.3708V17.7792H18.1V23.05ZM18.1 15.5792H23.3708V10.3083H18.1V15.5792ZM18.1 8.10833H23.3708V2.8375H18.1V8.10833ZM25.5708 30.5208H30.8417V25.25H25.5708V30.5208ZM25.5708 23.05H30.8417V17.7792H25.5708V23.05Z"
      fill="#757575"
    />
  </svg>
);
export const BuildingAccessCaseSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
  >
    <path
      d="M6.33358 15.5289H7.27858C7.54318 15.5289 7.75108 15.321 7.75108 15.0564V14.1114C7.75108 13.8468 7.54318 13.6389 7.27858 13.6389H6.33358C6.06898 13.6389 5.86108 13.8468 5.86108 14.1114V15.0564C5.86108 15.321 6.06898 15.5289 6.33358 15.5289Z"
      fill="#757575"
    />
    <path
      d="M12.167 15.5289H13.112C13.3766 15.5289 13.5845 15.321 13.5845 15.0564V14.1114C13.5845 13.8468 13.3766 13.6389 13.112 13.6389H12.167C11.9024 13.6389 11.6945 13.8468 11.6945 14.1114V15.0564C11.6945 15.321 11.9024 15.5289 12.167 15.5289Z"
      fill="#757575"
    />
    <path
      d="M6.33358 21.3623H7.27858C7.54318 21.3623 7.75108 21.1544 7.75108 20.8898V19.9448C7.75108 19.6802 7.54318 19.4723 7.27858 19.4723H6.33358C6.06898 19.4723 5.86108 19.6802 5.86108 19.9448V20.8898C5.86108 21.1544 6.06898 21.3623 6.33358 21.3623Z"
      fill="#757575"
    />
    <path
      d="M12.167 21.3623H13.112C13.3766 21.3623 13.5845 21.1544 13.5845 20.8898V19.9448C13.5845 19.6802 13.3766 19.4723 13.112 19.4723H12.167C11.9024 19.4723 11.6945 19.6802 11.6945 19.9448V20.8898C11.6945 21.1544 11.9024 21.3623 12.167 21.3623Z"
      fill="#757575"
    />
    <path
      d="M21.862 19.4723H20.917C20.6524 19.4723 20.4445 19.6802 20.4445 19.9448V20.8898C20.4445 21.1544 20.6524 21.3623 20.917 21.3623H21.862C22.1266 21.3623 22.3345 21.1544 22.3345 20.8898V19.9448C22.3345 19.6802 22.1266 19.4723 21.862 19.4723Z"
      fill="#757575"
    />
    <path
      d="M27.6953 19.4723H26.7503C26.4857 19.4723 26.2778 19.6802 26.2778 19.9448V20.8898C26.2778 21.1544 26.4857 21.3623 26.7503 21.3623H27.6953C27.9599 21.3623 28.1678 21.1544 28.1678 20.8898V19.9448C28.1678 19.6802 27.9599 19.4723 27.6953 19.4723Z"
      fill="#757575"
    />
    <path
      d="M16.5011 6.30644V5.36144C16.5011 5.09684 16.2932 4.88894 16.0286 4.88894H15.0836C14.819 4.88894 14.6111 5.09684 14.6111 5.36144V6.30644C14.6111 6.57104 14.819 6.77894 15.0836 6.77894H16.0286C16.2932 6.77894 16.5011 6.57104 16.5011 6.30644Z"
      fill="#757575"
    />
    <path
      d="M20.917 6.77894H21.862C22.1266 6.77894 22.3345 6.57104 22.3345 6.30644V5.36144C22.3345 5.09684 22.1266 4.88894 21.862 4.88894H20.917C20.6524 4.88894 20.4445 5.09684 20.4445 5.36144V6.30644C20.4445 6.57104 20.6524 6.77894 20.917 6.77894Z"
      fill="#757575"
    />
    <path
      d="M20.917 11.64H21.862C22.1266 11.64 22.3345 11.4321 22.3345 11.1675V10.2225C22.3345 9.95792 22.1266 9.75002 21.862 9.75002H20.917C20.6524 9.75002 20.4445 9.95792 20.4445 10.2225V11.1675C20.4445 11.4321 20.6524 11.64 20.917 11.64Z"
      fill="#757575"
    />
    <path
      d="M0.972222 29.94H2.91667C3.46111 29.94 3.88889 29.7332 3.88889 29.47C3.88889 29.2068 3.46111 29 2.91667 29H0.972222C0.427778 29 0 29.2068 0 29.47C0 29.7332 0.427778 29.94 0.972222 29.94Z"
      fill="#757575"
    />
    <path
      d="M10.6945 29.94C11.2389 29.94 11.6667 29.7332 11.6667 29.47C11.6667 29.2068 11.2389 29 10.6945 29H8.75005C8.20561 29 7.77783 29.2068 7.77783 29.47C7.77783 29.7332 8.20561 29.94 8.75005 29.94H10.6945Z"
      fill="#757575"
    />
    <path
      d="M18.4722 29.94C19.0167 29.94 19.4444 29.7332 19.4444 29.47C19.4444 29.2068 19.0167 29 18.4722 29H16.5278C15.9833 29 15.5555 29.2068 15.5555 29.47C15.5555 29.7332 15.9833 29.94 16.5278 29.94H18.4722Z"
      fill="#757575"
    />
    <path
      d="M26.25 29.94C26.7945 29.94 27.2223 29.7332 27.2223 29.47C27.2223 29.2068 26.7945 29 26.25 29H24.3056C23.7612 29 23.3334 29.2068 23.3334 29.47C23.3334 29.7332 23.7612 29.94 24.3056 29.94H26.25Z"
      fill="#757575"
    />
    <path
      d="M34.0278 29H32.0833C31.5389 29 31.1111 29.2068 31.1111 29.47C31.1111 29.7332 31.5389 29.94 32.0833 29.94H34.0278C34.5722 29.94 35 29.7332 35 29.47C35 29.2068 34.5722 29 34.0278 29Z"
      fill="#757575"
    />
    <path
      d="M34.0278 32.0834H0.972222C0.427778 32.0834 0 32.5111 0 33.0556C0 33.6 0.427778 34.0278 0.972222 34.0278H34.0278C34.5722 34.0278 35 33.6 35 33.0556C35 32.5111 34.5722 32.0834 34.0278 32.0834Z"
      fill="#757575"
    />
    <path
      d="M34.0278 24.3056H33.0556V15.5556C33.0556 15.0111 32.6278 14.5834 32.0833 14.5834H27.2222V0.972245C27.2222 0.427801 26.7944 2.28882e-05 26.25 2.28882e-05H10.6944C10.15 2.28882e-05 9.72222 0.427801 9.72222 0.972245V8.75002H2.91667C2.37222 8.75002 1.94444 9.1778 1.94444 9.72225V24.3056H0.972222C0.427778 24.3056 0 24.7334 0 25.2778C0 25.8222 0.427778 26.25 0.972222 26.25H2.91667H3.88889H28.1944H32.0833H34.0278C34.5722 26.25 35 25.8222 35 25.2778C35 24.7334 34.5722 24.3056 34.0278 24.3056ZM11.6667 1.94447H25.2778V14.5834H17.5V9.72225C17.5 9.1778 17.0722 8.75002 16.5278 8.75002H11.6667V1.94447ZM15.5556 24.3056H3.88889V10.6945H15.5556V24.3056ZM28.1944 24.3056H17.5V16.5278H31.1111V24.3056H28.1944Z"
      fill="#757575"
    />
  </svg>
);
export const BuildingOwnerShipSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="32"
    viewBox="0 0 35 32"
    fill="none"
  >
    <path
      d="M18.8393 0.549018C18.4346 0.195078 17.9152 0 17.3776 0C16.84 0 16.3206 0.195078 15.9159 0.549018L0.373896 14.2038C0.264556 14.301 0.175431 14.4188 0.111607 14.5504C0.0477839 14.682 0.0105129 14.8249 0.0019224 14.971C-0.00666814 15.117 0.01359 15.2633 0.0615401 15.4015C0.10949 15.5397 0.184193 15.6672 0.281384 15.7765C0.378575 15.8858 0.49635 15.975 0.627985 16.0388C0.75962 16.1026 0.902537 16.1399 1.04858 16.1485C1.19462 16.1571 1.34092 16.1368 1.47913 16.0889C1.61734 16.0409 1.74475 15.9662 1.85409 15.869L4.03737 13.9448V28.7097C4.03737 29.3967 4.31028 30.0555 4.79606 30.5413C5.28185 31.0271 5.94071 31.3 6.62771 31.3H28.0905C28.7775 31.3 29.4364 31.0271 29.9221 30.5413C30.4079 30.0555 30.6808 29.3967 30.6808 28.7097V13.9448L32.8641 15.869C32.9735 15.9662 33.1009 16.0409 33.2391 16.0889C33.3773 16.1368 33.5236 16.1571 33.6696 16.1485C33.8157 16.1399 33.9586 16.1026 34.0902 16.0388C34.2219 15.975 34.3396 15.8858 34.4368 15.7765C34.534 15.6672 34.6087 15.5397 34.6567 15.4015C34.7046 15.2633 34.7249 15.117 34.7163 14.971C34.7077 14.8249 34.6704 14.682 34.6066 14.5504C34.5428 14.4188 34.4536 14.301 34.3443 14.2038L18.8393 0.549018ZM28.4605 28.6727C28.4605 28.7708 28.4216 28.8649 28.3522 28.9343C28.2828 29.0037 28.1886 29.0427 28.0905 29.0427H6.62771C6.52957 29.0427 6.43544 29.0037 6.36604 28.9343C6.29665 28.8649 6.25766 28.7708 6.25766 28.6727V11.9835L17.3591 2.21423L28.4605 11.9835V28.6727Z"
      fill="#757575"
    />
    <path
      d="M13.2885 13.4267C13.2872 14.2199 13.5176 14.9962 13.9515 15.6601C14.3854 16.3241 15.0039 16.8469 15.7308 17.1642C14.4461 17.3738 13.277 18.0312 12.4305 19.02C11.5839 20.0088 11.1144 21.2652 11.1052 22.5669V25.4902C11.1052 25.8828 11.2612 26.2593 11.5388 26.5369C11.8164 26.8145 12.1928 26.9704 12.5854 26.9704H22.1327C22.327 26.9704 22.5195 26.9321 22.6991 26.8578C22.8787 26.7834 23.0419 26.6743 23.1793 26.5369C23.3168 26.3995 23.4258 26.2363 23.5002 26.0567C23.5746 25.8771 23.6128 25.6846 23.6128 25.4902V22.5669C23.6051 21.2706 23.1409 20.0185 22.3018 19.0305C21.4626 18.0424 20.3022 17.3816 19.0243 17.1642C19.6914 16.865 20.2657 16.3917 20.6868 15.794C21.1079 15.1962 21.3601 14.4961 21.4171 13.7671C21.4741 13.0382 21.3337 12.3073 21.0106 11.6514C20.6876 10.9955 20.1938 10.4386 19.5812 10.0395C18.9686 9.6403 18.2598 9.41349 17.5293 9.3829C16.7988 9.3523 16.0735 9.51905 15.4297 9.8656C14.7858 10.2122 14.2472 10.7257 13.8705 11.3524C13.4937 11.979 13.2927 12.6955 13.2885 13.4267ZM21.3926 22.5669V24.7501H13.3255V22.5669C13.3353 21.6998 13.6865 20.8716 14.3031 20.2619C14.9197 19.6523 15.7518 19.3104 16.6189 19.3104H18.0991C18.9662 19.3104 19.7984 19.6523 20.415 20.2619C21.0315 20.8716 21.3828 21.6998 21.3926 22.5669ZM19.2093 13.4267C19.2093 13.7926 19.1008 14.1503 18.8975 14.4546C18.6941 14.7589 18.4052 14.996 18.0671 15.1361C17.729 15.2761 17.357 15.3128 16.9981 15.2414C16.6392 15.17 16.3095 14.9938 16.0507 14.735C15.792 14.4762 15.6157 14.1466 15.5443 13.7876C15.473 13.4287 15.5096 13.0567 15.6496 12.7186C15.7897 12.3805 16.0268 12.0916 16.3311 11.8883C16.6354 11.685 16.9931 11.5764 17.359 11.5764C17.8498 11.5764 18.3204 11.7714 18.6674 12.1184C19.0143 12.4653 19.2093 12.936 19.2093 13.4267Z"
      fill="#757575"
    />
  </svg>
);
export const RoadSectionByCountSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="33"
    viewBox="0 0 33 33"
    fill="none"
  >
    <path
      d="M21.9018 10.9327H31.4982C31.8669 10.9327 32.166 10.6335 32.166 10.2649C32.166 9.89625 31.8669 9.59708 31.4982 9.59708H22.5696V0.667808C22.5696 0.299178 22.2704 0 21.9018 0C21.5332 0 21.234 0.299178 21.234 0.667808V10.2646C21.234 10.6335 21.5329 10.9327 21.9018 10.9327Z"
      fill="#757575"
    />
    <path
      d="M31.4982 21.234H21.9018C21.5332 21.234 21.234 21.5332 21.234 21.9018V31.4982C21.234 31.8669 21.5332 32.166 21.9018 32.166C22.2704 32.166 22.5696 31.8669 22.5696 31.4982V22.5696H31.4982C31.8669 22.5696 32.166 22.2705 32.166 21.9018C32.166 21.5332 31.8672 21.234 31.4982 21.234Z"
      fill="#757575"
    />
    <path
      d="M10.2649 21.234H0.667808C0.299178 21.234 0 21.5332 0 21.9018C0 22.2705 0.299178 22.5696 0.667808 22.5696H9.59674V31.4982C9.59674 31.8669 9.89592 32.166 10.2646 32.166C10.6332 32.166 10.9324 31.8669 10.9324 31.4982V21.9018C10.9327 21.5329 10.6335 21.234 10.2649 21.234Z"
      fill="#757575"
    />
    <path
      d="M0.667808 10.9327H10.2646C10.6332 10.9327 10.9324 10.6335 10.9324 10.2649V0.667808C10.9324 0.299178 10.6332 0 10.2646 0C9.89592 0 9.59674 0.299178 9.59674 0.667808V9.59674H0.667808C0.299178 9.59674 0 9.89592 0 10.2646C0 10.6332 0.299178 10.9327 0.667808 10.9327Z"
      fill="#757575"
    />
    <path
      d="M25.9895 15.4154C25.6208 15.4154 25.3217 15.7146 25.3217 16.0832C25.3217 16.4518 25.6208 16.751 25.9895 16.751H29.9963C30.3649 16.751 30.6641 16.4518 30.6641 16.0832C30.6641 15.7146 30.3649 15.4154 29.9963 15.4154H25.9895Z"
      fill="#757575"
    />
    <path
      d="M1.94796 15.4154C1.57933 15.4154 1.28015 15.7146 1.28015 16.0832C1.28015 16.4518 1.57933 16.751 1.94796 16.751H5.95481C6.32344 16.751 6.62262 16.4518 6.62262 16.0832C6.62262 15.7146 6.32344 15.4154 5.95481 15.4154H1.94796Z"
      fill="#757575"
    />
    <path
      d="M22.6504 16.0832C22.6504 15.7146 22.3512 15.4154 21.9826 15.4154H17.9754C17.6068 15.4154 17.3076 15.7146 17.3076 16.0832C17.3076 16.4518 17.6068 16.751 17.9754 16.751H21.9826C22.3512 16.751 22.6504 16.4518 22.6504 16.0832Z"
      fill="#757575"
    />
    <path
      d="M13.9686 15.4154H9.96175C9.59312 15.4154 9.29395 15.7146 9.29395 16.0832C9.29395 16.4518 9.59312 16.751 9.96175 16.751H13.9686C14.3372 16.751 14.6364 16.4518 14.6364 16.0832C14.6364 15.7146 14.3376 15.4154 13.9686 15.4154Z"
      fill="#757575"
    />
    <path
      d="M16.0832 1.66957C15.7146 1.66957 15.4154 1.96875 15.4154 2.33738V6.34423C15.4154 6.71286 15.7146 7.01203 16.0832 7.01203C16.4522 7.01203 16.751 6.71286 16.751 6.34423V2.33738C16.751 1.96875 16.4522 1.66957 16.0832 1.66957Z"
      fill="#757575"
    />
    <path
      d="M16.0832 17.6969C15.7146 17.6969 15.4154 17.9961 15.4154 18.3647V22.3719C15.4154 22.7405 15.7146 23.0397 16.0832 23.0397C16.4522 23.0397 16.751 22.7405 16.751 22.3719V18.3647C16.751 17.9961 16.4522 17.6969 16.0832 17.6969Z"
      fill="#757575"
    />
    <path
      d="M16.0832 9.68327C15.7146 9.68327 15.4154 9.98245 15.4154 10.3511V14.3579C15.4154 14.7266 15.7146 15.0257 16.0832 15.0257C16.4522 15.0257 16.751 14.7266 16.751 14.3579V10.3511C16.751 9.98245 16.4522 9.68327 16.0832 9.68327Z"
      fill="#757575"
    />
    <path
      d="M16.0832 25.711C15.7146 25.711 15.4154 26.0101 15.4154 26.3788V30.3856C15.4154 30.7542 15.7146 31.0534 16.0832 31.0534C16.4522 31.0534 16.751 30.7542 16.751 30.3856V26.3788C16.751 26.0098 16.4522 25.711 16.0832 25.711Z"
      fill="#757575"
    />
  </svg>
);

export const RoadSectionByLengthSvg = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
  >
    <path
      d="M30.303 0.427811H26.6305V29.8077H30.303V0.427811Z"
      fill="#757575"
    />
    <path
      d="M4.59558 0.427811H0.923096V29.8077H4.59558V0.427811Z"
      fill="#757575"
    />
    <path
      d="M17.4493 0.427811H13.7769V7.77278H17.4493V0.427811Z"
      fill="#757575"
    />
    <path
      d="M17.4493 11.4453H13.7769V18.7902H17.4493V11.4453Z"
      fill="#757575"
    />
    <path
      d="M17.4493 22.4627H13.7769V29.8077H17.4493V22.4627Z"
      fill="#757575"
    />
  </svg>
);
