/* eslint-disable no-nested-ternary */
import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import {
  call,
  put,
  takeLatest,
  all,
  takeEvery,
  select,
} from 'redux-saga/effects';
import { geojsonToWKT, wktToGeoJSON } from '@terraformer/wkt';
import {
  getBuildingDataByIdRequest,
  getBuildingDataByIdFailure,
  getDropdownOptionsFailure,
  getDropdownOptionsRequest,
  getDropdownOptionsSuccess,
  getRoadDataByIdFailure,
  getRoadDataByIdRequest,
  postFormDataFailure,
  postFormDataRequest,
  postFormDataSuccess,
  resetDataManagementFormState,
  setDataManagementFormState,
  setFormStateData,
  updateFormDataFailure,
  updateFormDataRequest,
  updateFormDataSuccess,
} from '@Store/actions/dataManagementForm';
import {
  FormOptionsRequestDataType,
  FormType,
} from '@Constants/dataManagementForm';
import { FormStateType } from '@Store/slices/dataManagementForm';
import {
  getBuildingDataById,
  getBuildingGeometry,
  getBuildingImages,
  getRoadDataById,
  getRoadGeometry,
  postBuildingData,
  postBuildingImage,
  postRoadData,
  updateBuildingGeometry,
  updatedBuildingData,
  updatedRoadData,
  updateRoadGeometry,
} from '@Services/dataManagement';
import removeObjectKeys from '@Utils/removeObjectKeys';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import prepareFormData from '@Utils/prepareFormData';
import { NavigateFunction } from 'react-router-dom';
import { toggleInfoDialog, togglePromptDialog } from '@Store/actions/common';
import { withLoader } from '@Utils/sagaUtils';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { format } from 'date-fns';

function* getDropdownOptionsSaga({
  payload,
}: PayloadAction<FormOptionsRequestDataType>) {
  try {
    const responses: AxiosResponse[] = yield all(
      payload.map(({ queryFn, params }) => call(queryFn, params)),
    );

    const responsesData = payload.map(({ params }, index) => ({
      key: params.field_name,
      data:
        params.field_name === 'ward_no'
          ? prepareDropdownOptions(responses[index].data, {
              labelKey: 'alias',
              valueKey: 'name',
            }).map((item: any) => ({
              ...item,
              alias: `Ward ${item.alias}`,
            }))
          : responses[index].data,
    }));

    yield put(getDropdownOptionsSuccess(responsesData));
  } catch (error) {
    yield put(getDropdownOptionsFailure());
  }
}

function* postFormDataSaga({
  payload,
}: PayloadAction<{
  formType: FormType;
  data: FormStateType;
  navigate: NavigateFunction;
  pathname?: string;
  redirectPath?: string;
}>) {
  try {
    const { formType, data, navigate, pathname, redirectPath } = payload;
    const payloadData = {
      ...removeObjectKeys(data, ['building_images', 'building_plans']),
      geom: geojsonToWKT(data?.geom?.features[0]?.geometry),
    };
    const response: AxiosResponse = yield call(
      formType === 'road' ? postRoadData : postBuildingData,
      prepareFormData(payloadData),
    );
    const { id: buildingId } = response.data.details;
    if (formType === 'building') {
      const imageRequestPayloads: Record<string, any> = [];
      if (data.building_images.length) {
        data.building_images.forEach((item: Record<string, any>) => {
          const imagePayload = {
            building: buildingId,
            image_type: 'building_image',
          };
          // @ts-ignore
          imagePayload.image = item.file;
          imageRequestPayloads.push(imagePayload);
        });
      }
      if (data.building_plans.length) {
        data.building_plans.forEach((item: Record<string, any>) => {
          const imagePayload = {
            building: buildingId,
            image_type: 'building_plan',
          };
          // @ts-ignore
          imagePayload.image = item.file;
          imageRequestPayloads.push(imagePayload);
        });
      }
      yield all(
        imageRequestPayloads.map((item: Record<string, any>) =>
          call(postBuildingImage, prepareFormData(item), {
            image_type: item.image_type,
            building_id: item.building,
          }),
        ),
      );
    }
    yield put(postFormDataSuccess());
    yield put(togglePromptDialog());
    if (!pathname?.includes('workshop-mode')) {
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate(`/data-management/${formType}-data`);
      }
      yield put(
        toggleInfoDialog(
          formType === 'building' ? 'add-building-success' : 'add-road-success',
        ),
      );
    } else {
      yield put(
        setWorkshopModeState({
          selectedFeature: null,
          attributeType: null,
          refreshLayerId: formType === 'building' ? 'building' : 'road',
        }),
      );
    }
  } catch (error) {
    yield put(postFormDataFailure());
  }
}

function* updateFormDataSaga({
  payload,
}: PayloadAction<{
  formType: FormType;
  formId: string;
  data: FormStateType;
  navigate: NavigateFunction;
  pathname?: string;
  redirectPath?: string;
}>) {
  try {
    const { formType, formId, data, navigate, pathname, redirectPath } =
      payload;
    const { featureId } = yield select(state => state.dataManagementForm);
    const timestamp = format(new Date(), 'yyyy-MM-dd HH:mm:ss');
    const payloadData: any = {
      ...removeObjectKeys(data, ['building_images', 'building_plans', 'geom']),
      timestamp,
    };
    if (data?.geom) {
      yield call(
        formType === 'building' ? updateBuildingGeometry : updateRoadGeometry,
        featureId,
        { geom: geojsonToWKT(data.geom.features[0].geometry), timestamp },
      );
    }
    // prevent calling patch api during geometry edit only
    if (Object.keys(payloadData).length > 1) {
      const response: AxiosResponse = yield call(
        formType === 'building' ? updatedBuildingData : updatedRoadData,
        formId,
        prepareFormData(payloadData),
      );

      yield put(updateFormDataSuccess(response.data));
    }
    yield put(togglePromptDialog());

    if (!pathname?.includes('workshop-mode')) {
      if (redirectPath) {
        navigate(redirectPath);
      } else {
        navigate(`/data-management/${formType}-data`);
      }
      yield put(
        toggleInfoDialog(
          formType === 'building'
            ? 'edit-building-success'
            : 'edit-road-success',
        ),
      );
    } else {
      yield put(resetDataManagementFormState());
      yield put(
        setWorkshopModeState({
          selectedFeature: null,
          attributeType: null,
          refreshLayerId: formType === 'building' ? 'building' : 'road',
        }),
      );
    }
  } catch (error) {
    yield put(updateFormDataFailure());
  }
}

function* getBuildingDataByIdSaga({
  payload: id,
}: PayloadAction<FormOptionsRequestDataType>) {
  try {
    const buildingDataResponse: AxiosResponse = yield call(
      getBuildingDataById,
      id,
    );
    const buildingImagesResponse: AxiosResponse = yield call(
      getBuildingImages,
      { building_id: id },
    );
    const buildingData = buildingDataResponse.data;
    const featureId = buildingData.feature_id;
    const buildingGeometryResponse: AxiosResponse = yield call(
      getBuildingGeometry,
      featureId,
    );
    const wkt = buildingGeometryResponse.data.geom;
    const geojson = wktToGeoJSON(wkt.split(';')[1]);
    yield put(
      setFormStateData({
        ...buildingData,
        ...buildingImagesResponse.data,
        // geom: geojson,
        // geom: null,
        formType: 'building',
      }),
    );
    const associationType = buildingData.association_type;
    const states: any = {};
    if (associationType === 'main') {
      states.selectedRoadId = buildingData.road_id;
    } else {
      states.selectedBuildingId = buildingData.main_building_id;
    }
    const refCentroid = buildingData.ref_centroid
      ? wktToGeoJSON(buildingData.ref_centroid.split(';')[1])
      : null;
    yield put(
      setDataManagementFormState({
        fetchedGeojson: geojson,
        drawnGeom: geojson,
        drawType: 'simple_select',
        featureId,
        refCentroid,
        ...states,
      }),
    );
  } catch (error) {
    yield put(getBuildingDataByIdFailure());
  }
}

function* getRoadDataByIdSaga({
  payload: id,
}: PayloadAction<FormOptionsRequestDataType>) {
  try {
    const roadDataResponse: AxiosResponse = yield call(getRoadDataById, id);
    const featureId = roadDataResponse.data.feature_id;
    const roadGeometryResponse: AxiosResponse = yield call(
      getRoadGeometry,
      featureId,
    );
    const wkt = roadGeometryResponse.data.geom;
    const geojson = wktToGeoJSON(wkt.split(';')[1]);
    yield put(
      setFormStateData({
        ...roadDataResponse.data,
        geom: geojson,
        formType: 'road',
      }),
    );
    yield put(
      setDataManagementFormState({
        fetchedGeojson: geojson,
        drawnGeom: geojson,
        drawType: 'simple_select',
        featureId,
        existingRoadName: roadDataResponse?.data?.road_name_en,
      }),
    );
  } catch (error) {
    yield put(getRoadDataByIdFailure());
  }
}

function* dataManagementFormWatcher() {
  yield takeLatest(
    getDropdownOptionsRequest.type,
    withLoader(getDropdownOptionsSaga),
  );
  yield takeEvery(postFormDataRequest.type, withLoader(postFormDataSaga));
  yield takeLatest(updateFormDataRequest.type, withLoader(updateFormDataSaga));
  yield takeEvery(
    getBuildingDataByIdRequest.type,
    withLoader(getBuildingDataByIdSaga),
  );
  yield takeLatest(
    getRoadDataByIdRequest.type,
    withLoader(getRoadDataByIdSaga),
  );
}

export default dataManagementFormWatcher;
