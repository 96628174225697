import { useEffect, useRef } from 'react';
import Spinner from '@Components/common/Spinner';
import MapLibreGL from 'maplibre-gl';
import { IMapFooter } from '@Components/common/MapLibreComponents/types';
import FullLogo from '@Assets/images/dmaps-logo-full.svg';
import NaxaLogo from '@Assets/images/naxa-logo.svg';
import Image from '@Components/RadixComponents/Image';
import { FlexRow } from '@Components/common/Layouts';

export default function MapFooter({ map, isMapLoaded }: IMapFooter) {
  const layerAttributionRef = useRef<HTMLDivElement | null>(null);
  const mapScaleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map) return;
    const attribution = new MapLibreGL.AttributionControl();
    if (layerAttributionRef.current) {
      layerAttributionRef.current.appendChild(attribution.onAdd(map));
    }

    const scale = new MapLibreGL.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    map.addControl(scale, 'bottom-right');
  }, [map]);

  return (
    <FlexRow
      gap={10}
      className="naxatw-absolute naxatw-bottom-0 naxatw-z-10 naxatw-h-[1.875rem] 
        naxatw-w-full  naxatw-items-center naxatw-justify-between naxatw-bg-white naxatw-px-4 naxatw-py-3"
    >
      <FlexRow gap={10} className="naxatw-items-center">
        <FlexRow gap={4}>
          <p className="naxatw-text-sm naxatw-font-medium">Powered by</p>
          <Image src={FullLogo} width={80} />
        </FlexRow>
        <FlexRow gap={10} className="map-footer naxatw-items-center">
          {!isMapLoaded && (
            <Spinner className="!naxatw-h-4 !naxatw-w-6 naxatw-fill-primary-400" />
          )}
          <span ref={layerAttributionRef} className=" naxatw-text-grey-500" />
        </FlexRow>
      </FlexRow>

      <div ref={mapScaleRef} />
      <FlexRow gap={4} className="naxatw-w-fit naxatw-items-center ">
        <p className="naxatw-text-sm naxatw-font-medium">Developed by</p>
        <Image src={NaxaLogo} width={80} />
      </FlexRow>
    </FlexRow>
  );
}
