import { api, authenticated } from '.';

export const getRoadDataList = (params: any) =>
  authenticated(api).get(`/road-data/`, { params });

export const getRoadDataById = (id: any) =>
  authenticated(api).get(`/road-data/${id}/`);

export const getBuildingDataList = (params: any) =>
  authenticated(api).get(`/building-data/`, { params });

export const getBuildingDataById = (id: any) =>
  authenticated(api).get(`/building-data/${id}/`);

export const getRoadUniqueValues = (params: any) =>
  authenticated(api).get('/road-unique-values/', { params });

export const getBuildingUniqueValues = (params: any) =>
  authenticated(api).get('/building-unique-values/', { params });

export const postRoadData = (data: any) =>
  authenticated(api).post('/road-data/', data);

export const postBuildingData = (data: any) =>
  authenticated(api).post('/building-data/', data);

export const postBuildingImage = (data: any, params: any) =>
  authenticated(api).post('/building-images/', data, { params });

export const getBuildingImages = (params: any) =>
  authenticated(api).get('/building-images/', { params });

export const getBuildingUniqueFields = (params: any) =>
  authenticated(api).get('/building-unique-fields/', { params });

export const getRoadUniqueFields = (params: any) =>
  authenticated(api).get('/road-unique-fields/', { params });

export const updatedBuildingData = (id: any, data: any) =>
  authenticated(api).patch(`/building-data/${id}/`, data);

export const updatedRoadData = (id: any, data: any) =>
  authenticated(api).patch(`/road-data/${id}/`, data);

export const getOverlayLayerDataList = (params: any) =>
  authenticated(api).get(`/vector-layer/`, { params });

export const getBuildingGeometry = (id: any) =>
  authenticated(api).get(`/building-geometry/${id}/`);

export const updateBuildingGeometry = (id: any, data: any) =>
  authenticated(api).patch(`/building-geometry/${id}/`, data);

export const getRoadGeometry = (id: any) =>
  authenticated(api).get(`/road-geometry/${id}/`);

export const updateRoadGeometry = (id: any, data: any) =>
  authenticated(api).patch(`/road-geometry/${id}/`, data);

export const getCheckRoadName = (params: any) =>
  authenticated(api).get(`/check-road-name/`, { params });
