/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { LngLat } from 'maplibre-gl';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import MapFooter from '@Components/common/MapLibreComponents/MapFooter';
import checkIfLoading from '@Utils/checkIfLoading';
import { apiURL } from '@Services/index';
import {
  resetWorkshopModeState,
  setWorkshopModeState,
} from '@Store/actions/workshopMode';
import {
  getBuildingDataByIdRequest,
  getDropdownOptionsRequest,
  getRoadDataByIdRequest,
  postFormDataRequest,
  setDataManagementFormState,
  updateFormDataRequest,
} from '@Store/actions/dataManagementForm';
import { selectedWardSelector } from '@Store/selector/common';
import {
  buildingStyles,
  drawStyles,
  getBuildingColor,
  mainBuildingColor,
  roadStyles,
  userInputColor,
  userLocationColor,
} from '@Constants/map';
import changeLayerOrder from '@Components/common/MapLibreComponents/helpers/changeLayerOrder';
import CircleMarker from '@Components/common/MapLibreComponents/Layers/CircleMarker';
import useDrawTool from '@Components/common/MapLibreComponents/useDrawTool';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import MeasureTool from '@Components/common/MapLibreComponents/MeasureTool';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import RoadVTLayer from '@Components/common/MapLibreComponents/CustomLayers/RoadVTLayer';
import FilterSection from './FilterSection';
import AttributeSection from './AttributeSection';
import MapTools from './MapTools';
import AddAttributeToolbox from './AddAttributeToolbox';
import MapToolbar from './MapToolbar';

export default function WorkshopMode() {
  const dispatch = useTypedDispatch();
  const [isBuildingLayerLoaded, setIsBuildingLayerLoaded] = useState(false);
  const [isRoadLayerLoaded, setIsRoadLayerLoaded] = useState(false);
  const [drawnFeatureGeojson, setDrawnFeatureGeojson] =
    useState<null | GeojsonType>(null);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 1,
      center: [84.124, 28.3949],
      maxZoom: 20,
      transformRequest: url => {
        if (url.includes('localhost:7767')) {
          return {
            url,
            headers: {
              Authorization: `Token ${localStorage.getItem('token')}`,
            },
            credentials: 'include', // Include cookies for cross-origin requests
          };
        }
        // Otherwise, return the request unmodified
        return { url };
      },
    },
    disableRotation: true,
  });

  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);
  const activeBaseLayer = useTypedSelector(
    state => state.workshopMode.activeBaseLayer,
  );
  const layers = useTypedSelector(state => state.workshopMode.layers);
  const searchedFeature = useTypedSelector(
    state => state.workshopMode.searchedFeature,
  );
  const layerOrderChanged = useTypedSelector(
    state => state.workshopMode.layerOrderChanged,
  );
  const changeStyle = useTypedSelector(state => state.workshopMode.changeStyle);
  const geolocation = useTypedSelector(state => state.workshopMode.geolocation);
  const zoomToGeolocation = useTypedSelector(
    state => state.workshopMode.zoomToGeolocation,
  );
  const inputCoordinates = useTypedSelector(
    state => state.workshopMode.inputCoordinates,
  );
  const zoomToLocationType = useTypedSelector(
    state => state.workshopMode.zoomToLocationType,
  );
  const selectedFeature = useTypedSelector(
    state => state.workshopMode.selectedFeature,
  );
  const fetchedGeojson = useTypedSelector(
    state => state.dataManagementForm.fetchedGeojson,
  );
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);
  const hasGeomChanged = useTypedSelector(
    state => state.dataManagementForm.hasGeomChanged,
  );
  const attributeType = useTypedSelector(
    state => state.workshopMode.attributeType,
  );
  const enableRoadSelect = useTypedSelector(
    state => state.dataManagementForm.enableRoadSelect,
  );
  const enableBuildingSelect = useTypedSelector(
    state => state.dataManagementForm.enableBuildingSelect,
  );
  const selectedRoadId = useTypedSelector(
    state => state.dataManagementForm.selectedRoadId,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.dataManagementForm.selectedBuildingId,
  );
  const refreshLayerId = useTypedSelector(
    state => state.workshopMode.refreshLayerId,
  );
  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const measureType = useTypedSelector(state => state.workshopMode.measureType);
  const selectedWards = useTypedSelector(selectedWardSelector);
  const permissions = useTypedSelector(state => state.user.permissions);

  const isMapDataLoading = useTypedSelector(state =>
    checkIfLoading(state, [
      getBuildingDataByIdRequest.type,
      getRoadDataByIdRequest.type,
      updateFormDataRequest.type,
      postFormDataRequest.type,
      getDropdownOptionsRequest.type,
    ]),
  );

  const handleDrawEnd = useCallback(
    (geojson: GeojsonType | null) => {
      setDrawnFeatureGeojson(geojson);
      const states: any = {
        drawnGeom: geojson,
      };
      if (!hasGeomChanged) {
        states.hasGeomChanged = !!geojson;
      }
      dispatch(setDataManagementFormState(states));
    },
    [dispatch, hasGeomChanged],
  );

  const { resetDraw, setDrawMode, reverseLineGeometry } = useDrawTool({
    map,
    enable:
      !measureType &&
      !!drawType &&
      (isBuildingLayerLoaded || isRoadLayerLoaded),
    drawMode: drawType,
    styles: drawStyles,
    geojson: drawType === 'split_line_mode' ? drawnGeom : fetchedGeojson,
    onDrawEnd: handleDrawEnd,
    onLineSplit: geojson => {
      dispatch(
        setDataManagementFormState({
          fetchedGeojson: geojson,
          drawnGeom,
          drawType: 'simple_select',
        }),
      );
    },
  });

  const formType = selectedFeature?.layer === 'building' ? 'building' : 'road';

  // zoom to palika boundary on initial map load
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingBbox) return;
    const { data } = buildingBbox;
    if (!data) return;
    const [x1, y1, x2, y2] = data;
    map.fitBounds(
      [
        [x1, y1],
        [x2, y2],
      ],
      { duration: 0 },
    );
  }, [map, isMapLoaded, buildingBbox]);

  // handle layer order change
  useEffect(() => {
    if (!map || !layerOrderChanged) return;
    const [from, to] = layerOrderChanged;
    if (!from) return;
    changeLayerOrder(map, { id: from.toString(), beforeId: to.toString() });
  }, [map, layerOrderChanged]);

  // change layer styles
  useEffect(() => {
    if (!map || !changeStyle.layerId) return;
    Object.entries(changeStyle.styles).forEach(([key, val]) => {
      map.setPaintProperty(changeStyle.layerId, key, val);
    });
  }, [map, changeStyle]);

  // zoom to geolocation
  useEffect(() => {
    if (!map || !geolocation || !zoomToGeolocation) return;
    map.flyTo({
      center: [geolocation.longitude, geolocation.latitude],
      zoom: 14,
    });
    dispatch(setWorkshopModeState({ zoomToGeolocation: false }));
  }, [dispatch, map, geolocation, zoomToGeolocation]);

  // zoom to user input coordinates
  useEffect(() => {
    if (!map || !inputCoordinates.length) return;
    const [lat, lng] = inputCoordinates;
    map.flyTo({
      center: new LngLat(lng, lat),
      zoom: 14,
    });
  }, [dispatch, map, inputCoordinates]);

  // cache geolocation coordinates
  const geolocationCoords: [number, number] | null = useMemo(
    () => (geolocation ? [geolocation.longitude, geolocation.latitude] : null),
    [geolocation],
  );

  // zoom to selected feature
  useEffect(() => {
    if (!searchedFeature?.bbox || !map) return;
    map.fitBounds(searchedFeature.bbox, { maxZoom: 18 });
  }, [map, searchedFeature]);

  // highlight only searched building on map
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || !searchedFeature) return () => {};
    const buildingColor = getBuildingColor(searchedFeature.association_type);
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'building_pk'],
      searchedFeature.id,
      buildingColor,
      '#BDBDBD',
    ]);
    return () => {
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [map, isBuildingLayerLoaded, searchedFeature]);

  // check if building layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'building') {
        setIsBuildingLayerLoaded(true);
      }
    });
  }, [map]);

  // check if road layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'road') {
        setIsRoadLayerLoaded(true);
      }
    });
  }, [map]);

  // dim building color when adding/editing road/building geometry
  useEffect(() => {
    if (!map || !attributeType) return () => {};
    // gray out building when adding or road/editing geometry
    map.setPaintProperty('building', 'fill-extrusion-color', '#BDBDBD');
    // color back building after adding building
    return () => {
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [map, formType, attributeType]);

  // change building color when editing building geometry
  useEffect(() => {
    if (
      !map ||
      !isBuildingLayerLoaded ||
      formType === 'road' ||
      !selectedFeature?.building_id
    )
      return () => {};
    // hide selected building outline opacity when editing building geometry
    map.setPaintProperty('building-outline', 'line-opacity', [
      'match',
      ['get', 'building_id'],
      selectedFeature.building_id,
      0,
      1,
    ]);
    // color back building after edit finish
    return () => {
      if (!selectedFeature?.building_id) return;
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
      map.setPaintProperty('building-outline', 'line-opacity', 1);
    };
  }, [map, isBuildingLayerLoaded, formType, selectedFeature?.building_id]);

  // highlight only selected access road on map while editing  main building
  useEffect(() => {
    if (!map || !isRoadLayerLoaded || !selectedRoadId) return () => {};
    map.setPaintProperty('road-outline', 'line-color', [
      'match',
      ['get', 'road_id'],
      selectedRoadId,
      '#EC7FA0',
      '#BDBDBD',
    ]);
    // reset road color
    return () => {
      if (!map || !isRoadLayerLoaded) return;
      map.setPaintProperty(
        'road-outline',
        'line-color',
        roadStyles.paint['line-color'],
      );
    };
  }, [map, isRoadLayerLoaded, selectedRoadId]);

  // highlight only selected main building on map when drawing associate and dissociate building
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || !selectedBuildingId) return () => {};
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'building_id'],
      selectedBuildingId,
      '#EC7FA0',
      '#BDBDBD',
    ]);
    // reset building color
    return () => {
      if (!map || !isBuildingLayerLoaded) return;
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [map, isBuildingLayerLoaded, selectedRoadId, selectedBuildingId]);

  // highlight only main building when enableBuildingSelect (drawing associate or dissociate building)
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || !enableBuildingSelect)
      return () => {};
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'association_type'],
      'main',
      mainBuildingColor,
      '#BDBDBD',
    ]);
    // reset building color
    return () => {
      if (!map || !isBuildingLayerLoaded) return;
      map.setPaintProperty('building', 'fill-extrusion-color', '#BDBDBD');
    };
  }, [map, isBuildingLayerLoaded, enableBuildingSelect, selectedFeature]);

  // change road color when editing road geometry
  useEffect(() => {
    if (
      !map ||
      !isRoadLayerLoaded ||
      // !fetchedGeojson ||
      formType === 'building' ||
      !selectedFeature?.road_id
    )
      return () => {};
    map.setPaintProperty('road-outline', 'line-color', '#BDBDBD');
    // gray out selected road color when editing road geometry
    map.setPaintProperty('road', 'line-color', [
      'match',
      ['get', 'road_id'],
      selectedFeature.road_id,
      '#BDBDBD',
      '#000',
    ]);
    // color back road after edit finish
    return () => {
      // if (!fetchedGeojson) return;
      map.setPaintProperty(
        'road-outline',
        'line-color',
        roadStyles.paint['line-color'],
      );
      map.setPaintProperty('road', 'line-color', '#000');
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [
    map,
    // fetchedGeojson,
    isRoadLayerLoaded,
    formType,
    selectedFeature?.road_id,
  ]);

  // reset refreshLayerId after layer refresh
  useEffect(() => {
    if (!refreshLayerId) return;
    dispatch(setWorkshopModeState({ refreshLayerId: null }));
  }, [dispatch, refreshLayerId]);

  const handleFeatureSelect = useCallback(
    (properties: any) => {
      if (enableRoadSelect) {
        dispatch(
          setDataManagementFormState({
            selectedRoadId: properties.road_id,
            enableRoadSelect: false,
            hasSelectedRoadIdChanged: true,
          }),
        );
        return;
      }
      if (
        enableBuildingSelect &&
        properties.association_type === 'main' &&
        selectedFeature?.building_geom_id !== properties.building_geom_id
      ) {
        dispatch(
          setDataManagementFormState({
            selectedBuildingId: properties.building_id,
            enableBuildingSelect: false,
            hasSelectedBuildingIdChanged: true,
          }),
        );
        return;
      }
      if (selectedFeature) return;
      dispatch(
        setWorkshopModeState({
          selectedFeature: properties,
          // @ts-ignore
          attributeType: `edit_${properties.layer}`,
        }),
      );
    },
    [dispatch, selectedFeature, enableBuildingSelect, enableRoadSelect],
  );

  // reset workshopmode state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetWorkshopModeState());
    };
  }, [dispatch]);

  const isLoading = useDebouncedLoadingState(
    !isBuildingLayerLoaded ||
      !isRoadLayerLoaded ||
      isMapDataLoading ||
      !isMapLoaded,
  );

  return (
    <>
      <AttributeSection />

      <div className="naxatw-relative naxatw-w-full naxatw-animate-fade-down">
        {isLoading && <MapLoader />}

        {/* search input / filter section */}
        <FilterSection />

        {/* Add attribute toolbox */}
        <AddAttributeToolbox setDrawMode={setDrawMode} resetDraw={resetDraw} />

        {/* map toolbar */}
        {!isLoading && (
          <MapToolbar
            resetDraw={resetDraw}
            reverseLineGeometry={reverseLineGeometry}
            drawnFeatureGeojson={drawnFeatureGeojson}
          />
        )}

        {/* map section */}
        <MapContainer
          map={map}
          isMapLoaded={isMapLoaded}
          style={{ width: '100%', height: '100vh' }}
        >
          {/* add base layer switcher */}
          <BaseLayerSwitcher activeLayer={activeBaseLayer} />

          {/* reverse layers order for z-index */}
          {[...layers]
            .reverse()
            .map(({ id, endPoint, checked, styles, showLabel, showOutline }) =>
              id === 'road' ? (
                <RoadVTLayer
                  key={id}
                  id={id}
                  url={
                    selectedWards.length
                      ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                          ',',
                        )}`
                      : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                  }
                  layerOptions={styles}
                  visibleOnMap={checked}
                  showLabel={showLabel}
                  refreshLayer={refreshLayerId === 'road'}
                  interactions={
                    measureType
                      ? []
                      : id === 'road' && enableRoadSelect
                        ? ['select']
                        : attributeType?.includes('add')
                          ? []
                          : drawType && !drawnFeatureGeojson
                            ? []
                            : id === 'road' &&
                                permissions.includes('change_road') // allow to edit for permitted users only
                              ? ['select']
                              : []
                  }
                  onFeatureSelect={handleFeatureSelect}
                />
              ) : (
                <VectorTileLayer
                  key={id}
                  id={id}
                  url={
                    selectedWards.length
                      ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?ward_no=${selectedWards.join(
                          ',',
                        )}`
                      : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                  }
                  layerOptions={styles}
                  visibleOnMap={checked}
                  showLabel={showLabel}
                  showOutline={!!showOutline}
                  refreshLayer={refreshLayerId === id}
                  interactions={
                    measureType
                      ? []
                      : id === 'building' && enableBuildingSelect
                        ? ['select']
                        : attributeType?.includes('add')
                          ? []
                          : drawType && !drawnFeatureGeojson
                            ? []
                            : id === 'building' &&
                                permissions.includes('change_building') // allow to edit for permitted users only
                              ? ['select']
                              : []
                  }
                  onFeatureSelect={handleFeatureSelect}
                  showRoadPopup={drawType !== 'split_line_mode'}
                />
              ),
            )}

          {/* plot circle marker on user location enable */}
          {geolocationCoords && (
            <CircleMarker
              id="geolocation"
              coordinates={geolocationCoords}
              zoomToLayer={zoomToLocationType === 'geolocation'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userLocationColor,
                },
              }}
            />
          )}

          {/* plot circle marker on user location enable */}
          {inputCoordinates.length && (
            <CircleMarker
              id="inputCoordinates"
              coordinates={[inputCoordinates[1], inputCoordinates[0]]}
              zoomToLayer={zoomToLocationType === 'user-input'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userInputColor,
                },
              }}
            />
          )}

          {/* map tools */}
          <MapTools />

          {/* measure tool */}
          <MeasureTool
            enable={!!measureType}
            measureType={measureType}
            onDrawChange={result => {
              dispatch(setWorkshopModeState({ measureResult: result }));
            }}
          />

          {/* map footer */}
          <MapFooter />
        </MapContainer>
      </div>
    </>
  );
}
