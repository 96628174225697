import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useTranslation from '@Hooks/useTranslation';
import { useGenerateHouseNumbersQuery } from '@Api/visualization';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { setMapExportState } from '@Store/actions/mapExport';

export default function Toolbox() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { _t } = useTranslation();

  const redirectPath = { state: { redirectPath: pathname } };

  const permissions = useTypedSelector(state => state.user.permissions);

  const displayAddHouse = permissions.includes('add_building');
  const displayAddRoad = permissions.includes('add_road');

  // generate house number
  const { data: generatedDataMessage, refetch } = useGenerateHouseNumbersQuery(
    null,
    { enabled: false },
  );

  interface DataItem {
    name: string;
    icon: string;
    onClick: () => void;
  }

  // Define the type for the entire data array
  type DataArray = DataItem[];

  const data: DataArray = [
    displayAddHouse && {
      name: 'Add House',
      icon: 'house',
      onClick: () => {
        navigate('/data-management/building-data/form', redirectPath);
      },
    },
    displayAddRoad && {
      name: 'Add Road',
      icon: 'add_road',
      onClick: () => {
        navigate('/data-management/road-data/form', redirectPath);
      },
    },
    {
      name: 'Export',
      icon: 'download',
      onClick: () => {
        dispatch(setMapExportState({ isExportOn: true }));
      },
    },
    {
      name: 'Generate House No',
      icon: 'cached',
      onClick: () => {
        refetch();
      },
    },
  ].filter(Boolean) as DataArray;

  return (
    <FlexColumn className="naxatw-px-3">
      <FlexRow className="naxatw-h-11 naxatw-items-center">
        <h5>{_t('Toolbox')}</h5>
      </FlexRow>

      <Grid>
        {data.map(({ name, icon, onClick }) => (
          <FlexColumn
            key={name}
            className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-p-[1.125rem]
              naxatw-text-primary-400 hover:naxatw-bg-primary-50"
            gap={1}
            onClick={onClick}
          >
            <Icon iconClassName="material-icons naxatw-icon-md" name={icon} />
            <p className="naxatw-text-center naxatw-text-sm naxatw-font-bold">
              {_t(name)}
            </p>
          </FlexColumn>
        ))}
      </Grid>
      {generatedDataMessage && (
        <h5 className="naxatw-px-2 naxatw-text-xs">
          <p>{generatedDataMessage.message}</p>
          <p>Status: {generatedDataMessage?.stat}</p>
        </h5>
      )}
    </FlexColumn>
  );
}
