import { useQuery } from '@tanstack/react-query';
import { useTypedSelector } from '@Store/hooks';
import prepareChartData from '@Utils/prepareChartData';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import ChartLegend from '@Components/common/Charts/ChartLegend';
import { getRoadCount } from '@Services/dashboard';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { selectedWardSelector } from '@Store/selector/common';
import useTranslation from '@Hooks/useTranslation';

export default function RoadByCategory() {
  const { _t } = useTranslation();
  const language = useTypedSelector(state => state.common.language);
  const selectedWards = useTypedSelector(selectedWardSelector);

  const { data: roadCategoryData, isLoading } = useQuery({
    queryKey: ['roadByCategory', selectedWards],
    queryFn: () =>
      getRoadCount({
        field_name: 'road_category',
        ward_no: selectedWards.join(','),
      }),
    select: (res: any) => {
      const roadCategoryOrder = ['Major', 'Minor', 'Subsidiary', 'Feeder'];
      const modifiedData = prepareChartData(
        language === 'English' ? res.data.en : res.data.ne,
      );
      if (language === 'Nepali') return modifiedData;
      return modifiedData.sort(
        (a, b) =>
          roadCategoryOrder.indexOf(a.name) - roadCategoryOrder.indexOf(b.name),
      );
    },
    enabled: !!selectedWards,
  });

  if (isLoading) {
    return (
      <Skeleton
        className="naxatw-h-[235px] naxatw-w-full
       naxatw-rounded-lg naxatw-bg-primary-50 naxatw-shadow-sm "
      />
    );
  }

  return (
    <div className="naxatw-animate-fade-up">
      <ChartContainer
        className="!naxatw-gap-0 lg:!naxatw-h-[235px]"
        type="donut"
        chartTitle={_t('Road count by category')}
        hasDownloadBtn
        header={ChartHeader}
        fillWithType
        legend={ChartLegend}
        data={roadCategoryData || []}
      />
    </div>
  );
}
