/* eslint-disable no-nested-ternary */
import { useQuery } from '@tanstack/react-query';
import prepareChartData from '@Utils/prepareChartData';
import prepareQueryParam from '@Utils/prepareQueryParam';
import Skeleton from '@Components/RadixComponents/Skeleton';

import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import ChartLegend from '@Components/common/Charts/ChartLegend';
import StackedChart from '@Components/common/Charts/StackedChart';
import useTranslation from '@Hooks/useTranslation';
import { useTypedSelector } from '@Store/hooks';
import {
  roadCategoryTypeOrderEng,
  roadCategoryTypeOrderNep,
  associationTypeOrderEng,
  associationTypeOrderNep,
} from '@Constants/dashboard';
import { prepareDataWithLimit } from '@Utils/prepareDataWithLimit';

// function to move other to last index
// eslint-disable-next-line no-unused-vars
function moveOtherToLast(data: Record<string, any>[]) {
  const otherIndex = data.findIndex(item => item.name === 'Other');
  if (otherIndex !== -1) {
    const otherItem = data.splice(otherIndex, 1)[0];
    data.push(otherItem);
  }
  return data;
}

interface IChartRendererProps {
  data: Record<string, any>;
  filterParams?: Record<string, any> | null;
}

export default function ChartRenderer({
  data,
  filterParams,
}: IChartRendererProps) {
  const { _t } = useTranslation();
  const language = useTypedSelector(state => state.common.language);
  const { id, title, chartType, queryFn, params } = data;
  const { data: chartData, isLoading } = useQuery({
    queryKey: [id, params, filterParams],
    queryFn: () =>
      queryFn({ ...params, ...prepareQueryParam(filterParams || {}) }),
    select: res => {
      const associationTypeOrder =
        language === 'English'
          ? associationTypeOrderEng
          : associationTypeOrderNep;
      const roadCategoryOrder =
        language === 'English'
          ? roadCategoryTypeOrderEng
          : roadCategoryTypeOrderNep;
      const modifiedData = prepareChartData(
        language === 'English' ? res.data.en : res.data.ne,
      );
      // sort by names for buildings
      if (
        id === 'building-count-by-association-type' ||
        id === 'building-by-association-type'
      ) {
        modifiedData.sort(
          (a, b) =>
            associationTypeOrder.indexOf(a.name) -
            associationTypeOrder.indexOf(b.name),
        );
        return modifiedData;
      }
      // sort by names for roads
      if (id === 'building-count-road-category') {
        modifiedData.sort(
          (a, b) =>
            roadCategoryOrder.indexOf(a.name) -
            roadCategoryOrder.indexOf(b.name),
        );
        return modifiedData;
      }
      if (
        id === 'road-section-pavement-type' ||
        id === 'road-section-with-different-lane' ||
        id === 'road-section-count-by-class'
      ) {
        return prepareDataWithLimit(modifiedData, 0);
      }
      return prepareDataWithLimit(modifiedData, 200);
    },
    enabled: !!filterParams,
  });

  if (isLoading) {
    return (
      <Skeleton
        className="naxatw-h-[256px] naxatw-w-full naxatw-rounded-lg
       naxatw-bg-primary-50 naxatw-shadow-sm"
      />
    );
  }

  function renderChart() {
    switch (chartType) {
      case 'donut':
        return (
          <div className="naxatw-animate-fade-up">
            <ChartContainer
              className="naxatw-h-full !naxatw-gap-0"
              type="donut"
              chartTitle={_t(title)}
              hasDownloadBtn
              header={ChartHeader}
              fillWithType
              legend={ChartLegend}
              data={chartData || []}
            />
          </div>
        );
      case 'horizontalBar':
        return (
          <div
            className={`naxatw-animate-fade-up ${
              id === 'building-count-by-use-category'
                ? 'naxatw-w-full xl:naxatw-col-span-1'
                : ''
            }`}
          >
            <ChartContainer
              className="naxatw-h-full !naxatw-gap-0"
              type="horizontalBar"
              chartTitle={_t(title)}
              header={ChartHeader}
              hasDownloadBtn
              data={chartData || []}
            />
          </div>
        );
      case 'bar':
        return (
          <div
            className={`naxatw-animate-fade-up ${
              id === 'building-count-by-specific-use'
                ? 'naxatw-w-full xl:naxatw-col-span-2'
                : ''
            }`}
          >
            <ChartContainer
              className="naxatw-h-full !naxatw-gap-0"
              type="bar"
              yLabel={_t('Number of Buildings')}
              chartTitle={_t(title)}
              header={ChartHeader}
              hasDownloadBtn
              data={chartData || []}
              fillWithType
            />
          </div>
        );
      case 'stackedChart':
        return (
          <div className="naxatw-animate-fade-up">
            <StackedChart
              chartTitle={_t(title)}
              header={ChartHeader}
              hasDownloadBtn
              data={chartData || []}
              className="naxatw-h-full !naxatw-gap-10 !naxatw-bg-grey-50 !naxatw-shadow-none"
              labelAlignment="vertical"
            />
          </div>
        );
      case 'scatterChart':
        return (
          <div className="naxatw-animate-fade-up">
            <ChartContainer
              type="scatterChart"
              className="naxatw-h-full !naxatw-gap-0"
              chartTitle={_t(title)}
              header={ChartHeader}
              hasDownloadBtn
              data={chartData || []}
              yLabel={_t('Number of house')}
              xLabel={_t('House floors')}
            />
          </div>
        );
      default:
        return <></>;
    }
  }

  return renderChart();
}
