import { useEffect } from 'react';
import { useTypedDispatch } from '@Store/hooks';
import { IPlotProps } from '../../types';

export default function PlotPoint({
  map,
  isMapLoaded,
  enabled = false,
  onClick,
}: IPlotProps) {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (!map || !isMapLoaded) return () => {};

    const handleClick = (e: any) => {
      const coord = e.lngLat;
      if (enabled) {
        const updatedCoord = {
          latitude: coord.lat,
          longitude: coord.lng,
        };
        onClick?.(updatedCoord);
      }
    };
    map.on('click', handleClick);

    return () => {
      map.off('click', handleClick);
    };
  }, [isMapLoaded, enabled, map, dispatch, onClick]);

  useEffect(() => {
    if (!map) return () => {};
    const handleMouseMove = () => {
      if (!map) return;
      if (!enabled) {
        map.getCanvas().style.cursor = '';
      } else {
        map.getCanvas().style.cursor = 'crosshair';
      }
    };
    map.on('mousemove', handleMouseMove);
    return () => {
      map.off('mousemove', handleMouseMove);
      map.getCanvas().style.cursor = '';
    };
  }, [map, enabled]);
  return null;
}
