import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';

export default function getLayerStyleColors(styles: Record<string, any>) {
  if (!('paint' in styles)) return null;
  const keys = Object.keys(styles.paint);
  const colorKey = keys.find(key => key.includes('color'));
  if (!colorKey) return null;
  const colorArr = styles.paint[colorKey];
  if (typeof colorArr === 'string') return null;
  const arrx = colorArr.slice(2, colorArr.length - 1);
  const arr = [];
  for (let i = 0; i < arrx.length / 2; i += 1) {
    const index = i * 2;
    arr.push({
      id: arrx[index],
      color: arrx[index + 1],
      label: capitalizeFirstLetter(arrx[index]),
    });
  }
  return arr;
}
