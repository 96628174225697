import { ChartTypes } from '@Components/common/Charts/types';
import {
  getBuildingCount,
  getBuildingFloorCount,
  getBuildingRoadCount,
} from '@Services/dashboard';
/* eslint-disable import/prefer-default-export */

interface IContentType {
  id: string;
  title: string;
  chartType: ChartTypes;
  queryFn: any;
  params?: any;
}

interface IDashboarChartProps {
  title: string;
  id: string;
  contents: IContentType[];
  layout?: 'flex';
}

export const dashboradChart: Record<string, IDashboarChartProps> = {
  'building-ownership': {
    title: 'Building ownership visualization',
    id: 'building-ownership',
    contents: [
      {
        id: 'building-count-by-association-type',
        title: 'Building count by different association type',
        chartType: 'donut',
        queryFn: getBuildingCount,
        params: { field_name: 'association_type' },
      },
      {
        id: 'building-count-ownership-category',
        title: 'Building count by ownership category',
        chartType: 'stackedChart',
        queryFn: getBuildingCount,
        params: { field_name: 'owner_status' },
      },
      {
        id: 'building-by-roof-type',
        title: 'Building count by Structural life',
        chartType: 'stackedChart',
        queryFn: getBuildingCount,
        params: { field_name: 'temporary_type' },
      },
    ],
  },
  'building-access-case': {
    title: 'Building access case visualization',
    id: 'building-access-case',
    contents: [
      {
        id: 'building-count-road-category',
        title: 'Building count in different road category',
        chartType: 'donut',
        queryFn: getBuildingRoadCount,
        params: { field_name: 'road_category' },
      },
      {
        id: 'building-count-road-pavement-type',
        title: 'Building count in different road pavement type',
        chartType: 'horizontalBar',
        queryFn: getBuildingCount,
        params: { field_name: 'road_type' },
      },
      {
        id: 'building-count-with-different-access',
        title: 'Building count in different road lane size',
        chartType: 'bar',
        queryFn: getBuildingCount,
        params: { field_name: 'road_lane' },
      },
    ],
  },
  'building-structure-information': {
    title: 'Building structure information visualization',
    id: 'building-structure-information',
    contents: [
      {
        id: 'building-count-by-structure',
        title: 'Building count by structure',
        chartType: 'horizontalBar',
        queryFn: getBuildingCount,
        params: { field_name: 'building_structure' },
      },
      {
        id: 'building-count-by-floors-number',
        title: 'Building count by number of floors',
        chartType: 'scatterChart',
        queryFn: getBuildingFloorCount,
      },
      {
        id: 'building-count-by-roof-type',
        title: 'Building count by roof type',
        chartType: 'donut',
        queryFn: getBuildingCount,
        params: { field_name: 'roof_type' },
      },
    ],
  },
  'building-use-case': {
    title: 'Building use case visualization',
    id: 'building-use-case',
    layout: 'flex',
    contents: [
      {
        id: 'building-count-by-use-category',
        title: 'Building count by use category',
        chartType: 'horizontalBar',
        queryFn: getBuildingCount,
        params: { field_name: 'building_use' },
      },
      {
        id: 'building-count-by-specific-use',
        title: 'Building count by specific use cases',
        chartType: 'bar',
        queryFn: getBuildingCount,
        params: {
          field_name: 'building_sp_use',
        },
      },
    ],
  },
};

export const associationTypeOrderEng: string[] = [
  'Main',
  'Associate',
  'Dissociate',
];

export const associationTypeOrderNep: string[] = ['मुख्य', 'सहायक', 'अलग'];

export const roadCategoryTypeOrderEng: string[] = [
  'Major',
  'Minor',
  'Subsidiary',
  'Feeder',
  'Null',
];

export const roadCategoryTypeOrderNep: string[] = [
  'प्रमुख',
  'सहायक',
  'सानातिना',
];
