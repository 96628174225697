import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  OverlayLayersFormKeys,
  defaultCategoryOptions,
  layoutProperties,
} from '@Constants/overlayLayers';
import {
  addZoomLevelOnLayerListWithDefaultStyle,
  getUpdatedZoomLevelOnAddNewLevel,
  getUpdatedZoomLevelOnDeleteRange,
  getUpdatedZoomLevelZoomFieldUpdate,
  removeZoomLevelFromLayerStyle,
  replaceValueByZoomLevel,
  replaceValueByZoomLevelAndSubLayerKey,
  updateZoomLevelByStyleType,
} from '@Components/DataManagement/OverlayLayers/MultiStepForm/MapSection/helper';

export interface ILayer {
  type:
    | 'background'
    | 'fill'
    | 'line'
    | 'symbol'
    | 'raster'
    | 'circle'
    | 'fill-extrusion'
    | 'heatmap'
    | 'hillshade';
  layout: Record<any, any>;
  paint: Record<any, any>;
}

export type FormStateType =
  | Record<OverlayLayersFormKeys, any>
  | Record<string, any>;

export interface IZoomLevel {
  id: number;
  min: number;
}

export type OverlayLayersFormState = {
  layerStyles: ILayer[] | [];
  dropdownOptions: object;
  formId: number | null;
  layerDetails: Record<string, any>;
  layerZoomLevels: Record<'layer' | 'subLayer', IZoomLevel[]>;
  labelZoomLevels: Record<'layer' | 'subLayer', IZoomLevel[]>;
};

export interface OverlayLayersState extends OverlayLayersFormState {
  selectedLayer: Record<string, any>;
  subLayerStyle: ILayer[] | [];
  isActiveSubLayer: boolean;
  subLayerList: string[] | number[];
  selectedSubLayer: string | number;
  subLayerKey: string;
  labelKey: string;
  attributeKeyList: string[];
}

export interface ISetLayerPropertyProps {
  zoom: number;
  type: string;
  key: string;
  value: string | number;
}

export const initialState: OverlayLayersState = {
  dropdownOptions: {
    category: defaultCategoryOptions,
  },
  formId: null,
  layerDetails: {},
  layerStyles: [],
  layerZoomLevels: {
    layer: [],
    subLayer: [],
  },
  labelZoomLevels: {
    layer: [],
    subLayer: [],
  },

  selectedLayer: {},
  labelKey: 'id',
  // sub-layer
  subLayerStyle: [],
  isActiveSubLayer: false,
  selectedSubLayer: '',
  subLayerKey: 'id',
  attributeKeyList: [],
  subLayerList: [],
};

const getVectorLayerSuccess: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, action) => ({
  ...state,
  subLayerKey: action.payload?.sublayer_key || state.subLayerKey,
  subLayerStyle: action.payload?.sublayer_style,
  isActiveSubLayer: action.payload?.is_sublayer_active,
  layerDetails: action.payload,
  layerStyles: action.payload.style,
  layerZoomLevels:
    action.payload.layer_zoom_levels || initialState.layerZoomLevels,
  labelZoomLevels:
    action.payload.label_zoom_levels || initialState.labelZoomLevels,
});

// set given style value on given key, on given zoom level
const setLayerProperty: CaseReducer<
  OverlayLayersState,
  PayloadAction<ISetLayerPropertyProps>
> = (state, { payload }) => {
  const { type, key, zoom, value } = payload;
  const newStyle = state.layerStyles.map((layer: ILayer) => {
    if (layer.type === type) {
      const { paint, layout } = layer;
      if (type === 'symbol' && layoutProperties.includes(key)) {
        return {
          ...layer,
          layout: {
            ...layout,
            [key]: replaceValueByZoomLevel(layout[key], zoom, value),
          },
        };
      }
      return {
        ...layer,
        paint: {
          ...paint,
          [key]: replaceValueByZoomLevel(paint[key], zoom, value),
        },
      };
    }
    return layer;
  });

  return {
    ...state,
    layerStyles: newStyle,
  };
};

const setSubLayerProperty: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, { payload }) => {
  const { type, key, zoom, value, subLayerKey } = payload;

  const newStyle = state.subLayerStyle.map((layer: ILayer) => {
    if (layer.type === type) {
      const { paint, layout } = layer;
      if (type === 'symbol' && layoutProperties.includes(key)) {
        return {
          ...layer,
          layout: {
            ...layout,
            [key]: replaceValueByZoomLevelAndSubLayerKey(
              layout[key],
              zoom,
              value,
              subLayerKey,
            ),
          },
        };
      }
      return {
        ...layer,
        paint: {
          ...paint,
          [key]: replaceValueByZoomLevelAndSubLayerKey(
            paint[key],
            zoom,
            value,
            subLayerKey,
          ),
        },
      };
    }
    return layer;
  });

  return {
    ...state,
    subLayerStyle: newStyle,
  };
};

// append new zoom level with default value on every style key
const addNewZoomLevel: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, { payload }) => {
  const { zoom, isActiveSubLayer, layerType } = payload;
  const getUpdatedLayers = {
    layerStyles: isActiveSubLayer
      ? state.layerStyles
      : addZoomLevelOnLayerListWithDefaultStyle(
          state.layerStyles,
          zoom,
          layerType,
        ),
    subLayerStyle: isActiveSubLayer
      ? addZoomLevelOnLayerListWithDefaultStyle(
          state.subLayerStyle,
          zoom,
          layerType,
        )
      : state.subLayerStyle,
  };

  const updatedZoomLevelList = {
    labelZoomLevels:
      layerType === 'label'
        ? getUpdatedZoomLevelOnAddNewLevel(
            isActiveSubLayer,
            state.labelZoomLevels,
            zoom,
          )
        : state.labelZoomLevels,
    layerZoomLevels:
      layerType === 'layer'
        ? getUpdatedZoomLevelOnAddNewLevel(
            isActiveSubLayer,
            state.layerZoomLevels,
            zoom,
          )
        : state.layerZoomLevels,
  };

  return {
    ...state,
    ...getUpdatedLayers,
    ...updatedZoomLevelList,
  };
};

const removeZoomLevel: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, { payload }) => {
  const { zoom, isActiveSubLayer, layerType } = payload;
  const updatedLayers = {
    layerStyles: isActiveSubLayer
      ? state.layerStyles
      : removeZoomLevelFromLayerStyle(state.layerStyles, zoom, layerType),
    subLayerStyle: isActiveSubLayer
      ? removeZoomLevelFromLayerStyle(state.subLayerStyle, zoom, layerType)
      : state.subLayerStyle,
  };

  const updatedZoomLevels = {
    labelZoomLevels:
      layerType === 'label'
        ? getUpdatedZoomLevelOnDeleteRange(
            isActiveSubLayer,
            state.labelZoomLevels,
            zoom,
          )
        : state.labelZoomLevels,
    layerZoomLevels:
      layerType === 'layer'
        ? getUpdatedZoomLevelOnDeleteRange(
            isActiveSubLayer,
            state.layerZoomLevels,
            zoom,
          )
        : state.layerZoomLevels,
  };

  return {
    ...state,
    ...updatedLayers,
    ...updatedZoomLevels,
  };
};

// update zoom level only
const updateZoomLevel: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, { payload }) => {
  const { currentZoomLevel, newZoomLevel, styleType, isActiveSubLayer } =
    payload;

  const updatedStyles = {
    layerStyles: isActiveSubLayer
      ? state.layerStyles
      : updateZoomLevelByStyleType(
          state.layerStyles,
          styleType,
          newZoomLevel,
          currentZoomLevel,
        ),
    subLayerStyle: isActiveSubLayer
      ? updateZoomLevelByStyleType(
          state.subLayerStyle,
          styleType,
          newZoomLevel,
          currentZoomLevel,
        )
      : state.subLayerStyle,
  };

  const updatedZoomLevels = {
    labelZoomLevels:
      styleType === 'label'
        ? getUpdatedZoomLevelZoomFieldUpdate(
            isActiveSubLayer,
            state.labelZoomLevels,
            currentZoomLevel,
            newZoomLevel,
          )
        : state.labelZoomLevels,
    layerZoomLevels:
      styleType === 'layer'
        ? getUpdatedZoomLevelZoomFieldUpdate(
            isActiveSubLayer,
            state.layerZoomLevels,
            currentZoomLevel,
            newZoomLevel,
          )
        : state.layerZoomLevels,
  };

  return {
    ...state,
    ...updatedStyles,
    ...updatedZoomLevels,
  };
};

const updateLabelKeyOnLayer: CaseReducer<
  OverlayLayersState,
  PayloadAction<string>
> = (state, payload) => {
  const newLayerStyle = state.layerStyles.map((layer: ILayer) => {
    if (layer.type === 'symbol') {
      const { layout } = layer;
      return {
        ...layer,
        layout: { ...layout, 'text-field': ['get', payload.payload] },
      };
    }
    return layer;
  });

  const newSubLayerStyle = state.subLayerStyle?.map((layer: ILayer) => {
    if (layer.type === 'symbol') {
      const { layout } = layer;
      return {
        ...layer,
        layout: { ...layout, 'text-field': ['get', payload.payload] },
      };
    }
    return layer;
  });

  return {
    ...state,
    layerStyles: newLayerStyle,
    subLayerStyle: newSubLayerStyle,
  };
};

const getLayerAttributeSuccess: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, action) => ({
  ...state,
  attributeKeyList: Object.keys(action.payload || []),
});

const setLayerState: CaseReducer<
  OverlayLayersState,
  PayloadAction<Record<string, any>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const overlayLayersSlice = createSlice({
  name: 'overlayLayersForm',
  initialState,
  reducers: {
    getVectorLayerRequest() {},
    getVectorLayerSuccess,
    getVectorLayerFailure() {},
    setLayerProperty,
    addNewZoomLevel,
    updateZoomLevel,
    postVectorLayerRequest() {},
    postVectorLayerSuccess() {},
    postVectorLayerFailure() {},
    setLayerState,
    setSubLayerProperty,
    updateLabelKeyOnLayer,
    removeZoomLevel,
    getLayerAttributeRequest() {},
    getLayerAttributeSuccess,
    getLayerAttributeFailure() {},
  },
});

export { overlayLayersSlice };

export default overlayLayersSlice.reducer;
