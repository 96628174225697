import { api, authenticated } from '.';

export const getUserList = (params: any) =>
  authenticated(api).get(`/user/user/`, { params });

export const getUserRole = () =>
  authenticated(api).get(`/user/user-role-unique-fields`);

export const postUser = (data: any) =>
  authenticated(api).post(`/user/user/`, data);

export const updateUser = (id: any, data: any) =>
  authenticated(api).patch(`/user/user/${id}/`, data);
