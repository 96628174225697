import { commonSlice } from '../slices/common';

export const {
  setCommonState,
  toggleModal,
  setModalContent,
  togglePromptDialog,
  setPromptDialogContent,
  toggleInfoDialog,
  setInfoDialogContent,
  setSelectedWards,
  resetCommonState,
} = commonSlice.actions;
