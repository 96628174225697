import { useMemo } from 'react';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import { useTypedSelector } from '@Store/hooks';
import { Label } from '@radix-ui/react-label';
import RangeSlider from '@Components/RadixComponents/RangeSlider';
import { Input } from '@Components/common/FormUI';
import {
  lineGeomTypeResponses,
  polygonGeomTypeResponses,
} from '@Constants/overlayLayers';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import {
  getAllStyles,
  getZoomBasedSubLayerValues,
  getZoomBasedValues,
} from '../../helper';
import { styleChangeHandlerType } from '..';

interface ILayerStyleController {
  minZoomValue: number;
  styleChangeHandler: styleChangeHandlerType;
}

function LayerStyleController({
  minZoomValue,
  styleChangeHandler,
}: ILayerStyleController) {
  const layerStyles = useTypedSelector(state => state.overlayLayer.layerStyles);
  const layerDetails = useTypedSelector(
    state => state.overlayLayer.layerDetails,
  );
  const subLayerStyles = useTypedSelector(
    state => state.overlayLayer.subLayerStyle,
  );
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const selectedSubLayer = useTypedSelector(
    state => state.overlayLayer.selectedSubLayer,
  );

  const layerAllStyles = useMemo(
    () => getZoomBasedValues(minZoomValue, getAllStyles(layerStyles)),
    [layerStyles, minZoomValue],
  );

  const subLayerAllStyles = useMemo(
    () =>
      selectedSubLayer &&
      getZoomBasedSubLayerValues(
        minZoomValue,
        selectedSubLayer,
        getAllStyles(subLayerStyles),
      ),
    [subLayerStyles, selectedSubLayer, minZoomValue],
  );

  const allStyles: Record<string, any> = useMemo(
    () =>
      isActiveSubLayer && selectedSubLayer
        ? subLayerAllStyles || {}
        : layerAllStyles || {},
    [isActiveSubLayer, selectedSubLayer, subLayerAllStyles, layerAllStyles],
  );

  return (
    <>
      {[...polygonGeomTypeResponses, ...lineGeomTypeResponses].includes(
        layerDetails.geometry_type,
      ) && (
        <>
          <Grid gap={3} className="naxatw-py-4">
            {polygonGeomTypeResponses.includes(layerDetails.geometry_type) && (
              <FlexRow className="naxatw-items-center naxatw-gap-3">
                <p className="naxatw-text-sm">Fill Color</p>
                <input
                  type="color"
                  className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                  value={allStyles['fill-color']}
                  onChange={e =>
                    styleChangeHandler(
                      minZoomValue,
                      'fill',
                      'fill-color',
                      e.target.value,
                    )
                  }
                />
              </FlexRow>
            )}

            <FlexRow className="naxatw-items-center naxatw-gap-3">
              <Label className="naxatw-text-sm">Line Color</Label>
              <input
                type="color"
                className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                value={allStyles['line-color']}
                onChange={e =>
                  styleChangeHandler(
                    minZoomValue,
                    'line',
                    'line-color',
                    e.target.value,
                  )
                }
              />
            </FlexRow>
          </Grid>
          <FlexColumn className="naxatw-gap-4 naxatw-py-4">
            <Label className="naxatw-text-sm">Line Opacity</Label>
            <RangeSlider
              defaultValue={[80]}
              max={100}
              step={1}
              value={[allStyles['line-opacity'] * 100]}
              onValueChange={value =>
                styleChangeHandler(
                  minZoomValue,
                  'line',
                  'line-opacity',
                  value[0] / 100,
                )
              }
            />
          </FlexColumn>

          {polygonGeomTypeResponses.includes(layerDetails.geometry_type) && (
            <FlexColumn className="naxatw-gap-4 naxatw-py-4">
              <Label className="naxatw-text-sm">Fill Opacity</Label>
              <RangeSlider
                defaultValue={[20]}
                max={100}
                step={1}
                value={[allStyles['fill-opacity'] * 100]}
                onValueChange={value =>
                  styleChangeHandler(
                    minZoomValue,
                    'fill',
                    'fill-opacity',
                    value[0] / 100,
                  )
                }
              />
            </FlexColumn>
          )}
          <FlexColumn className="naxatw-gap-4 naxatw-py-4">
            <Label className="naxatw-text-sm">Line Thickness</Label>
            <RangeSlider
              max={100}
              step={1}
              value={[allStyles['line-width'] * 10]}
              onValueChange={value =>
                styleChangeHandler(
                  minZoomValue,
                  'line',
                  'line-width',
                  value[0] / 10,
                )
              }
            />
          </FlexColumn>
        </>
      )}

      {lineGeomTypeResponses.includes(layerDetails.geometry_type) && (
        <FlexColumn className="naxatw-gap-4 naxatw-py-4">
          <Label className="naxatw-text-sm">Line Gap Width</Label>
          <RangeSlider
            max={100}
            step={1}
            value={[allStyles['line-gap-width'] * 10]}
            onValueChange={value =>
              styleChangeHandler(
                minZoomValue,
                'line',
                'line-gap-width',
                value[0] / 10,
              )
            }
          />
        </FlexColumn>
      )}

      {layerDetails.geometry_type === 'Point' && (
        <>
          <FlexColumn className="naxatw-py-4">
            <Label className="naxatw-text-sm">Radius</Label>
            <Input
              type="number"
              min={0}
              placeholder="circle radius"
              value={allStyles['circle-radius']}
              onChange={e =>
                styleChangeHandler(
                  minZoomValue,
                  'circle',
                  'circle-radius',
                  Number(e.target.value),
                )
              }
            />
          </FlexColumn>
          <Grid gap={3}>
            <FlexRow className="naxatw-items-center naxatw-gap-3">
              <p className="naxatw-text-sm">Circle Color</p>
              <input
                type="color"
                className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                value={allStyles['circle-color']}
                onChange={e =>
                  styleChangeHandler(
                    minZoomValue,
                    'circle',
                    'circle-color',
                    e.target.value,
                  )
                }
              />
            </FlexRow>
            <FlexRow className="naxatw-items-center naxatw-gap-3 naxatw-py-4">
              <p className="naxatw-text-sm">Circle Stroke Color</p>
              <input
                type="color"
                className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
                value={allStyles['circle-stroke-color']}
                onChange={e =>
                  styleChangeHandler(
                    minZoomValue,
                    'circle',
                    'circle-stroke-color',
                    e.target.value,
                  )
                }
              />
            </FlexRow>
          </Grid>
          <FlexColumn className="naxatw-gap-4 naxatw-py-4">
            <Label className="naxatw-text-sm">Circle Opacity</Label>
            <RangeSlider
              defaultValue={[80]}
              max={100}
              step={1}
              value={[allStyles['circle-opacity'] * 100]}
              onValueChange={value =>
                styleChangeHandler(
                  minZoomValue,
                  'circle',
                  'circle-opacity',
                  value[0] / 100,
                )
              }
            />
          </FlexColumn>

          <FlexColumn className="naxatw-gap-4 naxatw-py-4">
            <Label className="naxatw-text-sm">Circle Stroke width</Label>
            <RangeSlider
              defaultValue={[80]}
              max={100}
              step={1}
              value={[allStyles['circle-stroke-width'] * 10]}
              onValueChange={value =>
                styleChangeHandler(
                  minZoomValue,
                  'circle',
                  'circle-stroke-width',
                  value[0] / 10,
                )
              }
            />
          </FlexColumn>

          <FlexColumn className="naxatw-gap-4 naxatw-py-4">
            <Label className="naxatw-text-sm">Circle Stroke Opacity</Label>
            <RangeSlider
              defaultValue={[80]}
              max={100}
              step={1}
              value={[allStyles['circle-stroke-opacity'] * 100]}
              onValueChange={value =>
                styleChangeHandler(
                  minZoomValue,
                  'circle',
                  'circle-stroke-opacity',
                  value[0] / 100,
                )
              }
            />
          </FlexColumn>
        </>
      )}
    </>
  );
}

export default hasErrorBoundary(LayerStyleController);
