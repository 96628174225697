import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import { apiURL } from '@Services/index';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import { FlexColumn } from '@Components/common/Layouts';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@Components/common/MapLibreComponents/map.css';
import { buildingStyles, getRoadColor } from '@Constants/map';
import RoadDirectionLayer from '@Components/common/MapLibreComponents/CustomLayers/RoadDirectionLayer';

interface IMapSectionProps {
  data: Record<string, any>;
  geojson: any;
}

export default function MapSection({ data, geojson }: IMapSectionProps) {
  const { pathname } = useLocation();
  const { id } = useParams();
  const [isBuildingLayerLoaded, setIsBuildingLayerLoaded] = useState(false);

  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 6,
      center: [84.124, 28.3949],
    },
  });

  // zoom to layer  on initial map load
  useEffect(() => {
    if (!map || !data.bbox || !isMapLoaded) return;
    const [x1, y1, x2, y2] = data.bbox;
    map.fitBounds(
      [
        [x1, y1],
        [x2, y2],
      ],
      { duration: 0, padding: 100 },
    );
  }, [map, data.bbox, isMapLoaded]);

  const detailType = pathname.includes('building-detail') ? 'building' : 'road';
  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );

  // set isBuildingLayerLoaded state
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'building-detail-page') {
        // setIsBuildingLayerLoaded to true if ev has tile property
        setIsBuildingLayerLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // handle 3D toggle & pitch change
  useEffect(() => {
    if (!map || !isMapLoaded || !isBuildingLayerLoaded) return;
    map.setPaintProperty(
      'building-detail-page',
      'fill-extrusion-height',
      is3DToggled ? buildingStyles.paint['fill-extrusion-height'] : 0,
    );
    // set building opacity to 1
    map.setPaintProperty('building-detail-page', 'fill-extrusion-opacity', 1);
    setTimeout(() => {
      map.easeTo({
        duration: 1000,
        pitch: is3DToggled ? 60 : 0,
      });
    }, 10);
  }, [map, isMapLoaded, is3DToggled, isBuildingLayerLoaded]);

  return (
    <FlexColumn gap={2} className=" md:naxatw-w-[33%]">
      <h4>Location</h4>
      <div>
        <MapContainer
          map={map}
          isMapLoaded={isMapLoaded}
          style={{
            width: '100%',
            height: '32vh',
          }}
        >
          <BaseLayerSwitcher activeLayer={activeBaseLayer} />

          {/* road direction layer */}
          {detailType === 'road' && geojson && (
            <RoadDirectionLayer
              id="road-detail-page"
              layerOptions={{
                paint: {
                  'line-color': getRoadColor(data?.road_category),
                  'line-width': 6,
                },
              }}
              geojson={geojson}
            />
          )}

          {/* building vector layer */}
          {detailType === 'building' && (
            <VectorTileLayer
              id="building-detail-page"
              url={`${apiURL}/building-vector-tile/{z}/{x}/{y}/?building_id=${id}`}
              layerOptions={buildingStyles}
            />
          )}
        </MapContainer>
      </div>
    </FlexColumn>
  );
}
