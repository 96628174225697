import { ReactElement } from 'react';
import AddUser from '@Components/UserManagement/ModalContent/AddUser';
import DeleteUser from '@Components/UserManagement/ModalContent/DeleteUser';
import DeleteData from '@Components/DataManagement/DeleteData';
import LogoutPrompt from '@Components/Authentication/LogoutPrompt';
import ConfirmationPrompt from '@Components/DataManagement/MultiStepForm/ConfirmationPrompt';
import AddPhysicalInstallation from '@Components/Settings/ModalContent/AddPhysicalInstallation';
import RestoreEditHistoryPrompt from '@Components/Visualization/EditHistory/RestoreEditHistoryPrompt';
import DeleteLayer from '@Components/DataManagement/OverlayLayers/ModalContent/DelateLayer';

export type ModalContentsType =
  | 'add-user'
  | 'edit-user'
  | 'add-physical-installation'
  | 'edit-physical-installation'
  | null;
export type PromptDialogContentsType =
  | 'user-delete-layer'
  | 'delete-building-data'
  | 'delete-road-data'
  | 'continue-edit'
  | 'confirm-logout'
  | 'restore-edit-history'
  | 'delete-layer'
  | null;

type ModalReturnType = {
  title: string;
  subtitle?: string;
  iconName?: string;
  content: ReactElement;
  className?: string;
  hideCloseButton?: boolean;
};

export function getModalContent(content: ModalContentsType): ModalReturnType {
  switch (content) {
    case 'add-user':
      return {
        title: 'Add User',
        subtitle: 'Please fill up the details',
        content: <AddUser />,
      };
    case 'edit-user':
      return {
        title: 'Edit User',
        subtitle: 'Please fill up the details',
        content: <AddUser />,
      };
    case 'add-physical-installation':
      return {
        title: 'Add Installation',
        subtitle: 'Please fill up the details to add installation',
        content: <AddPhysicalInstallation />,
      };
    case 'edit-physical-installation':
      return {
        title: 'Edit Installation',
        subtitle: 'Please fill up the details to edit installation',
        content: <AddPhysicalInstallation />,
      };
    default:
      return {
        title: '',
        content: <></>,
      };
  }
}

export function getPromptDialogContent(
  content: PromptDialogContentsType,
): ModalReturnType {
  switch (content) {
    case 'user-delete-layer':
      return {
        title: 'Delete',
        content: <DeleteUser />,
      };
    case 'delete-building-data':
      return {
        title: 'Delete',
        content: <DeleteData />,
      };
    case 'delete-road-data':
      return {
        title: 'Delete',
        content: <DeleteData />,
      };
    case 'delete-layer':
      return { title: 'Delete', content: <DeleteLayer /> };
    case 'continue-edit':
      return {
        title: 'Empty Fields',
        content: <ConfirmationPrompt />,
      };
    case 'confirm-logout':
      return {
        iconName: 'error_outline',
        title: 'Confirm Logout',
        content: <LogoutPrompt />,
      };
    case 'restore-edit-history':
      return {
        title: 'Restore',
        content: <RestoreEditHistoryPrompt />,
      };
    default:
      return {
        title: '',
        content: <></>,
      };
  }
}
