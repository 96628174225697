import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface LayerAttributeState {
  selectedLayer: string;
  showTableSection: boolean;
  searchInput: string;
  layerList: string[];
}

const initialState: LayerAttributeState = {
  selectedLayer: '',
  showTableSection: false,
  searchInput: '',
  layerList: [],
};

const setLayerAttributeState: CaseReducer<
  LayerAttributeState,
  PayloadAction<Partial<LayerAttributeState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const layerAttributeSlice = createSlice({
  name: 'physicalInstallation',
  initialState,
  reducers: {
    setLayerAttributeState,
  },
});

export { layerAttributeSlice };

export default layerAttributeSlice.reducer;
