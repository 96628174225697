/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { FlexRow } from '@Components/common/Layouts';
import Switch from '@Components/RadixComponents/Switch';
import ToolTip from '@Components/RadixComponents/ToolTip';
import DataTable from '@Components/common/DataTable';
import { getLayerAttributeData } from '@Services/layerAttribute';
import { useTypedSelector } from '@Store/hooks';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import useWindowDimensions from '@Hooks/useWindowDimensions';

function TableSection() {
  const { width } = useWindowDimensions();
  const selectedLayer = useTypedSelector(
    state => state.layerAttribute.selectedLayer,
  );
  const searchInput = useTypedSelector(
    state => state.layerAttribute.searchInput,
  );
  const buildingDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Label',
      accessorKey: 'label',
    },
    {
      header: 'Alias',
      accessorKey: 'alias',
    },
    {
      header: 'Type',
      accessorKey: 'type',
    },
    {
      header: 'Public Fields',
      accessorKey: 'is_public',
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_public} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Editable',
      accessorKey: 'is_editable',
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_editable} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: 'Mandatory',
      accessorKey: 'is_mandatory',
      cell: ({ row }: Record<string, any>) => (
        <FlexRow gap={2}>
          <Switch checked={row.original.is_mandatory} onClick={() => {}} />
        </FlexRow>
      ),
    },
    {
      header: '',
      accessorKey: 'icon',
      // eslint-disable-next-line react/no-unstable-nested-components
      cell: () => (
        <FlexRow className="naxatw-cursor-pointer naxatw-justify-around">
          <ToolTip name="info" message="More info" />
          <ToolTip
            name="delete"
            message="Delete"
            iconClassName="naxatw-text-red-400 hover:naxatw-text-red-500"
          />
        </FlexRow>
      ),
    },
  ];

  return (
    <DataTable
      columns={buildingDataColumns}
      queryKey="attribute-data-list"
      queryFn={getLayerAttributeData}
      queryFnParams={{ layer: selectedLayer }}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      wrapperStyle={{
        height: width < 390 ? '60vh' : 'calc(100vh - 11rem)',
      }}
      searchInput={searchInput}
      useQueryOptions={{ enabled: !!selectedLayer }}
    />
  );
}

export default hasErrorBoundary(TableSection);
