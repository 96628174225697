/* eslint-disable no-underscore-dangle */
import engNep from '@Constants/eng-nep.json';
import { useSelector } from 'react-redux';

const engNepJson: Record<string, string> = engNep;

const getLanguageJSON = (lan: any): Record<string, string> => {
  switch (lan) {
    case 'Nepali':
      return engNepJson;
    default:
      return {};
  }
};

function useTranslation() {
  const language = useSelector((state: any) => state.common.language);

  const _t = (key: string) => {
    return getLanguageJSON(language)[key.toLowerCase()] || key;
  };
  return { _t };
}

export default useTranslation;
