import Dashboard from '@Views/Dashboard';
import TestMap from '@Views/TestMap';
import sidebarData, { ISidebar } from '@Constants/sidebarData';
import PublicPage from '@Views/PublicPage';
import Authentication from '@Views/Authentication';
import MultiStepForm from '@Components/DataManagement/MultiStepForm';
import OverlayLayersMultiStepForm from '@Components/DataManagement/OverlayLayers/MultiStepForm';
import DetailPage from '@Components/Visualization/DetailPage';
import EditHistory from '@Components/Visualization/EditHistory';
import PageNotFound from '@Components/common/PageNotFound';
import { IRoute } from './types';

// Lazy loading
//*  Please consider to rethink before implementing lazy loading
// const HomePage = React.lazy(() => import('@Pages/HomePage'));
// const DashboardPage = React.lazy(() => import('@Pages/DashboardPage'));

function getRoutes(data: ISidebar[]): ISidebar[] {
  return data.map(({ name, path, component, children }) => ({
    path,
    name,
    component,
    authenticated: true,
    children: children ? getRoutes(children) : undefined,
  }));
}

const appRoutes: IRoute[] = [
  {
    path: '/public-page',
    name: 'Public Page',
    component: PublicPage,
    authenticated: false,
  },
  {
    path: '/data-management/road-data/form/:formId',
    name: 'Road Data Form',
    component: MultiStepForm,
    authenticated: true,
  },
  {
    path: '/data-management/building-data/form/:formId',
    name: 'Building Data Form',
    component: MultiStepForm,
    authenticated: true,
  },
  {
    path: '/data-management/road-data/form',
    name: 'Road Data Form',
    component: MultiStepForm,
    authenticated: true,
  },
  {
    path: '/data-management/building-data/form',
    name: 'Building Data Form',
    component: MultiStepForm,
    authenticated: true,
  },

  {
    path: '/data-management/overlay-layer/form',
    name: 'Overlay Data Form',
    component: OverlayLayersMultiStepForm,
    authenticated: true,
  },
  {
    path: '/data-management/overlay-layer/:formId',
    name: 'Overlay Data Form',
    component: OverlayLayersMultiStepForm,
    authenticated: true,
  },

  {
    path: '/visualization/building-detail/:id',
    name: 'Building Detail',
    component: DetailPage,
    authenticated: true,
  },
  {
    path: '/visualization/road-detail/:id',
    name: 'Road Detail',
    component: DetailPage,
    authenticated: true,
  },
  {
    path: '/visualization/building-edit-history/:id',
    name: 'Edit History',
    component: EditHistory,
    authenticated: true,
  },
  {
    path: '/visualization/road-edit-history/:id',
    name: 'Edit History',
    component: EditHistory,
    authenticated: true,
  },
  {
    path: '/test-map',
    name: 'TestMap ',
    component: TestMap,
    authenticated: false,
  },
  ...getRoutes(sidebarData),
  {
    path: '/login',
    name: 'login',
    component: Authentication,
    authenticated: false,
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: Authentication,
    authenticated: false,
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    authenticated: true,
  },
  {
    path: '*',
    name: 'PageNotFound',
    component: PageNotFound,
    authenticated: false,
  },
];

export default appRoutes;
