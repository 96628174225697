import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { getOverlayLayers } from '@Services/visualization';
import { setOverlayLayersState } from '@Store/actions/visualization';
import MapSection from '@Components/Visualization/MapSection';
import { defaultLayersData } from '@Constants/map';

export default function Visualization() {
  const dispatch = useTypedDispatch();
  const layers = useTypedSelector(state => state.visualization.layers);

  // fetch overlay layer list
  useQuery({
    queryKey: ['overlay-layer'],
    queryFn: getOverlayLayers,
    onSuccess: res => {
      dispatch(setOverlayLayersState(res.data.results));
    },
    // check if layers are already fetched
    enabled: layers.length <= defaultLayersData.length,
  });

  return <MapSection />;
}
