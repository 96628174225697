// import ScatterChartComponent from '../ScatterChart';
import chroma from 'chroma-js';
import {
  BarChartFills,
  ChartFills,
  HorizontalBarChartFills,
  donutChartFills,
} from '../constants';

interface IGetFillChartTypeProps<T> {
  type: string;
  data: T[];
}

/**
 * Generates a color mapping based on the provided data values.
 *
 * @param {any} data - array of data objects with a 'value' property used for sorting.
 * @returns {Object} - object where keys are data values, and values are corresponding colors.
 */

function getColorValues(data: any) {
  return [...data]
    .sort((a, b) => b.value - a.value)
    .reduce(
      (obj, item, index) => ({
        ...obj,
        [item.value]: donutChartFills[index] || chroma.random().hex(),
      }),
      {},
    );
}

export default function getChartFillOfType<T>({
  type,
  data,
}: IGetFillChartTypeProps<T>) {
  const colorValues = getColorValues(data);
  const modifiedData = data.map((item: any) => ({
    ...item,
    color: colorValues[item.value],
  }));
  const donutFills = modifiedData.map(
    (colorValue: Record<string, any>) => colorValue.color,
  );
  switch (type) {
    case 'bar':
      return BarChartFills;
    case 'donut':
      return donutFills;
    case 'horizontalBar':
      return HorizontalBarChartFills;
    default:
      return ChartFills;
  }
}
