import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import SearchInput from '@Components/common/SearchInput';
import { getBuildingDataList } from '@Services/dataManagement';
import { setWorkshopModeState } from '@Store/actions/workshopMode';

export default function FilterSection() {
  const [searchInput, setSearchInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);

  const dispatch = useTypedDispatch();

  // fetch building data list on search
  const { data: searchData } = useQuery({
    queryKey: ['map-search', searchInput],
    queryFn: () => getBuildingDataList({ search: searchInput }),
    select: res => res.data,
    enabled: !!searchInput,
  });

  const searchedFeature = useTypedSelector(
    state => state.workshopMode.searchedFeature,
  );

  return (
    <div
      className="naxatw-absolute naxatw-left-3 naxatw-top-4 naxatw-z-30 naxatw-animate-fade-up
     naxatw-shadow-dark naxatw-shadow-grey-400"
    >
      <SearchInput
        isOpen={isOpen}
        data={searchData || []}
        inputValue={searchInput}
        onChange={e => {
          setSearchInput(e.target.value);
          setIsOpen(true);
        }}
        onSelect={selectedItem => {
          dispatch(
            setWorkshopModeState({
              searchedFeature: { ...selectedItem, layer: 'building' },
            }),
          );
          setSearchInput('');
          setIsOpen(false);
        }}
        selectedValue={searchedFeature}
        onClear={() => {
          setSearchInput('');
          dispatch(
            setWorkshopModeState({
              searchedFeature: null,
            }),
          );
        }}
      />
    </div>
  );
}
