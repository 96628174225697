/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
import { format } from 'date-fns';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useOutsideClick from '@Hooks/useOutsideClick';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import useTranslation from '@Hooks/useTranslation';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import {
  getBuildingAdvanceFiltering,
  getRoadAdvanceFiltering,
} from '@Services/visualization';
import { apiURL } from '@Services/index';
import DataTable from '@Components/common/DataTable';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import SearchInput from '@Components/common/FormUI/SearchInput';
import ToolTip from '@Components/RadixComponents/ToolTip';
import FilterImage from '@Assets/images/filter-remove-icon 1.svg';
import Image from '@Components/RadixComponents/Image';
import { Button } from '@Components/RadixComponents/Button';

export default function FilterResult() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();

  const [toggleRef, download, toggleDownload] = useOutsideClick();
  const { width } = useWindowDimensions();

  const queryExpression = useTypedSelector(
    state => state.advanceFilter.queryExpression,
  );
  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );
  const searchInput = useTypedSelector(
    state => state.advanceFilter.searchInput,
  );
  const hideQueryFilter = useTypedSelector(
    state => state.advanceFilter.hideQueryFilter,
  );

  const buildingDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'House No.',
      accessorKey: 'house_no',
    },
    {
      header: 'Associate Road Name',
      accessorKey: 'associate_road_name',
    },
    {
      header: 'Owner Name',
      accessorKey: 'owner_name',
    },
    {
      header: 'Ward No.',
      accessorKey: 'ward_no',
      cell: ({ row }: any) => <p>{`ward ${row?.original?.ward_no}`}</p>,
    },
    {
      header: 'Tole Name',
      accessorKey: 'tole_name',
    },
    {
      header: 'Plus Code',
      accessorKey: 'plus_code',
    },
  ];

  const roadDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Road Name',
      accessorKey: 'road_name_en',
    },
    {
      header: 'Road ID',
      accessorKey: 'road_id',
    },
    {
      header: 'Road Type',
      accessorKey: 'road_type',
    },
    {
      header: 'Ward No.',
      accessorKey: 'ward_no',
      cell: ({ row }: any) => {
        const ward = row?.original?.ward_no;
        const sortedWard = ward.sort((a: number, b: number) => a - b);
        const wardNumber = Array.isArray(ward)
          ? `ward ${sortedWard?.join('. ')}`
          : ward;
        return wardNumber;
      },
    },
    {
      header: 'Last Modified',
      accessorKey: 'updated_date',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
  ];

  return (
    <FlexColumn
      className={`${selectedLayerId ? 'naxatw-h-36' : 'naxatw-h-full'} `}
    >
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <h4>{_t('Filter Result')}</h4>
        {queryExpression && (
          <Button
            leftIcon="restart_alt"
            variant="ghost"
            onClick={() =>
              dispatch(
                setAdvanceFilterState({
                  queryExpression: null,
                  hideQueryFilter: false,
                  selectedLayerId: null,
                }),
              )
            }
            className="!naxatw-h-fit !naxatw-p-0"
          >
            {_t('Reset all Filter')}
          </Button>
        )}
      </FlexRow>
      {!queryExpression ? (
        <FlexColumn className="naxatw-h-full naxatw-items-center naxatw-justify-center ">
          <Image src={FilterImage} />
          <h4>{_t('No Filter Selected')}</h4>
          <p className="naxatw-text-sm">
            {_t('Please choose filter to see data')}
          </p>
        </FlexColumn>
      ) : (
        <FlexColumn gap={2}>
          <FlexRow
            gap={5}
            className=" naxatw-relative naxatw-items-center naxatw-justify-end"
          >
            <SearchInput
              inputValue={searchInput}
              onChange={e =>
                dispatch(
                  setAdvanceFilterState({
                    searchInput: e.target.value,
                  }),
                )
              }
              className="naxatw-h-9 naxatw-w-[300px]"
              onClear={() =>
                dispatch(
                  setAdvanceFilterState({
                    searchInput: '',
                  }),
                )
              }
            />
            <div
              ref={toggleRef as React.RefObject<HTMLDivElement>}
              onClick={toggleDownload}
              tabIndex={0}
              role="button"
              onKeyDown={() => {}}
              className="actions naxatw-w-40px naxatw-flex naxatw-cursor-pointer
           naxatw-rounded-lg naxatw-p-1 hover:naxatw-bg-primary-50"
            >
              <ToolTip
                name="download"
                message="Download Table"
                className="!naxatw-text-2xl"
              />
            </div>
            {download && (
              <FlexColumn
                className="naxatw-absolute naxatw-right-2 naxatw-top-8 naxatw-z-[100] naxatw-w-[8.3rem] naxatw-cursor-pointer
         naxatw-rounded naxatw-border naxatw-bg-white naxatw-text-center naxatw-text-sm naxatw-shadow-sm"
              >
                <div className="naxatw-py-3 hover:naxatw-bg-primary-50">
                  <a
                    href={`${apiURL}/${selectedLayerId}-advance-filtering-download/?type=csv&expression=${JSON.stringify(
                      queryExpression,
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Export as CSV
                  </a>
                </div>
                <div className="naxatw-py-3 hover:naxatw-bg-primary-50">
                  <a
                    href={`${apiURL}/${selectedLayerId}-advance-filtering-download/?type=kml&expression=${JSON.stringify(
                      queryExpression,
                    )}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Export as KML
                  </a>
                </div>
              </FlexColumn>
            )}
          </FlexRow>
          <DataTable
            columns={
              selectedLayerId === 'building'
                ? buildingDataColumns
                : roadDataColumns
            }
            queryKey={
              selectedLayerId === 'building'
                ? 'building-advance-filter-list'
                : 'road-advance-filter-list'
            }
            queryFn={
              selectedLayerId === 'building'
                ? getBuildingAdvanceFiltering
                : getRoadAdvanceFiltering
            }
            queryFnParams={{ expression: JSON.stringify(queryExpression) }}
            searchInput={searchInput}
            wrapperStyle={{
              height:
                width <= 390
                  ? '60vh'
                  : !hideQueryFilter
                    ? 'calc(100vh - 38.5rem)'
                    : 'calc(100vh - 20rem)',
            }}
            useQueryOptions={{ enabled: !!queryExpression }}
          />
        </FlexColumn>
      )}
    </FlexColumn>
  );
}
