/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { Input } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useState } from 'react';
import { getAttributeLayerList } from '@Services/layerAttribute';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { setLayerAttributeState } from '@Store/actions/layerAttribute';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import NoLayerAvaibale from '@Assets/images/NoLayerAvailable.png';

function SelectLayerSection() {
  const dispatch = useTypedDispatch();
  const layerList = useTypedSelector(state => state.layerAttribute.layerList);
  const [searchText, setSearchText] = useState('');

  useQuery({
    queryKey: ['layer-attribute-list'],
    queryFn: () => getAttributeLayerList(),
    select: (res: any) => res.data,
    onSuccess: res => dispatch(setLayerAttributeState({ layerList: res })),
  });

  const filterOptions = layerList?.filter((opt: string) =>
    opt.toLowerCase().includes(searchText.toLowerCase()),
  );
  return (
    <FlexRow className="naxatw-h-full">
      <FlexColumn className="naxatw-h-full naxatw-w-[300px] naxatw-shadow-dark">
        <FlexColumn className="naxatw-px-3">
          <h4 className="naxatw-py-5">Select Layer</h4>
          <FlexRow
            className="naxatw-group naxatw-w-full naxatw-items-center
       naxatw-border-b-2 naxatw-pr-2 hover:naxatw-border-b-primary-400"
          >
            <Input
              className="naxatw-w-full naxatw-border-none"
              value={searchText}
              onChange={e => setSearchText(e.target.value)}
            />
            <Icon
              name="search"
              className="naxatw-w-full group-hover:naxatw-text-primary-400"
            />
          </FlexRow>
        </FlexColumn>
        <FlexColumn className="scrollbar naxatw-h-[calc(100%-40px)] naxatw-overflow-auto">
          {filterOptions
            ? filterOptions?.map((layer: string) => (
                <li
                  key={layer}
                  className="naxatw-cursor-pointer naxatw-list-none naxatw-px-3 naxatw-py-3 hover:naxatw-bg-primary-50"
                  onClick={() => {
                    dispatch(
                      setLayerAttributeState({
                        selectedLayer: layer,
                        showTableSection: true,
                      }),
                    );
                  }}
                >
                  {capitalizeFirstLetter(layer)}
                </li>
              ))
            : ''}
        </FlexColumn>
      </FlexColumn>
      <FlexColumn
        gap={3}
        className="naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center"
      >
        <img src={NoLayerAvaibale} alt="No Layer" />
        <div className="naxatw-text-center">
          <h4>No Layer Selected</h4>
          <span>Please select a layer to see data </span>
        </div>
      </FlexColumn>
    </FlexRow>
  );
}

export default hasErrorBoundary(SelectLayerSection);
