/* eslint-disable no-nested-ternary */
import { useEffect, useCallback, useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiURL } from '@Services/index';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setPublicPageState } from '@Store/actions/publicPage';
import { useGetMapPopupDataQuery } from '@Api/publicPage';
import { createQueryParams } from '@Utils/createQueryParams';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import {
  userLocationColor,
  buildingStyles,
  getBuildingColor,
} from '@Constants/map';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import PopupUI from '@Components/common/MapLibreComponents/PopupUI';
import AsyncPopup from '@Components/common/MapLibreComponents/AsyncPopup';
import RoadVTLayer from '@Components/common/MapLibreComponents/CustomLayers/RoadVTLayer';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { Flex, FlexColumn } from '@Components/common/Layouts';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import {
  getRouteDataRequest,
  setVisualizationState,
} from '@Store/actions/visualization';
import CircleMarker from '@Components/common/MapLibreComponents/Layers/CircleMarker';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import RouteOverlay from '@Components/Visualization/MapSection/RouteOverlay';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';
import PlotPoint from '@Components/common/MapLibreComponents/CustomLayers/PlotPoint';
import FilterSection from './FilterSection';
import MapTools from './MapTools';
import MapFooter from './MapFooter';
import MapLegend from './MapLegend';

/**
 * render popup title for house/road layer
 * @param {object} popupData - json containing popup data
 */
function getPopupTitle(popupData: Record<string, any> | null): string {
  if (!popupData) return '';
  const isHousePopup = 'house_no' in popupData;
  return isHousePopup
    ? `${popupData?.house_no} - ${popupData?.associate_road_name}`
    : popupData?.road_name_en;
}

function MapSection() {
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const [isBuildingLayerLoaded, setIsBuildingLayerLoaded] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isRoadLayerLoaded, setIsRoadLayerLoaded] = useState(false);

  const activeBaseLayer = useTypedSelector(
    state => state.publicPage.activeBaseLayer,
  );
  const layers = useTypedSelector(state => state.publicPage.layers);
  const selectedFeature = useTypedSelector(
    state => state.publicPage.selectedFeature,
  );
  const selectedLayerId = useTypedSelector(
    state => state.publicPage.selectedLayerId,
  );
  const selectedLayerType = useTypedSelector(
    state => state.publicPage.selectedLayerType,
  );
  const popupData = useTypedSelector(state => state.publicPage.popupData);
  const filterParams = useTypedSelector(state => state.publicPage.filterParams);

  const geolocation = useTypedSelector(
    state => state.visualization.geolocation,
  );
  const zoomToGeolocation = useTypedSelector(
    state => state.visualization.geolocation,
  );
  const zoomToLocationType = useTypedSelector(
    state => state.visualization.zoomToLocationType,
  );
  const routeData = useTypedSelector(state => state.visualization.routesData);
  const buildingBbox = useTypedSelector(state => state.publicPage.buildingBbox);
  const isRoutingOn = useTypedSelector(
    state => state.visualization.isRoutingOn,
  );
  const showLegend = useTypedSelector(state => state.publicPage.showLegend);
  const drawnCoordinate = useTypedSelector(
    state => state.visualization.drawnCoordinate,
  );
  const locateOnMap = useTypedSelector(
    state => state.visualization.locateOnMap,
  );

  // cache geolocation coordinates
  const geolocationCoords: [number, number] | null = useMemo(
    () => (geolocation ? [geolocation.longitude, geolocation.latitude] : null),
    [geolocation],
  );

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 1,
      center: [84.124, 28.3949],
      maxZoom: 19,
    },
    disableRotation: true,
  });

  const { isLoading: isPopupDataFetching } = useGetMapPopupDataQuery(
    selectedLayerId,
    selectedLayerType,
    {
      select: (res: any) => {
        dispatch(setPublicPageState({ popupData: res?.data }));
      },
      enabled: !!selectedLayerId,
    },
  );

  // zoom to overall building bbox on initial map load
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingBbox) return () => {};
    const [x1, y1, x2, y2] = buildingBbox;
    const timer = setTimeout(() => {
      map.fitBounds(
        [
          [x1, y1],
          [x2, y2],
        ],
        { duration: 0, padding: 40 },
      );
    }, 0);
    return () => clearTimeout(timer);
  }, [map, isMapLoaded, buildingBbox]);

  // check if building layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'building') {
        // setIsBuildingLayerLoaded to true if ev has tile property
        setIsBuildingLayerLoaded(!!ev?.tile);
      }
    });
  }, [map]);

  // check if road layer is loaded
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'road') {
        setIsRoadLayerLoaded(true);
      }
    });
  }, [map]);

  // zoom to selected feature
  useEffect(() => {
    if (!selectedFeature?.bbox || !map) return;
    map.fitBounds(selectedFeature.bbox, { maxZoom: 18 });
  }, [map, selectedFeature]);

  // highlight only selected building on map
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || selectedFeature?.layer !== 'building')
      return () => {};
    const buildingColor = getBuildingColor(selectedFeature.association_type);
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'building_pk'],
      selectedFeature.id,
      buildingColor,
      '#D7D7D7',
    ]);
    // color back building unmount
    return () => {
      map.setPaintProperty(
        'building',
        'fill-extrusion-color',
        buildingStyles.paint['fill-extrusion-color'],
      );
    };
  }, [map, isBuildingLayerLoaded, selectedFeature]);

  // zoom to geolocation
  useEffect(() => {
    if (!map || !geolocation || !zoomToGeolocation) return;
    map.flyTo({
      center: [geolocation.longitude, geolocation.latitude],
      zoom: 14,
    });
    dispatch(setVisualizationState({ zoomToGeolocation: false }));
  }, [dispatch, map, geolocation, zoomToGeolocation]);

  const getPopupUI = useCallback(() => {
    return isPopupDataFetching ? (
      // display skeleton loader when fetching data
      <FlexColumn gap={3} className="naxatw-mb-12">
        {Array.from({ length: 7 }, (_, index) => (
          <Skeleton
            key={index}
            className="naxatw-h-4 naxatw-w-full naxatw-rounded-md naxatw-bg-grey-100 naxatw-shadow-sm"
          />
        ))}
      </FlexColumn>
    ) : (
      <PopupUI data={popupData} />
    );
  }, [popupData, isPopupDataFetching]);

  const isFilterApplied =
    !!filterParams.ward_no?.length ||
    !!filterParams.road_type?.length ||
    !!filterParams.building_use?.length;

  const isLoading = useDebouncedLoadingState(
    !isBuildingLayerLoaded || !isRoadLayerLoaded || !isMapLoaded,
  );

  // getting current users coordinates when routing feature in turned on
  useEffect(() => {
    if (!map && !isRoutingOn) return;
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(position => {
        dispatch(
          setVisualizationState({
            startDirectionCoordinates: position.coords,
            locationCoordinates: position.coords,
          }),
        );
      });
    }
  }, [map, isRoutingOn, dispatch]);

  // get route when the drawnCoordinate is changed and not null
  useEffect(() => {
    if (!locateOnMap || !drawnCoordinate) return;
    dispatch(
      setVisualizationState({ startDirectionCoordinates: drawnCoordinate }),
    );
    dispatch(getRouteDataRequest());
  }, [dispatch, drawnCoordinate, locateOnMap]);

  useEffect(() => {
    if (!map || !routeData) return () => {};
    const timer = setTimeout(() => {
      map.fitBounds(routeData.bbox, { maxZoom: 18, duration: 0 });
    }, 300);
    // cleanup timer on unmount
    return () => clearTimeout(timer);
  }, [routeData, map]);

  return (
    <Flex className="naxatw-relative naxatw-h-fit naxatw-w-full naxatw-bg-grey-100 lg:naxatw-w-[calc(100%-428px)]">
      {/* map loadder */}
      {isLoading && <MapLoader />}

      {/* search input / filter section */}
      <FilterSection />

      {/* map legend */}
      <MapLegend />

      <MapContainer
        map={map}
        isMapLoaded={isMapLoaded}
        style={{
          width: '100%',
          height: '672px',
        }}
      >
        <BaseLayerSwitcher activeLayer={activeBaseLayer} />

        {layers.map(
          // eslint-disable-next-line no-unused-vars
          ({ id, endPoint, checked, styles, showLabel, showOutline }) =>
            id === 'road' ? (
              <RoadVTLayer
                key={id}
                id={id}
                url={
                  isFilterApplied
                    ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?${createQueryParams(
                        filterParams,
                      )}`
                    : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                }
                layerOptions={styles}
                visibleOnMap={checked}
                interactions={['select']} // add cursor pointer on layer hover
                showLabel
              />
            ) : (
              <VectorTileLayer
                key={id}
                id={id}
                url={
                  isFilterApplied && id === 'building'
                    ? `${apiURL}/${endPoint}/{z}/{x}/{y}/?${createQueryParams(
                        filterParams,
                      )}`
                    : `${apiURL}/${endPoint}/{z}/{x}/{y}/`
                }
                layerOptions={styles}
                visibleOnMap={checked}
                showOutline={showOutline}
                interactions={['select']}
                showLabel
              />
            ),
        )}
        <MapTools />

        <AsyncPopup
          fetchPopupData={properties => {
            dispatch(
              setPublicPageState({
                selectedLayerId:
                  properties.layer === 'building'
                    ? properties.building_pk
                    : properties.layer === 'road'
                      ? properties.road_pk
                      : properties.id,
                selectedLayerType: properties.layer,
              }),
            );
          }}
          popupUI={getPopupUI}
          title={getPopupTitle(popupData)}
          isPublicPage
          enable={!locateOnMap}
          handleBtnClick={properties => {
            const { layer } = properties;
            if (layer === 'building') {
              navigate(`building-detail/${properties.building_pk}`);
            } else if (layer === 'road') {
              navigate(`road-detail/${properties.road_pk}`);
            }
          }}
          isLoading={isPopupDataFetching}
          onClose={() => {
            dispatch(
              setPublicPageState({
                selectedLayerId: null,
                selectedLayerType: '',
                popupData: null,
              }),
            );
          }}
          onRoutingBtnClick={coordinates => {
            dispatch(
              setVisualizationState({
                isRoutingOn: true,
                endDirectionCoordinates: coordinates,
              }),
            );
            dispatch(getRouteDataRequest());
          }}
        />

        {isRoutingOn && (
          <RouteOverlay
            popUpData={popupData as Record<string, any>}
            className={`${
              showLegend ? 'naxatw-bottom-52' : 'naxatw-bottom-28'
            } naxatw-left-5`}
          />
        )}

        {isRoutingOn && (
          <VectorLayer
            key="route-layer"
            id="route-layer"
            geojson={routeData as GeojsonType}
            layerOptions={{
              paint: {
                'line-color': '#ff474c',
                'line-width': 6,
              },
            }}
            visibleOnMap
          />
        )}

        {locateOnMap && (
          <PlotPoint
            enabled={locateOnMap}
            onClick={(coordinates: Record<string, any>) => {
              dispatch(setVisualizationState({ drawnCoordinate: coordinates }));
            }}
          />
        )}

        {/* plot circle marker on user location enable */}
        {geolocationCoords && (
          <CircleMarker
            id="geolocation"
            coordinates={geolocationCoords}
            zoomToLayer={zoomToLocationType === 'geolocation'}
            layerOptions={{
              type: 'circle',
              paint: {
                'circle-radius': 8,
                'circle-color': userLocationColor,
              },
            }}
          />
        )}
        {/* map footer */}
        <MapFooter />
      </MapContainer>
    </Flex>
  );
}

export default hasErrorBoundary(MapSection);
