/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface UserManagementState {
  searchInput: string;
  selectedUser: Record<string, any> | null;
}

const initialState: UserManagementState = {
  searchInput: '',
  selectedUser: null,
};

const setUserManagementState: CaseReducer<
  UserManagementState,
  PayloadAction<Partial<UserManagementState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const userManagementSlice = createSlice({
  name: 'userManagement',
  initialState,
  reducers: {
    setUserManagementState,
  },
});

export { userManagementSlice };

export default userManagementSlice.reducer;
