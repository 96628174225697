/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { wktToGeoJSON } from '@terraformer/wkt';
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import getCoordinatesFromWKT from '@Utils/getCoordinatesFromWKT';
import { labelMappings } from '@Constants/index';
import Image from '@Components/RadixComponents/Image';
import Icon from '@Components/common/Icon';
import NoMedia from '@Assets/images/no-media-files.svg';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  getBuildingData,
  getBuildingImages,
  getRoadData,
} from '@Services/visualization';
import { getRoadGeometry } from '@Services/dataManagement';
import {
  buildingAttributeKeys,
  buildingDetailKeys,
  imageFieldKeys,
  roadAttributeKeys,
  roadDetailKeys,
} from '@Constants/detailPage';
import Skeleton from '@Components/RadixComponents/Skeleton';
import FeatureImage from '@Components/common/FeatureImage';
import ImageSlider from '@Components/common/ReactSlick';
import ToolTip from '@Components/RadixComponents/ToolTip';
import MapSection from './Mapsection';

export default function DetailPage() {
  const { id } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [allImages, setAllImages] = useState<string[]>([]);
  const [showFeatureImage, setShowFeatureImage] = useState<boolean>(false);

  const detailType = pathname.includes('building-detail') ? 'building' : 'road';

  // fetch building and road data
  const { data, isLoading: dataLoading } = useQuery({
    queryKey: [detailType, id],
    queryFn: () =>
      detailType === 'building' ? getBuildingData(id) : getRoadData(id),
    enabled: !!id,
    select: res => res.data,
  });

  // fetch geojsonData in road detail to add start and end point
  const { data: geojsonData, isLoading: isGeojsonLaoding } = useQuery({
    queryKey: ['get-road-geometry', data?.feature_id],
    queryFn: () => getRoadGeometry(data?.feature_id),
    select: res => {
      const wkt = res.data.geom;
      // convert wkt to GeoJson
      const geojson = wktToGeoJSON(wkt.split(';')[1]);
      return geojson;
    },
    enabled: detailType === 'road' && !!data?.feature_id,
  });

  // fetch building images in building detail
  const { data: buildingImages, isLoading: isImageLoading } = useQuery({
    queryKey: ['building-images', id],
    queryFn: () => getBuildingImages({ building_id: id }),
    select: res => res.data,
    enabled: detailType === 'building' && !!id,
  });

  const detailKeys =
    detailType === 'building' ? buildingDetailKeys : roadDetailKeys;

  const attributeKeys =
    detailType === 'building' ? buildingAttributeKeys : roadAttributeKeys;

  if (
    dataLoading ||
    (detailType === 'building' && isImageLoading) ||
    (detailType === 'road' && isGeojsonLaoding)
  ) {
    return (
      <FlexColumn
        gap={5}
        className="naxatw-w-full naxatw-rounded-lg naxatw-px-5 naxatw-pt-4"
      >
        <Skeleton className="naxatw-h-6 naxatw-w-1/4 naxatw-rounded-lg naxatw-bg-grey-100 naxatw-shadow-sm" />
        <FlexRow gap={5}>
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton
              key={index}
              className="naxatw-h-64 naxatw-w-1/3 naxatw-rounded-lg naxatw-bg-grey-100 naxatw-shadow-sm"
            />
          ))}
        </FlexRow>
        <Skeleton className=" naxatw-h-10 naxatw-w-full" />
        {Array.from({ length: 8 }, (_, idx) => (
          <FlexRow key={idx} className="naxatw-space-x-20">
            <Skeleton className="naxatw-h-5 naxatw-w-full " />
          </FlexRow>
        ))}
      </FlexColumn>
    );
  }

  return (
    <FlexColumn className="naxatw-w-full naxatw-pb-12 naxatw-pl-5 naxatw-pr-4 naxatw-pt-4">
      {/* header-section */}

      <FlexRow className="naxatw-justify-between">
        <FlexRow gap={2} className="naxatw-items-center">
          <Icon
            name="arrow_back"
            className="naxatw-mt-1 naxatw-text-grey-600 hover:naxatw-text-primary-400"
            onClick={() => navigate(-1)}
          />
          <h3>
            {detailType === 'building'
              ? data?.associate_road_name
              : data?.road_name_en}
          </h3>
        </FlexRow>
        <FlexRow gap={2} className="naxatw-items-center">
          <ToolTip
            name="edit"
            message="Edit"
            onClick={() => {
              navigate(
                `/data-management/${
                  detailType === 'building' ? 'building-data' : 'road-data'
                }/form/${id}`,
              );
            }}
          />
          <ToolTip
            name="history"
            message="Show edit history"
            onClick={() => {
              navigate(
                `/visualization/${
                  detailType === 'building' ? 'building' : 'road'
                }-edit-history/${id}`,
              );
            }}
          />
          <ToolTip name="share" message="Share" />
        </FlexRow>
      </FlexRow>

      {/* Building */}

      <FlexRow
        className="naxatw-mt-8 naxatw-h-full naxatw-flex-col naxatw-justify-between md:naxatw-flex-row"
        gap={6}
      >
        {detailType === 'building' && (
          <FlexColumn
            className="naxatw-items-center naxatw-justify-center naxatw-bg-primary-800
           naxatw-px-5 md:naxatw-w-1/3"
          >
            <FlexColumn className="naxatw-w-full naxatw-items-center naxatw-justify-center">
              <h1 className="naxatw-font-bold naxatw-text-white">
                {data?.house_no || '419/27'}
              </h1>
              <div className="naxatw-h-0.5 naxatw-w-1/2 naxatw-bg-white" />
              <h3 className="naxatw-text-white ">
                {data?.associate_road_name}
              </h3>
            </FlexColumn>
            <FlexRow className="naxatw-mt-10 naxatw-w-full naxatw-justify-end">
              <div>
                <h5 className="naxatw-text-white">{data?.tole_name}</h5>
                <p className="naxatw-text-sm naxatw-font-medium naxatw-text-white">
                  {data?.plus_code}
                </p>
              </div>
            </FlexRow>
          </FlexColumn>
        )}
        <FlexColumn>
          <h4 className="naxatw-text-primary-400">
            {detailType === 'building' ? data?.owner_name : data?.road_name_en}
          </h4>
          <FlexColumn gap={4} className="naxatw-pt-5">
            {Object.entries(detailKeys).map(([key, label]) => {
              const value = data?.[key];
              const valueType = Array.isArray(value)
                ? value.join(', ')
                : typeof value === 'object'
                  ? JSON.stringify(value)
                  : typeof value === 'string'
                    ? capitalizeFirstLetter(value)
                    : typeof value === 'number' &&
                        !Number.isNaN(value) &&
                        !Number.isInteger(value)
                      ? value.toFixed(2)
                      : value;

              return (
                <FlexRow gap={4} key={key} className="naxatw-text-sm">
                  <FlexColumn className="naxatw-w-[40%]">
                    <p>{label}</p>
                  </FlexColumn>
                  <FlexColumn className="naxatw-w-[60%] naxatw-font-bold">
                    <p>
                      {key === 'road_length'
                        ? `${valueType} ${valueType !== 'Null' ? 'm' : ''}`
                        : value
                          ? valueType
                          : '-'}
                    </p>
                  </FlexColumn>
                </FlexRow>
              );
            })}
          </FlexColumn>
        </FlexColumn>

        {/* Map */}
        <MapSection data={data} geojson={geojsonData} />
      </FlexRow>

      {/*  attribute section */}
      <FlexColumn className="naxatw-mt-16">
        <FlexRow
          className="naxatw-w-full naxatw-bg-primary-400 naxatw-px-3
         naxatw-py-2 naxatw-text-sm naxatw-font-bold naxatw-text-white"
        >
          Attribute
        </FlexRow>
        <FlexColumn className="naxatw-text-sm">
          {Object.entries(attributeKeys).map(([key, label]) => {
            const value = data?.[key];
            const valueType = Array.isArray(value)
              ? value.join(', ')
              : typeof value === 'object'
                ? JSON.stringify(value)
                : key === 'start_point' && value
                  ? `[${getCoordinatesFromWKT(value).join(', ')}]`
                  : typeof value === 'string' && value in labelMappings
                    ? labelMappings[value]
                    : typeof value === 'string'
                      ? capitalizeFirstLetter(value)
                      : typeof value === 'number' &&
                          !Number.isNaN(value) &&
                          !Number.isInteger(value)
                        ? value.toFixed(3)
                        : value;
            const isImageField = imageFieldKeys.includes(key);
            const images = buildingImages?.[key];
            const hasImages = !!images?.length;
            return (
              <FlexRow key={key} className="naxatw-p-3 odd:naxatw-bg-grey-100">
                <p className="naxatw-w-[30%]">
                  {typeof label === 'string' ? label : '-'}
                </p>
                {!isImageField ? (
                  <p className="naxatw-font-bold naxatw-capitalize">
                    {value ? valueType : '-'}
                  </p>
                ) : (
                  <FlexRow gap={5} className=" naxatw-py-5">
                    <ImageSlider className=" naxatw-flex naxatw-w-[450px]">
                      {hasImages ? (
                        images.map((item: string, index: number) => {
                          return (
                            <div
                              key={item}
                              onClick={() => {
                                setCurrentImage(index);
                                setShowFeatureImage(true);
                                setAllImages(images);
                              }}
                              className="naxatw-cursor-pointer naxatw-duration-100 naxatw-ease-in-out hover:naxatw-scale-105"
                            >
                              <Image
                                key={item}
                                src={item}
                                width={450}
                                className="naxatw-h-64"
                              />
                            </div>
                          );
                        })
                      ) : (
                        <Image src={NoMedia} />
                      )}
                    </ImageSlider>
                  </FlexRow>
                )}
              </FlexRow>
            );
          })}
        </FlexColumn>
        {showFeatureImage && (
          <FeatureImage
            currentimage={currentImage}
            images={allImages}
            setShowImage={setShowFeatureImage}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  );
}
