/* component for add/edit user form */

import { useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import removeObjectKeys from '@Utils/removeObjectKeys';
import isEmpty from '@Utils/isEmpty';
import 'react-toastify/dist/ReactToastify.css';
import prepareFormData from '@Utils/prepareFormData';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import getInputElement from '@Components/DataManagement/MultiStepForm/FormSection/getInputElement';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { userManagementFormSchema } from '@Constants/userManagementForm';
import { Button } from '@Components/RadixComponents/Button';
import { getUserRole, postUser, updateUser } from '@Services/userManagement';
import { toggleInfoDialog, toggleModal } from '@Store/actions/common';
import { setUserManagementState } from '@Store/actions/userManagement';
import {
  handleUserTableAdd,
  handleUserTablePatch,
} from '@Queries/userManagement';
import { allWardsSelector } from '@Store/selector/common';

type UnknownArrayOrObject = unknown[] | Record<string, unknown>;

// https://github.com/react-hook-form/react-hook-form/discussions/1991#discussioncomment-351784
export const getDirtyValues = (
  dirtyFields: UnknownArrayOrObject | boolean,
  allValues: UnknownArrayOrObject,
  // dirtyValues: UnknownArrayOrObject,
): UnknownArrayOrObject | Record<string, any> => {
  // NOTE: Recursive function.

  // If *any* item in an array was modified, the entire array must be submitted, because there's no
  // way to indicate "placeholders" for unchanged elements. `dirtyFields` is `true` for leaves.
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }

  // Here, we have an object.
  return Object.fromEntries(
    Object.keys(dirtyFields).map(key => [
      key,
      // @ts-ignore
      getDirtyValues(dirtyFields[key], allValues[key]),
    ]),
  );
};

export default function AddUser() {
  const queryClient = useQueryClient();
  const dispatch = useTypedDispatch();

  const modalContent = useTypedSelector(state => state.common.modalContent);
  const selectedUser = useTypedSelector(
    state => state.userManagement.selectedUser,
  );
  const formType = modalContent === 'edit-user' ? 'edit' : 'add';

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    register,
    getValues,
    control,
    setValue,
    reset,
    watch,
  } = useForm({ mode: 'all' });

  const allWards = useTypedSelector(allWardsSelector);

  const { data: dropdownOptions } = useQuery({
    queryKey: ['user-role'],
    queryFn: getUserRole,
    select: res => ({
      role_type: prepareDropdownOptions(res.data, {
        labelKey: 'alias',
        valueKey: 'name',
      }),
      ward: allWards.map(({ label, value }) => ({
        alias: label,
        name: value,
      })),
    }),
  });

  // for edit user form
  useEffect(() => {
    if (!selectedUser || modalContent !== 'edit-user') return;
    // replace thumbnail to image key
    const data = {
      ...removeObjectKeys(selectedUser, ['thumbnail']),
      image: selectedUser.thumbnail ? [selectedUser.thumbnail] : null,
    };
    // reset form data before setting values
    reset();
    // set form state values
    Object.entries(data).forEach(([name, value]: any) => setValue(name, value));
  }, [selectedUser, modalContent, setValue, reset]);

  const handleModalClose = () => {
    dispatch(toggleModal());
    dispatch(
      setUserManagementState({
        selectedUser: null,
      }),
    );
    dispatch(
      toggleInfoDialog(
        formType === 'add' ? 'add-user-success' : 'edit-user-success',
      ),
    );
  };

  // update query cache on add/edit
  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'add'
        ? handleUserTableAdd(queryClient, payloadData)
        : handleUserTablePatch(queryClient, payloadData),
  });

  const { mutate: postUserRequest, isLoading } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'add'
        ? postUser(payloadData)
        : updateUser(selectedUser?.id, payloadData),
    onSuccess: res => {
      if (res) {
        updateTableData({ id: selectedUser?.id, ...res.data.details });
        handleModalClose();
      }
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });

  const onSubmit = (values: Record<string, any>) => {
    const payload: Record<string, any> =
      formType === 'add' ? values : getDirtyValues(dirtyFields, values);
    const modifiedPayload: Record<string, any> = {
      ...payload,
      image: payload?.image?.length ? payload?.image : '',
    };
    if (modifiedPayload?.image?.[0]) {
      modifiedPayload.image = values.image[0]?.file || '';
    }
    if (
      'image' in payload &&
      !modifiedPayload?.image &&
      isEmpty(modifiedPayload?.image)
    ) {
      modifiedPayload.delete_image = true;
    }
    if (isEmpty(modifiedPayload)) {
      handleModalClose();
      return;
    }
    postUserRequest(prepareFormData(modifiedPayload));
  };

  const formProps = { register, control, errors, setValue, getValues };

  // watch for role_type change when selecting ward editor or viewer
  const currentRoleType = watch('role_type');

  return (
    <FlexColumn gap={2}>
      <FlexColumn
        gap={5}
        className="scrollbar naxatw-max-h-[calc(100vh-18rem)] naxatw-items-center naxatw-overflow-y-auto"
      >
        {userManagementFormSchema.map(data => {
          const { id, label, required } = data;
          // display ward select field for role type ward editor or viewer
          if (id === 'ward' && !currentRoleType?.includes('ward')) return <></>;
          return (
            <FormControl key={id} className="naxatw-w-full">
              <Label required={required}>{label}</Label>
              {getInputElement(
                { ...data, readOnly: formType === 'edit' && id === 'email' },
                formProps,
                dropdownOptions || {},
              )}
              {formProps.errors[id] && (
                <ErrorMessage
                  className="naxatw-mt-2"
                  /* @ts-ignore */
                  message={formProps.errors[id]?.message}
                />
              )}
            </FormControl>
          );
        })}
      </FlexColumn>

      <FlexRow className=" naxatw-w-full naxatw-justify-center">
        <Button variant="ghost" onClick={() => dispatch(toggleModal())}>
          Cancel
        </Button>
        <Button
          withLoader
          isLoading={isLoading}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          {formType === 'add' ? 'Add User' : 'Save'}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
