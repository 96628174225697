/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { format } from 'date-fns';
import {
  handleFilterReset,
  setAdvanceFilterState,
} from '@Store/actions/advanceFilter';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { getBuildingPlanFilter } from '@Services/visualization';
import { getBuildingDataList } from '@Services/dataManagement';
import Chip from '@Components/common/Chip';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import ChartContainer from '@Components/common/Charts/ChartContainer';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import prepareChartData from '@Utils/prepareChartData';
import prepareQueryParam from '@Utils/prepareQueryParam';
import { Button } from '@Components/RadixComponents/Button';
import DataTable from '@Components/common/DataTable';
import useTranslation from '@Hooks/useTranslation';

const tabList = ['Visualization', 'Data'];

export default function DataVisualizationSection() {
  const [activeTab, setActiveTab] = useState('Visualization');
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();
  const { _t } = useTranslation();

  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );

  const basicFilterParams = useTypedSelector(
    state => state.advanceFilter.basicFilterParams,
  );

  const { data: buildingPlanData } = useQuery({
    queryKey: ['building-paln-filter', selectedLayerId, basicFilterParams],
    queryFn: () =>
      getBuildingPlanFilter({
        layer: selectedLayerId,
        ...(basicFilterParams ? prepareQueryParam(basicFilterParams) : {}),
      }),
    select: (res: any) =>
      Array.isArray(res.data) ? res.data : prepareChartData(res.data),
    enabled: !!selectedLayerId,
  });

  const isFilterApplied =
    !!basicFilterParams.ward_no?.length ||
    !!basicFilterParams.tole_name?.length ||
    !!basicFilterParams.owner_status?.length ||
    !!basicFilterParams.reg_type?.length;

  const buildingDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'House No.',
      accessorKey: 'house_no',
    },
    {
      header: 'Associate Road Name',
      accessorKey: 'associate_road_name',
    },
    {
      header: 'Owner Name',
      accessorKey: 'owner_name',
    },
    {
      header: 'Ward No.',
      accessorKey: 'ward_no',
      cell: ({ row }: any) => <p>{`ward ${row?.original?.ward_no}`}</p>,
    },
    {
      header: 'Tole Name',
      accessorKey: 'tole_name',
    },
    {
      header: 'Plus Code',
      accessorKey: 'plus_code',
    },
    {
      header: 'Last Modified',
      accessorKey: 'updated_date',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
  ];

  const handleChipClose = ({ key, val }: Record<string, any>) => {
    dispatch(
      handleFilterReset({
        key,
        val,
      }),
    );
  };

  // handle filter reset inside select dropdown on handleChipClose
  useEffect(() => {
    dispatch(
      setAdvanceFilterState({ selectedWards: basicFilterParams.ward_no || [] }),
    );
  }, [dispatch, basicFilterParams.ward_no]);
  useEffect(() => {
    dispatch(
      setAdvanceFilterState({
        selectedTole: basicFilterParams.tole_name || [],
      }),
    );
  }, [dispatch, basicFilterParams.tole_name]);
  useEffect(() => {
    dispatch(
      setAdvanceFilterState({
        selectedOwnerStatus: basicFilterParams.owner_status || [],
      }),
    );
  }, [dispatch, basicFilterParams.owner_status]);
  useEffect(() => {
    dispatch(
      setAdvanceFilterState({
        selectedBuildingType: basicFilterParams.reg_type || [],
      }),
    );
  }, [dispatch, basicFilterParams.reg_type]);

  return (
    <FlexColumn gap={3}>
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <h4>{_t('Filter Result')}</h4>
        {isFilterApplied && (
          <Button
            leftIcon="restart_alt"
            variant="ghost"
            onClick={() => {
              dispatch(handleFilterReset({}));
            }}
            className="!naxatw-h-fit !naxatw-p-0"
          >
            {_t('Reset all Filter')}
          </Button>
        )}
      </FlexRow>
      {isFilterApplied && (
        <FlexRow
          gap={4}
          className="scrollbar naxatw-h-10 naxatw-w-full naxatw-overflow-x-auto"
        >
          {Object.entries(basicFilterParams).map(([key, value]) =>
            !value
              ? null
              : value?.map(val => (
                  <Chip
                    key={key}
                    label={key === 'ward_no' ? `Ward ${val}` : val}
                    onClose={() => handleChipClose({ key, val })}
                  />
                )),
          )}
        </FlexRow>
      )}
      <FlexRow gap={5}>
        {tabList.map((item: string) => (
          <h5
            key={item}
            className={`${
              activeTab === item
                ? ' naxatw-border-b-2 naxatw-border-b-primary-400 naxatw-text-primary-400'
                : ''
            } naxatw-cursor-pointer naxatw-text-sm naxatw-text-grey-700 hover:naxatw-border-b-2
             hover:naxatw-border-b-primary-400 hover:naxatw-text-primary-400`}
            onClick={() => setActiveTab(item)}
            tabIndex={0}
            role="button"
            onKeyDown={() => {}}
          >
            {`${_t(`${item}`)}`}
          </h5>
        ))}
      </FlexRow>
      {activeTab === 'Visualization' ? (
        <ChartContainer
          className="!naxatw-gap-0 lg:!naxatw-h-[256px]"
          type="bar"
          yLabel="Number of"
          chartTitle=""
          header={ChartHeader}
          hasDownloadBtn
          data={buildingPlanData || []}
          fillWithType
        />
      ) : (
        <DataTable
          columns={buildingDataColumns}
          queryKey="building-data-list"
          queryFn={getBuildingDataList}
          queryFnParams={basicFilterParams}
          initialState={{
            paginationState: {
              pageIndex: 0,
              pageSize: 25,
            },
          }}
          wrapperStyle={{
            height:
              width <= 390
                ? '60vh'
                : isFilterApplied
                  ? 'calc(100vh - 28rem)'
                  : 'calc(100vh - 26rem)',
          }}
        />
      )}
    </FlexColumn>
  );
}
