import { Fragment } from 'react';
import { useTypedSelector } from '@Store/hooks';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import { FormFieldType, formSchemaType } from '@Constants/dataManagementForm';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import useTranslation from '@Hooks/useTranslation';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import ToolTip from '@Components/RadixComponents/ToolTip';
import MapSection from '../MapSection';
import getInputElement from './getInputElement';
import { UseFormPropsType } from '../types';

interface IFormSectionProps {
  schema: formSchemaType;
  formProps: UseFormPropsType;
}

function renderFormGroups(
  data: FormFieldType[],
  formProps: UseFormPropsType,
  dropdownOptions: Record<string, any>,
  _t: Function,
) {
  return (
    <Grid className="naxatw-w-full naxatw-py-4 md:naxatw-w-[60vw]" gap={7}>
      {data.map(child => (
        <FormControl key={child.id} className="naxatw-w-full naxatw-gap-1">
          <FlexRow gap={2} className="naxatw-items-center">
            <Label required={child.required}>{_t(child.label)}</Label>
            <ToolTip
              name="info"
              side="right"
              iconClassName="!naxatw-text-xl"
              message={child.helpMessage}
            />
          </FlexRow>
          {getInputElement(child, { ...formProps }, dropdownOptions, _t)}
          {formProps.errors[child.id] && (
            /* @ts-ignore */
            <ErrorMessage message={formProps.errors[child.id].message} />
          )}
        </FormControl>
      ))}
    </Grid>
  );
}

function FormSection({ schema, formProps }: IFormSectionProps) {
  const { _t } = useTranslation();
  const dropdownOptions = useTypedSelector(
    state => state.dataManagementForm.dropdownOptions,
  );
  const isAssociateDissociateType = useTypedSelector(
    state => state.dataManagementForm.isAssociateDissociateType,
  );
  const existingRoadName = useTypedSelector(
    state => state.dataManagementForm.existingRoadName,
  );

  if (!schema) return <h3>No data</h3>;
  if (schema === 'map') return <MapSection />;
  if (schema === 'table') return <></>;

  const filteredData = schema.filter(data =>
    isAssociateDissociateType ? data.id !== 'grid3' : data,
  );

  return (
    <FlexColumn
      gap={10}
      className="scrollbar naxatw-items-center naxatw-overflow-auto naxatw-pt-5"
    >
      {filteredData.map(data => {
        // watch for road_category change when selecting subsidiary road
        const watchRoadCategory =
          formProps.watch && formProps.watch('road_category');
        const watchRoadName =
          formProps.watch && formProps.watch('road_name_en');

        const {
          id,
          label,
          placeholder,
          required,
          children,
          layout,
          type,
          validationSchema,
          inputType,
          multiple,
          readOnly,
          helpMessage,
        } = data;
        // Use nullish coalescing to handle the possibility of errors being undefined
        const isSubsidiarySelected = watchRoadCategory?.includes('subsidiary');
        const updatedRequired =
          id === 'road_name_en' && isSubsidiarySelected ? false : required;

        // check validation for existing road name
        const validateExistingRoadName =
          id === 'road_name_en' && watchRoadName === existingRoadName;

        // Create a new data object with the updated 'required' property
        const modifiedData = {
          id,
          label,
          required: updatedRequired,
          children,
          type,
          validationSchema: !validateExistingRoadName
            ? validationSchema
            : undefined,
          multiple,
          inputType,
          placeholder,
          readOnly,
          helpMessage,
        };

        // show schema in form group for grid layout
        if (children && layout === 'grid') {
          return (
            <Fragment key={id}>
              {renderFormGroups(children, formProps, dropdownOptions, _t)}
            </Fragment>
          );
        }

        return (
          <FormControl
            key={id}
            className={`${
              layout === 'grid'
                ? 'naxatw-w-full'
                : 'naxatw-w-full md:naxatw-w-[540px]'
            } ${
              type === 'switch'
                ? '!naxatw-flex-row !naxatw-justify-between'
                : ''
            } naxatw-gap-1`}
          >
            <FlexRow gap={2} className="naxatw-items-center">
              <Label required={updatedRequired}>{_t(`${label}`)}</Label>
              <ToolTip
                iconClassName="!naxatw-text-xl"
                name="info"
                side="right"
                message={helpMessage}
              />
            </FlexRow>
            {getInputElement(modifiedData, formProps, dropdownOptions, _t)}
            {formProps.errors[id] && (
              /* @ts-ignore */
              <ErrorMessage message={formProps.errors[id].message} />
            )}
          </FormControl>
        );
      })}
    </FlexColumn>
  );
}

export default hasErrorBoundary(FormSection);
