import { Label, Select } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useTranslation from '@Hooks/useTranslation';
import { basicFilterOptions } from '@Constants/advanceFilter';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import FilterSection from './FilterSection';
import DataVisualizationSection from './DataVisualizationSection';

export default function BasicFilter() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();

  const expandFilter = useTypedSelector(
    state => state.advanceFilter.expandFilter,
  );
  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );

  const handleSelect = (updatedvalue: string) => {
    dispatch(
      setAdvanceFilterState({
        selectedLayerId: updatedvalue,
      }),
    );
  };

  return (
    <FlexColumn gap={5}>
      {!selectedLayerId && (
        <FlexColumn className={`${expandFilter ? 'naxatw-w-1/2' : ''}`}>
          <Label>{_t('What are you interested in')}</Label>
          <Select
            options={basicFilterOptions}
            placeholder="Choose"
            selectedOption={selectedLayerId}
            onChange={handleSelect}
          />
        </FlexColumn>
      )}

      <FilterSection />

      {selectedLayerId && <DataVisualizationSection />}
    </FlexColumn>
  );
}
