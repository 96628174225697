/* eslint-disable no-unused-vars */
import { HTMLInputTypeAttribute } from 'react';
import { AxiosResponse } from 'axios';
import {
  getBuildingUniqueFields,
  getCheckRoadName,
  getRoadUniqueFields,
} from '@Services/dataManagement';
import { FieldValues, Validate } from 'react-hook-form';
import debouncePromise from 'awesome-debounce-promise';

export type FormType = 'building' | 'road';

export const buildingFormModal = {
  association_type: '',
  owner_status: '',
  temporary_type: '',
  owner_name: '',
  tole_name: '',
  reg_type: '',
  floor: '',
  building_structure: '',
  roof_type: '',
  plinth_area: '',
  building_use: '',
  building_sp_use: '',
  road_type: '',
  road_width: null,
  road_lane: '',
  remarks: '',
  geom: null,
  road_id: null,
  main_building_id: null,
  building_images: null,
  building_plans: null,
  associate_road_name: '',
  house_no: '',
  building_id: '',
  ref_centroid: '',
  plot_no: null,
};

export const roadFormModal = {
  road_name_en: '',
  ward_no: [],
  road_category: '',
  road_type: '',
  road_length: '',
  road_width: '',
  road_class: '',
  road_lane: '',
  geom: null,
  remarks: '',
  road_id: '',
};

export type BuildingFormKeys = keyof typeof buildingFormModal;

export type RoadFormKeys = keyof typeof roadFormModal;

export const buildingDataFormIndex: Record<number, string> = {
  0: 'basic',
  1: 'geometry',
  // 2: 'gate',
  2: 'access',
  3: 'attributes',
};

export const roadDataFormIndex: Record<number, string> = {
  0: 'basic',
  1: 'geometry',
  2: 'attributes',
};

type formUIType =
  | 'select'
  | 'multi-select'
  | 'input'
  | 'file-upload'
  | 'switch';

export type FormFieldType = {
  id: string;
  label: string;
  type: formUIType;
  layout?: 'grid';
  inputType?: HTMLInputTypeAttribute;
  placeholder?: string;
  required?: boolean;
  default?: string | number;
  readOnly?: boolean;
  multiple?: boolean;
  fileAccept?: string;
  children?: FormFieldType[];
  validationSchema?:
    | Validate<any, FieldValues>
    | Record<string, Validate<any, FieldValues>>
    | undefined;
  helpMessage?: string;
};

export type formSchemaType = FormFieldType[] | 'map' | 'table';

export const buildingFormSchema: Record<string, formSchemaType> = {
  basic: [
    {
      id: 'association_type',
      label: 'Building Association Type',
      type: 'select',
      placeholder: 'Select Building Association Type',
      required: true,
      helpMessage: 'Building Association Type Description',
    },
    {
      id: 'owner_status',
      label: 'Select Building Ownership',
      type: 'select',
      placeholder: 'Select Building Ownership',
      required: true,
      helpMessage: 'Building owner status',
    },
    {
      id: 'temporary_type',
      label: 'Select Permanency of Building',
      type: 'select',
      placeholder: 'Select Permanency of Building',
      required: true,
      helpMessage: 'Degree of durability and sustainability',
    },
    {
      id: 'owner_name',
      label: 'Owner Name',
      type: 'input',
      placeholder: 'Enter Owner Name',
      required: true,
      helpMessage: 'Owner name',
    },
    {
      id: 'tole_name',
      label: 'Tole Name',
      type: 'input',
      placeholder: 'Enter tole name',
      required: false,
      helpMessage: 'Enter Tole name',
    },
  ],
  geometry: 'map',
  gate: 'map',
  access: 'map',
  attributes: [
    {
      id: 'grid1',
      label: '',
      type: 'select',
      layout: 'grid',
      children: [
        {
          id: 'reg_type',
          label: 'Select Registration Type',
          type: 'select',
          placeholder: 'Select Registration Type',
          required: true,
          helpMessage: 'Select Registration Type',
        },
        {
          id: 'floor',
          label: 'Enter Building Floor',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter no of Building Floor',
          required: true,
          default: 1,
          validationSchema: {
            matchPattern: (v: any) =>
              !v ||
              /^[+]?\d+(\.5+([0]*)?)?$/.test(v) ||
              'Building Floor must in multiple of 0.5.',
          },
          helpMessage: 'Enter Building Floor',
        },
        {
          id: 'building_structure',
          label: 'Select Structure of Building',
          type: 'select',
          placeholder: 'Select Structure of Building',
          required: true,
          helpMessage: 'Select Structure of Building',
        },
        {
          id: 'roof_type',
          label: 'Select Building Roof Type',
          type: 'select',
          placeholder: 'Select Building Roof Type',
          required: true,
          helpMessage: 'Select Building Roof Type',
        },
        {
          id: 'plinth_area',
          label: 'Enter Plinth Area of Building (ft²)',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter Plinth Area of Building',
          required: false,
          validationSchema: {
            matchPattern: (v: any) =>
              !v ||
              /^\d{1,4}(\.\d{1,2})?$/.test(v) ||
              'Plinth area must not be greater than 4 digit.',
          },
          helpMessage: 'Enter Plinth Area of Building (ft²)',
        },
        {
          id: 'plot_no',
          label: 'Enter Building Plot Number',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter Building Plot Number',
          helpMessage: 'Enter Building Plot Number',
        },
      ],
    },
    {
      id: 'grid2',
      label: '',
      type: 'select',
      layout: 'grid',
      children: [
        {
          id: 'building_use',
          label: 'Select Building Use Category',
          type: 'select',
          placeholder: 'Select Building Use Category',
          required: true,
          helpMessage: 'Select Building Use Category',
        },
        {
          id: 'building_sp_use',
          label: 'Select Specific Building Use',
          type: 'multi-select',
          placeholder: 'Select Specific Building Use',
          required: true,
          helpMessage: 'Select Specific Building Use',
        },
      ],
    },
    {
      id: 'grid3',
      label: '',
      type: 'select',
      layout: 'grid',
      children: [
        {
          id: 'road_type',
          label: 'Select Access Road Pavement Type',
          type: 'select',
          placeholder: 'Select Access Road Pavement Type',
          required: true,
          helpMessage: 'Select Access Road Pavement Type',
        },
        {
          id: 'road_width',
          label: 'Enter Access Road Width (m)',
          type: 'input',
          inputType: 'number',
          placeholder: 'Enter Access Road Width',
          required: true,
          validationSchema: {
            matchPattern: (v: any) =>
              !v ||
              /^\d{1,3}(\.\d{1,2})?$/.test(v) ||
              'Access road width must not be greater than 3 digit.',
          },
          helpMessage: 'Enter Access Road Width',
        },
        {
          id: 'road_lane',
          label: 'Select Access Road Lane',
          type: 'select',
          placeholder: 'Select Access Road Lane',
          required: false,
          helpMessage: 'Select Access Road Lane',
        },
      ],
    },

    {
      id: 'remarks',
      label: 'Remarks',
      type: 'input',
      layout: 'grid',
      placeholder: 'Enter Remarks',
      required: false,
      helpMessage: 'Enter Remarks',
    },
    {
      id: 'building_images',
      label: 'Upload Building Picture',
      type: 'file-upload',
      layout: 'grid',
      required: false,
      multiple: true,
      helpMessage: 'Upload Building Picture',
    },
    {
      id: 'building_plans',
      label: 'Upload Building Plan',
      type: 'file-upload',
      layout: 'grid',
      required: false,
      multiple: true,
      helpMessage: 'Upload Building Plan',
    },
  ],
};
export const roadFormSchema: Record<string, formSchemaType> = {
  basic: [
    {
      id: 'road_category',
      label: 'Select Road Category',
      type: 'select',
      placeholder: 'Select Road Category',
      required: true,
      helpMessage: 'Select Road Category',
    },
    {
      id: 'road_name_en',
      label: 'Enter Road Name',
      type: 'input',
      placeholder: 'Enter Road Name',
      required: true,
      validationSchema: debouncePromise(async (value: any, x) => {
        if (!value) return true;
        if (!/^[a-zA-Z][a-zA-Z0-9\-_\s.,$;]+$/.test(value))
          return 'Road name must be alphanumeric.';
        // validate existing road name
        const response = await getCheckRoadName({ road_name: value });
        return response?.data?.includes('already exists')
          ? response?.data
          : true;
      }, 500),
      helpMessage: 'Enter Road Name',
    },
    {
      id: 'road_width',
      label: 'Enter Road Width',
      type: 'input',
      inputType: 'number',
      placeholder: 'Enter Road Width',
      validationSchema: {
        matchPattern: (v: any) =>
          !v ||
          /^\d{1,3}(\.\d{1,2})?$/.test(v) ||
          'Road width must not be greater than 3 digit.',
      },
      required: true,
      helpMessage: 'Enter Road Width',
    },
  ],
  geometry: 'map',
  attributes: [
    {
      id: 'road_class',
      label: 'Select Road Class',
      type: 'select',
      placeholder: 'Select Road Class',
      required: true,
      helpMessage: 'Select Road Class',
    },
    {
      id: 'road_type',
      label: 'Select Road Pavement Type',
      type: 'select',
      placeholder: 'Select Road Pavement Type',
      required: true,
      helpMessage: 'Select Road Pavement Type',
    },
    {
      id: 'road_lane',
      label: 'Select Road Lane',
      type: 'select',
      placeholder: 'Select Road Lane',
      required: true,
      helpMessage: 'Select Road Lane',
    },
    {
      id: 'remarks',
      label: 'Remarks',
      type: 'input',
      placeholder: 'Remarks',
      required: false,
      helpMessage: 'Remarks',
    },
  ],
};

export type FormOptionsRequestDataType = {
  queryKey: string[];
  queryFn: (params: any) => Promise<AxiosResponse<any, any>>;
  params: { field_name: string };
}[];

export const buildingFormOptionsRequestData: FormOptionsRequestDataType = [
  {
    queryKey: ['association-type'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'association_type',
    },
  },
  {
    queryKey: ['building-ownership'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'owner_status',
    },
  },
  {
    queryKey: ['building-permanency'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'temporary_type',
    },
  },
  {
    queryKey: ['tole_name'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'tole_name',
    },
  },

  {
    queryKey: ['registration-type'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'reg_type',
    },
  },
  {
    queryKey: ['building-structure'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'building_structure',
    },
  },
  {
    queryKey: ['roof-type'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'roof_type',
    },
  },
  {
    queryKey: ['building-use-category'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'building_use',
    },
  },
  {
    queryKey: ['specific-building-use'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'building_sp_use',
    },
  },
  {
    queryKey: ['road_pavement-type'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'road_type',
    },
  },
  {
    queryKey: ['access-road-lane'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'road_lane',
    },
  },
];

export const roadFormOptionsRequestData: FormOptionsRequestDataType = [
  {
    queryKey: ['road-category'],
    queryFn: getRoadUniqueFields,
    params: {
      field_name: 'road_category',
    },
  },
  {
    queryKey: ['road-class'],
    queryFn: getRoadUniqueFields,
    params: {
      field_name: 'road_class',
    },
  },

  {
    queryKey: ['road_pavement-type'],
    queryFn: getRoadUniqueFields,
    params: {
      field_name: 'road_type',
    },
  },
  {
    queryKey: ['road-lane'],
    queryFn: getRoadUniqueFields,
    params: {
      field_name: 'road_lane',
    },
  },
];
