import React from 'react';
import { format } from 'date-fns';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  handleAttributeToggle,
  setEditHistoryState,
} from '@Store/actions/editHistory';
import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import UserAvatar from '@Components/common/UserAvatar';
import { togglePromptDialog } from '@Store/actions/common';

interface IHistoryCardProps {
  innerRef?: React.LegacyRef<HTMLDivElement> | undefined;
  data: Record<string, any>[];
}

export default function HistoryCard({ data, innerRef }: IHistoryCardProps) {
  const { username, timestamp, related_id: relatedId } = data[0];
  const dispatch = useTypedDispatch();

  const selectedAttributeIds = useTypedSelector(
    state => state.editHistory.selectedAttributeIds,
  );
  const relatedIdColors = useTypedSelector(
    state => state.editHistory.relatedIdColors,
  );

  return (
    <div ref={innerRef}>
      <FlexColumn
        gap={2}
        className="naxatw-w-full naxatw-rounded-lg naxatw-border-2
       naxatw-border-gray-200 naxatw-px-3 naxatw-py-2 hover:naxatw-border-primary-400"
      >
        <FlexRow gap={2} className="naxatw-items-center">
          <UserAvatar name={username} bgColor={relatedIdColors[relatedId]} />
          <h6 className="naxatw-text-xs naxatw-font-bold">{username || '-'}</h6>
        </FlexRow>
        <p className="naxatw-text-xs">
          {format(new Date(timestamp), 'MMM dd yyyy hh:mm a') || '-'}
        </p>
        <FlexColumn>
          {data.map((item: any) => (
            <Button
              key={item.id}
              leftIcon={
                selectedAttributeIds.includes(item.id)
                  ? 'visibility'
                  : 'visibility_off'
              }
              variant="ghost"
              className="naxatw-h-fit naxatw-w-fit !naxatw-p-0 !naxatw-text-grey-800"
              iconClassname={`${
                selectedAttributeIds.includes(item.id)
                  ? 'naxatw-text-primary-400'
                  : 'naxatw-text-grey-800'
              }`}
              onClick={() => {
                dispatch(handleAttributeToggle(item.id));
              }}
            >
              {item.changes.geom ? 'Geometry' : 'Attribute'}
            </Button>
          ))}
        </FlexColumn>
        <FlexRow className="naxatw-justify-end">
          <Button
            leftIcon="settings_backup_restore"
            variant="ghost"
            className="naxatw-h-fit naxatw-w-fit !naxatw-p-0 disabled:naxatw-text-grey-400"
            iconClassname="disabled:naxatw-text-grey-400"
            onClick={() => {
              dispatch(togglePromptDialog('restore-edit-history'));
              dispatch(setEditHistoryState({ selectedItemsToRestore: data }));
            }}
          >
            Restore
          </Button>
        </FlexRow>
      </FlexColumn>
    </div>
  );
}
