export const buidlingCountColors = ['#ffe28a', '#ffd85c', '#ffc52f'];
export const buildingDensityColors = ['#fff8e3', '#badfff', '#5bb6ff'];

export const buidlingPaintProperty = [
  'step',
  ['get', 'buildings'],
  '#ffe28a',
  5,
  '#ffd85c',
  20,
  '#ffc52f',
];

export const densityPaintProperty = [
  'step',
  ['get', 'density'],
  '#fff8e3',
  5,
  '#badfff',
  20,
  '#5bb6ff',
];

export const numberingStatusPaintProperty = [
  'match',
  ['get', 'numbering_status'],
  'not_started',
  '#fff8e3',
  'ongoing',
  '#badfff',
  'completed',
  '#5bb6ff',
  '#fff',
];

export const style = {
  type: 'fill',
  layout: {},
  paint: {
    'fill-color': buidlingPaintProperty,
    'fill-outline-color': '#000',
  },
};

export const symbolStyle = {
  layout: {
    'text-font': ['Noto Sans Regular'],
    'text-field': ['get', 'ward_no'],
    'text-size': 12,
    'text-anchor': 'center',
  },
  paint: {
    'text-color': 'black',
  },
};

export const selectOptions = [
  'Building Count',
  'Building Density',
  'Numbering Status',
];
