import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '@Components/RadixComponents/Table';
import { Input } from '@Components/common/FormUI';

interface ITableFormSectionProps {
  inputType: 'checkbox' | 'input';
  label: string;
}

export default function TableFormSection({
  inputType,
  label,
}: ITableFormSectionProps) {
  return (
    <div className="naxatw-flex naxatw-w-full naxatw-justify-center ">
      <div className="naxatw-w-full  md:naxatw-w-1/3 xl:naxatw-px-12">
        <p className="naxatw-py-5 naxatw-text-[13.4px]">{label}</p>
        <div style={{ height: 'calc(100vh - 22rem)' }}>
          <Table>
            <TableHeader className="">
              <TableHead className="naxatw-text-left">sn</TableHead>
              {inputType === 'input' ? (
                <>
                  <TableHead className="naxatw-text-left">Name</TableHead>
                  <TableHead className="naxatw-text-left">Alias</TableHead>
                </>
              ) : (
                <>
                  <TableHead className="naxatw-flex naxatw-justify-center">
                    <Input
                      type="checkbox"
                      id="check"
                      className="naxatw-h-6 naxatw-w-6"
                    />
                  </TableHead>
                  <TableHead className="naxatw-text-left">Title</TableHead>
                </>
              )}
            </TableHeader>
            <TableBody>
              {Array.from({ length: 20 })?.map((_, ind) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={`${ind}-'ind'`} className="naxatw-border-b-0">
                  <TableCell className="naxatw-w-1/5">{ind + 1}</TableCell>

                  {inputType === 'input' ? (
                    <>
                      <TableCell className="naxatw-w-2/5">Text</TableCell>
                      <TableCell className="naxatw-w-3/5">
                        <Input />
                      </TableCell>
                    </>
                  ) : (
                    <>
                      <TableCell className="naxatw-flex naxatw-w-full naxatw-justify-center">
                        <Input
                          type="checkbox"
                          id="check"
                          className="naxatw-h-5 naxatw-w-5"
                        />
                      </TableCell>
                      <TableCell className="naxatw-w-3/6">Text</TableCell>
                    </>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </div>
    </div>
  );
}
