import { getBuildingUniqueValues } from '@Services/dataManagement';
import prepareDropdownOptions from '@Utils/prepareDropdownOptions';
import { UseQueryOptions, useQuery } from '@tanstack/react-query';

export const useGetToleNameListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['tole-name-list'],
    queryFn: () =>
      getBuildingUniqueValues({
        field_name: 'tole_name',
      }),
    select: (res: any) => prepareDropdownOptions(res.data),
    ...queryOptions,
  });
};

export const useGetBuildingRegListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['building-type-list'],
    queryFn: () =>
      getBuildingUniqueValues({
        field_name: 'reg_type',
      }),
    select: (res: any) => prepareDropdownOptions(res.data),
    ...queryOptions,
  });
};

export const useGetOwnerStatusListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['owner-status-list'],
    queryFn: () =>
      getBuildingUniqueValues({
        field_name: 'owner_status',
      }),
    select: (res: any) => prepareDropdownOptions(res.data),
    ...queryOptions,
  });
};
