import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface CurtainState {
  openCurtain: boolean;
  expandCurtain: boolean;
}

const initialState: CurtainState = {
  openCurtain: true,
  expandCurtain: false,
};

const setCurtainState: CaseReducer<
  CurtainState,
  PayloadAction<Partial<CurtainState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const curtainSlice = createSlice({
  name: 'curtain',
  initialState,
  reducers: {
    setCurtainState,
  },
});

export { curtainSlice };

export default curtainSlice.reducer;
