import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import { togglePromptDialog } from '@Store/actions/common';
import {
  setBuildingDataState,
  setRoadDataState,
} from '@Store/actions/dataManagement';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';
import { Button } from '@Components/RadixComponents/Button';

interface IMapToolbarProps {
  resetDraw: () => void;
  reverseLineGeometry: () => void;
  drawnFeatureGeojson: null | GeojsonType;
}

export default function MapToolbar({
  resetDraw,
  reverseLineGeometry,
  drawnFeatureGeojson,
}: IMapToolbarProps) {
  const dispatch = useTypedDispatch();
  const attributeType = useTypedSelector(
    state => state.workshopMode.attributeType,
  );
  const fetchedGeojson = useTypedSelector(
    state => state.dataManagementForm.fetchedGeojson,
  );
  const selectedFeature = useTypedSelector(
    state => state.workshopMode.selectedFeature,
  );
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);

  if (!attributeType) return <></>;

  const formType = attributeType?.includes('building') ? 'building' : 'road';

  const isLineSplitted = fetchedGeojson?.features?.length > 1;

  const handleSplit = () => {
    dispatch(
      setDataManagementFormState({
        drawType: attributeType?.includes('road') ? 'split_line_mode' : null,
      }),
    );
  };

  const handleRedraw = () => {
    resetDraw();
    dispatch(
      setDataManagementFormState({
        drawType: attributeType?.includes('building')
          ? 'draw_polygon'
          : 'draw_line_string',
        drawnGeom: null,
        fetchedGeojson: null,
      }),
    );
  };

  const handleReverseRoadDirection = () => {
    reverseLineGeometry();
    dispatch(
      setDataManagementFormState({
        hasGeomChanged: true,
      }),
    );
  };

  const handleDelete = () => {
    if (formType === 'building') {
      dispatch(togglePromptDialog('delete-building-data'));
      dispatch(
        setBuildingDataState({
          selectedBuilding: {
            ...selectedFeature,
            id: selectedFeature?.building_pk,
          },
        }),
      );
    } else {
      dispatch(togglePromptDialog('delete-road-data'));
      dispatch(
        setRoadDataState({
          selectedRoad: { ...selectedFeature, id: selectedFeature?.road_pk },
        }),
      );
    }
  };

  function getContent() {
    if (drawnFeatureGeojson || fetchedGeojson) {
      return (
        <FlexRow className="naxatw-justify-evenly">
          {attributeType === 'edit_road' && (
            <Button
              variant="secondary"
              className="naxatw-capitalize"
              size="sm"
              onClick={handleSplit}
            >
              Split
            </Button>
          )}
          <Button
            variant="secondary"
            className="naxatw-capitalize"
            size="sm"
            onClick={handleRedraw}
          >
            Redraw {formType}
          </Button>
          {formType === 'road' &&
            fetchedGeojson &&
            !isLineSplitted &&
            drawType !== 'split_line_mode' && (
              <Button
                variant="secondary"
                className="naxatw-capitalize"
                size="sm"
                onClick={handleReverseRoadDirection}
              >
                Reverse Direction
              </Button>
            )}

          {!attributeType?.includes('add') && (
            <Button
              title="Delete"
              variant="outline"
              className="naxatw-border-red-500 naxatw-text-red-500"
              rightIcon="delete"
              size="sm"
              onClick={handleDelete}
            >
              Delete
            </Button>
          )}
        </FlexRow>
      );
    }
    return '';
  }

  return (
    <FlexColumn
      className="naxatw-absolute naxatw-right-11 naxatw-top-4 naxatw-z-30 naxatw-animate-fade-left
        naxatw-rounded-lg naxatw-bg-white naxatw-bg-opacity-90 naxatw-px-3 naxatw-py-2
        naxatw-shadow-dark naxatw-shadow-grey-400"
    >
      <FlexColumn>
        <h5>Map Toolbar</h5>

        <FlexColumn>
          <p className="naxatw-text-xs naxatw-font-bold">
            Place your cursor on the map. Drag on the map to draw into desired
            shape.
          </p>
        </FlexColumn>

        <FlexColumn>{getContent()}</FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
}
