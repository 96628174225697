/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { getRoadDataList } from '@Services/dataManagement';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import { togglePromptDialog } from '@Store/actions/common';
import { setRoadDataState } from '@Store/actions/dataManagement';
import DropdownOptions from '@Components/common/DropdownOptions';
import { setVisualizationState } from '@Store/actions/visualization';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { format } from 'date-fns';
import { selectedWardSelector } from '@Store/selector/common';
import { useMemo } from 'react';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';

function TableSection() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();
  const permissions = useTypedSelector(state => state.user.permissions);
  const queryFnParams = useTypedSelector(
    state => state.dataManagement.roadData.queryFnParams,
  );
  const searchInput = useTypedSelector(
    state => state.dataManagement.roadData.searchInput,
  );
  const selectedWards = useTypedSelector(selectedWardSelector);

  const dropdownOptions = [
    {
      id: 'edit',
      icon: 'edit',
      label: 'Edit',
      onClick: (data: any) => {
        navigate(`form/${data.id}`, { state: { redirectPath: pathname } });
      },
    },
    {
      id: 'delete',
      icon: 'delete',
      label: 'Delete',
      onClick: (data: Record<string, any>[]) => {
        dispatch(togglePromptDialog('delete-road-data'));
        dispatch(
          setRoadDataState({
            selectedRoad: data,
          }),
        );
      },
      iconClassName: 'naxatw-text-red-400',
    },
  ];

  const roadDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Road Name',
      accessorKey: 'road_name_en',
    },
    {
      header: 'Road ID',
      accessorKey: 'road_id',
    },
    {
      header: 'Road Type',
      accessorKey: 'road_type',
    },
    {
      header: 'Ward No.',
      accessorKey: 'ward_no',
      cell: ({ row }: any) => {
        const ward = row?.original?.ward_no;
        const sortedWard = ward.slice().sort((a: number, b: number) => a - b);
        const wardNumber = Array.isArray(ward)
          ? `ward ${sortedWard?.join(', ')}`
          : ward;
        return <p>{wardNumber}</p>;
      },
    },
    {
      header: 'Last Modified',
      accessorKey: 'updated_date',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm: a');
      },
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="naxatw-cursor-pointer naxatw-justify-evenly">
          <ToolTip
            name="info"
            message="More info"
            onClick={() => {
              navigate(`/visualization/road-detail/${row.original.id}`);
            }}
          />
          {permissions.includes('view_road') && (
            <ToolTip
              name="map"
              message="View on map"
              onClick={() => {
                dispatch(
                  setVisualizationState({
                    selectedFeature: { ...row.original, layer: 'road' },
                  }),
                );
                navigate(`/visualization`);
              }}
            />
          )}
          {(permissions.includes('change_road') ||
            permissions.includes('delete_road')) && (
            <DropdownOptions
              options={
                !permissions.includes('change_road')
                  ? dropdownOptions.filter(item => item.id !== 'edit')
                  : !permissions.includes('delete_road')
                    ? dropdownOptions.filter(item => item.id !== 'delete')
                    : dropdownOptions
              }
              data={row.original}
            />
          )}
        </FlexRow>
      ),
    },
  ];

  const isFilterApplied =
    !!queryFnParams.ward_no?.length || !!queryFnParams.road_type?.length;

  // to apply ward filter for user permisssion
  const modifiedQueryFnParams = useMemo(
    () =>
      queryFnParams.ward_no?.length
        ? queryFnParams
        : { ...queryFnParams, ward_no: selectedWards },
    [queryFnParams, selectedWards],
  );

  return (
    <DataTable
      columns={roadDataColumns}
      queryKey="road-data-list"
      queryFn={getRoadDataList}
      queryFnParams={modifiedQueryFnParams}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          width <= 390
            ? '60vh'
            : isFilterApplied
              ? 'calc(100vh - 15rem)'
              : 'calc(100vh - 11.5rem)',
      }}
      useQueryOptions={{ enabled: !!selectedWards }}
    />
  );
}

export default hasErrorBoundary(TableSection);
