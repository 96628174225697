/* eslint-disable import/prefer-default-export */

import { overlayLayersSlice } from '@Store/slices/overlayLayers';

export const {
  getVectorLayerRequest,
  getVectorLayerSuccess,
  getVectorLayerFailure,
  setLayerProperty,
  addNewZoomLevel,
  updateZoomLevel,
  postVectorLayerRequest,
  postVectorLayerSuccess,
  postVectorLayerFailure,
  setLayerState,
  setSubLayerProperty,
  updateLabelKeyOnLayer,
  removeZoomLevel,
  getLayerAttributeRequest,
  getLayerAttributeSuccess,
  getLayerAttributeFailure,
} = overlayLayersSlice.actions;
