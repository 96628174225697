/* eslint-disable import/prefer-default-export */
export const orientationOptions: Record<string, number | string>[] = [
  {
    id: 1,
    label: 'Landscape',
    value: 'landscape',
  },
  {
    id: 2,
    label: 'Potrait',
    value: 'portrait',
  },
];

export const pageSizeOptions: Record<string, number | string>[] = [
  {
    id: 1,
    label: 'A4',
    value: 'a4',
  },
  {
    id: 2,
    label: 'A3',
    value: 'a3',
  },
  {
    id: 3,
    label: 'A2',
    value: 'a2',
  },
  {
    id: 4,
    label: 'A1',
    value: 'a1',
  },
];

export const paperSize: Record<string, number[]> = {
  a1: [594, 841],
  a2: [420, 594],
  a3: [297, 420],
  a4: [210, 297],
};

export const outputFormatOptions: Record<string, number | string>[] = [
  {
    id: 1,
    label: 'PDF',
    value: 'pdf',
  },
  {
    id: 2,
    label: 'JPEG',
    value: 'jpeg',
  },
  {
    id: 3,
    label: 'PNG',
    value: 'png',
  },
  {
    id: 4,
    label: 'Print',
    value: 'print',
  },
];

export const marginOptions: Record<string, string | number>[] = [
  {
    id: 1,
    label: 'None',
    value: '0',
  },
  {
    id: 2,
    label: 'Minimum',
    value: '5',
  },
  {
    id: 3,
    label: 'Custom',
    value: 'custom',
  },
];

export const routeDropDownData: Record<string, string | number>[] = [
  {
    id: 'places',
    name: 'Search by Places',
  },
  {
    id: 'buildings',
    name: 'Search by Buildings',
  },
  {
    id: 'your location',
    name: 'Search by Your Location',
  },
  {
    id: 'draw',
    name: 'Draw on Map',
  },
];
