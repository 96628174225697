import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { togglePromptDialog } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import {
  postFormDataRequest,
  setDataManagementFormState,
  updateFormDataRequest,
} from '@Store/actions/dataManagementForm';
import checkIfLoading from '@Utils/checkIfLoading';

export default function ConfirmationPrompt() {
  const dispatch = useTypedDispatch();

  const handleContinue = () => {
    dispatch(setDataManagementFormState({ isSubmitClicked: true }));
  };

  const isLoading = useTypedSelector(state =>
    checkIfLoading(state, [
      postFormDataRequest.type,
      updateFormDataRequest.type,
    ]),
  );

  return (
    <FlexColumn gap={2} className="naxatw-text-sm">
      <p className="naxatw-text-grey-600">
        Some of the fields are empty, are you sure want to continue ?
      </p>
      <FlexRow className=" naxatw-w-full naxatw-justify-end naxatw-pt-5">
        <Button variant="ghost" onClick={() => dispatch(togglePromptDialog())}>
          Keep Editing
        </Button>
        <Button
          rightIcon="chevron_right"
          withLoader
          isLoading={isLoading}
          onClick={handleContinue}
        >
          Submit Anyways
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
