import { RefObject } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { sidebarSections, SidebarSectionKeys } from '@Constants/publicPage';
import { setActiveTab } from '@Store/actions/publicPage';
import useTranslation from '@Hooks/useTranslation';
import './style.css';

type SectionRefs = {
  [sectionName: string]: RefObject<HTMLElement>;
};

interface ISidebarSectionProps {
  refs: SectionRefs;
}

export default function SidebarSection({ refs }: ISidebarSectionProps) {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const activeTab = useTypedSelector(state => state.publicPage.activeTab);

  const handleActiveTab = (tabId: SidebarSectionKeys) => {
    dispatch(setActiveTab(tabId));
    // @ts-ignore
    const element = refs.current[tabId];
    const headerOffset = 75;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  return (
    <FlexColumn className="naxatw-top-16 naxatw-hidden naxatw-h-[86vh] naxatw-w-[375px] md:naxatw-sticky md:naxatw-block">
      {Object.entries(sidebarSections).map(([id, { name, icon }]) => {
        const isActive = activeTab === id;
        return (
          <FlexRow
            key={id}
            gap={2.5}
            className={`${
              isActive
                ? 'naxatw-rounded naxatw-bg-primary-400 naxatw-text-white'
                : ''
            }   naxatw-group naxatw-cursor-pointer naxatw-items-center 
            naxatw-px-5 naxatw-py-4 naxatw-text-grey-600`}
            // @ts-ignore
            onClick={() => handleActiveTab(id)}
          >
            <span className={`${isActive ? 'svg-active' : ''} `}>{icon}</span>
            <p
              className={`${
                isActive ? '!naxatw-text-white' : ''
              } naxatw-text-base naxatw-font-bold naxatw-capitalize
              naxatw-text-grey-600 group-hover:naxatw-text-primary-400`}
            >
              {_t(name)}
            </p>
          </FlexRow>
        );
      })}
    </FlexColumn>
  );
}
