import { Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { featureCollection } from '@turf/helpers';
import getInputElement from '@Components/DataManagement/MultiStepForm/FormSection/getInputElement';
import { Button } from '@Components/RadixComponents/Button';
import ErrorMessage from '@Components/common/ErrorMessage';
import { FormControl, Label } from '@Components/common/FormUI';
import Icon from '@Components/common/Icon';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { buildingDataFields, roadDataFields } from '@Constants/workshopMode';
import {
  getBuildingDataByIdRequest,
  getDropdownOptionsRequest,
  getRoadDataByIdRequest,
  postFormDataRequest,
  resetDataManagementFormState,
  setDataManagementFormState,
  updateFormDataRequest,
} from '@Store/actions/dataManagementForm';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  buildingFormModal,
  buildingFormOptionsRequestData,
  roadFormModal,
  roadFormOptionsRequestData,
} from '@Constants/dataManagementForm';
import checkIfLoading from '@Utils/checkIfLoading';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { FormStateType } from '@Store/slices/dataManagementForm';
import { getDirtyValues } from '@Components/UserManagement/ModalContent/AddUser';
import AttributeSkeleton from './AttributeSkeleton';

export default function AttributeSection() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const dropdownOptions = useTypedSelector(
    state => state.dataManagementForm.dropdownOptions,
  );
  const selectedFeature = useTypedSelector(
    state => state.workshopMode.selectedFeature,
  );
  const formState = useTypedSelector(
    state => state.dataManagementForm.formState,
  );
  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const selectedRoadId = useTypedSelector(
    state => state.dataManagementForm.selectedRoadId,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.dataManagementForm.selectedBuildingId,
  );
  const attributeType = useTypedSelector(
    state => state.workshopMode.attributeType,
  );
  const isLoading = useTypedSelector(state =>
    checkIfLoading(state, [
      getBuildingDataByIdRequest.type,
      getRoadDataByIdRequest.type,
      getDropdownOptionsRequest.type,
    ]),
  );
  const hasGeomChanged = useTypedSelector(
    state => state.dataManagementForm.hasGeomChanged,
  );
  const isAssociateDissociateType = useTypedSelector(
    state => state.dataManagementForm.isAssociateDissociateType,
  );
  const hasSelectedRoadIdChanged = useTypedSelector(
    state => state.dataManagementForm.hasSelectedRoadIdChanged,
  );
  const hasSelectedBuildingIdChanged = useTypedSelector(
    state => state.dataManagementForm.hasSelectedBuildingIdChanged,
  );
  const existingRoadName = useTypedSelector(
    state => state.dataManagementForm.existingRoadName,
  );

  const filteredBuildingDataFields = buildingDataFields.filter(data =>
    isAssociateDissociateType ? !data.id.includes('road') : data,
  );

  const formSchema = attributeType?.includes('building')
    ? filteredBuildingDataFields
    : roadDataFields;

  const formId =
    attributeType === 'edit_building'
      ? selectedFeature?.building_pk
      : selectedFeature?.road_pk;

  const formMode: 'add' | 'edit' = !formId ? 'add' : 'edit';

  const formType = attributeType?.includes('building') ? 'building' : 'road';

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    register,
    getValues,
    reset,
    control,
    setValue,
    watch,
  } = useForm({ mode: 'all' });

  const formProps = { register, control, errors, setValue, getValues };

  useEffect(() => {
    const isDataFetched = Object.values(formState).some(val => !!val);
    if (!isDataFetched) return;
    reset(formState);
  }, [formState, reset]);

  useEffect(() => {
    dispatch(
      getDropdownOptionsRequest(
        attributeType?.includes('building')
          ? buildingFormOptionsRequestData
          : roadFormOptionsRequestData,
      ),
    );
  }, [dispatch, attributeType]);

  useEffect(() => {
    if (!formId) return;
    if (attributeType === 'edit_building') {
      dispatch(getBuildingDataByIdRequest(formId));
    } else {
      dispatch(getRoadDataByIdRequest(formId));
    }
  }, [dispatch, formId, attributeType]);

  useEffect(() => {
    dispatch(
      setDataManagementFormState({
        formState:
          attributeType === 'edit_building' ? buildingFormModal : roadFormModal,
      }),
    );
  }, [dispatch, attributeType]);

  // watch for association_type change
  const currentAssociationType = watch('association_type');

  // set isAssociateDissociateType to true for associate/dissociate type
  useEffect(() => {
    if (!currentAssociationType) return;
    let states: any = {
      isAssociateDissociateType: ['associate', 'dissociate'].includes(
        currentAssociationType,
      ),
    };
    // reset selected states on association_type change
    if (currentAssociationType !== formState?.association_type) {
      states = {
        ...states,
        selectedRoadId: null,
        selectedBuildingId: null,
        hasSelectedRoadIdChanged: false,
        hasSelectedBuildingIdChanged: false,
        enableRoadSelect: false,
        enableBuildingSelect: false,
      };
    }
    dispatch(setDataManagementFormState({ ...states }));
  }, [dispatch, currentAssociationType, formState]);

  // reset dataManagementForm state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetDataManagementFormState());
    };
  }, [dispatch]);

  const onSubmit = (values: FormStateType) => {
    if (!formType) return;
    if (formMode === 'edit' && formId) {
      const payloadData: any = getDirtyValues(dirtyFields, getValues());
      if (hasGeomChanged && drawnGeom) {
        // check if the selected road is splitted
        const isLineSplitted = drawnGeom.features.length > 1;

        if (isLineSplitted) {
          // patch longest splitted line
          const { features } = drawnGeom;
          let longestLineFeature = features[0];
          if (!longestLineFeature) return;
          features.forEach((feature: Record<string, any>) => {
            if (
              feature?.properties?.length >
              longestLineFeature?.properties?.length
            ) {
              longestLineFeature = feature;
            }
          });
          payloadData.geom = featureCollection([longestLineFeature]);
          // post remaining splitted line features
          const otherLineFeatures = drawnGeom.features.filter(
            (feature: Record<string, any>) =>
              feature?.properties?.id !== longestLineFeature?.properties?.id,
          );

          // @ts-ignore
          otherLineFeatures.forEach((feature, index) => {
            const payload: any = {
              ...values,
              geom: featureCollection([feature]),
              road_name_en: `${formState.road_name_en} - ${index + 1}`,
            };
            if (selectedRoadId) {
              payloadData.road_id = selectedRoadId;
            }
            if (selectedBuildingId) {
              payloadData.main_building_id = selectedBuildingId;
            }
            dispatch(
              postFormDataRequest({
                formType,
                data: payload,
                navigate,
                pathname,
              }),
            );
          });
        } else {
          payloadData.geom = drawnGeom;
        }
      }
      if (hasSelectedRoadIdChanged) {
        payloadData.road_id = selectedRoadId;
      }
      if (hasSelectedBuildingIdChanged) {
        payloadData.main_building_id = selectedBuildingId;
      }
      dispatch(
        updateFormDataRequest({
          formType,
          formId,
          data: payloadData,
          navigate,
          pathname,
        }),
      );
    } else {
      const payloadData: any = {
        ...values,
        geom: drawnGeom,
      };
      if (selectedRoadId) {
        payloadData.road_id = selectedRoadId;
      }
      if (selectedBuildingId) {
        payloadData.main_building_id = selectedBuildingId;
      }
      dispatch(
        postFormDataRequest({
          formType,
          data: payloadData,
          navigate,
          pathname,
        }),
      );
    }
  };

  function getTitle() {
    if (formMode === 'add') {
      return `Add ${formType}`;
    }
    if (!formState) return '';
    return (
      `${formState?.house_no || null} - ${formState?.associate_road_name}` ||
      formState?.road_name_en
    );
  }

  const handleSelectRoadToggle = () => {
    dispatch(setDataManagementFormState({ enableRoadSelect: true }));
  };

  const handleRoadReselect = () => {
    dispatch(
      setDataManagementFormState({
        enableRoadSelect: true,
        selectedRoadId: null,
      }),
    );
  };

  const handleSelectBuildingToggle = () => {
    dispatch(setDataManagementFormState({ enableBuildingSelect: true }));
  };

  const handleBuildingReselect = () => {
    dispatch(
      setDataManagementFormState({
        enableBuildingSelect: true,
        selectedBuildingId: null,
      }),
    );
  };

  const handleClose = () => {
    dispatch(
      setWorkshopModeState({
        selectedFeature: null,
        attributeType: null,
      }),
    );
    dispatch(resetDataManagementFormState());
  };

  const showAttributeSection = !!attributeType;

  if (isLoading && showAttributeSection) {
    return <AttributeSkeleton />;
  }

  return (
    <FlexColumn
      gap={5}
      className={`${
        showAttributeSection ? '' : 'naxatw-hidden'
      }  naxatw-z-10 naxatw-h-screen naxatw-w-[25rem] naxatw-animate-fade-right naxatw-border-r-2 naxatw-px-4
      naxatw-py-5 naxatw-shadow-[0_4px_11px_0_#00000040] naxatw-duration-300`}
    >
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <h5 className="naxatw-capitalize">{getTitle()}</h5>
        <Icon
          name="close"
          className="hover:naxatw-text-primary-400"
          onClick={handleClose}
        />
      </FlexRow>

      <FlexColumn
        gap={5}
        className="scrollbar naxatw-h-[90vh] naxatw-items-center
         naxatw-overflow-y-auto"
      >
        {formSchema.map(data => {
          const {
            id,
            label,
            required,
            children,
            type,
            validationSchema,
            multiple,
            inputType,
            placeholder,
            readOnly,
          } = data;
          const watchRoadName = watch && watch('road_name_en');

          // check validation for existing road name
          const validateExistingRoadName =
            id === 'road_name_en' && watchRoadName === existingRoadName;

          // Create a new data object with the updated 'required' property
          const modifiedData = {
            id,
            label,
            required,
            type,
            children,
            validationSchema: !validateExistingRoadName
              ? validationSchema
              : undefined,
            multiple,
            inputType,
            placeholder,
            readOnly,
          };

          return (
            <Fragment key={id}>
              <FormControl className="naxatw-w-full">
                <Label required={required}>{label}</Label>
                {getInputElement(modifiedData, formProps, dropdownOptions)}
                {formProps.errors[id] && (
                  /* @ts-ignore */
                  <ErrorMessage message={formProps.errors[id].message} />
                )}
              </FormControl>
              {id === 'association_type' &&
                getValues()?.association_type &&
                (!isAssociateDissociateType ? (
                  <FormControl className="naxatw-w-full">
                    <Label required={required}>Access Road</Label>
                    <div className="naxatw-mt-2">
                      <Button
                        variant={selectedRoadId ? 'outline' : 'default'}
                        onClick={
                          selectedRoadId
                            ? handleRoadReselect
                            : handleSelectRoadToggle
                        }
                      >
                        {selectedRoadId ? 'Reselect' : 'Select Road'}
                      </Button>
                    </div>
                  </FormControl>
                ) : (
                  <FormControl className="naxatw-w-full">
                    <Label required={required}>Select Main Building</Label>
                    <div className="naxatw-mt-2">
                      <Button
                        variant={selectedRoadId ? 'outline' : 'default'}
                        onClick={
                          selectedBuildingId
                            ? handleBuildingReselect
                            : handleSelectBuildingToggle
                        }
                      >
                        {selectedBuildingId ? 'Reselect' : 'Select Building'}
                      </Button>
                    </div>
                  </FormControl>
                ))}
            </Fragment>
          );
        })}
      </FlexColumn>
      <FlexRow className="naxatw-w-full naxatw-justify-center" gap={4}>
        <Button variant="outline" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
          className="naxatw-w-16"
        >
          Save
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
