import { CaseReducer, PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface PhysicalInstallationState {
  searchInput: string;
  selectedInstallation: Record<string, any> | null;
}

const initialState: PhysicalInstallationState = {
  searchInput: '',
  selectedInstallation: null,
};

const setPhysicalInstallationState: CaseReducer<
  PhysicalInstallationState,
  PayloadAction<Partial<PhysicalInstallationState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const physicalInstallationSlice = createSlice({
  name: 'physicalInstallation',
  initialState,
  reducers: {
    setPhysicalInstallationState,
  },
});

export { physicalInstallationSlice };

export default physicalInstallationSlice.reducer;
