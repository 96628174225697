import { useMemo } from 'react';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn, Grid } from '@Components/common/Layouts';
import { Label } from '@radix-ui/react-label';
import RangeSlider from '@Components/RadixComponents/RangeSlider';
import { Input } from '@Components/common/FormUI';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import {
  getAllStyles,
  getZoomBasedSubLayerValues,
  getZoomBasedValues,
} from '../../helper';
import { styleChangeHandlerType } from '..';

interface ILabelStyleController {
  minZoomValue: number;
  styleChangeHandler: styleChangeHandlerType;
}

function LabelStyleController({
  minZoomValue,
  styleChangeHandler,
}: ILabelStyleController) {
  const layerStyles = useTypedSelector(state => state.overlayLayer.layerStyles);
  const subLayerStyles = useTypedSelector(
    state => state.overlayLayer.subLayerStyle,
  );
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const selectedSubLayer = useTypedSelector(
    state => state.overlayLayer.selectedSubLayer,
  );

  const layerAllStyles = useMemo(
    () => getZoomBasedValues(minZoomValue, getAllStyles(layerStyles)),
    [layerStyles, minZoomValue],
  );

  const subLayerAllStyles = useMemo(
    () =>
      selectedSubLayer &&
      getZoomBasedSubLayerValues(
        minZoomValue,
        selectedSubLayer,
        getAllStyles(subLayerStyles),
      ),
    [subLayerStyles, selectedSubLayer, minZoomValue],
  );

  const allStyles: Record<string, any> = useMemo(
    () =>
      isActiveSubLayer && selectedSubLayer
        ? subLayerAllStyles || {}
        : layerAllStyles || {},
    [isActiveSubLayer, selectedSubLayer, subLayerAllStyles, layerAllStyles],
  );

  return (
    <>
      <FlexColumn className="naxatw-col-span-2  naxatw-gap-4 naxatw-py-4">
        <Label className="naxatw-text-sm">Text Opacity</Label>
        <RangeSlider
          className=""
          defaultValue={[20]}
          max={100}
          step={1}
          value={[allStyles['text-opacity'] * 100]}
          onValueChange={value =>
            styleChangeHandler(
              minZoomValue,
              'symbol',
              'text-opacity',
              value[0] / 100,
            )
          }
        />
      </FlexColumn>
      <FlexColumn className="naxatw-col-span-2">
        <Label className="naxatw-text-sm">Font Size</Label>
        <Input
          type="number"
          value={allStyles['text-size']}
          min={0}
          placeholder="font size"
          onChange={e =>
            styleChangeHandler(
              minZoomValue,
              'symbol',
              'text-size',
              Number(e.target.value),
            )
          }
        />
      </FlexColumn>
      <Grid gap={3} className="naxatw-py-4">
        <FlexColumn>
          <Label className="naxatw-text-sm">Halo</Label>
          <Input
            type="number"
            placeholder="halo"
            min={0}
            max={8}
            value={allStyles['text-halo-width']}
            onChange={e =>
              styleChangeHandler(
                minZoomValue,
                'symbol',
                'text-halo-width',
                Number(e.target.value),
              )
            }
          />
        </FlexColumn>

        <Grid gap={3}>
          <FlexColumn className="naxatw-items-center naxatw-justify-center naxatw-gap-3">
            <p className="naxatw-text-center naxatw-text-sm">Halo Color</p>
            <input
              type="color"
              className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
              value={allStyles['text-halo-color']}
              onChange={e =>
                styleChangeHandler(
                  minZoomValue,
                  'symbol',
                  'text-halo-color',
                  e.target.value,
                )
              }
            />
          </FlexColumn>
          <FlexColumn className="naxatw-items-center naxatw-justify-center naxatw-gap-3">
            <p className="naxatw-text-center naxatw-text-sm">Font Color</p>
            <input
              type="color"
              className="naxatw-h-7 naxatw-w-7 naxatw-cursor-pointer"
              value={allStyles['text-color']}
              onChange={e =>
                styleChangeHandler(
                  minZoomValue,
                  'symbol',
                  'text-color',
                  e.target.value,
                )
              }
            />
          </FlexColumn>
        </Grid>
      </Grid>
      <Grid gap={3} className="naxatw-py-4">
        <FlexColumn>
          <Label className="naxatw-text-sm">Text Offset X</Label>
          <Input
            type="number"
            placeholder="X"
            value={allStyles['text-offset'][1][0]}
            onChange={e => {
              // @ts-ignore
              styleChangeHandler(minZoomValue, 'symbol', 'text-offset', [
                'literal',
                [Number(e.target.value), allStyles['text-offset'][1][1]],
              ]);
            }}
          />
        </FlexColumn>
        <FlexColumn>
          <Label className="naxatw-text-sm">Text Offset Y</Label>
          <Input
            type="number"
            placeholder="Y"
            value={allStyles['text-offset'][1][1]}
            onChange={e => {
              // @ts-ignore
              styleChangeHandler(minZoomValue, 'symbol', 'text-offset', [
                'literal',
                [allStyles['text-offset'][1][0], Number(e.target.value)],
              ]);
            }}
          />
        </FlexColumn>
      </Grid>
    </>
  );
}

export default hasErrorBoundary(LabelStyleController);
