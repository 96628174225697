/* eslint-disable import/prefer-default-export */

/**
 * prepares data by sorting it based on the 'value' property in descending order,
 * filters out items with a 'value' below the specified limit (excluding 'other'), and grouping
 * the remaining items. If there are items below the limit, a summary 'others' category is created
 * to represent their cumulative value.
 *
 * @param {Record<string, any>[]} data - input data array containing objects with 'name' and 'value' properties.
 * @param {number} limit -threshold value used to filter items.
 * @returns {Record<string, any>[]} - array of processed data, with an optional 'others' category if there are items below the limit.
 */
export function prepareDataWithLimit(
  data: Record<string, any>[],
  limit: number,
) {
  const sortedData = data.sort((a, b) => b.value - a.value);

  let othersSumx = 0;
  const otherElimination = sortedData.reduce(
    (prev: Record<string, any>[], curr: Record<string, any>, index: number) => {
      if (curr.value >= limit && curr.name !== 'Other') return [...prev, curr];

      if (index < sortedData.length - 1) {
        othersSumx += curr.value;
        return prev;
      }
      if (index === sortedData.length - 1)
        return [...prev, { name: 'Others', value: othersSumx + curr.value }];
      return prev;
    },
    [],
  );

  return otherElimination;
}
