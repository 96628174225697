/* eslint-disable no-param-reassign */
import { QueryClient } from '@tanstack/react-query';
import { produce } from 'immer';

export async function handleBuildingTableDelete(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['building-data-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    draft.data.results = draft.data.results.filter(
      (item: any) => item.id !== payloadData.id,
    );
  });
  queryClient.setQueryData(keys, updatedData);
}

export async function handleRoadTableDelete(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['road-data-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    draft.data.results = draft.data.results.filter(
      (item: any) => item.id !== payloadData.id,
    );
  });
  queryClient.setQueryData(keys, updatedData);
}
