import { useTypedDispatch } from '@Store/hooks';
import { FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';
import { setMapExportState } from '@Store/actions/mapExport';

export default function MapExportHeader() {
  const dispatch = useTypedDispatch();
  return (
    <FlexRow className="naxatw-w-full naxatw-justify-between naxatw-p-4">
      <h3 className="naxatw-tracking-wider">Map Export</h3>
      <Icon
        name="close"
        className="naxatw-z-50 hover:naxatw-text-primary-400 "
        onClick={() => dispatch(setMapExportState({ isExportOn: false }))}
      />
    </FlexRow>
  );
}
