/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import React, { useState, ChangeEventHandler } from 'react';
import { Input } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import Icon from '@Components/common/Icon';

interface ISearchInputProps {
  data: Record<string, any>;
  inputValue: string;
  selectedValue?: Record<string, any> | null;
  className?: string;
  isOpen: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSelect?: (item: Record<string, any>) => void;
  hasIcon?: boolean;
}

export default function OSMSearch({
  data,
  inputValue,
  selectedValue,
  onChange,
  onSelect,
  isOpen,
  className,
  hasIcon = true,
}: ISearchInputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);

  let placeHolderText: string;

  return (
    <FlexRow
      className={`${className} naxatw-group naxatw-relative naxatw-h-9 naxatw-w-[300px] naxatw-items-center
       naxatw-justify-between naxatw-rounded-lg naxatw-bg-white naxatw-ring-[1px] naxatw-ring-grey-300
        hover:naxatw-ring-primary-400 ${
          isInputFocused ? 'naxatw-ring-primary-400' : ''
        }`}
    >
      <FlexRow className="naxatw-items-center">
        {hasIcon && (
          <Icon
            name="search"
            className={`naxatw-mt-1 naxatw-pl-3 naxatw-text-grey-800 group-hover:naxatw-text-primary-400  ${
              isInputFocused ? 'naxatw-text-primary-400' : ''
            }`}
          />
        )}

        <Input
          type="text"
          className={`naxatw-h-9 naxatw-border-none naxatw-pl-2 focus:placeholder:naxatw-text-grey-400 ${
            selectedValue ? 'placeholder:naxatw-text-grey-800' : ''
          }`}
          value={inputValue}
          placeholder={
            isInputFocused
              ? ''
              : selectedValue && selectedValue.label
                ? selectedValue.label
                : 'Search By Places'
          }
          onChange={onChange}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </FlexRow>
      {isOpen && (
        <ul
          className="scrollbar naxatw-absolute naxatw-top-10 naxatw-max-h-[10.5rem] 
          naxatw-w-full naxatw-animate-flip-down naxatw-overflow-y-auto naxatw-bg-white"
        >
          {data?.results?.map((item: Record<string, any>) => (
            <li
              key={item.id}
              className="naxatw-cursor-pointer naxatw-border-b-[0.5px] naxatw-border-grey-300 naxatw-px-4 
                naxatw-py-3 focus:naxatw-bg-primary-50"
              role="presentation"
              onKeyDown={() => {}}
              onClick={() => {
                onSelect?.(item);
              }}
            >
              <FlexColumn>
                <FlexColumn className="naxatw-mt-2">
                  <p className="naxatw-text-xs naxatw-font-bold naxatw-leading-4 naxatw-text-primary-400">
                    {item?.label}
                  </p>
                </FlexColumn>
              </FlexColumn>
            </li>
          ))}
        </ul>
      )}
    </FlexRow>
  );
}
