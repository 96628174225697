/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import capitalizeFirstLetter from '@Utils/capitalizeFirstLetter';
import isEmpty from '@Utils/isEmpty';
// import { popupExceptionKeys } from '@src/constants/map';

const exceptions: string[] = [];

interface IPopupUIProps {
  data: Record<string, any> | null;
}

export default function PopupUI({ data = {} }: IPopupUIProps) {
  const popupData: Record<string, any> = Object.keys(data || {}).reduce(
    (obj, key) => {
      const name = capitalizeFirstLetter(key);
      const exceptionKeys = [...exceptions];
      const value = data?.[key];
      // if (typeof value === 'object') {
      //   return { ...obj, [name]: JSON.stringify(value) };
      // }

      if (key === 'submitted_date') {
        return {
          ...obj,
          // [name]: format(date, ['MMM do yyyy, h:mm a'])
        };
      }
      if (exceptionKeys.includes(key)) {
        return { ...obj };
      }
      return { ...obj, [name]: value };
    },
    {},
  );

  return (
    <ul
      className="scrollbar naxatw-flex naxatw-h-[12.5rem] naxatw-flex-col 
    naxatw-overflow-y-auto naxatw-border-y-[1px] naxatw-border-y-grey-500 naxatw-text-grey-800"
    >
      {popupData &&
        Object.keys(popupData).map(key => {
          const value = popupData[key];

          const valueType =
            typeof value === 'string'
              ? popupData[key]?.toString()
              : typeof value === 'number' &&
                  key !== 'Floor' &&
                  !Number.isNaN(value) &&
                  !Number.isInteger(value)
                ? value?.toFixed(3)
                : Array.isArray(value)
                  ? value.join(', ')
                  : typeof value === 'object' && !isEmpty(value)
                    ? `[${value.map((val: number) => val.toString()).join(', ')}]`
                    : value;

          return (
            <li
              key={key}
              className="naxatw-flex naxatw-items-center naxatw-py-1.5 
              naxatw-text-body-sm naxatw-font-bold odd:naxatw-bg-grey-100"
            >
              <p className="naxatw-w-1/2">{key}</p>
              {key === 'Road Length' ? (
                `${valueType} ${valueType !== 'null' ? 'm' : ''}`
              ) : key === 'Plus Code' ? (
                // if the field name is Plus Code then dynamically lead to google maps when clicked on the value
                <a
                  href={`https://www.google.com/maps/place/${
                    // eg. valueType = 7MR7PWJ6+9F
                    valueType.split('+')[0]
                  }%2B${valueType.split('+')[1]}`}
                  target="_blank"
                  rel="noreferrer"
                  className="naxatw-w-1/2 naxatw-font-primary naxatw-text-primary-500"
                >
                  {valueType || '-'}
                </a>
              ) : (
                <p className="naxatw-w-1/2">{valueType || '-'}</p>
              )}
            </li>
          );
        })}
    </ul>
  );
}
