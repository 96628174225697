import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getFeatureCount, getPhysicalInstallation } from '@Services/dashboard';
import { Flex } from '@Components/common/Layouts';
import DataCard from '@Components/common/DataCard';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useTypedSelector } from '@Store/hooks';
import StackedChart from '@Components/common/Charts/StackedChart';
import ChartHeader from '@Components/common/Charts/ChartHeader';
import { selectedWardSelector } from '@Store/selector/common';
import useTranslation from '@Hooks/useTranslation';

const installationData = [
  { name: 'installed', color: '#0664D2' },
  { name: 'remaining', color: '#42b4a2' },
];

export default function DataCardSection() {
  const { _t } = useTranslation();
  const [featureCountData, setFeatureCountData] = useState([
    { id: 'building_count', title: 'Buildings', count: 0, iconName: 'home' },
    {
      id: 'road_count',
      title: 'Road Sections',
      count: 0,
      iconName: 'add_road',
    },
    {
      id: 'built_up_area_sq_km',
      title: 'Built-up area &nbsp;(<p>km<sup>2</sup></p>)',
      count: 0,
      iconName: 'apartment',
    },
  ]);

  const selectedWards = useTypedSelector(selectedWardSelector);

  const updateFeatureCountData = (res: any) => {
    setFeatureCountData(prev =>
      prev.map(item => ({
        ...item,
        count:
          res?.data[item.id] !== undefined ? res.data[item.id] : item.count,
      })),
    );
  };

  const { isLoading: isLoadingBuilding } = useQuery({
    queryKey: ['feature-building-count', selectedWards],
    queryFn: () =>
      getFeatureCount({
        layer: 'building',
        ward_no: selectedWards?.join(','),
      }),
    onSuccess: res => updateFeatureCountData(res),
    enabled: !!selectedWards,
  });

  const { isLoading: isLoadingRoad } = useQuery({
    queryKey: ['feature-road-count', selectedWards],
    queryFn: () =>
      getFeatureCount({ layer: 'road', ward_no: selectedWards?.join(',') }),
    onSuccess: res => updateFeatureCountData(res),
    enabled: !!selectedWards,
  });

  const { data: physicalInstallation } = useQuery({
    queryKey: ['physical-installation-dashboard', selectedWards],
    queryFn: () =>
      getPhysicalInstallation({
        ward_no: selectedWards?.join(','),
      }),

    select: res =>
      installationData.map(item => ({
        ...item,
        value: res.data[item.name],
      })),
  });

  if (isLoadingBuilding || isLoadingRoad) {
    return (
      <Flex
        gap={5}
        className="naxatw-grid naxatw-w-full naxatw-rounded-lg naxatw-py-2 lg:naxatw-grid-cols-3 "
      >
        <Flex
          gap={5}
          className="naxatw-grid md:naxatw-grid-cols-3 lg:naxatw-col-span-2"
        >
          {Array.from({ length: 3 }, (_, index) => (
            <Skeleton
              key={index}
              className="naxatw-h-32 naxatw-rounded-lg
               naxatw-bg-primary-50 naxatw-shadow-sm md:naxatw-h-28"
            />
          ))}
        </Flex>
        <Skeleton
          className="naxatw-h-32 naxatw-rounded-lg
         naxatw-bg-primary-50 naxatw-shadow-sm md:naxatw-h-28"
        />
      </Flex>
    );
  }

  return (
    <Flex className="naxatw-grid naxatw-animate-fade-up naxatw-gap-5 lg:naxatw-grid-cols-3">
      <Flex
        gap={5}
        className="naxatw-grid md:naxatw-grid-cols-3 lg:naxatw-col-span-2"
      >
        {featureCountData?.map(({ title, count, iconName }) => (
          <DataCard
            key={title}
            title={_t(title)}
            count={count}
            iconName={iconName}
          />
        ))}
      </Flex>

      <StackedChart
        data={physicalInstallation || []}
        header={ChartHeader}
        chartTitle={_t('Physical Installation')}
        labelAlignment="horizontal"
      />
    </Flex>
  );
}
