/* eslint-disable no-unused-vars */
import { useLocation } from 'react-router-dom';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { handle3DToggle } from '@Store/actions/visualization';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import DroneOrtho from '@Assets/images/map/drone_ortho.png';
import OSM from '@Assets/images/map/osm.png';
import Satellite from '@Assets/images/map/satellite.png';
import Hybrid from '@Assets/images/map/hybrid.png';
import Outdoor from '@Assets/images/map/outdoor.png';
import None from '@Assets/images/map/none.png';
import Switch from '@Components/RadixComponents/Switch';
import useTranslation from '@Hooks/useTranslation';

interface IBaseLayersProps {
  activeBaseLayer: string;
  onChange: (id: string) => void;
}

const baseLayersData = [
  { id: 'droneOrtho', name: 'Drone Ortho', image: DroneOrtho },
  { id: 'osm', name: 'OSM', image: OSM },
  { id: 'satellite', name: 'Satellite', image: Satellite },
  // { id: 'hybrid', name: 'Hybrid', image: Hybrid },
  { id: 'topo', name: 'Outdoor', image: Outdoor },
  { id: 'none', name: 'None', image: None },
];

const activeClassName = 'naxatw-border-2 naxatw-border-blue-400';

export default function BaseLayers({
  activeBaseLayer,
  onChange,
}: IBaseLayersProps) {
  const dispatch = useTypedDispatch();
  const { pathname } = useLocation();
  const { _t } = useTranslation();

  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );

  return (
    <FlexColumn className="naxatw-px-3">
      <FlexRow className="naxatw-h-11 naxatw-items-center naxatw-justify-between">
        <h5>{_t('Base Layers')}</h5>
        {pathname.includes('visualization') && (
          <FlexRow gap={2}>
            <p className="naxatw-btn-text">3D</p>
            <Switch
              checked={is3DToggled}
              onClick={() => dispatch(handle3DToggle())}
            />
          </FlexRow>
        )}
      </FlexRow>

      <Grid cols={2} className="naxatw-w-full">
        {baseLayersData.map(({ id, name, image }) => {
          const isActive = id === activeBaseLayer;
          return (
            <FlexColumn
              key={name}
              className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-px-3 naxatw-py-2"
              gap={1}
              onClick={() => onChange(id)}
            >
              <img
                className={`naxatw-scale-95 naxatw-object-none naxatw-transition 
                naxatw-duration-500 hover:naxatw-scale-110 hover:naxatw-animate-pulse ${
                  isActive ? activeClassName : ''
                }`}
                src={image}
                alt={name}
              />
              <p
                className={`naxatw-px-1  naxatw-text-center naxatw-text-sm ${
                  isActive ? 'naxatw-text-blue-400' : ''
                }`}
              >
                {_t(name)}
              </p>
            </FlexColumn>
          );
        })}
      </Grid>
    </FlexColumn>
  );
}
