/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export type filterTypeType = 'basic' | 'advance';

type basicFilterParamsType = {
  ward_no?: string[];
  tole_name?: string[];
  owner_status?: string[];
  reg_type?: string[];
};

export interface AdvanceFilterState {
  showFilterSection: boolean;
  expandFilter: boolean;
  filterType: filterTypeType;
  selectedLayerId: string | null;
  selectedLayerAttribute: string | number | null;
  queryExpression: Record<string, any>[] | null;
  searchInput: string;
  hideQueryFilter: boolean;
  minimizeFilterSection: boolean;
  selectedWards: string[];
  selectedTole: string[];
  selectedOwnerStatus: string[];
  selectedBuildingType: string[];
  basicFilterParams: basicFilterParamsType;
}

const initialState: AdvanceFilterState = {
  showFilterSection: false,
  expandFilter: false,
  filterType: 'basic',
  selectedLayerId: null, // selected layer Id for advance filter layer selection
  selectedLayerAttribute: null,
  queryExpression: null,
  searchInput: '',
  hideQueryFilter: false,
  minimizeFilterSection: false,
  selectedWards: [],
  selectedTole: [],
  selectedOwnerStatus: [],
  selectedBuildingType: [],
  basicFilterParams: {
    ward_no: [],
    tole_name: [],
    owner_status: [],
    reg_type: [],
  },
};

const setAdvanceFilterState: CaseReducer<
  AdvanceFilterState,
  PayloadAction<Partial<AdvanceFilterState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

type resetPayloadType = {
  key?: string;
  val?: string | number;
};

const handleFilterReset: CaseReducer<
  AdvanceFilterState,
  PayloadAction<resetPayloadType>
> = (state, { payload }) => {
  return {
    ...state,
    basicFilterParams: !payload.key
      ? initialState.basicFilterParams
      : {
          ...state.basicFilterParams,
          // @ts-ignore
          [payload.key]: state.basicFilterParams[payload.key].filter(
            // @ts-ignore
            item => item !== payload.val,
          ),
        },
  };
};

const advanceFilterSlice = createSlice({
  name: 'advanceFilter',
  initialState,
  reducers: {
    setAdvanceFilterState,
    handleFilterReset,
  },
});

export { advanceFilterSlice };

export default advanceFilterSlice.reducer;
