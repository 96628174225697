import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setSelectedWards } from '@Store/actions/common';
import { allWardsSelector } from '@Store/selector/common';
import JanakpurMuniLogo from '@Assets/images/janakpur-muni-logo.svg';
import DemoMuniLogo from '@Assets/images/demo-muni-logo.png';
import Image from '@Components/RadixComponents/Image';
import { MultiSelect } from '@Components/common/FormUI';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';

const { PROJECT } = process.env;

export default function DashboardHeader() {
  const dispatch = useTypedDispatch();
  const selectedWards = useTypedSelector(state => state.common.selectedWards);
  const allWards = useTypedSelector(allWardsSelector);

  const handleWardFilter = (updatedSelected: string[]) => {
    dispatch(setSelectedWards(updatedSelected));
  };

  const handleClearFilter = () => {
    dispatch(setSelectedWards([]));
  };

  return (
    <FlexRow className="naxatw-flex-col naxatw-items-center naxatw-justify-between md:naxatw-flex-row">
      <Image
        src={
          PROJECT?.toLowerCase() === 'janakpur'
            ? JanakpurMuniLogo
            : DemoMuniLogo
        }
        className="naxatw-hidden naxatw-w-[60%] md:naxatw-block md:naxatw-h-16 md:naxatw-w-full"
      />

      <FlexRow
        gap={4}
        className="naxatw-w-full naxatw-items-center naxatw-justify-end naxatw-pr-2 md:naxatw-w-auto"
      >
        <MultiSelect
          options={allWards || []}
          selectedOptions={selectedWards}
          onChange={handleWardFilter}
          placeholder="All Ward"
          className="!naxatw-w-40 naxatw-justify-center naxatw-border-b-[1.5px]"
        />
        <ToolTip
          name="restart_alt"
          message="Reset filter"
          iconClassName="!naxatw-text-primary-400 hover:naxatw-animate-pulse hover:naxatw-text-primary-600 
          naxatw-hidden md:naxatw-block"
          onClick={handleClearFilter}
        />
      </FlexRow>
    </FlexRow>
  );
}
