/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import useTranslation from '@Hooks/useTranslation';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  getLayerAttribute,
  getLayerAttributeValues,
} from '@Services/visualization';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { dataQueryOperators } from '@Constants/advanceFilter';
import { Button } from '@Components/RadixComponents/Button';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import ToolTip from '@Components/RadixComponents/ToolTip';
import SearchList from './SearchList';

const initialQuery = {
  field: null,
  operator: null,
  value: null,
};

function getOperatorLabel(value: any) {
  return dataQueryOperators.find(item => item.value === value)?.label;
}

export function stringifyQuery(data: Record<string, any>) {
  return data.reduce((str: string, item: any) => {
    const { field, operator, value, join } = item;
    if (join) {
      return `${str} ${join}`;
    }
    return `${str} ${field ? `"${field}"` : ''} ${
      getOperatorLabel(operator) || ''
    } ${value?.toString() ? `"${value}"` : ''}`;
  }, '');
}

export default function QuerySection() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();

  const [selectedLayerAttribute, setSelectedLayerAttribute] = useState<
    string | number | null
  >(null);
  const [query, setQuery] = useState<Record<string, any>[]>([initialQuery]);

  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );
  const filterType = useTypedSelector(state => state.advanceFilter.filterType);
  const hideQueryFilter = useTypedSelector(
    state => state.advanceFilter.hideQueryFilter,
  );
  const queryExpression = useTypedSelector(
    state => state.advanceFilter.queryExpression,
  );

  // fetch road/building layer attributes
  const { data: layerAttributeData, isLoading: isAttributeLoading } = useQuery({
    queryKey: ['get-layer-attributes', selectedLayerId],
    queryFn: () =>
      getLayerAttribute({
        layer: selectedLayerId,
      }),
    select: res => res.data,
  });

  // fetch road/building layer attribute values
  const { data: layerAttributeValues, isLoading: isAttributeValueLoading } =
    useQuery({
      queryKey: [
        'get-layer-attribute-values',
        selectedLayerAttribute,
        selectedLayerId,
      ],
      queryFn: () =>
        getLayerAttributeValues({
          layer: selectedLayerId,
          field: selectedLayerAttribute,
        }),
      select: res => res.data,
      enabled: !!selectedLayerAttribute,
    });

  const handleQuerySelect = (val: string | number, type: string) => {
    setQuery(prev => {
      const lastItem = prev[prev.length - 1];
      const { field, operator, value, join } = lastItem;
      if (type === 'join' && !!field && !!operator && !!value) {
        return [
          ...prev,
          {
            [type]: val,
          },
        ];
      }
      if (type !== 'join' && !!join) {
        return [
          ...prev,
          {
            field: '',
            operator: null,
            value: null,
            [type]: val,
          },
        ];
      }
      return [
        ...prev.filter((_, idx) => idx !== prev.length - 1),
        type === 'field' && !!field && !!operator && !!value
          ? {
              ...initialQuery,
              [type]: val,
            }
          : {
              ...lastItem,
              [type]: val,
            },
      ];
    });
  };

  const handleBackspace = () => {
    setQuery((prev: any) => {
      const lastItem = prev[prev.length - 1];
      // @ts-ignore
      return prev.reduce((arr, item, idx) => {
        if (idx < prev.length - 1) return [...arr, item];
        if (!lastItem) return [{ ...initialQuery }];
        if (lastItem.join) {
          return arr;
        }
        if (lastItem.value) {
          return [...arr, { ...item, value: null }];
        }
        if (lastItem.operator) {
          return [...arr, { ...item, operator: null }];
        }
        return [{ ...initialQuery }];
      }, []);
    });
  };

  const handleClearQuery = () => {
    setQuery([initialQuery]);
  };

  const lastQueryItem = query[query.length - 1];

  const hasNullValues = query.some(item =>
    Object.values(item).some(value => value === null),
  );

  return (
    <FlexColumn gap={3} className="naxatw-h-fit">
      {!hideQueryFilter && (
        <FlexRow gap={2}>
          <SearchList
            title="Attributes"
            data={layerAttributeData || []}
            onClick={item => {
              setSelectedLayerAttribute(item);
              handleQuerySelect(item, 'field');
            }}
            isLoading={isAttributeLoading}
          />
          <FlexColumn
            gap={3}
            className="naxatw-grid naxatw-h-20 naxatw-w-40 naxatw-grid-cols-3"
          >
            {dataQueryOperators.map(({ id, label, value, type }) => (
              <Button
                key={id}
                className="!naxatw-h-10 naxatw-w-10 naxatw-border !naxatw-border-grey-200 !naxatw-bg-grey-100
               !naxatw-text-grey-800 hover:!naxatw-shadow-grey-400"
                onClick={() => {
                  if (
                    type === 'join' &&
                    (!lastQueryItem?.field ||
                      !lastQueryItem?.operator ||
                      !lastQueryItem?.value)
                  ) {
                    return;
                  }
                  if (!lastQueryItem?.field) {
                    // dispatch(
                    //   toastActions.error({
                    //     message: 'Please select field first.',
                    //   }),
                    // );
                    // alert('no');
                    return;
                  }
                  handleQuerySelect(value, type);
                }}
              >
                {label}
              </Button>
            ))}
          </FlexColumn>
          {selectedLayerAttribute && (
            <SearchList
              title="Value"
              data={layerAttributeValues || []}
              onClick={item => {
                if (!lastQueryItem?.operator) {
                  // dispatch(
                  //   toastActions.error({
                  //     message: 'Please select operator first.',
                  //   }),
                  // );
                  // alert('no');
                  return;
                }
                handleQuerySelect(item, 'value');
              }}
              isLoading={isAttributeValueLoading}
            />
          )}
        </FlexRow>
      )}

      <FlexRow gap={2}>
        <FlexColumn
          className={`${
            queryExpression ? 'naxatw-h-10 naxatw-max-h-20' : ''
          }  scrollbar naxatw-h-20 naxatw-w-full naxatw-overflow-auto
           naxatw-rounded-lg naxatw-border-2 naxatw-border-grey-200 naxatw-p-1 naxatw-text-sm`}
        >
          {stringifyQuery(query)}
        </FlexColumn>
        <FlexColumn className="naxatw-justify-center">
          <ToolTip
            name="backspace"
            message="Backspace"
            onClick={handleBackspace}
            iconClassName="naxatw-text-primary-400 !naxatw-text-2xl"
          />
          <ToolTip
            name="restart_alt"
            message="clear"
            onClick={handleClearQuery}
            iconClassName="naxatw-text-primary-400"
          />
        </FlexColumn>
      </FlexRow>

      {/* apply button section */}
      <FlexRow gap={2}>
        <Button
          variant="outline"
          onClick={() =>
            dispatch(
              setAdvanceFilterState({
                filterType: filterType === 'advance' ? 'basic' : 'advance',
                selectedLayerId: null,
              }),
            )
          }
        >
          {filterType === 'basic'
            ? `${_t('Advance Filter')}`
            : `${_t('Basic Filter')}`}
        </Button>
        <Button
          disabled={hasNullValues}
          className="disabled:!naxatw-bg-grey-600"
          onClick={() =>
            dispatch(
              setAdvanceFilterState({
                queryExpression: query,
                hideQueryFilter: true,
              }),
            )
          }
        >
          {_t('Apply Filter')}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
