import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import {
  ModalContentsType,
  PromptDialogContentsType,
} from '@Constants/modalContents';
import { InfoDialogContentsType } from '@Constants/getInfoDialogContent';

export interface CommonState {
  language: string;
  palikaProfile: Record<string, any> | null;
  buildingBbox: Record<string, any> | null;
  showModal: boolean;
  modalContent: ModalContentsType;
  showPromptDialog: boolean;
  promptDialogContent: PromptDialogContentsType;
  showInfoDialog: boolean;
  infoDialogContent: InfoDialogContentsType;
  allWards: Record<string, any>[];
  selectedWards: string[];
}

const initialState: CommonState = {
  language: 'English',
  palikaProfile: null,
  showModal: false,
  modalContent: null,
  showPromptDialog: false,
  promptDialogContent: null,
  showInfoDialog: false,
  infoDialogContent: null,
  buildingBbox: null,
  allWards: [],
  selectedWards: [],
};

const setCommonState: CaseReducer<
  CommonState,
  PayloadAction<Partial<CommonState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const toggleModal: CaseReducer<
  CommonState,
  PayloadAction<ModalContentsType | undefined>
> = (state, action) => ({
  ...state,
  showModal: !!action.payload,
  modalContent: action.payload || state.modalContent,
});

const setModalContent: CaseReducer<
  CommonState,
  PayloadAction<ModalContentsType>
> = (state, action) => ({
  ...state,
  modalContent: action.payload || null,
});

const togglePromptDialog: CaseReducer<
  CommonState,
  PayloadAction<PromptDialogContentsType | undefined>
> = (state, action) => ({
  ...state,
  // showPromptDialog: !!action.payload || !state.showPromptDialog,
  showPromptDialog: !!action.payload,
  promptDialogContent: action.payload || state.promptDialogContent,
});

const setPromptDialogContent: CaseReducer<
  CommonState,
  PayloadAction<PromptDialogContentsType>
> = (state, action) => ({
  ...state,
  promptDialogContent: action.payload || null,
});

const toggleInfoDialog: CaseReducer<
  CommonState,
  PayloadAction<InfoDialogContentsType | undefined>
> = (state, action) => ({
  ...state,
  showInfoDialog: !!action.payload,
  infoDialogContent: action.payload || state.infoDialogContent,
});

const setInfoDialogContent: CaseReducer<
  CommonState,
  PayloadAction<InfoDialogContentsType>
> = (state, action) => ({
  ...state,
  infoDialogContent: action.payload || null,
});

const setSelectedWards: CaseReducer<CommonState, PayloadAction<string[]>> = (
  state,
  action,
) => ({
  ...state,
  selectedWards: action.payload,
});

const resetCommonState: CaseReducer<CommonState> = () => ({
  ...initialState,
});

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setCommonState,
    toggleModal,
    setModalContent,
    togglePromptDialog,
    setPromptDialogContent,
    toggleInfoDialog,
    setInfoDialogContent,
    setSelectedWards,
    resetCommonState,
  },
});

export { commonSlice };

export default commonSlice.reducer;
