/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { getBuildingDataList } from '@Services/dataManagement';
import DataTable from '@Components/common/DataTable';
import { FlexRow } from '@Components/common/Layouts';
import DropdownOptions from '@Components/common/DropdownOptions';
import { togglePromptDialog } from '@Store/actions/common';
import { setBuildingDataState } from '@Store/actions/dataManagement';
import { setVisualizationState } from '@Store/actions/visualization';
import ToolTip from '@Components/RadixComponents/ToolTip';
import useWindowDimensions from '@Hooks/useWindowDimensions';
import { selectedWardSelector } from '@Store/selector/common';

export default function TableSection() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();
  const permissions = useTypedSelector(state => state.user.permissions);

  const queryFnParams = useTypedSelector(
    state => state.dataManagement.buildingData.queryFnParams,
  );
  const searchInput = useTypedSelector(
    state => state.dataManagement.buildingData.searchInput,
  );
  const selectedWards = useTypedSelector(selectedWardSelector);

  const dropdownOptions = [
    {
      id: 'edit',
      icon: 'edit',
      label: 'Edit',
      onClick: (data: any) => {
        navigate(`form/${data.id}`, { state: { redirectPath: pathname } });
      },
    },
    {
      id: 'delete',
      icon: 'delete',
      label: 'Delete',
      onClick: (data: Record<string, any>[]) => {
        dispatch(togglePromptDialog('delete-building-data'));
        dispatch(
          setBuildingDataState({
            selectedBuilding: data,
          }),
        );
      },
      iconClassName: 'naxatw-text-red-400',
    },
  ];

  const buildingDataColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'House No.',
      accessorKey: 'house_no',
    },
    {
      header: 'Associate Road Name',
      accessorKey: 'associate_road_name',
    },
    {
      header: 'Owner Name',
      accessorKey: 'owner_name',
    },
    {
      header: 'Ward No.',
      accessorKey: 'ward_no',
      cell: ({ row }: any) => <p>{`ward ${row?.original?.ward_no}`}</p>,
    },
    {
      header: 'Tole Name',
      accessorKey: 'tole_name',
    },
    {
      header: 'Plus Code',
      accessorKey: 'plus_code',
    },
    {
      header: 'Last Modified',
      accessorKey: 'updated_date',
      cell: (info: any) => {
        const date = info.getValue();
        if (!date) return '';
        return format(new Date(date), 'MMM dd yyyy hh:mm a');
      },
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="naxatw-justify-around">
          <ToolTip
            name="info"
            message="More info"
            onClick={() => {
              navigate(`/visualization/building-detail/${row.original.id}`);
            }}
          />
          {permissions.includes('view_building') && (
            <ToolTip
              name="map"
              message="View on map"
              onClick={() => {
                dispatch(
                  setVisualizationState({
                    selectedFeature: { ...row.original, layer: 'building' },
                  }),
                );
                navigate(`/visualization`);
              }}
            />
          )}
          {(permissions.includes('change_building') ||
            permissions.includes('delete_building')) && (
            <DropdownOptions
              options={
                !permissions.includes('change_building')
                  ? dropdownOptions.filter(item => item.id !== 'edit')
                  : !permissions.includes('delete_building')
                    ? dropdownOptions.filter(item => item.id !== 'delete')
                    : dropdownOptions
              }
              data={row.original}
            />
          )}
        </FlexRow>
      ),
    },
  ];

  const isFilterApplied =
    !!queryFnParams.ward_no?.length ||
    !!queryFnParams.tole_name?.length ||
    !!queryFnParams.reg_type?.length;

  // to apply ward filter for user permisssion
  const modifiedQueryFnParams = useMemo(
    () =>
      queryFnParams.ward_no?.length
        ? queryFnParams
        : { ...queryFnParams, ward_no: selectedWards },
    [queryFnParams, selectedWards],
  );

  return (
    <DataTable
      columns={buildingDataColumns}
      queryKey="building-data-list"
      queryFn={getBuildingDataList}
      queryFnParams={modifiedQueryFnParams}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          width <= 390
            ? '60vh'
            : isFilterApplied
              ? 'calc(100vh - 15rem)'
              : 'calc(100vh - 11.5rem)',
      }}
      useQueryOptions={{ enabled: !!selectedWards }}
    />
  );
}
