import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  addNewZoomLevel,
  removeZoomLevel,
  setLayerProperty,
  setSubLayerProperty,
} from '@Store/actions/overlayLayer';
import { ZOOM_INTERVAL_ON_NEW_ADD } from '@Constants/overlayLayers';
import { FlexColumn } from '@Components/common/Layouts';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import LabelStyles from './LabelStyles';
import LayerStyles from './LayerStyles';
import SubLayer from './subLayer';

interface IStyleCustomizationSection {
  activeStep: string;
}

export type styleChangeHandlerType = (
  zoom: number, // eslint-disable-line no-unused-vars
  type: string, // eslint-disable-line no-unused-vars
  key: string, // eslint-disable-line no-unused-vars
  value: string | number, // eslint-disable-line no-unused-vars
) => void;

export type addNewZoomLevelFuncType = (
  lastZoomLevel: number, // eslint-disable-line no-unused-vars
  layerType: 'label' | 'layer', // eslint-disable-line no-unused-vars
) => void; // eslint-disable-line no-unused-vars

export type handleDeleteZoomLevelFuncType = (
  zoomLevel: number, // eslint-disable-line no-unused-vars
  layerType: 'label' | 'layer', // eslint-disable-line no-unused-vars
) => void;

function StyleCustomizationSection({ activeStep }: IStyleCustomizationSection) {
  const dispatch = useTypedDispatch();
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const selectedSubLayer = useTypedSelector(
    state => state.overlayLayer.selectedSubLayer,
  );

  const styleChangeHandler = (
    zoomLevel: number,
    styleType: string,
    styleKey: string,
    value: string | number,
  ) => {
    if (isActiveSubLayer && selectedSubLayer) {
      dispatch(
        setSubLayerProperty({
          subLayerKey: selectedSubLayer,
          zoom: zoomLevel,
          type: styleType,
          key: styleKey,
          value,
        }),
      );
      return;
    }

    dispatch(
      setLayerProperty({
        zoom: zoomLevel,
        type: styleType,
        key: styleKey,
        value,
      }),
    );
  };

  const handleAddNewZoomLevel = (
    lastZoomLevel: number,
    layerType: 'layer' | 'label',
  ) => {
    dispatch(
      addNewZoomLevel({
        isActiveSubLayer,
        layerType,
        zoom: lastZoomLevel + ZOOM_INTERVAL_ON_NEW_ADD,
      }),
    );
  };

  const handleDeleteZoomLevel = (
    zoomLevel: number,
    layerType: 'layer' | 'label',
  ) => {
    dispatch(
      removeZoomLevel({
        zoom: zoomLevel,
        isActiveSubLayer,
        layerType,
      }),
    );
  };

  return (
    <FlexColumn className="naxatw-max-w-[320px]">
      <SubLayer />
      <FlexColumn
        className="naxatw-px-2"
        style={{ height: `calc(100vh - ${isActiveSubLayer ? 30 : 21}rem)` }}
      >
        {activeStep === 'layer styles' ? (
          <LayerStyles
            styleChangeHandler={styleChangeHandler}
            addNewZoomLevel={handleAddNewZoomLevel}
            handleDeleteZoomLevel={handleDeleteZoomLevel}
          />
        ) : (
          <LabelStyles
            styleChangeHandler={styleChangeHandler}
            addNewZoomLevel={handleAddNewZoomLevel}
            handleDeleteZoomLevel={handleDeleteZoomLevel}
          />
        )}
      </FlexColumn>
    </FlexColumn>
  );
}

export default hasErrorBoundary(StyleCustomizationSection);
