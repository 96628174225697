import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { toggleInfoDialog, togglePromptDialog } from '@Store/actions/common';
import { Button } from '@Components/RadixComponents/Button';
import { Input } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { updateUser } from '@Services/userManagement';
import { handleUserTableDelete } from '@Queries/userManagement';

const deleteConfirmationText = 'delete';

export default function DeleteUser() {
  const dispatch = useTypedDispatch();
  const queryClient = useQueryClient();
  const [input, setInput] = useState('');

  const selectedUser = useTypedSelector(
    state => state.userManagement.selectedUser,
  );

  const handlePromptClose = () => {
    dispatch(togglePromptDialog());
    dispatch(toggleInfoDialog('delete-user-success'));
  };

  // update query cache on delete
  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      handleUserTableDelete(queryClient, payloadData),
  });

  const { mutate: deleteUserRequest, isLoading } = useMutation({
    mutationFn: id => updateUser(id, { is_deleted: true }),
    onSuccess: () => {
      handlePromptClose();
      if (!selectedUser?.id) return;
      updateTableData({ id: selectedUser.id });
    },
  });

  const handleUserDelete = () => {
    if (!selectedUser?.id) return;
    if (deleteConfirmationText !== input) return;
    deleteUserRequest(selectedUser.id);
  };

  return (
    <FlexColumn gap={2} className="naxatw-text-sm">
      <p className=" naxatw-text-grey-600">
        Are you sure want to delete {selectedUser?.username} ?
      </p>
      <p>
        Please type &quot;
        <span className="naxatw-select-none">{deleteConfirmationText}</span>
        &quot; to confirm
      </p>
      <Input
        type="text"
        placeholder="delete"
        onPaste={e => e.preventDefault()}
        onChange={e => setInput(e.target.value)}
      />
      <FlexRow className=" naxatw-w-full naxatw-justify-end naxatw-pt-5">
        <Button variant="ghost" onClick={() => dispatch(togglePromptDialog())}>
          Cancel
        </Button>
        <Button
          leftIcon="delete"
          variant="destructive"
          withLoader
          isLoading={isLoading}
          onClick={handleUserDelete}
          disabled={deleteConfirmationText !== input}
          className="disabled:!naxatw-pointer-events-auto disabled:!naxatw-cursor-not-allowed"
        >
          Confirm
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
