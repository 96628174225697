/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
import { useState, ChangeEventHandler } from 'react';
import { useTypedSelector } from '@Store/hooks';
import { Input } from '../FormUI';
import { FlexColumn, FlexRow } from '../Layouts';
import Icon from '../Icon';

interface ISearchInputProps {
  data: Record<string, any>;
  inputValue: string;
  selectedValue?: Record<string, any> | null;
  className?: string;
  isOpen: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onSelect?: (item: Record<string, any>) => void;
  onClear: () => void;
  hasIcon?: boolean;
  hasClearIcon?: boolean;
}

export default function SearchInput({
  data,
  inputValue,
  selectedValue,
  onChange,
  onSelect,
  onClear,
  isOpen,
  className,
  hasIcon = true,
  hasClearIcon = true,
}: ISearchInputProps) {
  const [isInputFocused, setIsInputFocused] = useState(false);
  const showClearIcon = !!inputValue.trim().length || selectedValue;

  return (
    <FlexRow
      className={`${className} naxatw-group naxatw-relative naxatw-h-9 naxatw-w-[300px] naxatw-items-center
       naxatw-justify-between naxatw-rounded-lg naxatw-bg-white naxatw-shadow-dark naxatw-shadow-gray-400 naxatw-ring-[1px]
        naxatw-ring-grey-300 hover:naxatw-ring-primary-400 ${
          isInputFocused ? 'naxatw-ring-primary-400' : ''
        }`}
    >
      <FlexRow className="naxatw-items-center">
        {hasIcon && (
          <Icon
            name="search"
            className={`naxatw-mt-1 naxatw-pl-3 naxatw-text-grey-800 group-hover:naxatw-text-primary-400  ${
              isInputFocused ? 'naxatw-text-primary-400' : ''
            }`}
          />
        )}

        <Input
          type="text"
          className={`naxatw-h-9 naxatw-border-none naxatw-pl-2 naxatw-capitalize focus:placeholder:naxatw-text-grey-400 ${
            selectedValue ? 'placeholder:naxatw-text-grey-800' : ''
          }`}
          value={inputValue}
          placeholder={
            isInputFocused
              ? ''
              : selectedValue?.house_no && selectedValue?.owner_name
                ? `${selectedValue.house_no} - ${selectedValue.owner_name}`
                : 'Search by House No, Ward No, Owner Name and Pulse Code'
          }
          onChange={onChange}
          onFocus={() => setIsInputFocused(true)}
          onBlur={() => setIsInputFocused(false)}
        />
      </FlexRow>
      {showClearIcon && hasClearIcon && (
        <Icon
          name="clear"
          className="naxatw-cursor-pointer naxatw-pr-3 naxatw-text-[#484848] "
          onClick={onClear}
        />
      )}
      {isOpen && (
        <ul
          className="scrollbar naxatw-absolute naxatw-top-10 naxatw-max-h-[20.5rem] 
          naxatw-w-full naxatw-animate-flip-down naxatw-overflow-y-auto naxatw-bg-white"
        >
          {data?.results?.length
            ? data?.results?.map((item: Record<string, any>) => (
                <li
                  key={item.id}
                  className="naxatw-cursor-pointer naxatw-border-b-[0.5px] naxatw-border-grey-300 naxatw-px-4 
              naxatw-py-3 focus:naxatw-bg-primary-50"
                  role="presentation"
                  onKeyDown={() => {}}
                  onClick={() => {
                    onSelect?.(item);
                  }}
                >
                  <FlexColumn gap={1}>
                    <FlexColumn>
                      <p className="naxatw-text-xs naxatw-font-bold naxatw-text-grey-800">
                        {`${item?.house_no} - ${item?.associate_road_name}`}
                      </p>
                      <p className="naxatw-text-xs naxatw-font-bold naxatw-leading-4 naxatw-text-primary-400">
                        {item?.owner_name}
                      </p>
                    </FlexColumn>
                    <FlexRow className="naxatw-justify-between naxatw-text-xs">
                      <p className="naxatw-font-bold naxatw-leading-4 naxatw-text-grey-800">
                        Ward {item?.ward_no}
                      </p>
                      <p className="naxatw-font-normal naxatw-text-grey-800">
                        {item?.plus_code}
                      </p>
                    </FlexRow>
                  </FlexColumn>
                </li>
              ))
            : inputValue !== '' && (
                <FlexColumn className="naxatw-px-4 naxatw-py-3 naxatw-text-sm">
                  No data found
                </FlexColumn>
              )}
        </ul>
      )}
    </FlexRow>
  );
}
