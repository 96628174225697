import { Flex, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import { toggleModal } from '@Store/actions/common';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setUserManagementState } from '@Store/actions/userManagement';
import SearchInput from '@Components/common/FormUI/SearchInput';
import useTranslation from '@Hooks/useTranslation';

export default function FilterSection() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const searchInput = useTypedSelector(
    state => state.userManagement.searchInput,
  );
  const permissions = useTypedSelector(state => state.user.permissions);

  return (
    <Flex className="naxatw-flex-col naxatw-justify-between md:naxatw-flex-row">
      <SearchInput
        inputValue={searchInput}
        placeholder="Search by Username, Designation & Role"
        onChange={e =>
          dispatch(
            setUserManagementState({
              searchInput: e.target.value,
            }),
          )
        }
        onClear={() =>
          dispatch(
            setUserManagementState({
              searchInput: '',
            }),
          )
        }
        className="md:!naxatw-w-[320px]"
      />

      <FlexRow className="naxatw-flex naxatw-items-center naxatw-justify-end naxatw-gap-2 naxatw-pt-3 md:naxatw-py-0">
        {permissions.includes('add_user') && (
          <Button
            leftIcon="add"
            onClick={() => dispatch(toggleModal('add-user'))}
          >
            {_t('Add New User')}
          </Button>
        )}
      </FlexRow>
    </Flex>
  );
}
