import { useEffect, useRef, useState } from 'react';
import { MapMouseEvent, Popup } from 'maplibre-gl';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { Button } from '@Components/RadixComponents/Button';
import { IAsyncPopup } from '../types';

const popup = new Popup({
  closeOnClick: false,
  closeButton: false,
});

export default function MapPopup({
  map,
  enable = true,
  onClose,
  handleEdit,
  showPopupFor = [],
}: IAsyncPopup) {
  const popupRef = useRef(null);

  const [properties, setProperties] = useState<Record<string, any> | null>(
    null,
  );

  const permissions = useTypedSelector(state => state.user.permissions);

  useEffect(() => {
    if (!map || !enable) return () => {};
    function displayPopup(e: MapMouseEvent): void {
      if (!map) return;
      const features = map.queryRenderedFeatures(e.point);
      const clickedFeature = features?.[0];
      if (!showPopupFor.includes(clickedFeature.source)) return;
      if (!clickedFeature) return;
      setProperties({
        ...clickedFeature.properties,
        layer: clickedFeature.source,
      });
      popup.setLngLat(e.lngLat);
    }
    map.on('click', displayPopup);
    return () => {
      map.off('click', displayPopup);
      popup.remove();
    };
  }, [map, enable, showPopupFor]);

  useEffect(() => {
    if (!map || !properties || !popupRef.current) return;
    popup.setDOMContent(popupRef.current).addTo(map);
  }, [map, properties]);

  const onPopupClose = () => {
    popup.remove();
    onClose?.();
    setProperties(null);
  };

  if (!properties) return <div />;

  return (
    <div ref={popupRef} className="naxatw-w-[10rem] naxatw-pl-2">
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <p className="naxatw-font-bold">Ward number: {properties.ward_no}</p>
        <ToolTip
          name="close"
          message="Close"
          side="top"
          className="naxatw-mt-1"
          onClick={onPopupClose}
        />
      </FlexRow>
      <FlexRow className="naxatw-items-center naxatw-justify-between naxatw-py-3">
        <FlexRow gap={2} className="naxatw-items-center">
          {permissions.includes('change_palikawardgeometry') && (
            <FlexRow>
              <Button
                size="sm"
                leftIcon="edit"
                onClick={() => handleEdit?.(properties)}
              >
                Edit Boundary
              </Button>
            </FlexRow>
          )}
        </FlexRow>
      </FlexRow>
    </div>
  );
}
