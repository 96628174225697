/* eslint-disable react/no-array-index-key */
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import { Button } from '@Components/RadixComponents/Button';
import { setActiveIndex } from '@Store/actions/dataManagementForm';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { apiURL } from '@Services/index';
import { overlayDataFormIndex } from '@Constants/overlayLayers';
import { updateVectorLayer } from '@Services/overlayLayers';
import { useMutation } from '@tanstack/react-query';
import { getVectorLayerRequest } from '@Store/actions/overlayLayer';
import checkIfLoading from '@Utils/checkIfLoading';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { toggleInfoDialog } from '@Store/actions/common';
import OLVectorTileLayer from './OLVectorTileLayer';
import StyleCustomizationSection from './StyleCustomizationSection';

interface IOverlayLayersMapSectionProps {
  activeStep: string;
}

function MapSection({ activeStep }: IOverlayLayersMapSectionProps) {
  const dispatch = useTypedDispatch();
  const { formId } = useParams();
  const navigate = useNavigate();
  const [expandMap, setExpandMap] = useState(false);
  const activeIndex = useTypedSelector(
    state => state.dataManagementForm.activeIndex,
  );
  const layerDetails = useTypedSelector(
    state => state.overlayLayer.layerDetails,
  );
  const layerStyles = useTypedSelector(state => state.overlayLayer.layerStyles);
  const labelZoomLevels = useTypedSelector(
    state => state.overlayLayer.labelZoomLevels,
  );
  const layerZoomLevels = useTypedSelector(
    state => state.overlayLayer.layerZoomLevels,
  );
  const isActiveSubLayer = useTypedSelector(
    state => state.overlayLayer.isActiveSubLayer,
  );
  const subLayerStyle = useTypedSelector(
    state => state.overlayLayer.subLayerStyle,
  );
  const labelKey = useTypedSelector(state => state.overlayLayer.labelKey);

  const lastIndex = Object.keys(overlayDataFormIndex).length - 1;

  const isLayerDataFetching = useTypedSelector(state =>
    checkIfLoading(state, [getVectorLayerRequest.type]),
  );

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 6,
      center: [84.124, 28.3949],
    },
    disableRotation: true,
  });

  const { mutate, isLoading } = useMutation<any, any, any, unknown>({
    mutationFn: updateVectorLayer,
    onSuccess: (res: Record<string, any>) => {
      if (res.data.message === 'success') {
        navigate('/data-management/overlay-layer/');
      }
    },
    onError: () => {
      dispatch(toggleInfoDialog('error-on-form-submit'));
    },
  });

  const handlePrevious = () => {
    dispatch(setActiveIndex(activeIndex - 1));
  };

  const handleNext = () => {
    if (activeIndex < lastIndex)
      return dispatch(setActiveIndex(activeIndex + 1));
    return mutate({
      style: layerStyles,
      id: formId,
      layer_zoom_levels: layerZoomLevels,
      label_zoom_levels: labelZoomLevels,
      sublayer_style: subLayerStyle,
      is_sublayer_active: isActiveSubLayer ? 'True' : 'False',
      sublayer_key: 'id',
      label_key: labelKey,
    });
  };

  useEffect(() => {
    if (!map || !layerDetails?.bbox) return;
    map?.fitBounds(layerDetails.bbox?.total_bounds, { padding: 40 });
  }, [map, layerDetails]);

  useEffect(() => {
    if (!formId || layerDetails?.id) return;
    // @ts-ignore
    dispatch(getVectorLayerRequest(formId));
  }, [formId, dispatch, layerDetails?.id]);

  return (
    <FlexColumn className="naxatw-flex naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-gap-6 naxatw-py-5">
      <FlexRow
        className={` ${
          expandMap
            ? 'naxatw-w-full'
            : 'naxatw-w-full md:naxatw-w-[90%] lg:naxatw-w-[80%]'
        }`}
      >
        {isLayerDataFetching ? (
          <FlexColumn className="naxatw-flex naxatw-min-w-[330px] naxatw-gap-6">
            {Array.from({ length: 7 }).map((__, index) => (
              <Skeleton
                key={`${index}-skleton`}
                className="naxatw-h-12 naxatw-w-5/6"
              />
            ))}
          </FlexColumn>
        ) : (
          <FlexColumn className="scrollbar naxatw-mx-3 naxatw-h-full naxatw-min-w-[220px] naxatw-gap-4 lg:naxatw-min-w-[330px]">
            <StyleCustomizationSection activeStep={activeStep} />
          </FlexColumn>
        )}
        <MapContainer
          map={map}
          isMapLoaded={isMapLoaded}
          style={{
            width: '100%',
            height: 'calc(100vh - 18rem)',
          }}
        >
          <BaseLayerSwitcher activeLayer="osm" />
          <div className="naxatw-absolute naxatw-left-2 naxatw-top-2 naxatw-z-50 naxatw-flex naxatw-h-8 naxatw-w-8 naxatw-items-center naxatw-justify-center naxatw-bg-white">
            <i
              className="material-icons naxatw-cursor-pointer hover:naxatw-text-blue-400"
              role="presentation"
              onClick={() => setExpandMap(!expandMap)}
            >
              {expandMap ? 'chevron_right' : 'chevron_left'}
            </i>
          </div>

          <OLVectorTileLayer
            id="new-overlay-layer"
            visibleOnMap
            url={`${apiURL}/vector-layer/{z}/{x}/{y}/?vector_layer_id=${formId}`}
            layers={isActiveSubLayer ? subLayerStyle : layerStyles}
            hasInteraction
          />
        </MapContainer>
      </FlexRow>

      <FlexRow className="naxatw-mt-3 naxatw-justify-center naxatw-gap-4">
        <Button
          variant="outline"
          className="naxatw-w-24"
          leftIcon="chevron_left"
          onClick={handlePrevious}
        >
          Previous
        </Button>
        <Button
          className="naxatw-w-24"
          rightIcon="chevron_right"
          onClick={handleNext}
          withLoader
          isLoading={isLoading}
        >
          {activeStep === 'label styles' ? 'Save' : 'Next'}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}

export default hasErrorBoundary(MapSection);
