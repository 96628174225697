/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { defaultLayersData, ILayerState } from '@Constants/map';
import { DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export type ChangeStyleType = {
  layerId: string | number;
  styles: Record<string, any>;
};

export interface VisualizationState {
  activeBaseLayer: string;
  layers: ILayerState[];
  showMapTool: boolean;
  is3DToggled: boolean;
  popupData: Record<string, any> | null;
  layerOrderChanged: string[] | number[] | null; // [from, to]
  changeStyle: ChangeStyleType | Record<string, any>;
  isFullScreenToggled: boolean;
  selectedFeature: Record<string, any> | null;
  geolocation: Record<string, any> | null;
  zoomToGeolocation: boolean;
  inputCoordinates: number[];
  zoomToLocationType: 'geolocation' | 'user-input' | null;
  isRoutingOn: boolean;
  startDirectionCoordinates: Record<string, any> | null;
  endDirectionCoordinates: Record<string, any> | null;
  locationCoordinates: Record<string, any> | null;
  routesData: Record<string, any> | null;
  selectedPlace: Record<string, any>;
  locateOnMap: boolean;
  drawnCoordinate: Record<string, any> | null; // coordinate drawn on map when routing
  selectedBuilding: Record<string, any>; // building selected while drawing route
  searchBy: 'places' | 'buildings' | 'draw' | 'your location' | '';
  previousMapBounds: [number, number][] | null;
  selectedBuildingId: number | null;
}

const initialState: VisualizationState = {
  activeBaseLayer: 'osm',
  layers: defaultLayersData,
  showMapTool: true,
  is3DToggled: true,
  popupData: null,
  layerOrderChanged: null,
  changeStyle: {},
  isFullScreenToggled: false,
  selectedFeature: null, // { id: '', layer:'' }
  geolocation: null, // [lat, lng]
  zoomToGeolocation: false,
  inputCoordinates: [],
  zoomToLocationType: null,
  isRoutingOn: false,
  startDirectionCoordinates: null,
  endDirectionCoordinates: null,
  locationCoordinates: null,
  routesData: null, // {}
  selectedPlace: {},
  locateOnMap: false,
  drawnCoordinate: null,
  selectedBuilding: {},
  searchBy: '',
  previousMapBounds: null,
  selectedBuildingId: null, // for generating house number
};

const setVisualizationState: CaseReducer<
  VisualizationState,
  PayloadAction<Partial<VisualizationState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setOverlayLayersState: CaseReducer<
  VisualizationState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const data = action.payload.map((item: any) => ({
    id: item.id,
    name: item.display_name_en,
    endPoint: 'vector-layer',
    checked: false,
    styles: {
      type:
        // eslint-disable-next-line no-nested-ternary
        item.geometry_type === 'Polygon'
          ? 'fill'
          : item.geometry_type === 'LineString'
            ? 'line'
            : 'circle',
      layout: {},
      paint: item.style || {},
    },
    showLabel: false,
    type: 'overlay-layer',
    dynamicStyle: item.style,
    isSubLayerActive: item.is_sublayer_active,
    dynamicSubLayerStyle: item.sublayer_style,
  }));
  state.layers = [...state.layers, ...data];
};

const getMapPopupDataSuccess: CaseReducer<
  VisualizationState,
  PayloadAction<VisualizationState>
> = (state, action) => ({
  ...state,
  popupData: action.payload,
});

const handleLayerSort: CaseReducer<
  VisualizationState,
  PayloadAction<DragEndEvent>
> = (state, action) => {
  const { active, over } = action.payload;
  if (!over?.id) return;
  if (active.id === over.id) return;
  const oldIndex = state.layers.findIndex(item => item.id === active.id);
  const newIndex = state.layers.findIndex(item => item.id === over.id);
  const sortedLayers = arrayMove(state.layers, oldIndex, newIndex);
  state.layers = sortedLayers;
  // @ts-ignore
  state.layerOrderChanged =
    newIndex > oldIndex ? [active.id, over.id] : [over.id, active.id];
};

const handleLayerToggle: CaseReducer<
  VisualizationState,
  PayloadAction<string | number>
> = (state, action) => {
  const id = action.payload;
  const toggledLayer = state.layers.find(item => item.id === id);
  if (!toggledLayer) return;
  toggledLayer.checked = !toggledLayer.checked;
};

const handleLayerStyleChange: CaseReducer<
  VisualizationState,
  PayloadAction<ChangeStyleType>
> = (state, action) => {
  const { layerId, styles } = action.payload;
  const layer = state.layers.find(item => item.id === layerId);
  if (!layer) return;
  const keys = Object.keys(styles);
  if (!(keys[0] in layer.styles.paint)) return;
  layer.styles.paint = {
    ...layer.styles.paint,
    ...styles,
  };
  state.changeStyle = action.payload;
};

const handle3DToggle: CaseReducer<VisualizationState> = state => {
  // state.changeStyle = {
  //   layerId: 'building',
  //   styles: {
  //     'fill-extrusion-height': !state.is3DToggled
  //       ? ['*', ['coalesce', ['get', 'floor'], 0], 3]
  //       : 0,
  //   },
  // };
  state.is3DToggled = !state.is3DToggled;
};

const handleLabelToggle: CaseReducer<
  VisualizationState,
  PayloadAction<string | number>
> = (state, action) => {
  const layer = state.layers.find(item => item.id === action.payload);
  if (!layer) return;
  layer.showLabel = !layer.showLabel;
};

const getRouteDataSuccess: CaseReducer<
  VisualizationState,
  PayloadAction<Record<string, any>>
> = (state, action) => {
  const route = {
    type: 'Feature',
    properties: {},
    bbox: action.payload.data.paths[0].bbox,
    geometry: action.payload.data.paths[0].points,
  };
  return {
    ...state,
    routesData: route,
  };
};

const visualizationSlice = createSlice({
  name: 'visualization',
  initialState,
  reducers: {
    setVisualizationState,
    setOverlayLayersState,
    // getMapPopupDataRequest(state, action: GetMapPopupDataRequestPayload) {},
    getMapPopupDataSuccess,
    getMapPopupDataFailure() {},
    handleLayerSort,
    handleLayerToggle,
    handleLayerStyleChange,
    handle3DToggle,
    handleLabelToggle,
    getRouteDataRequest() {},
    getRouteDataSuccess,
    getRouteDataFailure() {},
  },
});

export { visualizationSlice };

export default visualizationSlice.reducer;
