import useTranslation from '@Hooks/useTranslation';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import { Label, Select } from '@Components/common/FormUI';
import { FlexColumn } from '@Components/common/Layouts';
import QuerySection from './QuerySection';
import FilterResult from './FilterResult';

export default function AdvanceFilter() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();

  const layers = useTypedSelector(state => state.visualization.layers);
  const expandFilter = useTypedSelector(
    state => state.advanceFilter.expandFilter,
  );
  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );
  const hideQueryFilter = useTypedSelector(
    state => state.advanceFilter.hideQueryFilter,
  );

  const handleSelect = (selectedValue: string) => {
    dispatch(
      setAdvanceFilterState({
        selectedLayerId: selectedValue,
      }),
    );
  };

  return (
    <FlexColumn gap={3} className="naxatw-h-full">
      {!hideQueryFilter && (
        <FlexColumn className={`${expandFilter ? 'naxatw-w-1/2' : ''}`}>
          <Label>{_t('Select the layer you want to Query')}</Label>
          <Select
            options={layers}
            labelKey="name"
            valueKey="id"
            placeholder="Choose"
            selectedOption={selectedLayerId}
            onChange={handleSelect}
          />
        </FlexColumn>
      )}

      {selectedLayerId && <QuerySection />}
      <FilterResult />
    </FlexColumn>
  );
}
