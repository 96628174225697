/* eslint-disable no-param-reassign */
import { produce } from 'immer';
import type { QueryClient } from '@tanstack/react-query';

export async function handleUserTablePatch(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['user-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    const results = draft?.data?.results;
    const index = results.findIndex((item: any) => item.id === payloadData.id);
    results[index] = {
      ...results[index],
      ...payloadData,
    };
  });
  queryClient.setQueryData(keys, updatedData);
}

export async function handleUserTableAdd(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['user-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    const results = draft?.data?.results;
    results.unshift(payloadData);
  });
  queryClient.setQueryData(keys, updatedData);
}

export async function handleUserTableDelete(
  queryClient: QueryClient,
  payloadData: Record<string, any>,
) {
  const queryResults = queryClient.getQueriesData(['user-list']);
  const [keys, previousData] = queryResults[0];
  if (!previousData) return;
  await queryClient.cancelQueries({ queryKey: keys });
  const updatedData = produce(previousData, (draft: Record<string, any>) => {
    draft.data.results = draft.data.results.filter(
      (item: any) => item.id !== payloadData.id,
    );
  });
  queryClient.setQueryData(keys, updatedData);
}
