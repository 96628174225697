import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { useRef, useEffect, useState } from 'react';
import { MapInstanceType } from '@Components/common/MapLibreComponents/types';
import maplibreGl from 'maplibre-gl';
import northArrow from '@Assets/images/map/NorthArrow.svg';
import { useTypedSelector } from '@Store/hooks';
import { buildingStyles } from '@Constants/map';
import MaplibreGraticule from '@Components/common/MapLibreComponents/MapLibreGraticule';
import MapExportHeader from './MapExportHeader';
import MapExportSidebar from './MapExportSidebar';
import ExportLegend from './Legend';

// defining controls to add to the map
const scaleControl = new maplibreGl.ScaleControl({ maxWidth: 100 });
const graticule = new MaplibreGraticule({
  minZoom: 0,
  maxZoom: 20,
  showLabels: true,
  labelType: 'hdms',
  labelSize: 12,
  labelColor: '#0000ee',
  longitudePosition: 'bottom',
  latitudePosition: 'right',
  paint: {
    'line-opacity': 0.8,
    'line-color': 'rgba(255,120,0,0.9)',
    'line-dasharray': [2, 1],
  },
});

export default function MapExportSection({
  map,
}: {
  map: MapInstanceType | null;
}) {
  const [exportMap, setExportMap] = useState<MapInstanceType | null>();
  const exportMapRef = useRef<HTMLDivElement | null>(null);
  const paperRef = useRef<HTMLDivElement | null>(null);
  const fullPaperRef = useRef<HTMLDivElement | null>(null);

  const activeBaseLayer = useTypedSelector(
    state => state.visualization.activeBaseLayer,
  );
  const mapTitle = useTypedSelector(state => state.mapExport.mapTitle);
  const orientation = useTypedSelector(state => state.mapExport.orientation);
  const mapRotation = useTypedSelector(state => state.mapExport.mapRotation);
  const isLegendOn = useTypedSelector(state => state.mapExport.isLegendOn);
  const isScaleBarOn = useTypedSelector(state => state.mapExport.isScaleBarOn);
  const isNorthArrowOn = useTypedSelector(
    state => state.mapExport.isNorthArrowOn,
  );
  const isGridOn = useTypedSelector(state => state.mapExport.isGridOn);
  const baseLayerOpacity = useTypedSelector(
    state => state.mapExport.baseLayerOpacity,
  );
  const currentPaperSize = useTypedSelector(
    state => state.mapExport.currentPaperSize,
  );
  const marginValue = useTypedSelector(state => state.mapExport.marginValue);
  const marginColor = useTypedSelector(state => state.mapExport.marginColor);
  const marginTop = useTypedSelector(state => state.mapExport.marginTop);
  const marginLeft = useTypedSelector(state => state.mapExport.marginLeft);
  const marginBottom = useTypedSelector(state => state.mapExport.marginBottom);
  const marginRight = useTypedSelector(state => state.mapExport.marginRight);
  const is3DToggled = useTypedSelector(
    state => state.visualization.is3DToggled,
  );

  // gets all the layers and styles from existing map
  useEffect(() => {
    if (!map) return;
    const style = map?.getStyle();
    if (style && style.sources) {
      const { sources } = style;
      Object.keys(sources).forEach(name => {
        const src: { [key: string]: any } = sources[name];
        Object.keys(src).forEach(key => {
          // delete properties if value is undefined.
          if (!src[key]) {
            delete src[key];
          }
        });
      });
    }

    // creates new instance of the map with same styles
    const mapInstance = new maplibreGl.Map({
      container: exportMapRef.current!,
      style,
      center: map.getCenter(),
      zoom: map.getZoom(),
      bearing: map.getBearing(),
      pitch: map.getPitch(),
      interactive: true,
      preserveDrawingBuffer: true,
      fadeDuration: 0,
      attributionControl: true,
    });
    setExportMap(mapInstance);
  }, [map]);

  // sets the map rotation
  useEffect(() => {
    if (!exportMap || !mapRotation) return;
    exportMap.setBearing(+mapRotation);
  }, [exportMap, mapRotation]);

  //  sets the opacity of the base layer
  useEffect(() => {
    if (!exportMap) return;
    exportMap.setPaintProperty(
      activeBaseLayer,
      'raster-opacity',
      baseLayerOpacity / 100,
    );
  }, [exportMap, baseLayerOpacity, activeBaseLayer]);

  // sets scale bar in the mapf
  useEffect(() => {
    if (!exportMap) return;
    const hasScale = exportMap.hasControl(scaleControl);

    if (isScaleBarOn && !hasScale) {
      exportMap.addControl(scaleControl);
    } else if (hasScale && !isScaleBarOn) {
      exportMap.removeControl(scaleControl);
    }
  }, [exportMap, isScaleBarOn]);

  // sets the graticule (grid)
  useEffect(() => {
    if (!exportMap) return;
    const hasGrid = exportMap.hasControl(graticule);
    if (isGridOn && !hasGrid) {
      exportMap.addControl(graticule);
    } else if (hasGrid && !isGridOn) {
      exportMap.removeControl(graticule);
    }
  }, [exportMap, isGridOn]);

  // resizes the map when container dimesion changes
  useEffect(() => {
    if (!exportMap) return;
    setTimeout(() => {
      exportMap.resize();
    }, 500);
  }, [exportMap, orientation]);

  // handle 3D toggle & pitch change
  useEffect(() => {
    if (!exportMap) return;
    exportMap.setPaintProperty(
      'building',
      'fill-extrusion-height',
      is3DToggled ? buildingStyles.paint['fill-extrusion-height'] : 0,
    );
    // set building opacity to 1 on 3D toggle
    exportMap.setPaintProperty(
      'building',
      'fill-extrusion-opacity',
      is3DToggled ? 1 : 0.5,
    );
    setTimeout(() => {
      exportMap.easeTo({
        duration: 1000,
        pitch: is3DToggled ? 60 : 0,
      });
    }, 10);
  }, [exportMap, is3DToggled]);

  // handles the paperSize
  const handleSize = (paperSize: number[]) => {
    let size = [
      Math.trunc((paperSize[0] * 96) / 25.4),
      Math.trunc((paperSize[1] * 96) / 25.4),
    ];
    if (orientation === 'landscape') {
      size = [size[1], size[0]];
    }
    if (!fullPaperRef.current || !paperRef.current) return;
    const s = fullPaperRef.current.getBoundingClientRect();
    const scx = (s.width - 40) / size[0];
    const scy = (s.height - 40) / size[1];
    const sc = Math.min(scx, scy, 1);
    paperRef.current.style.width = `${size[0]}px`;
    paperRef.current.style.height = `${size[1]}px`;
    paperRef.current.style.transform = `scale(${sc})`;
  };

  // handles size when orientation changes
  useEffect(() => {
    handleSize(currentPaperSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPaperSize, orientation]);

  // handles size and maintains ratio when window resizes
  window.onresize = () => {
    handleSize(currentPaperSize);
  };

  return (
    <div className="naxatw-absolute naxatw-top-0 naxatw-z-[150] naxatw-h-full naxatw-w-full naxatw-bg-grey-200">
      <MapExportHeader />
      <FlexRow className="naxatw-h-[calc(100%-64px)] naxatw-w-full">
        <MapExportSidebar exportMap={exportMap || null} paperRef={paperRef} />
        <div
          ref={fullPaperRef}
          className="naxatw-grid naxatw-h-full naxatw-w-[75%] naxatw-place-content-center"
        >
          <div>
            <div
              ref={paperRef}
              className="naxatw-w-full naxatw-bg-white naxatw-shadow-xl"
              style={{
                paddingTop: marginTop,
                paddingLeft: marginLeft,
                paddingRight: marginRight,
                paddingBottom: marginBottom,
              }}
            >
              <div
                className={`naxatw-flex naxatw-h-full naxatw-w-full naxatw-bg-white ${
                  orientation === 'landscape'
                    ? 'naxatw-flex-row'
                    : 'naxatw-flex-col'
                }`}
                style={{ padding: marginValue, backgroundColor: marginColor }}
              >
                <div
                  ref={exportMapRef}
                  className={`naxatw-relative ${
                    orientation === 'landscape'
                      ? 'naxatw-h-full naxatw-w-[71%]'
                      : 'naxatw-h-[75%] naxatw-w-full'
                  }`}
                >
                  {/* northArrow on the map */}
                  {isNorthArrowOn && (
                    <img
                      src={northArrow}
                      className="naxatw-absolute naxatw-right-3 naxatw-top-3 naxatw-z-[150] naxatw-h-[3rem] naxatw-w-[2rem]"
                      alt="north arrow"
                      style={{ transform: `rotate(-${+mapRotation}deg)` }}
                    />
                  )}
                </div>
                <div
                  className={`naxatw-bg-white ${
                    orientation === 'landscape'
                      ? 'naxatw-h-full naxatw-w-[29%]'
                      : 'naxatw-h-[25%] naxatw-w-full'
                  }`}
                >
                  <FlexColumn gap={3}>
                    <h4 className="naxatw-w-[100%] naxatw-bg-grey-200 naxatw-px-4 naxatw-py-3 naxatw-font-primary naxatw-text-[1.125rem] naxatw-font-bold naxatw-tracking-wider">
                      {mapTitle || 'Map Title'}
                    </h4>
                    {isLegendOn && <ExportLegend />}
                  </FlexColumn>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FlexRow>
    </div>
  );
}
