/* eslint-disable import/prefer-default-export */
import { WorkshopModeSlice } from '../slices/workshopMode';

export const {
  setWorkshopModeState,
  handleLayerSort,
  handleLayerToggle,
  handleLayerStyleChange,
  handleLabelToggle,
  resetWorkshopModeState,
} = WorkshopModeSlice.actions;
