/* eslint-disable import/prefer-default-export */

export type InfoDialogContentsType =
  | 'add-user-success'
  | 'edit-user-success'
  | 'delete-user-success'
  | 'delete-building-success'
  | 'delete-road-success'
  | 'add-building-success'
  | 'edit-building-success'
  | 'add-road-success'
  | 'edit-road-success'
  | 'add-physical-installation-success'
  | 'edit-physical-installation-success'
  | 'delete-layer-success'
  | 'overlay-layer-file-upload-error'
  | 'error-on-form-submit'
  | null;

type InfoDialogReturnType = {
  status?: string;
  title?: string;
  description?: string;
};

export function getInfoDialogContent(
  content: InfoDialogContentsType,
): InfoDialogReturnType {
  switch (content) {
    case 'add-user-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'User Added Successfully',
      };
    case 'edit-user-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'User Edited Successfully',
      };
    case 'delete-user-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'User Deleted Successfully',
      };
    case 'delete-building-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Building Deleted Successfully',
      };
    case 'delete-road-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Road Deleted Successfully',
      };
    case 'add-building-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Building Added Successfully',
      };
    case 'add-road-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Road Added Successfully',
      };
    case 'edit-building-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Building Edited Successfully',
      };
    case 'edit-road-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Road Edited Successfully',
      };
    case 'add-physical-installation-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Physical Installation Added Successfully',
      };
    case 'edit-physical-installation-success':
      return {
        status: 'success',
        title: 'Success',
        description: 'Physical Installation Edited Successfully',
      };
    case 'delete-layer-success': {
      return {
        status: 'success',
        title: 'Success',
        description: 'Layer Deleted Successfully',
      };
    }
    case 'overlay-layer-file-upload-error': {
      return {
        status: 'error',
        title: 'Invalid Data',
        description: 'Something went wrong on your uploaded file',
      };
    }
    case 'error-on-form-submit':
      return {
        status: 'error',
        title: 'Error',
        description:
          'Something went wrong retry with another data or try again later.',
      };

    default:
      return {
        status: '',
        title: '',
      };
  }
}
