import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn } from '@Components/common/Layouts';

export default function FormSkeleton() {
  return (
    <FlexColumn
      gap={8}
      className="naxatw-h-screen naxatw-w-full naxatw-animate-fade naxatw-items-center naxatw-justify-center"
    >
      {Array.from({ length: 5 }).map((__, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FlexColumn key={index} gap={3} className="naxatw-h-fit naxatw-w-1/3 ">
          <Skeleton className="naxatw-h-6 naxatw-w-1/2" />
          <Skeleton className="naxatw-h-8 naxatw-w-full " />
        </FlexColumn>
      ))}
    </FlexColumn>
  );
}
