import { FlexColumn } from '../Layouts';

export default function PageNotFound() {
  return (
    <FlexColumn
      gap={3}
      className="naxatw-h-screen naxatw-w-full naxatw-items-center naxatw-justify-center"
    >
      <h1 className="naxatw-text-[6rem] naxatw-text-black">404</h1>
      <h5>OOPS! PAGE NOT FOUND.</h5>
    </FlexColumn>
  );
}
