import { Button } from '@Components/RadixComponents/Button';
import { FlexColumn } from '@Components/common/Layouts';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';

interface IAddZoomLevel {
  disabled: boolean;
  handleAddClick: () => void;
}

function AddZoomLevel({ disabled = false, handleAddClick }: IAddZoomLevel) {
  return (
    <FlexColumn className="naxatw-w-40 naxatw-px-2 naxatw-pt-4">
      <Button
        variant="outline"
        leftIcon="add"
        disabled={disabled}
        onClick={handleAddClick}
      >
        Add Zoom Level
      </Button>
    </FlexColumn>
  );
}

export default hasErrorBoundary(AddZoomLevel);
