import { useTypedSelector } from '@Store/hooks';
import useTranslation from '@Hooks/useTranslation';
import { selectedWardSelector } from '@Store/selector/common';
import { dashboradChart } from '@Constants/dashboard';
import { Flex, FlexColumn } from '@Components/common/Layouts';
import ChartRenderer from '@Components/common/Charts/ChartRenderer';
import DashboardHeader from './DashboardHeader';
import DataCardSection from './DataCardSection';
import MapSection from './MapSection';
import RoadByCategory from './RoadByCategory';
import BuildingRegistration from './BuildingByRegistration';
import WardDetailTable from './WardDetailTable';
import UserLogs from './UserLogs';
import UserDetail from './UserDetail';

export default function DashboardComponent() {
  const { _t } = useTranslation();
  const permissions = useTypedSelector(state => state.user.permissions);
  const selectedWards = useTypedSelector(selectedWardSelector);

  return (
    <FlexColumn
      gap={5}
      className="dashboard naxatw-w-full naxatw-px-5 naxatw-py-4"
    >
      <DashboardHeader />
      <DataCardSection />
      <Flex gap={5} className="xl:naxatw-grid xl:naxatw-grid-cols-3">
        <FlexColumn
          className="map-container naxatw-col-span-2 naxatw-h-full naxatw-animate-fade-down
          naxatw-rounded-xl naxatw-border naxatw-bg-grey-50 lg:naxatw-h-[490px]"
        >
          <MapSection />
        </FlexColumn>
        <Flex className="naxatw-gap-5 md:naxatw-grid md:naxatw-grid-cols-2 xl:naxatw-grid-cols-1">
          <BuildingRegistration />
          <RoadByCategory />
        </Flex>
      </Flex>

      <FlexColumn gap={12} className="naxatw-w-full naxatw-animate-fade">
        {Object.entries(dashboradChart).map(
          ([id, { title, contents, layout }]) => (
            <div key={id}>
              <FlexColumn gap={5} className="naxatw-w-full">
                <h3 className="naxatw-text-[23px]">{_t(title)}</h3>
                <div
                  className={`naxatw-gap-5 ${
                    layout === 'flex'
                      ? 'naxatw-grid md:naxatw-grid-cols-2 xl:naxatw-grid-cols-3'
                      : 'naxatw-grid md:naxatw-grid-cols-2 xl:naxatw-grid-cols-3'
                  }`}
                >
                  {contents.map(data => (
                    <ChartRenderer
                      key={data.id}
                      data={data}
                      filterParams={
                        selectedWards
                          ? {
                              ward_no: selectedWards.join(','),
                            }
                          : null
                      }
                    />
                  ))}
                </div>
              </FlexColumn>
            </div>
          ),
        )}
      </FlexColumn>
      <Flex
        className="naxatw-grid naxatw-animate-fade-up naxatw-grid-cols-1 md:naxatw-h-[360px]
         md:naxatw-grid-cols-2 xl:naxatw-grid-cols-3"
        gap={5}
      >
        <WardDetailTable />
        {permissions.includes('view_user') && <UserDetail />}
        {permissions.includes('view_user') && <UserLogs />}
      </Flex>
    </FlexColumn>
  );
}
