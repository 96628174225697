import { Fragment, useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, Grid } from '@Components/common/Layouts';
import { FormFieldType, formSchemaType } from '@Constants/dataManagementForm';
import ErrorMessage from '@Components/common/FormUI/ErrorMessage';
import { UseFormPropsType } from '@Components/DataManagement/MultiStepForm/types';
import getInputElement from '@Components/DataManagement/MultiStepForm/FormSection/getInputElement';
import MapSection from '@Components/DataManagement/OverlayLayers/MultiStepForm/MapSection';
import InfoDialog from '@Components/common/InfoDialog';
import { getInfoDialogContent } from '@Constants/getInfoDialogContent';
import { toggleInfoDialog } from '@Store/actions/common';
import { infoDialogTimeoutValue } from '@Constants/index';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import TableFormSection from '../TableFormSection';

interface IFormSectionProps {
  schema: formSchemaType;
  formProps: UseFormPropsType;
  activeStep: string;
}

function renderFormGroups(
  data: FormFieldType[],
  formProps: UseFormPropsType,
  dropdownOptions: Record<string, any>,
) {
  return (
    <Grid className="naxatw-w-[65%] naxatw-py-4" gap={7}>
      {data.map(child => (
        <FormControl key={child.id} className="naxatw-w-10/12 ">
          <Label required={child.required}>{child.label}</Label>
          {getInputElement(child, { ...formProps }, dropdownOptions)}
          {formProps.errors[child.id] && (
            /* @ts-ignore */
            <ErrorMessage message={formProps.errors[child.id].message} />
          )}
        </FormControl>
      ))}
    </Grid>
  );
}

function FormSection({ schema, formProps, activeStep }: IFormSectionProps) {
  const dispatch = useTypedDispatch();
  const dropdownOptions = useTypedSelector(
    state => state.overlayLayer.dropdownOptions,
  );
  const isAssociateDissociateType = useTypedSelector(
    state => state.dataManagementForm.isAssociateDissociateType,
  );
  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);
  const infoDialogContent = useTypedSelector(
    state => state.common.infoDialogContent,
  );

  useEffect(() => {
    if (!getInfoDialogContent) return () => {};
    const timer = setTimeout(
      () => dispatch(toggleInfoDialog()),
      infoDialogTimeoutValue,
    );
    return () => {
      clearTimeout(timer);
    };
  }, [dispatch, infoDialogContent]);

  if (!schema) return <h3>No data</h3>;
  if (schema === 'map') return <MapSection activeStep={activeStep} />;
  if (schema === 'table')
    return (
      <TableFormSection
        inputType={activeStep === 'attributes alias' ? 'input' : 'checkbox'}
        label={
          activeStep === 'attributes alias'
            ? 'Select fields to make public'
            : 'Select public fields'
        }
      />
    );

  const filteredData = schema.filter(data =>
    isAssociateDissociateType ? data.id !== 'grid3' : data,
  );

  return (
    <FlexColumn
      gap={10}
      className="scrollbar naxatw-h-[100vh-2rem] naxatw-items-center naxatw-overflow-auto naxatw-pt-5"
    >
      {showInfoDialog && (
        <FlexColumn className="naxatw-w-full md:naxatw-w-[40%]">
          <InfoDialog
            status={getInfoDialogContent(infoDialogContent)?.status}
            title={getInfoDialogContent(infoDialogContent)?.title}
            description={getInfoDialogContent(infoDialogContent)?.description}
            onClose={() => dispatch(toggleInfoDialog())}
          />
        </FlexColumn>
      )}

      {filteredData.map(data => {
        const { id, label, required, children, layout, type } = data;

        // show schema in form group for grid layout
        if (children && layout === 'grid') {
          return (
            <Fragment key={id}>
              {renderFormGroups(children, formProps, dropdownOptions)}
            </Fragment>
          );
        }

        return (
          <FormControl
            key={id}
            className={`naxatw-w-full ${
              type === 'switch'
                ? '!naxatw-flex-row !naxatw-justify-between'
                : ''
            }`}
          >
            <Label required={required}>{label}</Label>
            {getInputElement(data, formProps, dropdownOptions)}
            {formProps.errors[id] && (
              /* @ts-ignore */
              <ErrorMessage message={formProps.errors[id].message} />
            )}
          </FormControl>
        );
      })}
    </FlexColumn>
  );
}

export default hasErrorBoundary(FormSection);
