/* eslint-disable no-unused-vars */
import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

type queryFnParamsType = {
  ward_no?: string[];
  road_type?: string[];
  reg_type?: string[];
  tole_name?: string[];
};

interface RoadDataState {
  searchInput: string;
  queryFnParams: queryFnParamsType;
  selectedRoad: Record<string, any> | null;
}

interface BuildingDataState {
  searchInput: string;
  queryFnParams: queryFnParamsType;
  selectedBuilding: Record<string, any> | null;
}

interface overlayState {
  searchInput: string;
}

export interface DataManagementState {
  showFilterPopover: boolean;
  roadData: RoadDataState;
  buildingData: BuildingDataState;
  overlayLayerData: overlayState;
}

const initialState: DataManagementState = {
  showFilterPopover: false,
  roadData: {
    searchInput: '',
    queryFnParams: {
      ward_no: [],
      road_type: [],
    },
    selectedRoad: null,
  },
  buildingData: {
    searchInput: '',
    queryFnParams: {
      ward_no: [],
      reg_type: [],
      tole_name: [],
    },
    selectedBuilding: null,
  },
  overlayLayerData: {
    searchInput: '',
  },
};

const setDataManagementState: CaseReducer<
  DataManagementState,
  PayloadAction<Partial<DataManagementState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const setRoadDataState: CaseReducer<
  DataManagementState,
  PayloadAction<Partial<RoadDataState>>
> = (state, action) => ({
  ...state,
  roadData: {
    ...state.roadData,
    ...action.payload,
  },
});

const setBuildingDataState: CaseReducer<
  DataManagementState,
  PayloadAction<Partial<BuildingDataState>>
> = (state, action) => ({
  ...state,
  buildingData: {
    ...state.buildingData,
    ...action.payload,
  },
});

const setOverlayLayerState: CaseReducer<
  DataManagementState,
  PayloadAction<Partial<overlayState>>
> = (state, action) => ({
  ...state,
  overlayLayerData: {
    ...state.overlayLayerData,
    ...action.payload,
  },
});

type resetPayloadType = {
  stateName: string;
  key?: string;
  val?: string | number;
};

const handleFilterReset: CaseReducer<
  DataManagementState,
  PayloadAction<resetPayloadType>
> = (state, { payload }) => {
  const { stateName } = payload;
  return {
    ...state,
    [stateName]: {
      // @ts-ignore
      ...state[stateName],
      queryFnParams: !payload.key
        ? // @ts-ignore
          initialState[stateName].queryFnParams
        : {
            // @ts-ignore
            ...state[stateName].queryFnParams,
            // @ts-ignore
            [payload.key]: state[stateName].queryFnParams[payload.key].filter(
              // @ts-ignore
              item => item !== payload.val,
            ),
          },
    },
  };
};

const resetDataManagementState: CaseReducer<DataManagementState> = state => ({
  ...state,
  showFilterPopover: initialState.showFilterPopover,
});

const dataManagementSlice = createSlice({
  name: 'dataManagement',
  initialState,
  reducers: {
    setDataManagementState,
    setRoadDataState,
    setBuildingDataState,
    handleFilterReset,
    resetDataManagementState,
    setOverlayLayerState,
  },
});

export { dataManagementSlice };

export default dataManagementSlice.reducer;
