import { useEffect } from 'react';
import getInputElement from '@Components/DataManagement/MultiStepForm/FormSection/getInputElement';
import { Button } from '@Components/RadixComponents/Button';
import ErrorMessage from '@Components/common/ErrorMessage';
import removeObjectKeys from '@Utils/removeObjectKeys';
import { FormControl, Label } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { physicalInstallationFormSchema } from '@Constants/physicalInstallationForm';
import {
  handlePhysicalInstallationAdd,
  handlePhysicalInstallationPatch,
} from '@Queries/physicalInstallation';
import {
  postPhysicalInstallation,
  updatePhysicalInstallation,
} from '@Services/physicalInstallation';
import { toggleInfoDialog, toggleModal } from '@Store/actions/common';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import prepareFormData from '@Utils/prepareFormData';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getDirtyValues } from '@Components/UserManagement/ModalContent/AddUser';
import isEmpty from '@Utils/isEmpty';
import { setPhysicalInstallationState } from '@Store/actions/physicalInstallation';

export default function AddPhysicalInstallation() {
  const queryClient = useQueryClient();
  const dispatch = useTypedDispatch();
  const modalContent = useTypedSelector(state => state.common.modalContent);
  const selectedInstallation = useTypedSelector(
    state => state.physicalInstallation.selectedInstallation,
  );
  const formType =
    modalContent === 'add-physical-installation' ? 'add' : 'edit';
  const {
    handleSubmit,
    formState: { errors },
    register,
    getValues,
    control,
    reset,
    setValue,
    formState: { dirtyFields },
  } = useForm({ mode: 'all' });

  const handleModalClose = () => {
    dispatch(toggleModal());
    dispatch(
      setPhysicalInstallationState({
        selectedInstallation: null,
      }),
    );
    dispatch(
      toggleInfoDialog(
        formType === 'add'
          ? 'add-physical-installation-success'
          : 'edit-physical-installation-success',
      ),
    );
  };

  const { mutate: updateTableData } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'add'
        ? handlePhysicalInstallationAdd(queryClient, payloadData)
        : handlePhysicalInstallationPatch(queryClient, payloadData),
  });

  const { mutate: postPhysicalInstallationData, isLoading } = useMutation({
    mutationFn: (payloadData: Record<string, any>) =>
      formType === 'edit'
        ? updatePhysicalInstallation(selectedInstallation?.id, payloadData)
        : postPhysicalInstallation(payloadData),
    onSuccess: (res: { data: { details: Record<string, any> } }) => {
      updateTableData({ id: selectedInstallation?.id, ...res.data.details });
      handleModalClose();
    },
    onError: (error: any) => {
      toast.error(error.response.data.message);
    },
  });
  const formProps = { register, control, errors, setValue, getValues };
  const dropdownOptions = {};

  const onSubmit = (values: Record<string, any>) => {
    const payload: Record<string, any> =
      formType === 'add' ? values : getDirtyValues(dirtyFields, values);
    const modifiedPayload: Record<string, any> = {
      ...payload,
    };
    if (isEmpty(modifiedPayload)) {
      handleModalClose();
      return;
    }
    postPhysicalInstallationData(prepareFormData(modifiedPayload));
  };

  // this is for edit installation form
  useEffect(() => {
    if (!selectedInstallation || modalContent !== 'edit-physical-installation')
      return;
    // replace thumbnail to image key
    const data = {
      ...removeObjectKeys(selectedInstallation, ['thumbnail']),
    };
    // reset form data before setting values
    reset();
    // set form state values
    Object.entries(data).forEach(([name, value]: any) => setValue(name, value));
  }, [selectedInstallation, modalContent, setValue, reset]);

  return (
    <FlexColumn gap={2}>
      <FlexColumn
        gap={5}
        className="scrollbar naxatw-max-h-[calc(100vh-18rem)] naxatw-items-center naxatw-overflow-y-auto"
      >
        {physicalInstallationFormSchema.map(data => {
          const { id, label, required } = data;
          return (
            <FormControl key={id} className="naxatw-w-full">
              <Label required={required}>{label}</Label>
              {getInputElement({ ...data }, formProps, dropdownOptions || {})}
              {formProps.errors[id] && (
                <ErrorMessage
                  className="naxatw-mt-2"
                  /* @ts-ignore */
                  message={formProps.errors[id]?.message}
                />
              )}
            </FormControl>
          );
        })}
      </FlexColumn>

      <FlexRow className=" naxatw-w-full naxatw-justify-center">
        <Button variant="ghost" onClick={() => dispatch(toggleModal())}>
          Cancel
        </Button>
        <Button
          withLoader
          isLoading={isLoading}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        >
          {formType === 'add' ? 'Add' : 'Save'}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
