import axios from 'axios';
import { api, authenticated } from '.';

const { GRAPHHOPPER_URL } = process.env;

export const getMapPopupData = (params: any) =>
  authenticated(api).get('/map-popup/', {
    params,
  });

export const getBuildingData = (id: any) =>
  authenticated(api).get(`/building-data/${id}/`);

export const getRoadData = (id: any) =>
  authenticated(api).get(`/road-data/${id}/`);

export const getBuildingImages = (params: any) =>
  authenticated(api).get(`/building-images/`, { params });

export const postBuildingImages = (data: any) =>
  authenticated(api).post(`/building-images/`, data);

export const getOverlayLayers = () => authenticated(api).get(`/vector-layer/`);

export const getGraphopperRoute = (params: any) =>
  axios.get(`${GRAPHHOPPER_URL}/route?${params}`);
export const getLayerAttribute = (params: any) =>
  authenticated(api).get(`/visualization/fields/`, { params });

export const getLayerAttributeValues = (params: any) =>
  authenticated(api).get(`/visualization/field-values/`, { params });

export const getBuildingAdvanceFiltering = (params: any) =>
  authenticated(api).get(`/building-advance-filtering/`, { params });

export const getRoadAdvanceFiltering = (params: any) =>
  authenticated(api).get(`/road-advance-filtering/`, { params });

export const getBuildingPlanFilter = (params: any) =>
  authenticated(api).get(`/visualization/building-plan-filter/`, { params });

export const getFilteredOverallBoundary = (params: any) =>
  authenticated(api).get(`/filtered-overall-boundary/`, { params });

export const getBuildingPlanBoundary = (params: any) =>
  authenticated(api).get(`/visualization/building-plan-boundary/`, { params });

export const getHistoryLogs = (params: any) =>
  authenticated(api).get(`/history-logs/`, { params });

export const updateRestoreHistoryLog = (id: any, payload: any) =>
  authenticated(api).patch(`/restore-history/${id}/`, payload);

export const generateHouseNumbers = (params: any) =>
  authenticated(api).get(`/generate_house_numbers/`, { params });
