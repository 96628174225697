interface IUserAvatarProps {
  name: string;
  bgColor: string;
}

export default function UserAvatar({ name, bgColor }: IUserAvatarProps) {
  const nameParts = name?.split(' ');
  const firstNameInitial = nameParts?.[0] ? nameParts?.[0][0] : '';

  return (
    <div
      className="naxatw-flex naxatw-h-6 naxatw-w-6 naxatw-items-center naxatw-justify-center
     naxatw-rounded-full naxatw-text-xs naxatw-font-bold naxatw-text-white"
      style={{ backgroundColor: bgColor }}
    >
      {firstNameInitial || '-'}
    </div>
  );
}
