/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-unstable-nested-components */
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import DataTable from '@Components/common/DataTable';
import { getPhysicalInstallation } from '@Services/physicalInstallation';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { toggleModal } from '@Store/actions/common';
import { setPhysicalInstallationState } from '@Store/actions/physicalInstallation';
import useWindowDimensions from '@Hooks/useWindowDimensions';

export default function TableSection() {
  const dispatch = useTypedDispatch();
  const { width } = useWindowDimensions();

  const searchInput = useTypedSelector(
    state => state.physicalInstallation.searchInput,
  );
  const permissions = useTypedSelector(state => state.user.permissions);

  const installationColumns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Planned',
      accessorKey: 'planned',
    },
    {
      header: 'Installed',
      accessorKey: 'installed',
    },
    {
      header: 'Remaining',
      accessorKey: 'remaining',
    },
    {
      header: 'Planned Installation Date',
      accessorKey: 'planned_date',
    },
    {
      header: 'Eta(Months)',
      accessorKey: 'eta',
    },
    {
      header: 'Date',
      accessorKey: 'updated_date',
    },
    {
      header: '',
      accessorKey: 'icon',
      cell: ({ row }: any) => (
        <FlexRow className="naxatw-cursor-pointer naxatw-justify-around">
          {permissions.includes('change_physicalinstallation') && (
            <ToolTip
              name="edit"
              message="Edit"
              onClick={() => {
                dispatch(toggleModal('edit-physical-installation'));
                dispatch(
                  setPhysicalInstallationState({
                    selectedInstallation: row.original,
                  }),
                );
              }}
            />
          )}
        </FlexRow>
      ),
    },
  ];

  const showInfoDialog = useTypedSelector(state => state.common.showInfoDialog);

  return (
    <DataTable
      columns={installationColumns}
      queryKey="physical-installation-list"
      queryFn={getPhysicalInstallation}
      initialState={{
        paginationState: {
          pageIndex: 0,
          pageSize: 25,
        },
      }}
      searchInput={searchInput}
      wrapperStyle={{
        height:
          width <= 390
            ? '60vh'
            : showInfoDialog
              ? 'calc(100vh - 15rem)'
              : 'calc(100vh - 11.5rem)',
      }}
    />
  );
}
