import { dataManagementFormSlice } from '../slices/dataManagementForm';

export const {
  setDataManagementFormState,
  setActiveIndex,
  setFormState,
  getDropdownOptionsRequest,
  getDropdownOptionsSuccess,
  getDropdownOptionsFailure,
  postFormDataRequest,
  postFormDataSuccess,
  postFormDataFailure,
  updateFormDataRequest,
  updateFormDataSuccess,
  updateFormDataFailure,
  setFormStateData,
  getBuildingDataByIdRequest,
  getBuildingDataByIdSuccess,
  getBuildingDataByIdFailure,
  resetDataManagementFormState,
  getRoadDataByIdRequest,
  getRoadDataByIdSuccess,
  getRoadDataByIdFailure,
  handleLayerSort,
  handleLayerToggle,
  handleLabelToggle,
  handleLayerStyleChange,
} = dataManagementFormSlice.actions;
