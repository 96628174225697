import { useLocation, useNavigate } from 'react-router-dom';
import { useTypedSelector } from '@Store/hooks';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { Flex, FlexColumn, FlexRow } from '@Components/common/Layouts';
import SidebarSection from './SidebarSection';
import AttributeSection from './AttributeSection';
import GeometrySection from './GeometrySection';

export default function EditHistory() {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const detailType = pathname.includes('building') ? 'building' : 'road';

  const historyLogs = useTypedSelector(state => state.editHistory.historyLogs);

  const lastLog = historyLogs ? historyLogs[historyLogs.length - 1] : null;

  function getLogTitle() {
    if (!lastLog) return '';
    if (detailType === 'building') {
      return `${lastLog.house_no} - ${lastLog.associate_road_name}`;
    }
    return `${lastLog.road_name_en}`;
  }
  return (
    <FlexColumn
      gap={3}
      className="naxatw-h-screen naxatw-w-full  naxatw-px-4 naxatw-py-2"
    >
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <FlexRow gap={2} className="naxatw-items-center naxatw-justify-center">
          <ToolTip
            name="arrow_back"
            message="Go back"
            onClick={() => navigate(-1)}
          />
          <h5>Edit History </h5>
          <h5 className="!naxatw-font-normal naxatw-capitalize">
            {getLogTitle()}
          </h5>
        </FlexRow>
        <ToolTip name="close" message="Close" onClick={() => navigate(-1)} />
      </FlexRow>
      <Flex className="naxatw-w-full md:naxatw-flex-row" gap={2}>
        <SidebarSection />
        {historyLogs?.length ? (
          <Flex className="naxatw-grid naxatw-w-full md:naxatw-grid-cols-2">
            <AttributeSection />
            <GeometrySection />
          </Flex>
        ) : (
          <h5>No logs yet</h5>
        )}
      </Flex>
    </FlexColumn>
  );
}
