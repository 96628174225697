import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import {
  getPalikaProfile,
  getOverallBuildingBbox,
  fetchPalikaWardGeojson,
} from '@Services/common';
import { getUserPermissions, getUserProfile } from '@Services/user';
import { getWardNoList } from '@Services/dashboard';

export const useGetPalikaProfileQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['palika-profile'],
    queryFn: () => getPalikaProfile(),
    ...queryOptions,
  });
};

export const useGetOverallBuildingBboxQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['building-bbox'],
    queryFn: getOverallBuildingBbox,
    ...queryOptions,
  });
};

export const useGetUserPermissionsQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['user-permissions'],
    queryFn: () => getUserPermissions(),
    ...queryOptions,
  });
};

export const useGetUserProfileQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['user-profile'],
    queryFn: () => getUserProfile(),
    ...queryOptions,
  });
};

export const useGetWardNoListQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['ward-no-list'],
    queryFn: getWardNoList,
    ...queryOptions,
  });
};

export const useGetPalikaWardGeojsonQuery = (
  ward?: any,
  queryOptions?: Partial<UseQueryOptions>,
): Record<string, any> => {
  return useQuery({
    queryKey: ['palika-ward-geojson', ward],
    queryFn: () =>
      fetchPalikaWardGeojson({
        ward_no: ward,
      }),
    select: (res: any) => res?.data?.features[0]?.geometry,
    enabled: !!ward,
    ...queryOptions,
  });
};
