import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { FormControl, Label, MultiSelect } from '@Components/common/FormUI';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { allWardsSelector } from '@Store/selector/common';
import { setAdvanceFilterState } from '@Store/actions/advanceFilter';
import {
  useGetBuildingRegListQuery,
  useGetOwnerStatusListQuery,
  useGetToleNameListQuery,
} from '@Api/dataManagement';
import { Button } from '@Components/RadixComponents/Button';
import useTranslation from '@Hooks/useTranslation';

export default function FilterSection() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const filterType = useTypedSelector(state => state.advanceFilter.filterType);
  const selectedLayerId = useTypedSelector(
    state => state.advanceFilter.selectedLayerId,
  );

  const selectedWards = useTypedSelector(
    state => state.advanceFilter.selectedWards,
  );
  const selectedTole = useTypedSelector(
    state => state.advanceFilter.selectedTole,
  );
  const selectedOwnerStatus = useTypedSelector(
    state => state.advanceFilter.selectedOwnerStatus,
  );
  const selectedBuildingType = useTypedSelector(
    state => state.advanceFilter.selectedBuildingType,
  );

  const allWards = useTypedSelector(allWardsSelector);
  const { data: toleNameOptions } = useGetToleNameListQuery();
  const { data: buildingRegistrationType } = useGetBuildingRegListQuery();
  const { data: ownerStatus } = useGetOwnerStatusListQuery();

  const handleWardFilter = (updatedSelected: string[]) => {
    dispatch(
      setAdvanceFilterState({
        selectedWards: updatedSelected,
      }),
    );
  };
  const handleToleFilter = (updatedSelected: string[]) => {
    dispatch(
      setAdvanceFilterState({
        selectedTole: updatedSelected,
      }),
    );
  };
  const handleOwnerStatusFilter = (updatedSelected: string[]) => {
    dispatch(
      setAdvanceFilterState({
        selectedOwnerStatus: updatedSelected,
      }),
    );
  };
  const handleBuildingRegFilter = (updatedSelected: string[]) => {
    dispatch(
      setAdvanceFilterState({
        selectedBuildingType: updatedSelected,
      }),
    );
  };

  const handleApplyFilter = () => {
    dispatch(
      setAdvanceFilterState({
        basicFilterParams: {
          ward_no: selectedWards,
          tole_name: selectedTole,
          owner_status: selectedOwnerStatus,
          reg_type: selectedBuildingType,
        },
      }),
    );
  };

  const isFilterApplied =
    !!selectedWards.length ||
    !!selectedTole?.length ||
    !!selectedOwnerStatus.length ||
    !!selectedBuildingType.length;

  return (
    <FlexColumn gap={3}>
      {selectedLayerId && (
        <FlexColumn gap={3} className="naxatw-grid naxatw-grid-cols-2">
          <FormControl>
            <Label>Ward</Label>
            <MultiSelect
              options={allWards || []}
              selectedOptions={selectedWards}
              onChange={handleWardFilter}
              placeholder="All Ward"
            />
          </FormControl>
          <FormControl>
            <Label>Tole</Label>
            <MultiSelect
              options={toleNameOptions}
              selectedOptions={selectedTole}
              onChange={handleToleFilter}
              placeholder="Choose Tole"
            />
          </FormControl>
          <FormControl>
            <Label>Building Ownership</Label>
            <MultiSelect
              options={ownerStatus || []}
              selectedOptions={selectedOwnerStatus}
              onChange={handleOwnerStatusFilter}
              placeholder="Choose Building Ownership"
            />
          </FormControl>
          <FormControl>
            <Label>Building Registration</Label>
            <MultiSelect
              options={buildingRegistrationType}
              selectedOptions={selectedBuildingType}
              onChange={handleBuildingRegFilter}
              placeholder="Choose Building Registration"
            />
          </FormControl>
        </FlexColumn>
      )}
      {/* button */}
      <FlexRow gap={2}>
        <Button
          variant="outline"
          onClick={() =>
            dispatch(
              setAdvanceFilterState({
                filterType: filterType === 'advance' ? 'basic' : 'advance',
                selectedLayerId: null,
              }),
            )
          }
        >
          {filterType === 'basic'
            ? `${_t('Advance Filter')}`
            : `${_t('Basic Filter')}`}
        </Button>
        <Button
          disabled={!isFilterApplied}
          className="disabled:!naxatw-bg-grey-600"
          onClick={handleApplyFilter}
        >
          {_t('Apply Filter')}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}
