/* eslint-disable react/no-danger */
import { useEffect, useRef, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Popup } from 'maplibre-gl';
import type { MapMouseEvent } from 'maplibre-gl';
import { Button } from '@Components/RadixComponents/Button';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexRow } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { useTypedSelector } from '@Store/hooks';
import { IAsyncPopup } from '../types';

const popup = new Popup({
  closeOnClick: false,
  closeButton: false,
});

export default function AsyncPopup({
  map,
  fetchPopupData,
  popupUI,
  title,
  handleBtnClick,
  handleEdit,
  handleDelete,
  isLoading = false,
  onClose,
  enable = true,
  onRoutingBtnClick,
  handleShowHistory,
  handleGenerateHouse,
  isPublicPage = false,
  showPopupFor = [],
}: IAsyncPopup) {
  const [properties, setProperties] = useState<Record<string, any> | null>(
    null,
  );
  const [coordinates, setCoordinates] = useState<Record<string, any>>();
  const popupRef = useRef(null);
  const [popupHTML, setPopupHTML] = useState<string>('');

  const permissions = useTypedSelector(state => state.user.permissions);

  useEffect(() => {
    if (!map || !enable) return () => {};
    function displayPopup(e: MapMouseEvent): void {
      if (!map) return;
      const features = map.queryRenderedFeatures(e.point);
      const clickedFeature = features?.[0];
      if (!clickedFeature) return;
      if (!showPopupFor.includes(clickedFeature.source)) return;
      setCoordinates(e.lngLat);
      setProperties({
        ...clickedFeature.properties,
        layer: clickedFeature.source,
      });
      popup.setLngLat(e.lngLat);
    }
    map.on('click', displayPopup);
    return () => {
      map.off('click', displayPopup);
      popup.remove();
    };
  }, [map, enable, showPopupFor]);

  useEffect(() => {
    if (!map || !properties) return;
    fetchPopupData?.(properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [map, properties]);

  useEffect(() => {
    if (!map || !properties || !popupUI || !popupRef.current) return;
    const htmlString = renderToString(popupUI(properties));
    popup.setDOMContent(popupRef.current).addTo(map);
    setPopupHTML(htmlString);
  }, [map, popupUI, properties]);

  const onPopupClose = () => {
    popup.remove();
    onClose?.();
    setProperties(null);
  };

  if (!properties) return <div />;

  return (
    <div
      ref={popupRef}
      className={` ${
        isPublicPage && !isLoading ? 'naxatw-pb-10' : ''
      } naxatw-w-[18rem] naxatw-pl-2`}
    >
      <div className="naxatw-flex naxatw-items-center naxatw-justify-between naxatw-py-2">
        {isLoading ? (
          <Skeleton
            className="naxatw-my-3 naxatw-h-4 naxatw-w-1/2 naxatw-rounded-md
           naxatw-bg-grey-100 naxatw-shadow-sm"
          />
        ) : (
          <p className="naxatw-btn-text naxatw-text-primary-400">{title}</p>
        )}
        <FlexRow gap={2} className="naxatw-items-center">
          {properties?.layer === 'building' && (
            <ToolTip
              name="directions"
              message="Get direction"
              side="top"
              onClick={() => {
                onRoutingBtnClick?.(coordinates as Record<string, any>);
              }}
            />
          )}
          <ToolTip
            name="close"
            message="Close"
            side="top"
            onClick={onPopupClose}
          />
        </FlexRow>
      </div>
      <div dangerouslySetInnerHTML={{ __html: popupHTML }} />
      {!isLoading && !isPublicPage && (
        <FlexRow className="naxatw-items-center naxatw-justify-between naxatw-py-3">
          <Button
            variant="ghost"
            size="sm"
            onClick={() => handleBtnClick?.(properties)}
          >
            View More
          </Button>
          <FlexRow gap={2} className="naxatw-items-center">
            {(permissions.includes('change_building') ||
              (permissions.includes('change_road') && handleEdit)) && (
              <ToolTip
                side="top"
                name="edit"
                message="Edit"
                className="naxatw-rounded naxatw-p-1 hover:naxatw-bg-primary-50"
                onClick={() => handleEdit?.(properties)}
              />
            )}
            {permissions.includes('delete_building') ||
              (permissions.includes('delete_road') && handleDelete && (
                <ToolTip
                  side="top"
                  name="delete"
                  message="Delete"
                  className="naxatw-rounded naxatw-p-1"
                  iconClassName="hover:naxatw-text-red-500"
                  onClick={() => {
                    handleDelete?.(properties);
                    onPopupClose();
                  }}
                />
              ))}
            <ToolTip
              side="top"
              name="history"
              message="Show edit history"
              className="naxatw-rounded naxatw-p-1 hover:naxatw-bg-primary-50"
              onClick={() => handleShowHistory?.(properties)}
            />
            {properties?.layer === 'building' && (
              <ToolTip
                side="top"
                name="cached"
                message="Generate house number"
                className="naxatw-rounded naxatw-p-1 hover:naxatw-bg-primary-50"
                onClick={() => handleGenerateHouse?.(properties)}
              />
            )}
            <ToolTip
              side="top"
              name="share"
              message="Share"
              className="naxatw-rounded naxatw-p-1 hover:naxatw-bg-primary-50"
            />
          </FlexRow>
        </FlexRow>
      )}
    </div>
  );
}
