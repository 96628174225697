/* eslint-disable react/no-danger */
import { useQuery } from '@tanstack/react-query';
import RoundedContainer from '@Components/common/RoundedContainer';
import { Button } from '@Components/RadixComponents/Button';
import Image from '@Components/RadixComponents/Image';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import User from '@Assets/images/avatar-images.svg';
import { Link } from 'react-router-dom';
import { getUserLogs } from '@Services/dashboard';
import Skeleton from '@Components/RadixComponents/Skeleton';
import { useTypedSelector } from '@Store/hooks';
import { selectedWardSelector } from '@Store/selector/common';
import useTranslation from '@Hooks/useTranslation';

export default function UserLogs() {
  const { _t } = useTranslation();
  const selectedWards = useTypedSelector(selectedWardSelector);

  const { data, isLoading } = useQuery({
    queryKey: ['dashboardUserLog', selectedWards],
    queryFn: () => getUserLogs({ ward_no: selectedWards?.join(',') }),
    select: (res: any) => {
      return res.data.results;
    },
  });

  if (isLoading) {
    return (
      <Skeleton className="naxatw-h-[314px] naxatw-w-full naxatw-rounded-lg naxatw-bg-primary-50 naxatw-shadow-sm" />
    );
  }

  return (
    <RoundedContainer className="!naxatw-h-[360px] naxatw-w-full naxatw-bg-grey-50 naxatw-px-3">
      <FlexColumn>
        <FlexRow className="naxatw-sticky naxatw-top-0 naxatw-z-20 naxatw-justify-between naxatw-bg-grey-50 naxatw-pt-3">
          <h3 className="naxatw-text-lg">{_t('User Logs')}</h3>
          <Link to="/user-management">
            <Button variant="ghost" size="sm">
              {_t('View All')}
            </Button>
          </Link>
        </FlexRow>
        <FlexColumn className="scrollbar naxatw-h-[300px] naxatw-gap-2 naxatw-overflow-auto">
          {data?.map((user: any) => {
            return (
              <RoundedContainer
                key={`${user.username}-${user.change}`}
                className="naxatw-w-full !naxatw-rounded-lg naxatw-bg-white naxatw-px-3 naxatw-py-2"
              >
                <FlexRow className="naxatw-items-center naxatw-justify-between">
                  <FlexRow className="naxatw-items-center naxatw-gap-2">
                    <div className="naxatw-h-6 naxatw-w-6 naxatw-overflow-hidden naxatw-rounded-full">
                      {user.image_url ? (
                        <Image src={user.image_url} />
                      ) : (
                        <Image src={User} />
                      )}
                    </div>
                    <h3 className="naxatw-text-sm">{user.username}</h3>
                  </FlexRow>
                  <h3 className="naxatw-text-xs naxatw-text-grey-600">
                    {user.action_time}
                  </h3>
                </FlexRow>
                <FlexRow className="naxatw-px-9 naxatw-text-xs naxatw-text-grey-600">
                  <div dangerouslySetInnerHTML={{ __html: user.change }} />
                </FlexRow>
              </RoundedContainer>
            );
          })}
        </FlexColumn>
      </FlexColumn>
    </RoundedContainer>
  );
}
