/* eslint-disable no-unused-vars */
import {
  getBuildingCount,
  getBuildingUniqueFields,
  getHouseFloorCount,
  getRoadCount,
  getRoadUniqueFields,
  getWardNoList,
} from '@Services/publicPage';
import { ChartTypes } from '@Components/common/Charts/types';
import {
  BuildingAccessCaseSvg,
  BuildingOwnerShipSvg,
  BuildingSectionSvg,
  RoadSectionByCountSvg,
  RoadSectionByLengthSvg,
} from './svgIcons';

export type SidebarSectionType = {
  name: string;
  icon: string;
};

export type SidebarSectionsType = Record<string, SidebarSectionType>;

interface IContentType {
  id: string;
  title: string;
  chartType: ChartTypes;
  queryFn: any;
  params?: any;
}

interface ISidebarSectionsProps {
  name: string;
  title: string;
  icon: any;
  id: string;
  contents: IContentType[];
}

export const sidebarSections: Record<string, ISidebarSectionsProps> = {
  'building-structure': {
    name: 'Building structure',
    title: 'Building structure information visualization',
    icon: BuildingSectionSvg,
    id: 'building-structure',
    contents: [
      {
        id: 'building-by-structure',
        title: 'Building By Structure',
        chartType: 'horizontalBar',
        queryFn: getBuildingCount,
        params: { field_name: 'building_structure' },
      },
      {
        id: 'building-by-use',
        title: 'Building By Use',
        chartType: 'scatterChart',
        queryFn: getHouseFloorCount,
      },
      {
        id: 'building-by-roof-type',
        title: 'Building By Roof Type',
        chartType: 'donut',
        queryFn: getBuildingCount,
        params: { field_name: 'roof_type' },
      },
    ],
  },
  'building-access-case': {
    name: 'Building access case',
    title: 'Building access case visualization',
    icon: BuildingAccessCaseSvg,
    id: 'building-access-case',
    contents: [
      {
        id: 'building-count-road-category',
        title: 'Building count in different road category',
        chartType: 'donut',
        queryFn: getRoadCount,
        params: { field_name: 'road_category' },
      },
      {
        id: 'building-count-road-pavement-type',
        title: 'Building count in different road pavement type',
        chartType: 'horizontalBar',
        queryFn: getBuildingCount,
        params: { field_name: 'road_type' },
      },
      {
        id: 'building-count-with-different-access',
        title: 'Building count with different road access',
        chartType: 'bar',
        queryFn: getBuildingCount,
        params: { field_name: 'road_lane' },
      },
    ],
  },
  'building-ownership': {
    name: 'Building Ownership',
    title: 'Building ownership visualization',
    icon: BuildingOwnerShipSvg,
    id: 'building-ownership',
    contents: [
      {
        id: 'building-by-association-type',
        title: 'Building by Association Type',
        chartType: 'donut',
        queryFn: getBuildingCount,
        params: { field_name: 'association_type' },
      },
      {
        id: 'building-by-ownership-category',
        title: 'Building by ownership category',
        chartType: 'stackedChart',
        queryFn: getBuildingCount,
        params: { field_name: 'owner_status' },
      },
      {
        id: 'building-by-permanency',
        title: 'Building by Permanency',
        chartType: 'stackedChart',
        queryFn: getBuildingCount,
        params: { field_name: 'temporary_type' },
      },
    ],
  },
  'road-section-information': {
    name: 'Road section by Count',
    title: 'Road section information visualization',
    icon: RoadSectionByCountSvg,
    id: 'road-section-information',
    contents: [
      {
        id: 'road-section-pavement-type',
        title: 'Road section count by pavement type',
        chartType: 'horizontalBar',
        queryFn: getRoadCount,
        params: { field_name: 'road_type' },
      },
      {
        id: 'road-section-with-different-lane',
        title: 'Road section count with different lane',
        chartType: 'bar',
        queryFn: getRoadCount,
        params: { field_name: 'road_lane' },
      },
      {
        id: 'road-section-count-by-class',
        title: 'Road section count by class',
        chartType: 'horizontalBar',
        queryFn: getRoadCount,
        params: { field_name: 'road_class' },
      },
    ],
  },
  // 'road-section-by-length': {
  //   name: 'Road section by Length',
  //   title: 'Building',
  //   icon: RoadSectionByLengthSvg,
  //   id: 'building-structure',
  //   contents: [
  //     {
  //       id: 'building',
  //       title: 'Building',
  //       chartType: 'horizontalBar',
  //       queryFn: getRoadCount,
  //       params: { field_name: 'road_class' },
  //     },
  //   ],
  // },
};

export type SidebarSectionKeys = keyof typeof sidebarSections;

export const filterDropdownOptionsIndex: Record<string, number> = {
  'ward-list': 0,
  'road-type': 1,
  'building-use': 2,
};

export const filterDropdownOptions = [
  {
    queryKey: ['ward-list'],
    queryFn: getWardNoList,
    className: 'naxatw-min-w-[8rem]',
    paramKey: 'ward_no',
    placeholder: 'All Wards',
  },
  {
    queryKey: ['road-type'],
    queryFn: getRoadUniqueFields,
    params: {
      field_name: 'road_type',
    },
    className: 'naxatw-min-w-[12rem]',
    paramKey: 'road_type',
    placeholder: 'Road Type',
  },
  {
    queryKey: ['building-use'],
    queryFn: getBuildingUniqueFields,
    params: {
      field_name: 'building_use',
    },
    className: 'naxatw-min-w-[12rem]',
    paramKey: 'building_use',
    placeholder: 'Building Use',
  },
];
