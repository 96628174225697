import { useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { geojsonToWKT } from '@terraformer/wkt';
import {
  buildingDataFormIndex,
  buildingFormModal,
  buildingFormOptionsRequestData,
  buildingFormSchema,
  roadDataFormIndex,
  roadFormModal,
  roadFormOptionsRequestData,
  roadFormSchema,
} from '@Constants/dataManagementForm';
import { FormStateType } from '@Store/slices/dataManagementForm';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import { Button } from '@Components/RadixComponents/Button';
import {
  getBuildingDataByIdRequest,
  getDropdownOptionsRequest,
  getRoadDataByIdRequest,
  postFormDataRequest,
  resetDataManagementFormState,
  setActiveIndex,
  setDataManagementFormState,
  updateFormDataRequest,
} from '@Store/actions/dataManagementForm';
import { togglePromptDialog } from '@Store/actions/common';
import checkIfLoading from '@Utils/checkIfLoading';
import Icon from '@Components/common/Icon';
import { getDirtyValues } from '@Components/UserManagement/ModalContent/AddUser';
import useTranslation from '@Hooks/useTranslation';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import isEmpty from '@Utils/isEmpty';
import debounce from '@Utils/debounce';
import ProgressStep from './ProgressStep';
import FormSection from './FormSection';
import FormSkeleton from './FormSkeleton';

function MultiStepForm() {
  const { formId } = useParams();
  const { pathname, state: locationState } = useLocation();
  const { _t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const activeIndex = useTypedSelector(
    state => state.dataManagementForm.activeIndex,
  );
  const stepIndex = useTypedSelector(
    state => state.dataManagementForm.stepIndex,
  );
  const formState = useTypedSelector(
    state => state.dataManagementForm.formState,
  );
  const isLoading = useTypedSelector(state =>
    checkIfLoading(state, [
      getBuildingDataByIdRequest.type,
      getRoadDataByIdRequest.type,
      getDropdownOptionsRequest.type,
    ]),
  );

  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const hasGeomChanged = useTypedSelector(
    state => state.dataManagementForm.hasGeomChanged,
  );
  const selectedRoadId = useTypedSelector(
    state => state.dataManagementForm.selectedRoadId,
  );
  const hasSelectedRoadIdChanged = useTypedSelector(
    state => state.dataManagementForm.hasSelectedRoadIdChanged,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.dataManagementForm.selectedBuildingId,
  );
  const hasSelectedBuildingIdChanged = useTypedSelector(
    state => state.dataManagementForm.hasSelectedBuildingIdChanged,
  );
  const promptDialogContent = useTypedSelector(
    state => state.common.promptDialogContent,
  );
  const isSubmitClicked = useTypedSelector(
    state => state.dataManagementForm.isSubmitClicked,
  );
  const refCentroid = useTypedSelector(
    state => state.dataManagementForm.refCentroid,
  );

  const formMode: 'add' | 'edit' = !formId ? 'add' : 'edit';

  const {
    handleSubmit,
    formState: { errors, dirtyFields },
    register,
    getValues,
    reset,
    control,
    setValue,
    watch,
  } = useForm({ mode: 'all' });

  useEffect(() => {
    const isDataFetched = Object.values(formState).some(val => !!val);
    if (!isDataFetched) return;
    reset(formState);
  }, [formState, reset]);

  const formType = pathname.includes('building-data') ? 'building' : 'road';

  const formSchema =
    formType === 'building' ? buildingFormSchema : roadFormSchema;

  const lastIndex =
    Object.keys(
      formType === 'building' ? buildingDataFormIndex : roadDataFormIndex,
    ).length - 1;

  useEffect(() => {
    if (!formId) return;
    if (formType === 'building') {
      dispatch(getBuildingDataByIdRequest(formId));
    } else {
      dispatch(getRoadDataByIdRequest(formId));
    }
  }, [dispatch, formId, formType]);

  useEffect(() => {
    dispatch(
      setDataManagementFormState({
        formState: formType === 'building' ? buildingFormModal : roadFormModal,
      }),
    );
  }, [dispatch, formType]);

  useEffect(() => {
    dispatch(
      getDropdownOptionsRequest(
        formType === 'building'
          ? buildingFormOptionsRequestData
          : roadFormOptionsRequestData,
      ),
    );
  }, [dispatch, formType]);

  // reset dataManagementForm state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetDataManagementFormState());
    };
  }, [dispatch]);

  const handlePrevious = () => {
    dispatch(setActiveIndex(activeIndex - 1));
  };

  // set isAssociateDissociateType to true for associate/dissociate type
  useEffect(() => {
    const associationType = getValues()?.association_type;
    if (!associationType) return;
    let states: any = {
      isAssociateDissociateType: ['associate', 'dissociate'].includes(
        getValues()?.association_type,
      ),
    };
    // reset selected states on association_type change
    if (associationType !== formState?.association_type) {
      states = {
        ...states,
        selectedRoadId: null,
        selectedBuildingId: null,
        hasSelectedRoadIdChanged: false,
        hasSelectedBuildingIdChanged: false,
        enableRoadSelect: false,
        enableBuildingSelect: false,
      };
    }
    dispatch(setDataManagementFormState({ ...states }));
    // eslint-disable-next-line
  }, [dispatch, getValues, getValues()?.association_type]);

  const onSubmit = useCallback(
    (values: FormStateType) => {
      if (activeIndex !== lastIndex) {
        dispatch(setActiveIndex(activeIndex + 1));
        return;
      }
      const isSomeFieldEmpty = Object.values(values).some(value =>
        Array.isArray(value) ? !value.length : !value,
      );
      if (isSomeFieldEmpty && promptDialogContent !== 'continue-edit') {
        dispatch(togglePromptDialog('continue-edit'));
      } else if (formMode === 'edit' && formId) {
        const payloadData: any = getDirtyValues(dirtyFields, getValues());
        if (hasGeomChanged) {
          payloadData.geom = drawnGeom;
        }
        if (hasSelectedRoadIdChanged) {
          payloadData.road_id = selectedRoadId;
        }
        if (hasSelectedBuildingIdChanged) {
          payloadData.main_building_id = selectedBuildingId;
        }
        if (refCentroid?.features?.[0]?.geometry) {
          payloadData.ref_centroid = geojsonToWKT(
            refCentroid.features[0].geometry,
          );
        }
        if (isEmpty(payloadData)) {
          dispatch(togglePromptDialog('continue-edit'));
          return;
        }
        dispatch(
          updateFormDataRequest({
            formType,
            formId,
            data: payloadData,
            navigate,
            redirectPath: locationState?.redirectPath,
          }),
        );
      } else {
        const payloadData: any = {
          ...values,
          geom: drawnGeom,
        };
        if (selectedRoadId) {
          payloadData.road_id = selectedRoadId;
        }
        if (selectedBuildingId) {
          payloadData.main_building_id = selectedBuildingId;
        }
        dispatch(
          postFormDataRequest({
            formType,
            data: payloadData,
            navigate,
            redirectPath: locationState?.redirectPath,
          }),
        );
      }
      dispatch(setDataManagementFormState({ isSubmitClicked: false }));
    },
    [
      dispatch,
      navigate,
      activeIndex,
      dirtyFields,
      formId,
      formMode,
      drawnGeom,
      formType,
      getValues,
      hasGeomChanged,
      selectedRoadId,
      hasSelectedRoadIdChanged,
      selectedBuildingId,
      hasSelectedBuildingIdChanged,
      lastIndex,
      promptDialogContent,
      refCentroid,
      locationState?.redirectPath,
    ],
  );

  const onChange = useCallback(() => {
    handleSubmit(onSubmit)();
  }, [handleSubmit, onSubmit]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandler = useCallback(debounce(onChange, 300), [onChange]);

  useEffect(() => {
    if (!isSubmitClicked) return;
    handleSubmit(onSubmit)();
  }, [isSubmitClicked, handleSubmit, onSubmit]);

  const isMapSection = formSchema[buildingDataFormIndex[activeIndex]] === 'map';

  if (isLoading) {
    return <FormSkeleton />;
  }

  const formProps = {
    register,
    control,
    errors,
    setValue,
    getValues,
    watch,
  };

  return (
    <FlexColumn
      gap={2}
      className="naxatw-w-screen naxatw-animate-fade naxatw-px-4 naxatw-py-2"
    >
      <FlexRow className="naxatw-items-center naxatw-justify-between">
        <h3 className="naxatw-capitalize">
          {formMode === 'edit' ? _t(`Edit ${formType}`) : _t(`Add ${formType}`)}
        </h3>
        <Icon
          name="close"
          className="naxatw-rounded-lg naxatw-p-2 naxatw-text-2xl hover:naxatw-text-primary-400"
          onClick={() => {
            if (locationState?.redirectPath) {
              navigate(locationState.redirectPath);
            } else {
              navigate(`/data-management/${formType}-data`);
            }
          }}
        />
      </FlexRow>
      <FlexColumn className={`${!isMapSection ? `naxatw-items-center` : ''}`}>
        <FlexColumn
          className={`${
            !isMapSection
              ? `naxatw-w-full naxatw-gap-5 naxatw-rounded-xl naxatw-border-2 
              naxatw-border-grey-200 naxatw-bg-grey-50 naxatw-px-5 naxatw-pb-5 naxatw-pt-8 md:naxatw-w-fit md:naxatw-px-20`
              : ''
          } 
          `}
        >
          {/* Progress Step Section */}
          <ProgressStep
            data={
              formType === 'building'
                ? buildingDataFormIndex
                : roadDataFormIndex
            }
            activeIndex={activeIndex}
            stepIndex={stepIndex}
          />

          <FlexColumn
            className={`${
              !isMapSection
                ? 'naxatw-h-[calc(100vh-22rem)] md:naxatw-h-[calc(100vh-18rem)]'
                : 'naxatw-h-[calc(100vh-12rem)]'
            }`}
          >
            {/* Form Content Section */}
            <FormSection
              schema={
                formType === 'building'
                  ? formSchema[buildingDataFormIndex[activeIndex]]
                  : formSchema[roadDataFormIndex[activeIndex]]
              }
              formProps={formProps}
            />
          </FlexColumn>

          {/* Previous/Next/Apply Button Section */}
          {!isMapSection && (
            <FlexRow className="naxatw-justify-center naxatw-gap-4 naxatw-pt-3">
              {!!activeIndex && (
                <Button
                  variant="outline"
                  leftIcon="chevron_left"
                  onClick={handlePrevious}
                >
                  {_t('Previous')}
                </Button>
              )}
              <Button onClick={debouncedHandler} rightIcon="chevron_right">
                {activeIndex === lastIndex ? _t('Complete') : _t('Next')}
              </Button>
            </FlexRow>
          )}
        </FlexColumn>
      </FlexColumn>
    </FlexColumn>
  );
}

export default hasErrorBoundary(MultiStepForm);
