/* eslint-disable import/prefer-default-export */
import { dataManagementSlice } from '../slices/dataManagement';

export const {
  setDataManagementState,
  setRoadDataState,
  setBuildingDataState,
  handleFilterReset,
  resetDataManagementState,
  setOverlayLayerState,
} = dataManagementSlice.actions;
