/* eslint-disable no-nested-ternary */
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import getBbox from '@turf/bbox';
import { GeojsonType } from '@Components/common/MapLibreComponents/types';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import useDrawTool from '@Components/common/MapLibreComponents/useDrawTool';
import { LngLat, LngLatBoundsLike } from 'maplibre-gl';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import 'maplibre-gl/dist/maplibre-gl.css';
import '@Components/common/MapLibreComponents/map.css';
import { useTypedSelector, useTypedDispatch } from '@Store/hooks';
import {
  setActiveIndex,
  setDataManagementFormState,
} from '@Store/actions/dataManagementForm';
import { activeTabNameSelector } from '@Store/selector/dataManagementForm';
import {
  drawnBuildingStyle,
  drawnRoadStyle,
  drawStyles,
  userInputColor,
  userLocationColor,
  roadStyles,
  mainBuildingColor,
  refCentroidDistanceThreshold,
} from '@Constants/map';
import {
  resetWorkshopModeState,
  setWorkshopModeState,
} from '@Store/actions/workshopMode';
import useDebouncedLoadingState from '@Hooks/useDebouncedLoadingState';
import { apiURL } from '@Services/index';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import BaseLayerSwitcher from '@Components/common/MapLibreComponents/BaseLayerSwitcher';
import CircleMarker from '@Components/common/MapLibreComponents/Layers/CircleMarker';
import MeasureTool from '@Components/common/MapLibreComponents/MeasureTool';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';
import RoadVTLayer from '@Components/common/MapLibreComponents/CustomLayers/RoadVTLayer';
import { Button } from '@Components/RadixComponents/Button';
import { hasErrorBoundary } from '@xmanscript/has-error-boundary';
import useTranslation from '@Hooks/useTranslation';
import MapLoader from '@Components/common/MapLibreComponents/MapLoader';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toolbar from './Toolbar';
import MapTools from './MapTools';

function MapSection() {
  const { pathname } = useLocation();
  const { formId: selectedFeatureId } = useParams();
  const { _t } = useTranslation();
  const dispatch = useTypedDispatch();
  const activeBaseLayer = useTypedSelector(
    state => state.dataManagementForm.activeBaseLayer,
  );
  const [animationDirection, setAnimationDirection] = useState('');
  const buildingBbox = useTypedSelector(state => state.common.buildingBbox);
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);
  const drawnGeom = useTypedSelector(
    state => state.dataManagementForm.drawnGeom,
  );
  const selectedRoadId = useTypedSelector(
    state => state.dataManagementForm.selectedRoadId,
  );
  const selectedBuildingId = useTypedSelector(
    state => state.dataManagementForm.selectedBuildingId,
  );
  const layers = useTypedSelector(state => state.dataManagementForm.layers);
  const enableRoadSelect = useTypedSelector(
    state => state.dataManagementForm.enableRoadSelect,
  );
  const enableBuildingSelect = useTypedSelector(
    state => state.dataManagementForm.enableBuildingSelect,
  );
  const activeIndex = useTypedSelector(
    state => state.dataManagementForm.activeIndex,
  );
  const inputCoordinates = useTypedSelector(
    state => state.dataManagementForm.inputCoordinates,
  );
  const geolocation = useTypedSelector(
    state => state.dataManagementForm.geolocation,
  );
  const zoomToLocationType = useTypedSelector(
    state => state.dataManagementForm.zoomToLocationType,
  );
  const changeStyle = useTypedSelector(
    state => state.dataManagementForm.changeStyle,
  );
  const activeTabName = useTypedSelector(activeTabNameSelector);
  const fetchedGeojson = useTypedSelector(
    state => state.dataManagementForm.fetchedGeojson,
  );
  const hasGeomChanged = useTypedSelector(
    state => state.dataManagementForm.hasGeomChanged,
  );
  const measureType = useTypedSelector(state => state.workshopMode.measureType);
  const formState = useTypedSelector(
    state => state.dataManagementForm.formState,
  );
  const refCentroid = useTypedSelector(
    state => state.dataManagementForm.refCentroid,
  );
  const previousMapBounds = useTypedSelector(
    state => state.visualization.previousMapBounds,
  );
  const isRefCentroidValid = useTypedSelector(
    state => state.dataManagementForm.isRefCentroidValid,
  );
  const [drawnFeatureGeojson, setDrawnFeatureGeojson] =
    useState<null | GeojsonType>(null);
  const [isBuildingLayerLoaded, setIsBuildingLayerLoaded] = useState(false);
  const [isRoadLayerLoaded, setIsRoadLayerLoaded] = useState(false);

  const { map, isMapLoaded } = useMapLibreGLMap({
    mapOptions: {
      zoom: 6,
      center: [84.124, 28.3949],
      maxZoom: 19,
    },
    disableRotation: true,
  });

  const handleDrawEnd = useCallback((geojson: GeojsonType | null) => {
    setDrawnFeatureGeojson(geojson);
    // dispatch(setDataManagementFormState({ drawnGeom: geojson }));
  }, []);

  const { resetDraw, setDrawMode, reverseLineGeometry, undo, redo } =
    useDrawTool({
      map,
      enable:
        !measureType &&
        ((activeTabName === 'access' && refCentroid) ||
          (!!drawType && (isBuildingLayerLoaded || isRoadLayerLoaded))),
      drawMode: drawType,
      styles: drawStyles,
      geojson:
        activeTabName === 'geometry' && drawType
          ? drawnGeom
          : activeTabName === 'access' && refCentroid
            ? refCentroid
            : null,
      onDrawEnd: handleDrawEnd,
    });

  const formType = pathname.includes('building-data') ? 'building' : 'road';

  useEffect(() => {
    if (activeTabName !== 'geometry') return;
    dispatch(
      setDataManagementFormState({
        drawType: drawnGeom
          ? 'simple_select'
          : formType === 'road'
            ? 'draw_line_string'
            : 'draw_polygon',
      }),
    );
  }, [dispatch, activeTabName, drawnGeom, formType]);

  useEffect(() => {
    if (activeTabName !== 'access' || !refCentroid) return;
    setTimeout(() => {
      dispatch(
        setDataManagementFormState({
          drawType: 'simple_select',
        }),
      );
    }, 500);
  }, [dispatch, activeTabName, refCentroid, formType]);

  // update refCentroid on draw
  useEffect(() => {
    if (activeTabName !== 'access' || !refCentroid || !drawnFeatureGeojson)
      return () => {};
    // @ts-ignore
    if (drawnFeatureGeojson?.features?.[0]?.geometry?.type !== 'Point')
      return () => {};
    return () => {
      dispatch(
        setDataManagementFormState({ refCentroid: drawnFeatureGeojson }),
      );
      setDrawnFeatureGeojson(null);
    };
  }, [dispatch, activeTabName, refCentroid, drawnFeatureGeojson]);

  // zoom to palika boundary on initial map load
  useEffect(() => {
    if (!map || !isMapLoaded || !buildingBbox || previousMapBounds) return;
    const { data } = buildingBbox;
    if (!data) return;
    const [x1, y1, x2, y2] = data;
    map.fitBounds(
      [
        [x1, y1],
        [x2, y2],
      ],
      { duration: 0 },
    );
  }, [map, isMapLoaded, buildingBbox, previousMapBounds]);

  // zoom to user input coordinates
  useEffect(() => {
    if (!map || !inputCoordinates.length) return;
    const [lat, lng] = inputCoordinates;
    map.flyTo({
      center: new LngLat(lng, lat),
      zoom: 14,
    });
  }, [dispatch, map, inputCoordinates]);

  // zoom to fetched geojson
  useEffect(() => {
    if (!map || !isMapLoaded || !fetchedGeojson) return;
    const bbox = getBbox(fetchedGeojson);
    map.fitBounds(bbox as LngLatBoundsLike, { duration: 0 });
  }, [dispatch, map, isMapLoaded, fetchedGeojson]);

  // zoom to previous map extent
  useEffect(() => {
    if (!map || !isMapLoaded || !previousMapBounds || fetchedGeojson)
      return () => {};
    // for letting zoom to previous zoom extent
    const timer = setTimeout(() => {
      map.fitBounds(previousMapBounds as LngLatBoundsLike, {
        duration: 0,
      });
    }, 300);
    return () => clearTimeout(timer);
  }, [map, isMapLoaded, previousMapBounds, fetchedGeojson]);

  // cache geolocation coordinates
  const geolocationCoords: [number, number] | null = useMemo(
    () => (geolocation ? [geolocation.longitude, geolocation.latitude] : null),
    [geolocation],
  );

  // change layer styles
  useEffect(() => {
    if (!map || !changeStyle.layerId) return;
    Object.entries(changeStyle.styles).forEach(([key, val]) => {
      map.setPaintProperty(changeStyle.layerId, key, val);
    });
  }, [map, changeStyle]);

  // highlight only selected building on map
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'building') {
        setIsBuildingLayerLoaded(true);
      }
    });
  }, [map]);

  // highlight only selected road on map
  useEffect(() => {
    if (!map) return;
    map.on('sourcedata', (ev: Record<string, any>) => {
      if (ev?.sourceId === 'road') {
        setIsRoadLayerLoaded(true);
      }
    });
  }, [map]);

  // dim building style on adding building or road
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || !isRoadLayerLoaded) return;
    // gray out building
    map.setPaintProperty('building', 'fill-extrusion-color', '#BDBDBD');
  }, [map, isBuildingLayerLoaded, isRoadLayerLoaded]);

  // change building color when editing building geometry
  useEffect(() => {
    if (
      !map ||
      !isBuildingLayerLoaded ||
      !fetchedGeojson ||
      formType === 'road' ||
      !formState?.building_id
    )
      return;
    // hide selected building outline opacity when editing building geometry
    map.setPaintProperty('building-outline', 'line-opacity', [
      'match',
      ['get', 'building_id'],
      formState?.building_id,
      0,
      1,
    ]);
  }, [
    map,
    fetchedGeojson,
    isBuildingLayerLoaded,
    formType,
    formState?.building_id,
  ]);

  // highlight only selected access road on map while editing main building
  useEffect(() => {
    if (
      !map ||
      !isRoadLayerLoaded ||
      activeTabName !== 'access' ||
      !selectedRoadId
    )
      return () => {};
    map.setPaintProperty('road-outline', 'line-color', [
      'match',
      ['get', 'road_id'],
      selectedRoadId,
      '#EC7FA0',
      '#BDBDBD',
    ]);
    // reset road color
    return () => {
      if (!map || !isRoadLayerLoaded) return;
      map.setPaintProperty(
        'road-outline',
        'line-color',
        roadStyles.paint['line-color'],
      );
    };
  }, [map, isRoadLayerLoaded, selectedRoadId, activeTabName]);

  // highlight only selected main building on map when drawing associate and dissociate building
  useEffect(() => {
    if (
      !map ||
      !isBuildingLayerLoaded ||
      activeTabName !== 'main_building' ||
      !selectedBuildingId
    )
      return;
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'building_id'],
      selectedBuildingId,
      '#EC7FA0',
      '#BDBDBD',
    ]);
  }, [
    map,
    isBuildingLayerLoaded,
    selectedRoadId,
    activeTabName,
    selectedBuildingId,
  ]);

  // highlight only main building when enableBuildingSelect (drawing associate or dissociate building)
  useEffect(() => {
    if (!map || !isBuildingLayerLoaded || !enableBuildingSelect)
      return () => {};
    map.setPaintProperty('building', 'fill-extrusion-color', [
      'match',
      ['get', 'association_type'],
      'main',
      mainBuildingColor,
      '#BDBDBD',
    ]);
    // reset building color
    return () => {
      if (!map || !isBuildingLayerLoaded) return;
      map.setPaintProperty('building', 'fill-extrusion-color', '#BDBDBD');
    };
  }, [map, isBuildingLayerLoaded, enableBuildingSelect]);

  // change road color when editing road geometry
  useEffect(() => {
    if (
      !map ||
      !isRoadLayerLoaded ||
      !fetchedGeojson ||
      formType === 'building' ||
      !formState?.road_id
    )
      return;
    map.setPaintProperty('road-outline', 'line-color', '#BDBDBD');
    // gray out selected road color when editing road geometry
    map.setPaintProperty('road', 'line-color', [
      'match',
      ['get', 'road_id'],
      formState.road_id,
      '#BDBDBD',
      '#000',
    ]);
  }, [map, fetchedGeojson, isRoadLayerLoaded, formType, formState?.road_id]);

  // reset drawType on active tab change
  useEffect(() => {
    if (activeTabName === 'geometry') return;
    dispatch(
      setDataManagementFormState({
        drawType: null,
      }),
    );
  }, [dispatch, activeTabName]);

  // reset workshopmode state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetWorkshopModeState());
    };
  }, [dispatch]);

  const handlePrevious = () => {
    dispatch(setActiveIndex(activeIndex - 1));
    setAnimationDirection('naxatw-animate-fade-right');
  };

  // state to calculate refCentroidDistance for ref centroid validation
  useEffect(() => {
    if (
      !fetchedGeojson ||
      !refCentroid ||
      !drawnFeatureGeojson ||
      activeTabName !== 'access'
    )
      return;
    const buildingCentroid = centroid(fetchedGeojson);
    const refCentroidDistance = distance(
      buildingCentroid,
      // @ts-ignore
      drawnFeatureGeojson?.features[0],
      {
        units: 'meters',
      },
    );
    dispatch(
      setDataManagementFormState({
        isRefCentroidValid: refCentroidDistance <= refCentroidDistanceThreshold,
      }),
    );
  }, [
    dispatch,
    fetchedGeojson,
    refCentroid,
    drawnFeatureGeojson,
    activeTabName,
  ]);

  const handleNext = () => {
    if (activeTabName === 'geometry' && !drawnFeatureGeojson && !drawnGeom)
      return;
    if (activeTabName === 'access' && !selectedRoadId) return;
    if (activeTabName === 'geometry') {
      if (drawnFeatureGeojson) {
        dispatch(
          setDataManagementFormState({ drawnGeom: drawnFeatureGeojson }),
        );
        if (!hasGeomChanged) {
          dispatch(setDataManagementFormState({ hasGeomChanged: true }));
        }
      }
    }
    // check ref centroid validation on submit
    if (activeTabName === 'access') {
      if (!isRefCentroidValid && refCentroid) {
        toast.error(
          `Refrence centroid must be within ${refCentroidDistanceThreshold}m from building centroid`,
        );
        return;
      }
    }
    setDrawnFeatureGeojson(null);
    dispatch(setActiveIndex(activeIndex + 1));
    setAnimationDirection('naxatw-animate-fade-left');
  };

  // enable road select by default for access road
  useEffect(() => {
    dispatch(
      setDataManagementFormState({
        enableRoadSelect: activeTabName === 'access',
      }),
    );
  }, [dispatch, activeTabName]);

  // enable main building select by default
  useEffect(() => {
    if (selectedBuildingId) return;
    dispatch(
      setDataManagementFormState({
        enableBuildingSelect: activeTabName === 'main_building',
      }),
    );
  }, [dispatch, activeTabName, selectedBuildingId]);

  const handleFeatureSelect = useCallback(
    (properties: any) => {
      if (enableRoadSelect) {
        dispatch(
          setDataManagementFormState({
            selectedRoadId: properties.road_id,
            enableRoadSelect: false,
            hasSelectedRoadIdChanged: true,
          }),
        );
        return;
      }
      // prevent selecting same building on adding associate and dissociate building
      if (
        enableBuildingSelect &&
        properties.association_type === 'main' &&
        selectedFeatureId !== properties.building_pk
      )
        dispatch(
          setDataManagementFormState({
            selectedBuildingId: properties.building_id,
            enableBuildingSelect: false,
            hasSelectedBuildingIdChanged: true,
          }),
        );
    },
    [dispatch, selectedFeatureId, enableBuildingSelect, enableRoadSelect],
  );

  function getContent() {
    if (formType === 'building') {
      switch (activeTabName) {
        case 'geometry':
          return (
            <>
              <p className="naxatw-btn-text">{_t('Draw Building')}</p>
              <p className="naxatw-text-xs naxatw-font-bold">
                {_t(
                  'Place your cursor on the map. Drag on the map to draw into desired shape.',
                )}
              </p>
            </>
          );
        case 'access':
          return (
            <>
              <p className="naxatw-btn-text">
                {_t('Select Road Connecting Building')}
              </p>
              <p className="naxatw-text-xs naxatw-font-bold">
                {_t(
                  'Hover along the nearest road from the building and click to select.',
                )}
              </p>
            </>
          );
        case 'main_building':
          return (
            <>
              <p className="naxatw-btn-text">{_t('Select Main Building')}</p>
              <p className="naxatw-text-xs naxatw-font-bold">
                {_t('Hover over the main building and click to select.')}
              </p>
            </>
          );

        default:
          return '';
      }
    } else {
      switch (activeTabName) {
        case 'geometry':
          return (
            <>
              <p className="naxatw-btn-text">{_t('Draw Road on Map')}</p>
              <p className="naxatw-text-xs naxatw-font-bold">
                {_t(
                  'Click on draw road and then on map to start road and drag to draw into desired shape',
                )}
              </p>
            </>
          );
        default:
          return '';
      }
    }
  }

  const isLoading = useDebouncedLoadingState(
    !isBuildingLayerLoaded || !isRoadLayerLoaded || !isMapLoaded,
  );

  return (
    <FlexColumn className={animationDirection}>
      <FlexColumn>{getContent()}</FlexColumn>
      <div className="naxatw-relative">
        <Toolbar
          setDrawMode={setDrawMode}
          resetDraw={resetDraw}
          reverseLineGeometry={reverseLineGeometry}
          drawnFeatureGeojson={drawnFeatureGeojson}
          undo={undo}
          redo={redo}
        />

        {/* map loadder */}
        {isLoading && <MapLoader />}

        <MapContainer
          map={map}
          isMapLoaded={isMapLoaded}
          style={{
            width: '100%',
            height: '66vh',
          }}
        >
          <BaseLayerSwitcher activeLayer={activeBaseLayer} />

          {activeTabName !== 'geometry' && drawnGeom && (
            <VectorLayer
              id="drawn-building"
              geojson={drawnGeom}
              layerOptions={
                formType === 'building' ? drawnBuildingStyle : drawnRoadStyle
              }
            />
          )}

          {layers.map(
            ({ id, endPoint, checked, styles, showLabel, showOutline }) =>
              id === 'road' ? (
                <RoadVTLayer
                  key={id}
                  id={id}
                  url={`${apiURL}/${endPoint}/{z}/{x}/{y}/`}
                  layerOptions={styles}
                  visibleOnMap={checked}
                  interactions={
                    id === 'road' && enableRoadSelect
                      ? ['select']
                      : drawType
                        ? []
                        : []
                  }
                  showLabel={showLabel}
                  showOutline={!!showOutline}
                  onFeatureSelect={handleFeatureSelect}
                />
              ) : (
                <VectorTileLayer
                  key={id}
                  id={id}
                  url={`${apiURL}/${endPoint}/{z}/{x}/{y}/`}
                  layerOptions={styles}
                  visibleOnMap={checked}
                  interactions={
                    drawType
                      ? []
                      : id === 'building' && enableBuildingSelect
                        ? ['select']
                        : []
                  }
                  showLabel={showLabel}
                  showOutline={!!showOutline}
                  onFeatureSelect={handleFeatureSelect}
                />
              ),
          )}

          {/* plot circle marker on user location enable */}
          {geolocationCoords && (
            <CircleMarker
              id="geolocation"
              coordinates={geolocationCoords}
              zoomToLayer={zoomToLocationType === 'geolocation'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userLocationColor,
                },
              }}
            />
          )}

          {/* plot circle marker on user location enable */}
          {inputCoordinates.length && (
            <CircleMarker
              id="inputCoordinates"
              coordinates={[inputCoordinates[1], inputCoordinates[0]]}
              zoomToLayer={zoomToLocationType === 'user-input'}
              layerOptions={{
                type: 'circle',
                paint: {
                  'circle-radius': 8,
                  'circle-color': userInputColor,
                },
              }}
            />
          )}

          {/* map tools */}
          <MapTools />

          {/* measure tool */}
          <MeasureTool
            enable={!!measureType}
            measureType={measureType}
            onDrawChange={result => {
              dispatch(setWorkshopModeState({ measureResult: result }));
            }}
          />
        </MapContainer>
      </div>

      <FlexRow className="naxatw-mt-3 naxatw-justify-center naxatw-gap-4">
        <Button
          variant="outline"
          className="naxatw-w-24"
          leftIcon="chevron_left"
          onClick={handlePrevious}
        >
          {_t('Previous')}
        </Button>
        <Button
          className="naxatw-w-24"
          rightIcon="chevron_right"
          onClick={handleNext}
        >
          {_t('Next')}
        </Button>
      </FlexRow>
    </FlexColumn>
  );
}

export default hasErrorBoundary(MapSection);
