import { useMemo } from 'react';
import { format } from 'date-fns';
import { useTypedSelector } from '@Store/hooks';
import { FlexColumn, FlexRow } from '@Components/common/Layouts';
import DataTable from '@Components/common/DataTable';
import UserAvatar from '@Components/common/UserAvatar';

export default function AttributeSection() {
  const historyLogs = useTypedSelector(state => state.editHistory.historyLogs);
  const selectedAttributeIds = useTypedSelector(
    state => state.editHistory.selectedAttributeIds,
  );
  const relatedIdColors = useTypedSelector(
    state => state.editHistory.relatedIdColors,
  );

  const columns = [
    {
      header: 'S.N',
      accessorKey: 'serial',
      cell: ({ row }: any) => row.index + 1,
      pin: 'left',
    },
    {
      header: 'Field',
      accessorKey: 'field',
    },
    {
      header: 'Previous',
      accessorKey: 'old',
    },
    {
      header: 'Changed to',
      accessorKey: 'new',
    },
  ];

  // filter selected attribute data only
  const transformedData = useMemo(
    () =>
      historyLogs
        ?.filter(
          item => !item.changes.geom && selectedAttributeIds.includes(item.id),
        )
        ?.map((item: any) => {
          const changes = Object.entries(item.changes).map(
            ([field, values]: [string, any]) => ({
              field,
              new: values.new,
              old: values.old,
            }),
          );
          return { ...item, changes };
        }),
    [historyLogs, selectedAttributeIds],
  );

  return (
    <FlexColumn gap={3} className="naxatw-w-full naxatw-px-5">
      <h5>Edited Attributes</h5>
      <FlexColumn
        gap={8}
        className="scrollbar naxatw-h-[calc(100vh-6rem)] naxatw-overflow-auto"
      >
        {transformedData?.map(
          ({
            id,
            username,
            related_id: relatedId,
            house_no: houseNo,
            associate_road_name: associateRoadName,
            timestamp,
            changes,
          }: Record<string, any>) => {
            return (
              !!changes.length && (
                <FlexColumn key={id} gap={1}>
                  <FlexRow gap={2} className="naxatw-items-center">
                    <UserAvatar
                      name={username}
                      bgColor={relatedIdColors[relatedId]}
                    />
                    <FlexColumn>
                      <h6 className="naxatw-text-xs naxatw-font-bold">
                        {username} - {houseNo} - {associateRoadName}
                      </h6>
                      <p className="naxatw-text-xs">
                        {format(new Date(timestamp), 'MMM dd yyyy hh:mm a')}
                      </p>
                    </FlexColumn>
                  </FlexRow>
                  <DataTable key={id} columns={columns} data={changes} />
                </FlexColumn>
              )
            );
          },
        )}
      </FlexColumn>
    </FlexColumn>
  );
}
