/* eslint-disable no-unused-vars */
import { useEffect } from 'react';
import { FlexColumn } from '@Components/common/Layouts';
import ToolTip from '@Components/RadixComponents/ToolTip';
import { setWorkshopModeState } from '@Store/actions/workshopMode';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import { DrawModeTypes } from '@Components/common/MapLibreComponents/types';
import { setDataManagementFormState } from '@Store/actions/dataManagementForm';

interface DataItem {
  name: string;
  icon: string;
  onClick: () => void;
}

// Define the type for the entire data array
type DataArray = DataItem[];

interface IAddAttributeToolboxProps {
  setDrawMode: (mode: DrawModeTypes) => void;
  resetDraw: () => void;
}

export default function AddAttributeToolbox({
  setDrawMode,
  resetDraw,
}: IAddAttributeToolboxProps) {
  const dispatch = useTypedDispatch();
  const drawType = useTypedSelector(state => state.dataManagementForm.drawType);
  const permissions = useTypedSelector(state => state.user.permissions);

  const displayAddHouse = permissions.includes('add_building');
  const displayAddRoad = permissions.includes('add_road');

  useEffect(() => {
    setDrawMode(drawType);
  }, [setDrawMode, drawType]);

  const data = [
    displayAddHouse && {
      name: 'Add House',
      icon: 'house',
      onClick: () => {
        resetDraw();
        dispatch(
          setDataManagementFormState({
            drawType: 'draw_polygon',
            drawnGeom: null,
          }),
        );
        dispatch(
          setWorkshopModeState({
            attributeType: 'add_building',
            selectedFeature: null,
          }),
        );
      },
    },
    displayAddRoad && {
      name: 'Add Road',
      icon: 'add_road',
      onClick: () => {
        resetDraw();
        dispatch(
          setDataManagementFormState({
            drawType: 'draw_line_string',
            drawnGeom: null,
          }),
        );
        dispatch(
          setWorkshopModeState({
            attributeType: 'add_road',
            selectedFeature: null,
          }),
        );
      },
    },
  ].filter(Boolean) as DataArray;

  return (
    <FlexColumn className="naxatw-absolute naxatw-right-0 naxatw-top-2 naxatw-z-20 naxatw-w-10 naxatw-overflow-hidden naxatw-rounded-l-lg naxatw-bg-primary-400 xl:naxatw-top-[4rem] 2xl:naxatw-top-[8rem]">
      <FlexColumn>
        {data.map(({ name, icon, onClick }) => (
          <FlexColumn
            key={name}
            className="naxatw-cursor-pointer naxatw-items-center naxatw-justify-center naxatw-p-2.5
               hover:naxatw-bg-primary-600 "
            gap={1}
            onClick={onClick}
          >
            <ToolTip
              name={icon}
              message={name}
              iconClassName="naxatw-icon-md naxatw-text-white hover:naxatw-text-white hover:naxatw-animate-none "
            />
          </FlexColumn>
        ))}
      </FlexColumn>
    </FlexColumn>
  );
}
