/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-extraneous-dependencies */
import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Icon from '../Icon';

type ImageSliderProps = {
  children: ReactNode;
  className?: string;
};

function Arrow(props: any) {
  const { onClick, type } = props;
  return (
    <div
      onClick={onClick}
      className={`${
        type === 'prev' ? 'naxatw-left-[-50px]' : 'naxatw-right-[-50px]'
      } naxatw-absolute naxatw-top-[45%] naxatw-z-30 naxatw-rounded-md naxatw-bg-grey-100 naxatw-p-2 naxatw-text-white hover:naxatw-bg-primary-50`}
    >
      <Icon
        name={`${type === 'prev' ? 'navigate_before' : 'navigate_next'}`}
        className=" naxatw-text-black"
      />
    </div>
  );
}

export default function ImageSlider({ children, className }: ImageSliderProps) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    arrow: false,
    slidesToScroll: 1,
    prevArrow: <Arrow type="prev" />,
    nextArrow: <Arrow type="next" />,
  };
  return (
    <Slider {...settings} className={` ${className}`}>
      {children}
    </Slider>
  );
}
