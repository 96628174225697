import ToolTip from '@Components/RadixComponents/ToolTip';
import Icon from '@Components/common/Icon';
import { FlexRow } from '@Components/common/Layouts';
import useTranslation from '@Hooks/useTranslation';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import {
  handleFilterReset,
  setAdvanceFilterState,
} from '@Store/actions/advanceFilter';
import { setVisualizationState } from '@Store/actions/visualization';

export default function FilterHeader() {
  const dispatch = useTypedDispatch();
  const { _t } = useTranslation();
  const filterType = useTypedSelector(state => state.advanceFilter.filterType);
  const expandFilter = useTypedSelector(
    state => state.advanceFilter.expandFilter,
  );

  return (
    <FlexRow className="naxatw-items-center naxatw-justify-between">
      <FlexRow gap={2} className="naxatw-items-center">
        {filterType === 'advance' && (
          <Icon
            name="arrow_back"
            onClick={() =>
              dispatch(setAdvanceFilterState({ filterType: 'basic' }))
            }
          />
        )}
        <h4>
          {filterType === 'advance'
            ? `${_t('Advance Filter')}`
            : `${_t('Filter')}`}
        </h4>
      </FlexRow>
      <FlexRow gap={2}>
        <ToolTip
          name="close"
          message="Close"
          iconClassName="!naxatw-text-2xl"
          onClick={() => {
            dispatch(
              setAdvanceFilterState({
                showFilterSection: false,
                expandFilter: false,
                queryExpression: null,
                hideQueryFilter: false,
                selectedLayerId: null,
                minimizeFilterSection: false,
                filterType: 'basic',
              }),
            );
            dispatch(handleFilterReset({}));
            dispatch(
              setVisualizationState({
                showMapTool: true,
              }),
            );
          }}
        />
        <ToolTip
          name={expandFilter ? 'chevron_left' : 'chevron_right'}
          message={expandFilter ? 'Shrink Filter' : 'Expand Filter'}
          iconClassName="!naxatw-text-2xl"
          className="naxatw-rounded-lg naxatw-p-2 hover:naxatw-bg-primary-50"
          onClick={() =>
            dispatch(
              setAdvanceFilterState({
                expandFilter: !expandFilter,
              }),
            )
          }
        />
      </FlexRow>
    </FlexRow>
  );
}
