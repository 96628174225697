import { createSlice } from '@reduxjs/toolkit';
import type { CaseReducer, PayloadAction } from '@reduxjs/toolkit';

export interface EditHistoryState {
  historyLogs: Record<string, any>[] | null;
  selectedAttributeIds: number[];
  selectedItemsToRestore: Record<string, any>[];
  relatedIdColors: Record<string, string>;
}

const initialState: EditHistoryState = {
  historyLogs: null,
  selectedAttributeIds: [],
  selectedItemsToRestore: [],
  relatedIdColors: {},
};

const setEditHistoryState: CaseReducer<
  EditHistoryState,
  PayloadAction<Partial<EditHistoryState>>
> = (state, action) => ({
  ...state,
  ...action.payload,
});

const handleAttributeToggle: CaseReducer<
  EditHistoryState,
  PayloadAction<number>
> = (state, action) => ({
  ...state,
  selectedAttributeIds: state.selectedAttributeIds.includes(action.payload)
    ? state.selectedAttributeIds.filter(item => item !== action.payload)
    : [...state.selectedAttributeIds, action.payload],
});

const setInitialAttributeIds: CaseReducer<
  EditHistoryState,
  PayloadAction<number[]>
> = (state, action) => ({
  ...state,
  selectedAttributeIds: [...state.selectedAttributeIds, ...action.payload],
});

const resetEditHistoryState: CaseReducer<EditHistoryState> = () => ({
  ...initialState,
});

const editHistorySlice = createSlice({
  name: 'editHistory',
  initialState,
  reducers: {
    setEditHistoryState,
    handleAttributeToggle,
    setInitialAttributeIds,
    resetEditHistoryState,
  },
});

export { editHistorySlice };

export default editHistorySlice.reducer;
