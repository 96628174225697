import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { getOverallBuildingBbox, getMapPopupData } from '@Services/publicPage';

export const useGetOverallBuildingBboxQuery = (
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-overall-building-bbox'],
    queryFn: getOverallBuildingBbox,
    ...queryOptions,
  });
};

export const useGetMapPopupDataQuery = (
  id: any,
  selectedLayerType: string,
  queryOptions?: Partial<UseQueryOptions>,
) => {
  return useQuery({
    queryKey: ['get-map-popup-data'],
    queryFn: () => getMapPopupData({ id, type: selectedLayerType }),
    ...queryOptions,
  });
};
