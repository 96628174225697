/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import Spinner from '@Components/common/Spinner';
import MapLibreGL from 'maplibre-gl';
import { IMapFooter } from '../types';

export default function MapFooter({ map, isMapLoaded }: IMapFooter) {
  const layerAttributionRef = useRef<HTMLDivElement | null>(null);
  const mapScaleRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!map) return;
    const attribution = new MapLibreGL.AttributionControl();
    if (layerAttributionRef.current) {
      layerAttributionRef.current.appendChild(attribution.onAdd(map));
    }

    const scale = new MapLibreGL.ScaleControl({
      maxWidth: 80,
      unit: 'metric',
    });
    if (mapScaleRef.current) {
      mapScaleRef.current.appendChild(scale.onAdd(map));
    }
  }, [map]);

  return (
    <div
      className="naxatw-absolute naxatw-bottom-0 naxatw-z-10 naxatw-flex naxatw-h-[1.875rem] 
        naxatw-w-full naxatw-items-center naxatw-justify-between naxatw-bg-white naxatw-px-5 naxatw-py-3"
    >
      <div className="map-footer naxatw-flex naxatw-items-center naxatw-gap-4">
        <p className="naxatw-text-sm naxatw-font-medium">D-MAPS</p>
        {!isMapLoaded && (
          <Spinner className="!naxatw-h-4 !naxatw-w-6 naxatw-fill-primary-400" />
        )}
        <span ref={layerAttributionRef} className=" naxatw-text-grey-500" />
      </div>

      <div ref={mapScaleRef} />
    </div>
  );
}
