import { useRef } from 'react';
import { useTypedDispatch, useTypedSelector } from '@Store/hooks';
import useScrollActiveListener from '@Hooks/useScrollActiveListener';
import { SidebarSectionKeys, sidebarSections } from '@Constants/publicPage';
import { FlexColumn, FlexRow, Grid } from '@Components/common/Layouts';
import { setActiveTab, setPublicPageState } from '@Store/actions/publicPage';
import { useGetOverallBuildingBboxQuery } from '@Api/publicPage';
import useTranslation from '@Hooks/useTranslation';
import PublicPageHeader from './PublicPageHeader';
import FilterSection from './FilterSection';
import MapSection from './MapSection';
import DataCountSection from './DataCountSection';
import ChartRenderer from '../common/Charts/ChartRenderer';
import SidebarSection from './SidebarSection';
import BuildingByCategory from './BuildingByCategory';
import RoadByCategory from './RoadByCategory';

export default function PublicPageComponent() {
  const { _t } = useTranslation();
  const sectionRefs = useRef<Record<SidebarSectionKeys, HTMLDivElement> | {}>(
    {},
  );
  const dispatch = useTypedDispatch();
  const filterParams = useTypedSelector(state => state.publicPage.filterParams);

  useScrollActiveListener({
    sectionRefs,
    onChange: key => {
      // @ts-ignore
      dispatch(setActiveTab(key));
    },
  });

  // fetch buildingBbox on mount
  useGetOverallBuildingBboxQuery({
    onSuccess: (res: any) => {
      dispatch(
        setPublicPageState({
          buildingBbox: res.data,
        }),
      );
    },
  });

  return (
    <FlexColumn className="naxatw-relative naxatw-w-full naxatw-pb-20">
      <PublicPageHeader />
      <FilterSection />
      <FlexRow className="naxatw-flex-col-reverse naxatw-gap-1 md:naxatw-gap-4 lg:naxatw-flex-row lg:naxatw-pr-4">
        <MapSection />
        <FlexColumn className="naxatw-flex-1 naxatw-gap-4">
          <DataCountSection />
          <BuildingByCategory />
          <RoadByCategory />
        </FlexColumn>
      </FlexRow>
      <FlexRow gap={8} className="naxatw-mt-16 naxatw-px-4 ">
        {/* @ts-ignore */}
        <SidebarSection refs={sectionRefs} />

        <FlexColumn gap={20} className="naxatw-w-full">
          {Object.entries(sidebarSections).map(([id, { title, contents }]) => (
            <div
              key={id}
              ref={ref => {
                // @ts-ignore
                sectionRefs.current[id] = ref;
              }}
            >
              <FlexColumn gap={5} className="naxatw-w-full">
                <h3>{_t(title)}</h3>
                <Grid gap={5} className="naxatw-flex-wrap">
                  {contents.map(data => (
                    <ChartRenderer
                      key={data.id}
                      data={data}
                      filterParams={filterParams}
                    />
                  ))}
                </Grid>
              </FlexColumn>
            </div>
          ))}
        </FlexColumn>
      </FlexRow>
    </FlexColumn>
  );
}
