/* eslint-disable no-unused-vars */
import { ErrorComponentProps } from '@xmanscript/has-error-boundary/lib/@types';
import Icon from '../Icon';

const ErrorComponent = ({
  fileName,
  errorLocation,
  errorMessage,
}: ErrorComponentProps): JSX.Element => {
  return (
    <div
      id="error-component"
      className="naxatw-flex naxatw-h-full naxatw-w-full naxatw-items-center naxatw-justify-center naxatw-bg-blue-100"
    >
      <div>
        <div className="naxatw-flex naxatw-flex-row naxatw-items-center naxatw-gap-3">
          <Icon name="warning" className="naxatw-text-red-500" />
          <h4>Ahhh! , Something went wrong at</h4>
          <a
            href={errorLocation}
            target="_blank"
            rel="noopener noreferrer"
            className="naxatw-text-[1.1rem]"
            style={{ color: 'blue' }}
          >
            {fileName};
          </a>
        </div>
        <p>
          Brace yourselves till we get the error fixed. You may also try to
          refresh the page
        </p>
      </div>
    </div>
  );
};

export default ErrorComponent;
