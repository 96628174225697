import { useEffect, useMemo } from 'react';
import { IVectorLayer } from '@Components/common/MapLibreComponents/types';
import { startPointColor, endPointColor } from '@Constants/map';
import DirectionArrow from '../../navigation-image.png';

// extract first and last coordinates of linestring
function extractFirstAndLastCoordinates(geoJson: any) {
  if (!Array.isArray(geoJson.coordinates) || geoJson.coordinates.length === 0) {
    return null;
  }
  let firstCoordinates;
  let lastCoordinates;
  const { coordinates } = geoJson;
  if (geoJson.type === 'MultiLineString') {
    [[firstCoordinates]] = coordinates;
    [lastCoordinates] = coordinates.slice(-1)[0].slice(-1);
  }
  [firstCoordinates] = coordinates;
  lastCoordinates = coordinates[coordinates.length - 1];
  return {
    firstCoordinates,
    lastCoordinates,
  };
}

export default function RoadDirectionLayer({
  map,
  id,
  geojson,
  isMapLoaded,
  layerOptions,
  visibleOnMap = true,
}: IVectorLayer) {
  const sourceId = useMemo(() => id.toString(), [id]);

  useEffect(() => {
    if (!map || !isMapLoaded) return;
    if (map.getSource(sourceId)) {
      map?.removeLayer(sourceId);
      map?.removeSource(sourceId);
    }
    map.addSource(sourceId, {
      type: 'geojson',
      data: geojson,
    });
  }, [sourceId, isMapLoaded, map, geojson]);

  useEffect(() => {
    if (!map || !isMapLoaded) return;
    if (visibleOnMap) {
      map.addLayer({
        id: sourceId,
        type: 'line',
        source: sourceId,
        layout: {},
        ...layerOptions,
      });
    } else if (map.getLayer(sourceId)) {
      map.removeLayer(sourceId);
    }
  }, [map, isMapLoaded, visibleOnMap, sourceId, geojson]); // eslint-disable-line

  useEffect(
    () => () => {
      map?.removeLayer(sourceId);
      map?.removeSource(sourceId);
    },
    [map, sourceId],
  );

  // add line start and end point
  useEffect(() => {
    if (!map || !geojson || !isMapLoaded) return () => {};
    const coordinates = extractFirstAndLastCoordinates(geojson);
    if (!coordinates) return () => {};
    const { firstCoordinates, lastCoordinates } = coordinates;
    map.addSource('line-start-point', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: firstCoordinates,
        },
      },
    });
    map.addSource('line-end-point', {
      type: 'geojson',
      data: {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: lastCoordinates,
        },
      },
    });
    map.addLayer({
      id: 'line-start-point',
      type: 'circle',
      source: 'line-start-point',
      paint: {
        'circle-radius': 6,
        'circle-color': startPointColor,
      },
    });
    map.addLayer({
      id: 'line-end-point',
      type: 'circle',
      source: 'line-end-point',
      paint: {
        'circle-radius': 6,
        'circle-color': endPointColor,
      },
    });

    return () => {
      map.removeLayer('line-start-point');
      map.removeLayer('line-end-point');
      map.removeSource('line-start-point');
      map.removeSource('line-end-point');
    };
  }, [map, geojson, isMapLoaded]);

  // add direction arrow in line
  useEffect(() => {
    // @ts-ignore
    if (!map || !isMapLoaded || !geojson) return () => {};
    map.loadImage(DirectionArrow, (err, image) => {
      if (err) {
        return;
      }
      // @ts-ignore
      map.addImage('arrow', image);
      map.addLayer({
        id: 'arrowId',
        type: 'symbol',
        source: sourceId,
        layout: {
          'symbol-placement': 'line',
          'symbol-spacing': 100,
          'icon-allow-overlap': false,
          // 'icon-ignore-placement': true,
          'icon-image': 'arrow',
          'icon-size': 0.5,
          visibility: 'visible',
          'icon-rotate': 90,
        },
      });
    });
    return () => {
      if (map.getLayer('arrowId')) {
        map.removeImage('arrow');
        map.removeLayer('arrowId');
      }
    };
  }, [map, geojson, sourceId, isMapLoaded]);

  return null;
}
