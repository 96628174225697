/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import 'react-18-image-lightbox/style.css';
import Icon from '../Icon';

interface IFeatureImage {
  currentimage: number;
  images: string[];
  setShowImage: (value: boolean) => void;
}
export default function FeatureImage({
  currentimage,
  images,
  setShowImage,
}: IFeatureImage) {
  const [photoIndex, setPhotoIndex] = useState(currentimage);

  return (
    <div className=" naxatw-z-50 naxatw-bg-red-500">
      {images && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => {
            setShowImage(false);
          }}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
          toolbarButtons={[
            <div
              key="download"
              className="naxatw-flex naxatw-items-center naxatw-justify-end"
            >
              <Icon
                name="download"
                className=" naxatw-mb-3 naxatw-flex naxatw-items-center naxatw-justify-center"
              />
            </div>,
          ]}
        />
      )}
    </div>
  );
}
