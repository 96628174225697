/* eslint-disable no-unused-vars */
import { useEffect, useState } from 'react';
import { Feature, FeatureCollection } from 'geojson';
import { useMapLibreGLMap } from '@Components/common/MapLibreComponents';
import MapContainer from '@Components/common/MapLibreComponents/MapContainer';
import changeLayerOrder from '@Components/common/MapLibreComponents/helpers/changeLayerOrder';
import VectorTileLayer from '@Components/common/MapLibreComponents/Layers/VectorTileLayer';
import VectorLayer from '@Components/common/MapLibreComponents/Layers/VectorLayer';

const polygonFeature: FeatureCollection = {
  type: 'FeatureCollection',
  features: [
    {
      type: 'Feature',
      properties: {},
      geometry: {
        coordinates: [
          [
            [79.11485953828799, 31.370557963577937],
            [79.11485953828799, 25.45440329801177],
            [89.10026835216115, 25.45440329801177],
            [89.10026835216115, 31.370557963577937],
            [79.11485953828799, 31.370557963577937],
          ],
        ],
        type: 'Polygon',
      },
    },
  ],
};

const lineFeature: Feature = {
  type: 'Feature',
  properties: {},
  geometry: {
    coordinates: [
      [77.83233318062707, 29.911514126197545],
      [82.21339351651181, 27.94814608784253],
      [86.25105864439894, 27.42894495905628],
      [90.00394547584688, 26.975546679892233],
    ],
    type: 'LineString',
  },
};

const pointFeature: Feature = {
  type: 'Feature',
  properties: {},
  geometry: {
    coordinates: [86.04204687959566, 29.128772026501096],
    type: 'Point',
  },
};

const polygonStyles = {
  'fill-color': '#088',
  'fill-opacity': 0.7,
};

const lineStyles = {
  'line-color': '#ff0000',
  'line-width': 8,
};

const data = [
  {
    id: '1',
    geojson: lineFeature,
    layerOptions: {
      type: 'line',
      layout: {},
      paint: lineStyles,
    },
  },
  {
    id: '2',
    geojson: polygonFeature,
    layerOptions: {
      type: 'fill',
      layout: {},
      paint: polygonStyles,
    },
  },
  // {
  //   id: '3',
  //   geojson: pointFeature,
  //   layerOptions: {
  //     type: 'circle',
  //     paint: {
  //       'circle-radius': 10,
  //       'circle-color': '#00ff00',
  //     },
  //   },
  // },
];

const vtURL =
  'https://localews.naxa.com.np/api/v1/map/nepal/{z}/{x}/{y}/?municipality_code=328&ward=true';

export default function TestMap() {
  const { map, isMapLoaded } = useMapLibreGLMap({});
  const [mapState, setMapState] = useState(data);

  // useEffect(() => {
  //   if (!map) return;
  //   setTimeout(() => {
  //     setMapState((prev) => {
  //       const temp = [...prev];
  //       temp.reverse();
  //       const ids = temp.map((item) => item.id);
  //       changeLayerOrder(map, ids);
  //       return temp;
  //     });
  //   }, 5000);
  // }, [map]);

  return (
    <div id="maplibre-gl-map" style={{ width: '90vw', height: '93vh' }}>
      <MapContainer map={map} isMapLoaded={isMapLoaded}>
        <VectorTileLayer
          id="vt-layer1"
          url={vtURL}
          layerOptions={{
            paint: {
              'fill-color': '#088',
              'fill-opacity': 0.7,
            },
          }}
          visibleOnMap={false}
        />
        {mapState.map(({ id, geojson, layerOptions }) => (
          <VectorLayer
            key={id}
            id={id}
            geojson={geojson}
            layerOptions={layerOptions}
            visibleOnMap={false}
          />
        ))}
      </MapContainer>
    </div>
  );
}
