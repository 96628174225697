import Skeleton from '@Components/RadixComponents/Skeleton';
import { FlexColumn } from '@Components/common/Layouts';

export default function AttributeSkeleton() {
  return (
    <FlexColumn
      gap={5}
      className="naxatw-z-10 naxatw-h-screen naxatw-w-[25rem] naxatw-items-center naxatw-justify-center naxatw-border-r-2
      naxatw-px-4 naxatw-py-5 naxatw-shadow-[0_4px_11px_0_#00000040]"
    >
      {Array.from({ length: 8 }).map((__, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <FlexColumn key={index} gap={3} className="naxatw-h-fit naxatw-w-11/12">
          <Skeleton className="naxatw-h-5 naxatw-w-1/2" />
          <Skeleton className="naxatw-h-8 naxatw-w-full " />
        </FlexColumn>
      ))}
    </FlexColumn>
  );
}
